import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { eng } from './en';
import { esp } from './es';
import { se } from './se';
import { initReactI18next } from 'react-i18next';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
      lng: 'se-SE', //en-US
      resources: {
          'en-US': {
        translation: eng,
      },
          'se-SE': {
            translation: se,
        },
          'es-ES': {
        translation: esp,
      },
    },
  });

export default i18next;
