import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';

class RequestInvoiceRow{
    constructor({
                    name = '',
                    quantity = 1,
                    price = 0,
                    vatAmount = 0,
                } = {}) {
        this.name = name;
        this.quantity = quantity;
        this.price = price;
        this.vatAmount = vatAmount;
    }

    static getAttributes(filter = null, validationSchema = null ,/* validationSchema2 = null ,*/) {
        const greaterO = i18n.t('validationMessage.greater0');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            name: string().required(fieldRequired),
            quantity: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            price: string().required(fieldRequired).test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            vatAmount: string().nullable()/*.test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            })*/
        });
       /* const completeSchemaSend = yup.object().shape({
            name: string().required(fieldRequired),
            quantity: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            price: string().required(fieldRequired).test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            vatAmount: string().nullable()/!*.test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            })*!/
        });*/

        const attributes = {
            name :{
               // 'label': 'Product Name',
                'form': {
                    'type': 'text',
                    'placeholder':'Vara/tjänst',
                    'validation': completeSchema.fields.name,
                },
                'value': (item) => item.name || "",
            },
            quantity :{
               // 'label': 'Quantity',
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('invoice.enterQuantity'),
                    'validation': completeSchema.fields.quantity,
                },
                'value': (item) => item.quantity || "",
            },
            price :{
               // 'label': 'Price',
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterPrice'),
                    'validation': completeSchema.fields.price,
                },
                "name": "price",
                'value': (item) => item.price || "",
            },
            vatAmount :{
                //'label': i18n.t('modelLabel.vatAmount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterVatAmount'),
                    'validation': completeSchema.fields.vatAmount,
                },
                "name": "vatAmount",
                'value': (item) => item.vatAmount || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        /*if (validationSchema2 === true) {
            return completeSchemaSend;
        }*/

        return attributes;
    }
}
export default RequestInvoiceRow;