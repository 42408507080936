import {Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import React, {useState} from "react";
import SortableList from "../../Common/Components/Lists/SortableList";
import {useTranslation} from "react-i18next";
import ResponseInvoice from "../Models/Response/ResponseInvoice";
import useApiCallService from "../../Common/Helpers/ApiCall";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import $ from "jquery";
import {ADMIN_PATHS, API_URL, ORGANIZATION_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";

export function AdminInvoiceList() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();

    const [dropdownOpen, setDropdownOpen] = useState({});
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [idRow, serIdRow] = useState('');
    const navigate = useNavigate();


    const handleApiResponse  = (apiResponse) => {
        if (apiResponse !== null && apiResponse !== undefined) {
           CustomReload();
        }

    }
    const ChangeStatus = async (id) => {
        if (id !== '') {
            let json = {
                id: id,
                status: 'Betalad',
            };

            const apiCallOptions = {
                method: "put",
                url: "/invoice",
                data: json
            };
            try {
                await ApiCall(apiCallOptions, handleApiResponse);
            } catch (error) {
                console.log(error);
            }
        }

    };

    const handleOptionSelect = async (id) => {
        $("#acceptButton").prop("disabled", true);
        await ChangeStatus(id);
    };

    const toggle = (id) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const defaultExportFormatOptions = ["Betalad", "Krediteras", "Återkallad"];
    const options = defaultExportFormatOptions;
    
    const renderActionColumn = (item) => {
        return (
            <>
                {item.status !== "Attestera kö" && item.status !== "Förslag" && item.type === "Faktura" && !item.creditInvoiceReferenceId ?
                    <Button className="box-orange border-0 position-relative me-2 rounded" onClick={() => {
                        navigate(`${ADMIN_PATHS.ADMIN_INVOICE_CREDIT}?id=${item.id}`)

                    }}>
                        <i className='bx bxs-file-import align-middle pb-1 fs-5'/>
                        <div className="tooltip-custom-button">
                            {t("invoice.creditInvoice")}
                        </div>
                    </Button>
                : ''}
                {item.status === "Godkänd" ?
                    <Button className="bg-green-light text-green border-0 position-relative me-2 rounded" onClick={() => {
                        serIdRow(item.id)
                        //handleOptionSelect(item.id)
                        setDisplayDeleteModal(true);
                        //setInvoiceId(item.id);
                    }}>
                        <i className="bx bx-check fs-5 align-middle pb-1"/>
                        <div className="tooltip-custom-button">
                            {t("invoice.paid")}
                        </div>
                    </Button>
                : ''}
                {item.fileUrl !== "" ?
                    <a href={API_URL + item.fileUrl} className="btn bg-green-light text-green border-0 rounded position-relative">
                        <i className="bx bxs-file-pdf fs-5 align-middle pb-1"/>
                    </a>
                : ''}
            </>
        );
    }

    const modalContent = (
        <div>
            {t('validationMessage.changeStatus')}
        </div>
    );
    const modalFooter = (
        <>
            <Button id="acceptButton" className="box-green border-0" onClick={()=>{handleOptionSelect(idRow)}}>
                {t('button.accept')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );


    const ignoreFields = ["Id", "createdByName", "fileUrl"];
    const listOptions = {
        "model": ResponseInvoice,
        'columns': [
            'visualId', "invoiceNumber", 'clientName', "orgName", 'expiringDate', 'status', 'createdAt', 'reference','totalAmount',
        ],
        "actions": {
            "actionColumn": renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/invoice/admin/search",
        "nameFile": t('invoice.invoice'),
        "ignoreFields": ignoreFields,
        "urlGetSearch": "/invoice/admin/search",
    };

    return (
        <Col lg={12}>
            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                         body={modalContent} footer={modalFooter}/>
            <Row className="pb-5">
                <Col lg={12} className="p-4 pb-3 mt-4 card-backend">
                    <h2 className="fw-bold rokkitt-font">{t('invoice.invoiceList')}</h2>
                    <SortableList listOptions={listOptions} />
                </Col>
            </Row>
        </Col>
    )
}
