import {Button, Card, Col, Row, Spinner} from "reactstrap";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import React, {useEffect, useRef, useState} from "react";
import DragAndDrop from "../../Common/Components/Lists/DragAndDrop";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {useTranslation} from "react-i18next";
import RequestClient from "../Models/Request/RequestClient";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import RequestInvoice from "../Models/Request/RequestInvoice";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import RequestInvoiceRow from "../Models/Request/RequestInvoiceRow";
import AccountingAccountModel from "../../Accounting/Models/Response/ResponseAccountingAccountModel";
import $ from "jquery";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {ORGANIZATION_PATHS} from "../../config";
import {ClearEmptyJson} from "../../AppSystem/Helpers/ClearEmptyJson";
import {useNavigate} from "react-router-dom";
import {generateFileName} from "../../AppSystem/Helpers/GenerateFileName";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";
import { useSelector } from "react-redux";

export function InvoiceCreate() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();
    const {userData, orgData } = useSelector(state => state.auth);
    const [apiResponseOrgInfo, setApiResponseOrgInfo] = useState(null);
   // const [errorPrice, setErrorPrice] = useState(false);
   // const [indexRow, setIndexRow] = useState('');

    // REQUEST
    const [requestCreateNewClient, setRequestCreateNewClient] = useState(new RequestClient());
    const [requestCreateInvoice, setRequestCreateInvoice] = useState(new RequestInvoice());
    const [idClient, setIdClient] = useState(null);
    const [getClient, setGetClient] = useState(null);
    const [visualId, setVisualId] = useState(null);


    //RESPONSE
    const [responseCreateNewClient, setResponseCreateNewClient] = useState('');
    const [responseCreateInvoice, setResponseCreateInvoice] = useState(null);

    const [items, setItems] = useState([ {id: '1', content: new RequestInvoiceRow()}]);

    // MODAL OPTIONS
    const [displayAddNewClientModal, setDisplayAddNewClientModal] = useState(false);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);
    const [costSelected, setCostSelected] = useState(null);

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState(null);

    //CLIENT
    const [clientListResponse, setClientListResponse] = useState(null);

    const [productsTotalAmount, setProductsTotalAmount] = useState('');

    //VALIDATION SCHEMAS
    const completeSchemaClient = RequestClient.getAttributes( null, true);
    const completeSchemaInvoice = RequestInvoice.getAttributes( null, true);
    const completeSchemaRows = RequestInvoiceRow.getAttributes(null, true);
    //const completeSchemaRowsSend = RequestInvoiceRow.getAttributes( null, null, true);

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [displaySpinnerClient, setDisplaySpinnerClient] = useState(false);

    const [isSave, setIsSave] = useState(false);

    //CHECK MOUSE OVER
    const [isMouseOver, setIsMouseOver] = useState(false);

    //PDF
    const [previewPdf, setPreviewPdf] = useState(null);

    //REF
    const formDivRefClient = useRef(null);
    const formDivRefInvoice = useRef(null);

    const messageCostBearer = InputMessage(costResponse, requestCreateInvoice.costBearerId);

    let permissionCreate = false;
    if (CheckUserPermission("client.create") || CheckUserPermission("client")) {
        permissionCreate = true;
    }
    let permissionViewClient = false;
    if (CheckUserPermission("client.view") || CheckUserPermission("client")) {
        permissionViewClient = true;
    }
    let permissionCreateInvoice = false;
    if (CheckUserPermission("invoice.create")) {
        permissionCreateInvoice = true;
    }

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    useEffect(() => {

        const handleApiResponseGetOrgUser  = (apiResponseOrgInfo) => {
            setApiResponseOrgInfo(apiResponseOrgInfo);

        };
        const GetOrgUser = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/org/admin?id=${orgData.orgId}`,
                };

                await ApiCall(apiCallOptions, handleApiResponseGetOrgUser );

            } catch (error) {
                console.log('error', error);
            }
        };

        GetOrgUser();

    }, []);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list",
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);

    let costJson = {};
    if (costResponse !== null) {
        const costBearerArray = [];
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
        costJson = {
            costBearer: costBearerArray
        };
    }

    let accountingJson = {};
    if (accountingResponse !== null) {
        const accountingArray = [];
        accountingResponse.forEach((item) => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
        accountingJson = {
            accounting: accountingArray,
        };
    }

    // CLIENT SEARCH
    useEffect(() => {
        const handleClientListResponse = (response) => {
            if ( response !== null) {
                setClientListResponse(response.items);
            }
        };
        const ClientApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/client/search?perpage=1000",
                };
                await ApiCall(apiCallOptions, handleClientListResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        ClientApiCall();
    }, [/*responseCreateNewClient*/]);

    let clientJson = {};
    if (clientListResponse !== null) {
        const clientArray = [];
        clientListResponse?.forEach((item) => {
            clientArray.push(item);
        });
        clientJson = {
            client: clientArray
        };
    }

    // GET ID IF EXISTS FOR DRAFT MODE
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET INVOICE FOR DRAFT MODE
    useEffect(() => {
        if (id !== "") {
            const handleDraftResponse = (response) => {
                if (response !== null) {
                    setRequestCreateInvoice(new RequestInvoice(response));
                    setVisualId(response.visualId);
                    setIdClient(response.clientId);
                    if (response.rows && response.rows.length > 0) {
                        //setRequestCreateInvoiceRow(response.rows);
                        const newItems = response.rows.map((value, index) => ({
                            id: (index + 1).toString(),
                            content: value,
                        }));

                        setItems(newItems);
                    }
                }
            }
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/invoice?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDraftResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    //TOTAL VALUES AMOUNT
    useEffect(() => {
        if(items) {
            const amounts =  items.map((value) => {
                if(value.content.quantity === undefined) {
                    value.content.quantity = 1;
                }
                if(value.content.price === undefined) {
                    value.content.price = 0;
                }
                let productAmount = value.content.quantity * value.content.price;
                return productAmount !== 0 ? productAmount : null;
            }).filter(amount => amount !== null);

            const total= amounts.reduce((acc, curr) => acc + curr, 0);
            const totalVatAmount = items.reduce((acc, item) => {
                const vatAmount = item.content.vatAmount ? parseFloat(item.content.vatAmount) : 0;
                return acc + vatAmount;
            }, 0);

            const totalAmount = total + totalVatAmount;

            setProductsTotalAmount ((prevData) => ({
                ...prevData,
                amount: total,
                vatAmount: totalVatAmount,
                totalAmount: totalAmount,
            }))
        }
    }, [items]);

    const handleDragEnd = ({ reorderedItems  }) => {
        setItems(reorderedItems);
    };


    const HandleChangeInvoiceRow = (e, name, id) => {
        const index = items.findIndex(item => item.id === id);
        let productName = items[index].content.name ?? "";
        let quantity = items[index].content.quantity;
        let price = items[index].content.price;
        let vatAmount = items[index].content.vatAmount;
        let totalAmountProduct = null;

        if (name === "name") {
            productName = e.target.value;
        }
        if (name === "quantity") {
            if (e && e.target && e.target.value !== "") {
                quantity = CheckDecimal(e.target.value);
            } else {
                quantity = 1;
            }
            vatAmount = price * quantity * 0.25;
        }
        if (name === "price") {
            if (e && e.target && e.target.value !== "") {
                price = CheckDecimal(e.target.value);
               // setErrorPrice(false)
                /*let stringIndex = String(index + 1);
                setIndexRow(stringIndex);*/
            } else {
                price = 0;
                /*let stringIndex = String(index + 1);
                setIndexRow(stringIndex);*/

            }
            vatAmount = price * quantity * 0.25;
        }
        /*if (quantity !== 0 && price !== 0 ) {
            totalAmountProduct = quantity * price;
            /!*if (totalAmountProduct !== null) {
                setTotalInvoiceAmount((prevData) => [
                    ...prevData,
                    totalAmountProduct
                ]);
            }*!/

        }*/
        /*const updatedTotalAmountProduct = items.map(item =>
            item.id === id ? { totalAmountProduct } : ''
        );
        setTotalInvoiceAmount((prevData) => [
            ...prevData,
            updatedTotalAmountProduct
        ]);
*/
       let newContent = {
            name: productName,
            quantity: quantity,
            price: price,
            vatAmount: vatAmount
        };

        const updatedItems = items.map(item =>
            item.id === id ? { ...item, content: newContent } : item
        );
        setItems(updatedItems);

    }

    const HandleChangeClient = (e, name) => {
        let clientName = requestCreateNewClient.name;
        let orgNumber = requestCreateNewClient.orgNumber;
        let address = requestCreateNewClient.address;
        let postalCode = requestCreateNewClient.postalCode;
        let city = requestCreateNewClient.city;
        let email = requestCreateNewClient.email;

        if (name === "name") {
            clientName = e.target.value;
        }
        if (name === "orgNumber") {
              orgNumber = e.target.value;
        }
        if (name === "address") {
            address = e.target.value;
        }
        if (name === "postalCode") {
              postalCode = e.target.value;
        }
        if (name === "city") {
            city = e.target.value;
        }
        if (name === "email") {
            email = e.target.value;
        }

        setRequestCreateNewClient((prevFormData) => ({
            ...prevFormData,
            name: clientName,
            orgNumber: orgNumber,
            address: address,
            postalCode: postalCode,
            city: city,
            email: email,
        }));

    }

    const HandleChangeNewInvoice = (e, name) => {
        let clientId = requestCreateInvoice.clientId;
        let expiringDate = requestCreateInvoice.expiringDate;
        let recipientReference = requestCreateInvoice.recipientReference;
        //let reference = requestCreateInvoice.reference;
        let costBearerId = requestCreateInvoice.costBearerId;
        let status = requestCreateInvoice.status;
        let fileUrl = requestCreateInvoice.fileUrl;
        let rows = requestCreateInvoice.rows;

        if (name === "clientId") {
            clientId = e.value;
            setIdClient(e.value);
        }
        if (name === "expiringDate") {
            expiringDate = ChangeDateFormat('date', e);
        }
        if (name === "recipientReference") {
            recipientReference = e.target.value;
        }
        /*if (name === "reference") {
            reference = e.target.value;
        }*/
        if (name === "costBearerId") {

            costBearerId = e.target.value;
        }
        if (name === "fileUrl") {
            //VER COMO FUNCIONAR EL FILE URL
            fileUrl = e.target.value;
        }
        if (name === "rows") {
            //aqui hay que construir el array de las row
            rows = e.target.value;
        }

        setRequestCreateInvoice((prevFormData) => ({
            ...prevFormData,
            clientId: clientId,
            expiringDate: expiringDate,
            recipientReference: recipientReference,
            //reference: reference,
            fileUrl: fileUrl,
            rows: rows,
            costBearerId:costBearerId,
        }));

    }

    const addRow = () =>{
        setItems((prevItems) =>{
            // Find the current maximum id
            const maxId = prevItems.reduce((max, item) => {
                const itemId = parseInt(item.id, 10); // Convert the id to a number
                return itemId > max ? itemId : max;
            }, 0);

            // Generate the new incremented id
            const newId = (maxId + 1).toString().padStart(1, '0'); // Ensure that the id has at least one digit
            //Create the new item
            const newItem = { id: newId, content: new RequestInvoiceRow() };

            return [...prevItems, newItem];
        })
        /*setItems((prevItems) =>{
            const newId = (prevItems.length + 1).toString();
            const newItem = { id: newId , content: requestCreateInvoiceRow};
            return [...prevItems,newItem]
        })*/
    }
    const deleteItem = (id) => {
        const removeItems = items.filter(item => item.id !== id);
        setItems(removeItems);
    }

    const SaveDraft = async () => {
        //create the row array
        let rows = items.map(item => ClearEmptyJson(item.content));
        const cleanedRows = rows.filter(obj => Object.keys(obj).length > 0); //fiter empty objects

        if (cleanedRows.length === 0) {
            rows = null;
        } else {
            rows = cleanedRows;
        }

        requestCreateInvoice.rows = rows;
        requestCreateInvoice.status = 'Förslag'; //Draft

        setIsSave(true);
        const apiCallOptions = {
            method: "post",
            url: "/invoice",
            data: ClearEmptyJson(requestCreateInvoice),
        };
        try {
            await ApiCall(apiCallOptions, setResponseCreateInvoice);

        } catch (error) {
            console.log(error);
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    }

    useEffect(() => {
        if (responseCreateInvoice !== null) {
            switch (responseCreateInvoice.status) {
                case 'Förslag':
                    FlashMessage(t('validationMessage.invoiceSave'), 'flash-messageSuccess');
                    break;
                case 'Attestera kö':
                case 'Godkänd':
                    FlashMessage(t('validationMessage.invoiceSendToAttest'), 'flash-messageSuccess');
                    break;
            }
            setTimeout(()=> {
                navigate(ORGANIZATION_PATHS.INVOICE_LIST);
            },2000);
        }
    }, [responseCreateInvoice]);

    useEffect(() => {
        if (responseCreateNewClient !== '') {
            setTimeout(() => {
                setDisplaySpinnerClient(false);
                setDisplayAddNewClientModal(!displayAddNewClientModal);
                CustomReload(ORGANIZATION_PATHS.INVOICE_CREATE);
            },2000)
        }
    }, [responseCreateNewClient]);

   // let errorPrice = false;

   // const [flagSendAttest, setflagSendAttest] = useState(false);

    const SendAttest = async () => {
        //create the row array
        const rows = items.map(item => item.content);
        requestCreateInvoice.rows = rows;
        requestCreateInvoice.status = 'Attestera kö'; //AttestQueue
        try {
           // $("#sendAttest").addClass("disabled");
            await completeSchemaInvoice.validate(requestCreateInvoice, {abortEarly: false});
            let isError = false;
            requestCreateInvoice.rows.map(async (item) => {
                if (item.price === 0 || item.quantity === 0 || item.name === '') {
                    isError = true;
                }
                /*if (item.price === 0) {
                    setErrorPrice(true);
                } else {
                    setErrorPrice(false);
                }*/
                try {
                    await completeSchemaRows.validate(item, {abortEarly: false});
                } catch (error) {
                    console.log(error);
                }
            });
            if (requestCreateInvoice.id === ''){
                requestCreateInvoice.id = null;
            }
            if (requestCreateInvoice.visualId === ''){
                requestCreateInvoice.visualId = null;
            }
            if (requestCreateInvoice.fileUrl === ''){
                requestCreateInvoice.fileUrl = null;
            }
            if (isError === false &&  requestCreateInvoice.rows.length > 0) {
                setDisplaySpinner(true);
                const apiCallOptions = {
                    method: "post",
                    url: "/invoice",
                    data: requestCreateInvoice,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseCreateInvoice);
                } catch (error) {
                   // $("#sendAttest").removeClass("disabled");
                    setDisplaySpinner(false);
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            } /*else {
                $("#sendAttest").removeClass("disabled");
            }*/
        }catch (err) {
            console.log(err)
        }
    }

    // USEEFFECT TO DISBALE BUTTON
   /* useEffect(() => {
        if (requestCreateInvoice.clientId !== '' && requestCreateInvoice.costBearerId !== '' && requestCreateInvoice.expiringDate !== '' && requestCreateInvoice.recipientReference !== '' && requestCreateInvoice.reference !== '' /!*&& requestCreateInvoice.type !== ''*!/) {
            setTimeout(() => {
                $("#sendAttest").removeClass("disabled");
            }, 1000)
        }

        /!*if (requestCreateInvoice.rows) {

        }*!/
    }, [requestCreateInvoice]);*/

    //CREATE CLIENT
    const handleValidationClient = async () => {
        try {
            if (requestCreateNewClient.orgNumber === '') {
                requestCreateNewClient.orgNumber = null;
            }
            if (requestCreateNewClient.address === '') {
                requestCreateNewClient.address = null;
            }
            if (requestCreateNewClient.postalCode === '') {
                requestCreateNewClient.postalCode = null;
            }
            if (requestCreateNewClient.city === '') {
                requestCreateNewClient.city = null;
            }
            await completeSchemaClient.validate(requestCreateNewClient, { abortEarly: false });
            $('#submitClient').prop('disabled', true);
            if (requestCreateNewClient && requestCreateNewClient.name !== "" &&  requestCreateNewClient.email !== "") {
                setDisplaySpinnerClient(true);
                const apiCallOptions = {
                    method: "post",
                    url: "/client",
                    data: requestCreateNewClient,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseCreateNewClient);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    // CLIENT ID
    useEffect(() => {
        const getDataClient = async () => {
            try {
                if (requestCreateNewClient && idClient !== null) {
                    const apiCallOptions = {
                        method: "get",
                        url: `/client?id=${idClient}`,
                    };
                    try {
                        await ApiCall(apiCallOptions, setGetClient);
                    } catch (error) {
                        console.log(error);
                        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
        getDataClient();
    }, [idClient]);



const previewInvoice = async () => {
        if (id !== '') {
            const apiCallOptions = {
                method: "get",
                url: `/invoice/pdf?id=${id}`,
            };
            try {
                await ApiCall(apiCallOptions, setPreviewPdf);
            } catch (error) {
                console.log(error);
            }
        }

}

    useEffect(() => {
        if (previewPdf !== null) {
            const DownloadTemporalFile = async () => {
                const handleDownloadApiResponse  = (response) => {
                    let file;
                    if (response instanceof Blob) {
                        file = response;

                    } else {
                        file = new Blob([response]);
                    }
                    const newFileName = generateFileName('invoice', visualId);
                    let fileName = newFileName;
                    const urlToFile = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = urlToFile;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setPreviewPdf(null);
                }
                const apiCallOptions = {
                    method: "get",
                    url: previewPdf,
                    dataTypes: 'blob',
                    responseType: 'blob'
                };
                try {
                    await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response));
                } catch (error) {
                    console.log(error);
                }
            }
            DownloadTemporalFile()
        }
    }, [previewPdf]);


    //RENDER ROWS
    const RenderItem = (row) => {
       // if (row.id !== '0') {
            return(
                <>
                    <Row key={'dragAndDrop' + row.id}>
                        <Col md={12}>
                            <>
                                <Row>
                                    <Col lg={5} >
                                        <CustomInput model={RequestInvoiceRow} attribute='name' data={/*requestCreateInvoiceRow.name*/ row.content.name} customChange={(e) => HandleChangeInvoiceRow(e, 'name', row.id)}/>
                                    </Col>

                                    <Col lg={2} >
                                        <CustomInput model={RequestInvoiceRow} attribute='quantity' data={row.content.quantity} customChange={(e) => HandleChangeInvoiceRow(e, 'quantity', row.id)}/>
                                    </Col>
                                    <Col lg={2}>
                                        {/*<div className='position-relative'>*/}
                                        <CustomInput model={RequestInvoiceRow} attribute='price' data={row.content.price} customChange={(e) => HandleChangeInvoiceRow(e, 'price', row.id)} />
                                        {/*<div id={'#errorPrice'+row.id} className={`tooltip-custom2 ${row.content.price === 0 && errorPrice === true ? 'opacity-1':''}`}>{t('validationMessage.fieldRequired')}</div>*/}
                                        {/*</div>*/}

                                    </Col>
                                    <Col lg={2}>
                                        <div className="bg-grey-tab mt-2 rounded py-1 border px-3 text-end">
                                            {row.content.quantity * row.content.price} kr
                                        </div>
                                    </Col>
                                    <Col lg={1} className="d-flex align-items-center justify-content-center">
                                        <CustomButton icon="bx bx-trash-alt py-1" iconColor='#EB5757' hoverIconColor='white' tooltipText={t('button.delete')} customClasses="box-red" onClick={() => deleteItem(row.id)}/>
                                    </Col>
                                </Row>
                                <hr />
                            </>
                        </Col>
                    </Row>
                </>
            )
       // }


    }

    //MODAL CONTENT

    const modalContent = (
        <div ref={formDivRefClient}>
            <h4 className="rokkitt-font">{t('invoice.addClient')}</h4>
            <Row>
                <Col lg={10} className='mx-auto'>
                    <CustomInput model={RequestClient} attribute='name' data={requestCreateNewClient.name} customChange={(e) => HandleChangeClient(e, 'name')}/>
                    <CustomInput model={RequestClient} attribute='orgNumber' data={requestCreateNewClient.orgNumber} customChange={(e) => HandleChangeClient(e, 'orgNumber')}/>
                    <CustomInput model={RequestClient} attribute='address' data={requestCreateNewClient.address} customChange={(e) => HandleChangeClient(e, 'address')}/>
                    <CustomInput model={RequestClient} attribute='postalCode' data={requestCreateNewClient.postalCode} customChange={(e) => HandleChangeClient(e, 'postalCode')}/>
                    <CustomInput model={RequestClient} attribute='city' data={requestCreateNewClient.city} customChange={(e) => HandleChangeClient(e, 'city')}/>
                    <CustomInput model={RequestClient} attribute='email' data={requestCreateNewClient.email} customChange={(e) => HandleChangeClient(e, 'email')}/>
                    <div className="text-green d-flex align-items-center mb-3">
                         <i className='bx bx-info-circle fs-5 pe-2'/>* obligatoriskt fält
                    </div>
                </Col>
            </Row>
            {displaySpinnerClient === true ?
                <>
                    {t('validationMessage.savingClient')} <Spinner color='success'/>
                </>
                : ''}
        </div>
    );

    const modalFooter = (
        <>
            <CustomSubmitButton text={t('invoice.addClient')} className="box-green border-0" id="submitClient" formDivRef={formDivRefClient} customClick={() => {
                handleValidationClient();
               // setDisplaySpinnerClient(true);

            }}/>
            <Button className="box-red border-0" onClick={() => setDisplayAddNewClientModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
        {permissionCreateInvoice === true ? '' :
            <Col lg={12} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'mb-3 ms-3 backButton'}
                    onClick={() => {
                        navigate(ORGANIZATION_PATHS.INVOICE_LIST)
                    }}
                />
            </Col>
        }
            <Col lg={12} className="pb-5">
                <CustomModal isOpen={displayAddNewClientModal} toggle={() => setDisplayAddNewClientModal(!displayAddNewClientModal)}
                             body={modalContent} footer={modalFooter}/>
                <Card className="card-backend p-4">
                    <Row>
                        <Col xs='auto'>
                            <h2 className='rokkitt-font'>{t('invoice.createInvoice')}</h2>
                        </Col>
                        <InfoPageMessage message={'Test: message create Invoice'}/>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Row >
                                {
                                    permissionViewClient === true ?
                                        <Col lg={7} className="order-1 order-lg-0">
                                            {/*AQUI VA UN SELECT CON LOS NOMBRES DE LOS CLIENTES GUARDADOS EN LA BASE DE DATOS*/}
                                            <CustomInput model={RequestInvoice}  attribute='clientId' data={requestCreateInvoice.clientId} customChange={(e) => HandleChangeNewInvoice(e, 'clientId')} customOptions={clientJson}/>
                                        </Col>
                                        : <Col lg={7} className="box-red align-self-center py-3">
                                            <i className="bx bx-info-circle fs-4  text-red me-3 align-middle"/>
                                            {t('validationMessage.clientPermission')}
                                        </Col>
                                        
                                }                           
                                {permissionCreate === true ?
                                    <Col lg={4} className="d-flex justify-content-center order-0 order-lg-1 pt-2">
                                        <CustomButton customClasses="align-self-center p-2" icon={"bx bx-plus"} text={t('invoice.addClient')} onClick={()=>{
                                            setDisplayAddNewClientModal(true);
                                        }} />
                                    </Col>
                                    : ''}

                            </Row>               
                            {getClient !== null ?
                                    <div ref={formDivRefInvoice}>
                                        {costResponse !== null && getClient !== null ?
                                        <Row  className="border-bottom pb-4">                                     
                                            <Col lg={4}>
                                                <CustomInput model={RequestInvoice}  attribute='costBearerId' data={requestCreateInvoice.costBearerId} customChange={(e) => HandleChangeNewInvoice(e, 'costBearerId')} customOptions={costJson}/>
                                                {messageCostBearer}
                                            </Col>
                                                            
                                        </Row>
                                        : ''}
                                        <Row className="pt-4">
                                        <Col lg={3}>
                                                {getClient !== null && userData !== null ?
                                                    <>
                                                        <div className="fw-bold pb-2">Vår referens</div>
                                                        <div className="border rounded pt-3 px-3 bg-card-grey" style={{ minHeight: '130px' }}>
                                                            <ul className='list-unstyled pb-0'>
                                                                {apiResponseOrgInfo.name ?
                                                                    <li>
                                                                        {`${apiResponseOrgInfo.name} - ${apiResponseOrgInfo.type ? apiResponseOrgInfo.type : ''}`}
                                                                    </li>
                                                                    :''}
                                                                {userData.firstName ?
                                                                    <li>{`${userData.firstName} ${userData.lastName ? userData.lastName : ''}`}</li>
                                                                    :''}
                                                                {userData.phone ?
                                                                    <li>{`${userData.phone}`}</li>
                                                                    :''}
                                                                {userData.email ?
                                                                    <li>{`${userData.email}`}</li>
                                                                    :''}
                                                            </ul>
                                                        </div>
                                                    </>
                                                    : ''}

                                            </Col>
                                            <Col lg={3}>                               
                                                {getClient !== null ?
                                                    <>
                                                        <div className="fw-bold pb-2">{t('invoice.invoiceRecipient')}</div>
                                                        <div className="border rounded pt-3 px-3 bg-card-grey" style={{ minHeight: '130px' }}>
                                                            <ul className='list-unstyled pb-0'>
                                                                {getClient.orgNumber ?
                                                                    <li>
                                                                        {/* <span>{t('modelLabel.orgNumber')}: </span> */}
                                                                        {`${getClient.name} - ${getClient.orgNumber}`}
                                                                    </li>
                                                                    :''}
                                                                {getClient.address ?
                                                                    <li>{getClient.address}</li>
                                                                    :''}
                                                                {getClient.postalCode ?
                                                                    <li>{`${getClient.postalCode} ${getClient.city ? getClient.city : ''}`}</li>
                                                                    :''}
                                                                {/* {getClient.city ?
                                                                    <li>{getClient.city}</li>
                                                                    :''} */}
                                                                <li>{getClient.email}</li>
                                                            </ul>
                                                        </div>
                                                    </>
                                                    : ''}

                                            </Col>
                                        </Row>
                                        <Row className="pt-4">
                                            <Col lg={3}>
                                                <CustomInput model={RequestInvoice}  attribute='recipientReference' data={requestCreateInvoice.recipientReference} customChange={(e) => HandleChangeNewInvoice(e, 'recipientReference')}/>
                                            </Col>
                                            <Col lg={3}>
                                                <CustomInput model={RequestInvoice}  attribute='expiringDate' data={requestCreateInvoice.expiringDate} customChange={(e) => HandleChangeNewInvoice(e, 'expiringDate')}/>
                                            </Col>                                        
                                            {/*<Col lg={3}>
                                                <CustomInput model={RequestInvoice}  attribute='reference' data={requestCreateInvoice.reference} customChange={(e) => HandleChangeNewInvoice(e, 'reference')}/>
                                            </Col>*/}
                                        </Row>
                                        <Row  className="pt-4">
                                            <Col lg={12}>
                                                <h4>{t('invoice.invoiceRow')}</h4>
                                            </Col>
                                            <Col lg={12}>
                                                <Row className="bg-card-grey py-2 mb-2">
                                                    <Col lg={5} >
                                                        {t('modelLabel.description')}
                                                    </Col>
                                                    <Col lg={2} >
                                                        {t('invoice.quantity')}
                                                    </Col>
                                                    <Col lg={2} >
                                                        {t('invoice.unitPrice')}
                                                    </Col>
                                                    <Col lg={2} >
                                                        {t('modelLabel.total')}
                                                    </Col>
                                                </Row>
                                                <DragAndDrop items={items}  droppableId="droppable" onDragEnd={handleDragEnd}  renderItem={RenderItem} side="vertical"/>
                                            </Col>
                                            <Col lg={12}>
                                                <CustomButton text={t('invoice.addInvoiceRow')} icon={"bx bx-plus pe-1"} customClasses="bg-secondary" onClick={() => addRow()}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3} className="offset-9">
                                                <Row>
                                                    <Col lg={6}>
                                                        {t('modelLabel.amountInvoice')}
                                                    </Col>
                                                    <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                                                        {productsTotalAmount?.amount ? ToCustomDecimal(productsTotalAmount.amount) +' kr' : '0 kr'}
                                                    </Col>
                                                    <Col lg={6}>
                                                        {t('modelLabel.vatAmountInvoice')}
                                                    </Col>
                                                    <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                                                        {productsTotalAmount?.vatAmount ? ToCustomDecimal(productsTotalAmount.vatAmount) +' kr' : '0 kr'}
                                                    </Col>
                                                    <Col lg={6}>
                                                        {t('modelLabel.total')}
                                                    </Col>
                                                    <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                                                        {productsTotalAmount?.totalAmount ? ToCustomDecimal(productsTotalAmount.totalAmount) +' kr' : '0 kr'}
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={7} className="text-center">
                                                {displaySpinner === true ?
                                                    <>
                                                        {isSave === true ? t('validationMessage.savingInvoice') : t('validationMessage.sendingInvoice')} <Spinner color='success'/>
                                                    </>
                                                    : ''}
                                            </Col>
                                            <Col lg={5} className="pt-4">
                                                <div className="d-flex justify-content-end">
                                                    <div onMouseEnter={handleMouseEnter}
                                                         onMouseLeave={handleMouseLeave} className="me-2">
                                                        <Button  id="buttonPreview" disabled={id === '' ? true : false }
                                                                 onClick={() => {
                                                                     previewInvoice()
                                                                 }}
                                                                 className="btn-secondary border-0 position-relative">
                                                            {t('invoice.previewInvoice')}
                                                            <div className={`tooltip-grey-pdf  ${isMouseOver === true ? 'opacity-1' : ''}`}>{t('validationMessage.needSave')} </div>
                                                        </Button>
                                                    </div>

                                                    <Button className="border-0 btn-submit-kassan me-2" onClick ={()=>{
                                                        SaveDraft();
                                                        setDisplaySpinner(true);
                                                    }}>
                                                        <i className="bx bx-save pe-2 pt-1"/>
                                                        {t('invoice.draft')}
                                                    </Button>

                                                    <CustomSubmitButton text={t('button.sendToAttest')} icon="bx bx-send pe-2" id="sendAttest" customClass="btn-submit-kassan" formDivRef={formDivRefInvoice} customClick={()=>{
                                                        SendAttest();
                                                    }}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            }

                        </Col>
                    </Row>
                </Card>
            </Col>

        </>


    )
    
}