import {Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS, ORGANIZATION_PATHS} from "../../config";
import {AccountRowView} from "../Components/AccountRowView";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useNavigate} from "react-router-dom";
import ResponseAccountRowModel from "../Models/Response/ResponseAccountRowModel";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";

export function AdminAccountRowInfo() {
    const { ApiCall } = useApiCallService();
    const idValue = new URLSearchParams(window.location.search).get('id');
    const { t } = useTranslation();
    const navigate = useNavigate();

    //API RESPONSE
    const [apiResponseAccountRowAdmin, setApiResponseAccountRowAdmin] = useState(new ResponseAccountRowModel());

    useEffect(() => {
        const handleApiResponseAccountRow  = (apiResponseAccountRowAdmin) => {
            setApiResponseAccountRowAdmin(apiResponseAccountRowAdmin);
        };
        const GetAccountRow = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/digital-account/admin/account-row?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponseAccountRow );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetAccountRow();
    }, [idValue]);

    const BackUrl = LoadLocalStorage('account');

    return(
        <>
            <Row>
                <Col lg={1}>
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'ms-3 mb-3'}
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.DIGITAL_ACCOUNT_VIEW}?id=${BackUrl}`)

                        }}
                    />
                </Col>
            </Row>
            {apiResponseAccountRowAdmin !== null ?
             <AccountRowView data={apiResponseAccountRowAdmin}/>
               : ''}
        </>
    );
}