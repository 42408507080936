import { PUBLIC_PATHS } from '../../config'; //IS_AUTH_GUARD_ACTIVE

// const userHasRole = (routeRoles, userRole) => {
//   if (!userRole) return false;
//   return routeRoles.includes(userRole);
// };

const clearRoute = route => {
  const item = {};
  ['path', 'to', 'exact', 'component', 'redirect'].forEach(key => {
    if (route[key]) item[key] = route[key];
  });
  return item;
};
// const clearMenuItem = menuItem => {
//   const item = {};
//   ['path', 'label', 'icon', 'isExternal', 'subs', 'mega', 'megaParent'].forEach(key => {
//     if (menuItem[key]) item[key] = menuItem[key];
//   });
//   return item;
// };
// const clearFlattedMenuItem = menuItem => {
//   const item = {};
//   ['path', 'label', 'isExternal'].forEach(key => {
//     if (menuItem[key]) item[key] = menuItem[key];
//   });
//   return item;
// };

export const convertToRoutes = ({
  data = [],
  isLogin = false,
  userRole = null,
  // authGuardActive = IS_AUTH_GUARD_ACTIVE,
  unauthorizedPath = PUBLIC_PATHS.UNAUTHORIZED,
  statusPaymentPath = PUBLIC_PATHS.STATUSPAYMENT,
  statusDepositFundsPath = PUBLIC_PATHS.STATUSDEPOSITFUNDS,
  loginPath = PUBLIC_PATHS.LOGIN,
  invalidAccessPath = PUBLIC_PATHS.INVALID_ACCESS,
  noLayout = false,
}) => {
  let items = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = data.mainMenuItems;
  }

  const routes = [];
  return () => {
    const itemMapper = item => {
      const tempItem = { ...item };
      if (item.hideInRoute) return itemMapper({});

      if (item.subs) tempItem.exact = true;

      /* Authentication Guard */
      //   if (authGuardActive) {
      //     if (tempItem.roles) tempItem.protected = true;
      //     if (tempItem.publicOnly) {
      //       delete tempItem.roles;
      //       delete tempItem.protected;
      //     }
      //     if (tempItem.protected) {
      //       if (!isLogin) {
      //         tempItem.redirect = true;
      //         tempItem.to = {
      //           pathname: [loginPath, statusDepositFundsPath],
      //           state: { from: tempItem.path },
      //         };
      //       } else if (tempItem.roles) {
      //         if (!userHasRole(tempItem.roles, userRole)) {
      //           tempItem.redirect = true;
      //           tempItem.to = {
      //             pathname: unauthorizedPath,
      //             state: { from: tempItem.path },
      //           };
      //         }
      //       }
      //     } else if (tempItem.publicOnly && isLogin) {
      //       tempItem.redirect = true;
      //       tempItem.to = {
      //         pathname: invalidAccessPath,
      //         state: { from: tempItem.path },
      //       };
      //     }
      //   }

      if (Object.keys(tempItem) && Object.keys(tempItem)?.length > 0 && !item.isExternal) {
        if (item.noLayout && noLayout) {
          routes.push(clearRoute({ ...tempItem, exact: true }));
        }
        if (!noLayout && item.noLayout !== true) {
          routes.push(clearRoute(tempItem));
        }
      }

      if (item.subs) {
        return item.subs.map(sub => {
          const controlledSub = { ...sub, path: tempItem.path + sub.path };
          //   if (authGuardActive) {
          //     if (tempItem.protected) controlledSub.protected = true;
          //     if (tempItem.roles) {
          //       if (!sub.roles) controlledSub.roles = tempItem.roles;
          //       else {
          //         // Common roles..
          //         controlledSub.roles = tempItem.roles.filter(x => sub.roles.includes(x));

          //         if (controlledSub.roles && controlledSub.roles?.length === 0) {
          //           controlledSub.inaccessible = true;
          //           console.log(
          //             `This route(${controlledSub.path}) is inaccessible. Please check the roles you defined in the hierarchical structure.`,
          //             controlledSub,
          //           );
          //         }
          //       }
          //     } else if (tempItem.publicOnly) {
          //       controlledSub.publicOnly = true;
          //     }
          //     if (controlledSub.roles && controlledSub.roles?.length === 0) delete controlledSub.roles;

          //     if (!controlledSub.inaccessible) return itemMapper(controlledSub);
          //     return itemMapper({});
          //   }
          return itemMapper(controlledSub);
        });
      }
      return tempItem;
    };
    items.map(itemMapper);
    return routes;
  };
};


export const getRoutes = ({ data, isLogin, userRole }) =>
  convertToRoutes({
    data,
    isLogin,
    userRole,
    //authGuardActive: IS_AUTH_GUARD_ACTIVE,
    unauthorizedPath: PUBLIC_PATHS.UNAUTHORIZED,
    loginPath: PUBLIC_PATHS.LOGIN,
    invalidAccessPath: PUBLIC_PATHS.INVALID_ACCESS,
    noLayout: false,
  })();


