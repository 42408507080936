class ResponseReimbursementMileage {
    constructor({
                    id = '',
                    orgId = '',
                    userId = '',
                    date = '',
                    purpose = '',
                    costBearerId = '',
                    costBearerName = '',
                    from = '',
                    to = '',
                    km = 0,
                    amount = 0,
                    passengers = [],
                    totalAmount = 0,
                    status = '',
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.userId = userId;
        this.date = date;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.from = from;
        this.to = to;
        this.km = km;
        this.amount = amount;
        this.passengers = passengers;
        this.totalAmount = totalAmount;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const attributes = {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': "id",
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': "orgId",
                'value': (item) => item.orgId || "",
            },
            userId :{
                'label': 'User Id',
                'sortable': true,
                'sortField': "userId",
                'value': (item) => item.userId || "",
            },
            date :{
                'label': 'Date',
                'sortable': true,
                'sortField': "date",
                'value': (item) => item.date || "",
            },
            purpose :{
                'label': 'Purpose',
                'sortable': true,
                'sortField': "purpose",
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': 'Cost Bearer',
                'sortable': true,
                'sortField': "costBearerId",
                'value': (item) => item.costBearerId || "",
            },
            costBearerName :{
                'label': 'Cost Bearer Name',
                'sortable': true,
                'sortField': "costBearerName",
                'value': (item) => item.costBearerName || "",
            },
            from :{
                'label': 'From',
                'sortable': true,
                'sortField': "from",
                'value': (item) => item.from || "",
            },
            to :{
                'label': 'To',
                'sortable': true,
                'sortField': "to",
                'value': (item) => item.to || "",
            },
            km :{
                'label': 'Kilometers',
                'sortable': true,
                'sortField': "km",
                'value': (item) => item.km || "",
            },
            amount :{
                'label': 'Amount',
                'sortable': true,
                'sortField': "amount",
                'value': (item) => item.amount || "",
            },
            passengers :{
                'label': 'Passengers',
                'sortable': true,
                'sortField': "passengers",
                'value': (item) => item.passengers || "",
            },
            totalAmount :{
                'label': 'Total Amount',
                'sortable': true,
                'sortField': "totalAmount",
                'value': (item) => item.totalAmount || "",
            },
            status :{
                'label': "Status",
                'sortable': true,
                'sortField': "status",
                'value': (item) => item.status || "",
            },
        }

        return attributes;
    }
}

export default ResponseReimbursementMileage;