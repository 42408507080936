import {Button, Col, Row, Spinner} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import useApiCallService from "../../Common/Helpers/ApiCall";
import { useSelector } from "react-redux";
import { generateFileName } from "../../AppSystem/Helpers/GenerateFileName";

export function CardCreditInvoiceInfo({dataInvoice, type = null}) {
    const { ApiCall } = useApiCallService();
    const { t } = useTranslation();
    const [dataClient, setDataClient] = useState(null);
    const {userData, orgData } = useSelector(state => state.auth);
    const [apiResponseOrgInfo, setApiResponseOrgInfo] = useState(null);


    useEffect(() => {

        const handleApiResponseGetOrgUser  = (apiResponseOrgInfo) => {
            setApiResponseOrgInfo(apiResponseOrgInfo);

        };
        const GetOrgUser = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/org/admin?id=${orgData.orgId}`,
                };

                await ApiCall(apiCallOptions, handleApiResponseGetOrgUser );

            } catch (error) {
                console.log('error', error);
            }
        };

        GetOrgUser();

    }, []);


    const downloadFile = async (url, number) => {
        const handleDownloadApiResponse  = (response) => {
            let file;
            if (response instanceof Blob) {
                file = response;
            } else {
                file = new Blob([response]);
            }
            const newFileName = generateFileName('invoice', number);
            let fileName = newFileName;
            const urlToFile = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = urlToFile;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        const apiCallOptions = {
            method: "get",
            url: url,
            dataTypes: 'blob',
            responseType: 'blob'
        };
        try {
            await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response));
        } catch (error) {
            console.log(error);
        }
    }
    // CLIENT ID
    useEffect(() => {
        if (dataInvoice?.clientId) {
            const getDataClient = async () => {
                try {
                    // if (requestCreateNewClient && idClient !== null) {
                    const apiCallOptions = {
                        method: "get",
                        url: `/client?id=${dataInvoice.clientId}`,
                    };
                    try {
                        await ApiCall(apiCallOptions, setDataClient);
                    } catch (error) {
                        console.log(error);
                        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    }
                    //  }
                } catch (err) {
                    console.log(err)
                }
            }
            getDataClient();
        }

    }, [dataInvoice]);

    return (
        <>
            <Row className="pt-4">
                <Col lg={5} className="order-1 order-lg-0">
                    <div className="fw-bold pb-2">{t('invoice.clientName')}</div>
                    <div className="bg-card-grey border rounded p-2 mb-2">{dataInvoice?.clientName}</div>
                </Col>             
            </Row>
            <Row className="border-bottom pb-4">
            <Col lg={3}>
                    <div className="fw-bold pb-2">{t('reimbursement.costBearer')}</div>
                    <div className="bg-card-grey border rounded p-2">{dataInvoice?.costBearerName}</div>
            </Col>
            <Col lg={9} className="order-1 order-lg-0 d-flex align-items-end justify-content-end">
                {/* {dataInvoice.status !== "Attestera kö" ? */}
                 {type !== null && type === 'Attest' ?
                            <Button className="bg-green-light text-green border-0 position-relative" onClick={() => {
                                 downloadFile(dataInvoice.fileUrl ,dataInvoice.visualId)
                            }}>
                                <i className="bx bx-down-arrow-alt fs-5 align-middle pb-1" />
                                <div className="tooltip-custom-button-table">
                                   {t('modelLabel.download')}
                                </div>
                            </Button>
                        : ''}
                         {/* : ''} */}
                </Col>
            </Row>
            <Row className="pt-4">
                <Col lg={3}>
                    {dataClient !== null && userData !== null ?
                        <>
                            <div className="fw-bold pb-2">Vår referens</div>
                            <div className="border rounded pt-3 px-3 bg-card-grey" style={{ minHeight: '130px' }}>
                                <ul className='list-unstyled pb-0'>
                                    {apiResponseOrgInfo.name ?
                                        <li>
                                            {`${apiResponseOrgInfo.name} - ${apiResponseOrgInfo.type ? apiResponseOrgInfo.type : ''}`}
                                        </li>
                                        :''}
                                    {userData.firstName ?
                                        <li>{`${userData.firstName} ${userData.lastName ? userData.lastName : ''}`}</li>
                                        :''}
                                    {userData.phone ?
                                        <li>{`${userData.phone}`}</li>
                                        :''}
                                    {userData.email ?
                                        <li>{`${userData.email}`}</li>
                                        :''}
                                </ul>
                            </div>
                        </>
                        : ''}

                </Col>
                <Col lg={3}>
                    {dataClient !== null ?
                        <>
                        <div className="fw-bold pb-2">{t('invoice.invoiceRecipient')}</div>
                        <div className="border rounded pt-3 px-3 bg-card-grey" style={{ minHeight: '130px' }}>
                            <ul className='list-unstyled pb-0'>
                                {dataClient.orgNumber ?
                                    <li>
                                        {/* <span>{t('modelLabel.orgNumber')}: </span> */}
                                        {`${dataClient.name} - ${dataClient.orgNumber}`}
                                    </li>
                                    :''}
                                {dataClient.address ?
                                    <li>{dataClient.address}</li>
                                    :''}
                                {dataClient.postalCode ?
                                    <li>{`${dataClient.postalCode} ${dataClient.city ? dataClient.city : ''}`}</li>
                                    :''}
                                {/* {getClient.city ?
                                    <li>{getClient.city}</li>
                                    :''} */}
                                <li>{dataClient.email}</li>
                            </ul>
                        </div>
                    </>
                        :''}
                </Col>
            </Row>
            <Row className="pt-4">
            <Col lg={3}>
                    <div className="fw-bold pb-2">{t('invoice.recipientRef')}</div>
                    <div className="bg-card-grey border rounded p-2">{dataInvoice?.recipientReference}</div>
                </Col>
                <Col lg={3}>
                    <div className="fw-bold pb-2">{t('invoice.date')}</div>
                    <div className="bg-card-grey border rounded p-2">{ChangeDateFormat('date',dataInvoice?.expiringDate)}</div>
                </Col>      
                {/* <Col lg={3}>
                    <div className="fw-bold pb-2">{t('invoice.reference')}</div>
                    <div className="bg-card-grey border rounded p-2">{dataInvoice?.reference}</div>
                </Col>                */}
            </Row>
            <Row  className="pt-4">
                <Col lg={12}>
                    <h4>{t('invoice.invoiceRow')}</h4>
                </Col>
                <Col lg={12}>
                    <Row className="bg-card-grey py-2 mb-2">
                        <Col lg={5} >
                            {t('modelLabel.description')}
                        </Col>
                        <Col lg={2} >
                            {t('invoice.quantity')}
                        </Col>
                        <Col lg={2} >
                            {t('invoice.unitPrice')}
                        </Col>
                        <Col lg={3} >
                            {t('modelLabel.total')}
                        </Col>
                    </Row>
                    {dataInvoice?.rows.map((item, index) => (
                        <Row key={'rowiCreditInvoice' + index}>
                            <Col md={12}>
                                <>
                                    <Row>
                                        <Col lg={5} >
                                            <div className="bg-card-grey border rounded p-2">{item.name}</div>
                                        </Col>

                                        <Col lg={2} >
                                            <div className="bg-card-grey border rounded p-2">{item.quantity}</div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="bg-card-grey border rounded p-2 text-end"> {type !== null && type === 'Attest'? '' : '-' } {item.price} kr</div>

                                        </Col>
                                        <Col lg={3}>
                                            <div className="bg-card-grey border rounded p-2 text-end">{type !== null && type === 'Attest'? '' : '-' }  {item.quantity * item.price} kr</div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            </Col>
                        </Row>
                    ))}

                </Col>
            </Row>
            <Row>
                <Col lg={3} className="offset-9">
                    <Row>
                        <Col lg={6}>
                            {t('modelLabel.amountInvoice')}
                        </Col>
                        <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                            {type !== null && type === 'Attest'? '' : '-' }  {dataInvoice?.amount} kr
                            {/*{productsTotalAmount?.amount ? ToCustomDecimal(productsTotalAmount.amount) +' kr' : '0 kr'}*/}
                        </Col>
                        <Col lg={6}>
                            {t('modelLabel.vatAmountInvoice')}
                        </Col>
                        <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                            {type !== null && type === 'Attest'? '' : '-' }  {dataInvoice?.vatAmount} kr
                            {/*{productsTotalAmount?.vatAmount ? ToCustomDecimal(productsTotalAmount.vatAmount) +' kr' : '0 kr'}*/}
                        </Col>
                        <Col lg={6}>
                            {t('modelLabel.total')}
                        </Col>
                        <Col lg={6} className="bg-grey-tab mb-2 rounded py-1 text-end">
                            {type !== null && type === 'Attest'? '' : '-' }  {dataInvoice?.totalAmount} kr
                            {/*{productsTotalAmount?.totalAmount ? ToCustomDecimal(productsTotalAmount.totalAmount) +' kr' : '0 kr'}*/}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )

}