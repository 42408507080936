import {Col} from "reactstrap";
import {useState} from "react";

export function InfoPageMessage({message}) {
    const [hidden, setHidden] = useState(true);
    return(
        <Col xs={hidden ? 12 : ''}>
            <div className={`${hidden ? 'border border-warning rounded bg-orange-light d-flex align-items-center cursor-pointer-custom' : 'text-end'}  p-2 mb-2`} onClick={()=>setHidden(!hidden)}>
                <i className={`bx bx-info-circle fs-4  ${hidden ? 'text-warning' : 'cursor-pointer-custom text-green'}`}></i>
                {hidden === true ?
                    /* This: style={{"white-space": "pre-line"}}, is for can use break line (\n) in message variable */
                    <span className='ps-3 text-warning text-start' style={{"white-space": "pre-line"}}>
                        {message}
                    </span>
                : ''}
            </div>
        </Col>
    );
}