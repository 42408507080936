
class AdminResponseCreateBasketModel {
    constructor({
                    basketId = '',
                    openPaymentId = '',
                    transactionStatus = '',
                } = {}) {
        this.basketId = basketId;
        this.openPaymentId = openPaymentId;
        this.transactionStatus = transactionStatus;

    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            basketId :{
                //'label': 'basket Id',
                'sortable': true,
                'sortField': 'basketId',
                'value': (item) => item.basketId || "",
            },
            openPaymentId :{
                //'label': 'open Payment Id',
                'sortable': true,
                'sortField': 'openPaymentId',
                'value': (item) => item.openPaymentId || "",
            },
            transactionStatus :{
                //'label': 'transaction Status',
                'sortable': true,
                'sortField': 'transactionStatus',
                'value': (item) => item.transactionStatus || "",
            },

        }
        return attributes;
    }
}
export default AdminResponseCreateBasketModel;