import {Card, Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function CustomReimbursementFixedView({data, editMode, onClickDelete, onClickEdit}) {
    const { t } = useTranslation();

    return(
        <>
            <Card className="bg-card-grey p-3">
                <Row>
                    <Col lg={12}>
                        <Row className="d-flex align-items-center">
                            <Col xxl={data?.status !== 'Förslag' ||  editMode === true ? 12 : 9} className={`order-1 order-xxl-0 ${data?.status !== 'Förslag' || editMode === true ? 'pe-3 ' : 'pe-0 '}`}>
                                {data?.type === 'Fixed' ?
                                    <>
                                        <Row >
                                            <Col lg={6}  className="">
                                                {t('reimbursement.fixed')}
                                            </Col>
                                            <Col lg={6} className="text-end">
                                                {data?.amount ? ToCustomDecimal(data?.amount) : 0} kr
                                            </Col>
                                        </Row>
                                        {/*Previous design just in case, delete when the new one is approved*/}
                                        {/*<Row className="pb-2">*/}
                                        {/*    <Col lg={5}>*/}
                                        {/*        <h6>{t('modelLabel.type')}:</h6>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                        {/*        {t('reimbursement.fixed')}*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Col lg={5}>*/}
                                        {/*        <h6>{t('modelLabel.amount')}:</h6>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                        {/*        {data.amount} {t('modelLabel.kr')}*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}

                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col lg={6}>
                                                {t('reimbursement.movable')}
                                            </Col>
                                            <Col lg={3} className=" d-flex align-items-center ps-3 ps-lg-4 justify-content-start justify-content-lg-end">
                                                {data?.hours ? ToCustomDecimal(data?.hours) : 0} h
                                            </Col>
                                            <Col lg={3} className=" d-flex align-items-center justify-content-start justify-content-lg-end">
                                                {data?.amount ? ToCustomDecimal(data?.amount) : 0} kr
                                            </Col>
                                        </Row>
                                        {/*Previous design just in case, delete when the new one is approved*/}
                                        {/*<Row className="pb-2">*/}
                                        {/*    <Col lg={5}>*/}
                                        {/*        <h6>{t('modelLabel.type')}:</h6>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                        {/*        {t('reimbursement.hourly')}*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row className="pb-2">*/}
                                        {/*    <Col lg={5}>*/}
                                        {/*        <h6>{t('modelLabel.hours')}:</h6>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                        {/*        {data.hours} {t('modelLabel.hours').toLowerCase()}*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Col lg={5}>*/}
                                        {/*        <h6>{t('modelLabel.amount')}:</h6>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                        {/*        {data.amount} {t('modelLabel.kr')}*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </>
                                }
                            </Col>
                            {data?.status !== 'Förslag' ?
                               ''
                                :
                                    editMode !== true ?
                                        <Col xxl={3} className="d-flex align-items-center justify-content-end order-0 order-xxl-1">
                                            <CustomButton text="" icon='bx bx-trash-alt py-1' iconColor='#EB5757'
                                                          buttonColor='#e9cbcd'
                                                          buttonRadius={3} hoverButtonRadius={3}
                                                          hoverIconColor='white' hoverButtonColor='#EB5757'
                                                          customClasses="border-0"
                                                          tooltipText={t('button.delete')}
                                                          onClick={onClickDelete}
                                            />
                                            <CustomButton text="" icon='bx bx-edit  py-1' iconColor='#157553'
                                                          buttonColor='#bed1cd'
                                                          buttonRadius={3} hoverButtonRadius={3}
                                                          hoverIconColor='white' hoverButtonColor='#157553'
                                                          customClasses="ms-3 border-0"
                                                          tooltipText={t('button.edit')}
                                                          onClick={onClickEdit}/>

                                        </Col>
                                        : ''

                            }

                        </Row>
                    </Col>
                </Row>
            </Card>

        </>
    );
}