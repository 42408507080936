import * as yup from "yup";
import {array, bool, string} from "yup";
import i18n from 'i18next';

class RequestQRCode{
    constructor({
                    id= '',
                    title = '',
                    name = '',
                    description = '',
                    costBearerId = '',
                    defaultAmounts = [],
                    thereIsComment = false,
                    thereIsCustomAmount = false,
                } = {}) {
        this.id = id;
        this.title = title;
        this.name = name;
        this.description = description;
        this.costBearerId = costBearerId;
        this.defaultAmounts = defaultAmounts;
        this.thereIsComment = thereIsComment;
        this.thereIsCustomAmount = thereIsCustomAmount;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const greaterO = i18n.t('validationMessage.greater0');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            title: string().required(fieldRequired),
            name: string().nullable(),
            description: string().required(fieldRequired),
            costBearerId: string().test("valid-option", `${i18n.t('validationMessage.validOption')}`, value => {
                return value !== "";
            }),
            defaultAmounts:array().nullable(),
            thereIsComment: bool().nullable(),
            thereIsCustomAmount: bool().nullable(),
        });

        let optionsCost = [];
        if (optionsData && optionsData.costBearer) {
            optionsData.costBearer.map(item => {
                optionsCost.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            title :{
                'label': i18n.t('QRCode.title'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterTitle'),
                    'validation': completeSchema.fields.title,
                },
                'value': (item) => item.title || "",
            },
            name :{
                'label': i18n.t('QRCode.name'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterQRName'),
                    'validation': completeSchema.fields.name,
                },
                'value': (item) => item.name || "",
            },
            description :{
                'label': i18n.t('modelLabel.description'),
                'form': {
                    'type': 'textarea',
                    'rows': 5,
                    'placeholder': i18n.t('formPlaceholder.enterDescription'),
                    'validation': completeSchema.fields.description,
                },
                'value': (item) => item.description || "",
            },
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options':optionsCost,
                    //'colSizeInput': 6,
                    'default': '',
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            defaultAmounts :{
                //'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'input',
                    //'options':optionsCost,
                    //'colSizeInput': 6,
                    'default': '',
                    'validation': completeSchema.fields.defaultAmounts,
                },
                'value': (item) => item.defaultAmounts || "",
            },
            thereIsComment :{
                'label': i18n.t('QRCode.thereIsComment'),
                'form': {
                    'type': 'checkbox',
                    //'placeholder': i18n.t('formPlaceholder.enterEmail'),
                    //'validation': completeSchema.fields.thereIsComment,
                },
                'value': (item) => item.thereIsComment || "",
            },
            thereIsCustomAmount :{
               // 'label': i18n.t('QRCode.addCustomAmount'),
                'form': {
                    'type': 'checkbox',
                    //'placeholder': i18n.t('formPlaceholder.enterEmail'),
                    //'validation': completeSchema.fields.thereIsComment,
                },
                'value': (item) => item.thereIsCustomAmount || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestQRCode;