import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ORGANIZATION_PATHS} from "../../config";
import {Card, Col, Input, Label, Row, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestQRCode from "../Models/Request/RequestQRCode";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {TagsInput} from "react-tag-input-component";
import {isArray} from "lodash";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";

export function QRCodeCreate() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();

    //REQUEST
    const [requestCreateQRCode, setRequestCreateQRCode] = useState(new RequestQRCode());

    //RESPONSE
    const [responseCreateQRCode, setResponseCreateQRCode] = useState(null);

    //VALIDATION SCHEMAS
    const completeSchemaQRCode = RequestQRCode.getAttributes( null, true);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState(null);

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const [tagValue ,setTagValue] = useState([]);

    const messageCostBearer = InputMessage(costResponse, requestCreateQRCode.costBearerId);

    let permissionCreate = false;
    if (CheckUserPermission("org-qr.create")) {
        permissionCreate = true;
    }

    // API CALL TO GET COST BEARER
    useEffect(() => {
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list",
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    /*useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);*/

    let costJson = {};
    if (costResponse !== null) {
        const costBearerArray = [];
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
        costJson = {
            costBearer: costBearerArray
        };
    }

    /*let accountingJson = {};
    if (accountingResponse !== null) {
        const accountingArray = [];
        accountingResponse.forEach((item) => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
        accountingJson = {
            accounting: accountingArray,
        };
    }*/


    // GET ID IF EXISTS FOR DRAFT MODE
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET QR-INFO FOR DRAFT MODE
    useEffect(() => {
        if (id !== "") {
            const handleDraftResponse = (response) => {
                if (response !== null) {
                    setRequestCreateQRCode(new RequestQRCode(response));
                }
            }
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/orgqr?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDraftResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (isArray(requestCreateQRCode?.defaultAmounts) && requestCreateQRCode?.defaultAmounts.length > 0) {
            setTagValue(requestCreateQRCode.defaultAmounts);
            setValidationCheckAmount(false);
        }

    }, [requestCreateQRCode.defaultAmounts]);

    useEffect(() => {
        if (requestCreateQRCode.thereIsCustomAmount === true) {
            setValidationCheckAmount(false);
        }
    }, [requestCreateQRCode.thereIsCustomAmount]);

    const HandleChangeQR = (e, name) => {
        let title = requestCreateQRCode.title;
        let nameValue = requestCreateQRCode.name;
        let description = requestCreateQRCode.description;
        let thereIsComment = requestCreateQRCode.thereIsComment;
        let thereIsCustomAmount = requestCreateQRCode.thereIsCustomAmount;
       // let accountingId = requestCreateQRCode.accountingId;
        let costBearerId = requestCreateQRCode.costBearerId;
        let defaultAmounts = requestCreateQRCode.defaultAmounts;


        if (name === "title") {
            title = e.target.value;
        }
        if (name === "name") {
            nameValue = e.target.value;
        }
        if (name === "description") {
            description = e.target.value;
        }
        if (name === "thereIsComment") {
            thereIsComment = !requestCreateQRCode.thereIsComment;
        }
        if (name === "thereIsCustomAmount") {
            thereIsCustomAmount = !requestCreateQRCode.thereIsCustomAmount;
        }

        if (name === "defaultAmounts") {
            if (isArray(e) && e.length > 0) {
                const numericNumbers = e.map(numStr => {
                    if (numStr) {
                        return parseFloat(numStr.toString().replace(',', '.'));
                    }
                });
                defaultAmounts = [...numericNumbers];
            } else {
                defaultAmounts = [];
            }
        }
        if (name === "costBearerId") {
            costBearerId = e.target.value;
        }

        setRequestCreateQRCode((prevData) => ({
            ...prevData,
            title: title,
            name: nameValue,
            description: description,
            thereIsComment: thereIsComment,
            thereIsCustomAmount:thereIsCustomAmount,
           // accountingId: accountingId,
            costBearerId: costBearerId,
            defaultAmounts: defaultAmounts,
        }));

    }
// validation number for tags
    const [error, setError] = useState('');
    const validateTag = (tag) => {
        //const isValid = /^\d+(?:[.,]\d{2})?$/.test(tag);
        const isValid = /^\d+(?:,\d{2})?$/.test(tag);
        const containsLetters = /[a-zA-Z]/.test(tag);

        if (containsLetters) {
            setError(t('validationMessage.dontLetter'));
        } else if (!isValid) {
            setError(t('validationMessage.onlyNumbers'));
        } else {
            setError('');
        }

        return isValid;

    };

    const [validationCheckAmount , setValidationCheckAmount] = useState(false);

    // GENERATE QR
    const GenerateQR = async () => {


         if (requestCreateQRCode.thereIsComment === false) {
             requestCreateQRCode.thereIsComment = null;
         }
        if (requestCreateQRCode.id === ''){
            requestCreateQRCode.id = null;
        }
        /*if (requestCreateQRCode.thereIsCustomAmount === false) {
            requestCreateQRCode.thereIsCustomAmount = null;
        }*/
        if ((requestCreateQRCode?.defaultAmounts?.length === 0 || requestCreateQRCode?.defaultAmounts === null) && requestCreateQRCode.thereIsCustomAmount === true) {
            requestCreateQRCode.thereIsCustomAmount = true;
            requestCreateQRCode.defaultAmounts = null;
            setValidationCheckAmount(false);
        } else {
            setValidationCheckAmount(true);
        }
        if (requestCreateQRCode?.defaultAmounts?.length > 0 && (requestCreateQRCode.thereIsCustomAmount === false || requestCreateQRCode.thereIsCustomAmount === null)) {
            requestCreateQRCode.thereIsCustomAmount = null;
            setValidationCheckAmount(false);
        }
        if (requestCreateQRCode?.defaultAmounts?.length > 0 && (requestCreateQRCode.thereIsCustomAmount === true || requestCreateQRCode.thereIsCustomAmount === null)) {
            setValidationCheckAmount(false);
        }

        /*if (requestCreateQRCode?.defaultAmounts?.length === 0){
            requestCreateQRCode.defaultAmounts = null;
        }*/

        if ((requestCreateQRCode?.defaultAmounts === null && requestCreateQRCode.thereIsCustomAmount === true) ||
            (requestCreateQRCode?.defaultAmounts?.length > 0 && requestCreateQRCode.thereIsCustomAmount === null) ||
            (requestCreateQRCode?.defaultAmounts?.length > 0 && requestCreateQRCode.thereIsCustomAmount === true)) {
            try {
                await completeSchemaQRCode.validate(requestCreateQRCode, {abortEarly: false});
                const apiCallOptions = {
                    method: "post",
                    url: "/orgqr",
                    data: requestCreateQRCode,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseCreateQRCode);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }catch (err) {
                console.log(err)
            }
        }



    }

    useEffect(() => {
       /* if(responseCreateQRCode !== null && responseCreateInvoice.status === 'Förslag') {
            FlashMessage(t('validationMessage.invoiceSave'), 'flash-messageSuccess');
            setTimeout(()=>{
                setDisplaySpinner(false);
                setResponseCreateInvoice(null);
                setRequestCreateInvoice((prevFormData) => ({
                    ...prevFormData,
                    id: responseCreateInvoice.id,
                }));
            },2000);
        }*/
        if(responseCreateQRCode !== null ) {
            setDisplaySpinner(true);
            FlashMessage(t('validationMessage.QRCode'), 'flash-messageSuccess');
            setTimeout(()=>{
                navigate(`${ORGANIZATION_PATHS.QR_CODE_INFO}?id=${responseCreateQRCode.id}`)
            },2000);
        }
    }, [responseCreateQRCode]);

    return(
        <>
            {permissionCreate === true ? '' :
                <Col lg={12} className="px-0">
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'mb-3 ms-3 backButton'}
                        onClick={() => {
                            navigate(ORGANIZATION_PATHS.QR_CODE_LIST)
                        }}
                    />
                </Col>
            }

            <Col lg={12} className="pb-5">
                <Card className="card-backend p-4">
                    <Row>
                        <Col xs='auto'>
                            <h2 className='rokkitt-font'>{t('QRCode.create')}</h2>
                        </Col>
                        <InfoPageMessage message={'Test: message create QR-code'}/>
                    </Row>
                    <Row>
                        <CustomInput model={RequestQRCode} attribute='title' data={requestCreateQRCode.title} customChange={(e) => HandleChangeQR(e, 'title')}/>

                        <CustomInput model={RequestQRCode} attribute='name' data={requestCreateQRCode.name} customChange={(e) => HandleChangeQR(e, 'name')} />
                        <CustomInput model={RequestQRCode} attribute='description' data={requestCreateQRCode.description} customChange={(e) => HandleChangeQR(e, 'description')} />

                        {/*<CustomInput model={RequestQRCode} attribute='thereIsComment' data={requestCreateQRCode.thereIsComment} customChange={(e) => HandleChangeQR(e, 'thereIsComment')}/>*/}
                       <Label>
                            <Input type="checkbox" name="thereIsComment" value={requestCreateQRCode.thereIsComment} checked={requestCreateQRCode.thereIsComment} onChange={(e) => HandleChangeQR(e, 'thereIsComment')} />
                            <span className="ms-2">{t('QRCode.thereIsComment')}</span>
                        </Label>
                       {/* <CustomInput model={RequestQRCode} attribute='thereIsCustomAmount' data={requestCreateQRCode.thereIsCustomAmount} customChange={(e) => HandleChangeQR(e, 'thereIsCustomAmount')} />*/}
                        <Label>
                            <Input type="checkbox" name="thereIsCustomAmount" value={requestCreateQRCode.thereIsCustomAmount} checked={requestCreateQRCode.thereIsCustomAmount} onChange={(e) => HandleChangeQR(e, 'thereIsCustomAmount')} />
                            <span className="ms-2">{t('QRCode.addCustomAmount')}</span>
                        </Label>
                        <Col lg={12} className="position-relative">
                            <Label>{t('QRCode.defaultAmounts')}:</Label>
                            <TagsInput
                                //key={key}
                                value={tagValue}
                                onChange={(e)=>{
                                    HandleChangeQR(e, 'defaultAmounts');

                                }}
                                //separators="',','Enter'"
                                classNames={{input:"p-0"}}
                                beforeAddValidate={validateTag}
                                name="defaultAmounts"
                                //placeHolder="Enter permissions"
                            />
                             <div className={`tooltip-custom-normal ${error !== '' ? 'opacity-1' : ''}`}>{error}</div>
                             <div className={`tooltip-custom-normal ${validationCheckAmount === true && error === '' ? 'opacity-1' : ''}`}>{t('validationMessage.selectAmountType')}</div>
                        </Col>


                       {/* <CustomInput model={RequestQRCode} attribute='accountingId' data={requestCreateQRCode.accountingId} customChange={(e) => HandleChangeQR(e, 'accountingId')} customOptions={accountingJson}/>*/}
                        {costResponse !== null ?
                            <>
                                <CustomInput model={RequestQRCode} attribute='costBearerId' data={requestCreateQRCode.costBearerId} customChange={(e) => HandleChangeQR(e, 'costBearerId')} customOptions={costJson}/>
                                {messageCostBearer}
                            </>
                            : ''}


                        {displaySpinner === true ?
                            <Col lg={12} className="text-center mt-3">
                                {t('validationMessage.sendingQr')} <Spinner color='success'/>
                            </Col>
                            : ''}

                        <Col lg={12}  className="mt-3">
                            <CustomSubmitButton text={t('QRCode.generate')} customClass="w-100" customClick={() =>{
                                GenerateQR()
                            }}/>
                        </Col>

                    </Row>
                </Card>
            </Col>
        </>

    )
}