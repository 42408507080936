import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { eventHandler } from './eventHandler';
import { API_URL } from '../../config';
import { SaveRedux } from '../../Common/Helpers/SaveRedux';


export const hubConnection = new HubConnectionBuilder()
  .withUrl(`${API_URL}/realTimeUpdateInfo`, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    withCredentials: true,
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect(0, 0, 10000)
  .build();

  export const onReceiveMessage = (response, callback) => {
    callback(response);
  };

  export function SendMessageToServerSignalR(token, userId, dispatch) {
    const message = {
      token: token, 
      errorMessage: null, 
      isSuccess: true, 
      result: null,
      userId: userId !== null ? userId : null,
    };

    hubConnection.on('ProcessUpdates', response => {
      if (response.isSuccess === true && response.roles !== null) {
        SaveRedux(dispatch,'tokenData', response.token);
        SaveRedux(dispatch,'refreshTokenData', response.refreshToken);
        SaveRedux(dispatch,'userData', response.user);
        SaveRedux(dispatch,'rolesData', response.roles);
        SaveRedux(dispatch,'orgData', response.orgData);
      }
      eventHandler.emit('ProcessUpdates', response);
  });
  
    if (hubConnection.state === 'Connected') {
      
      hubConnection.invoke('ProcessUpdates', message).catch(err => console.error(err.toString()));
    } else {
      
      hubConnection
        .start()
        .then(() => {
   
          hubConnection.invoke('ProcessUpdates', message).catch(err => console.error(err.toString()));
        })
        .catch(err => console.error(err.toString()));
    }
  }