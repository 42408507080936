import { Routes, Route } from 'react-router-dom';
import React, { memo } from 'react';
import { PUBLIC_PATHS } from '../../../config';

const RouteIdentifier = ({ routes, fallback = <div className="loading" />, notFoundPath = PUBLIC_PATHS.NOTFOUND }) => (
  //<BrowserRouter>
    <Routes>
      {routes.map((route, rIndex) => (
        <Route key={rIndex} path={route.path} element={route.component} />
      ))}
      // TODO: IF PARA PAGE 404 NOT FOUND
      {/* <Navigate to={notFoundPath} /> */}
    </Routes>
  //</BrowserRouter>
);

export default memo(RouteIdentifier);
