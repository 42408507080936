class CostBearerModel {
    constructor({
                    id = "",
                    name = "",
                    message = "",
                } = {}) {
         this.id = id;
         this.name = name;
        this.message = message;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const attributes = {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            name :{
                'label': 'Name',
                'sortable': true,
                'sortField': 'name',
                'value': (item) => item.name || "",
            },
            message :{
                'label': 'Message',
                'sortable': true,
                'sortField': 'message',
                'value': (item) => item.message || "",
            },
        }

        return attributes;
    }
}

export default CostBearerModel;