
class AdminResponseCreatePaymentModel {
    constructor({
                    transactionStatus = '',
                    paymentId = '',
                } = {}) {
        this.transactionStatus = transactionStatus;
        this.paymentId = paymentId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            transactionStatus :{
                //'label': 'transaction Status',
                'sortable': true,
                'sortField': 'transactionStatus',
                'value': (item) => item.transactionStatus || "",
            },
            paymentId :{
                //'label': 'payment Id',
                'sortable': true,
                'sortField': 'paymentId',
                'value': (item) => item.paymentId || "",
            },
        }
        return attributes;
    }
}
export default AdminResponseCreatePaymentModel;