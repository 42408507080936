import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import { PUBLIC_PATHS } from "../../config";
import { jwtDecode } from "jwt-decode";

export function redirectWhenCloseSession (tokenData) {
    const decodedToken = jwtDecode(tokenData);
    const loginMethod = decodedToken.loginMethod; 
    if (loginMethod) {
    switch (loginMethod.toLowerCase()) {
        case 'lrfssoback':
             CustomReload('https://lrfssoback.demondog.es/');
        break;
        case 'lrfssoqr':
            CustomReload(PUBLIC_PATHS.APP);
        break;
        case 'lrfssokassan':
            CustomReload(PUBLIC_PATHS.REDIRECT_VIEW);
        break;
        default:
            CustomReload(PUBLIC_PATHS.APP);;
    }
     CustomReload(PUBLIC_PATHS.APP);
    }
  };