import React, { useState } from 'react';
import { Header } from '../../Auth/Components/Nav/Header';
import { VerticalSidebar } from '../../Auth/Components/Nav/VerticalSidebarDesign';
import {CheckMobile} from "../Helpers/CheckMobile";
import {Row} from "reactstrap";
import Footer from "../../Auth/Components/Nav/Footer";

export function Main({ children }) {
  const [headerTitle, setHeaderTitle] = useState('Dashboard');
  const windowWidth = CheckMobile(998);

  const handleMenuClick = (newTitle) => {
    setHeaderTitle(newTitle);
  };

  return (
    <div className="container-fluid p-0 bg-main ">
      <div id="layout-wrapper" className="bg-darkBlue">
        <Header title={headerTitle} /*onMenuClick={handleMenuClick}*//>
        <VerticalSidebar /*onMenuClick={handleMenuClick}*//>
        <div id="contentId" className="main-content pb-0 px-0">
          <div className={`page-content ${windowWidth ? 'min-height-contain-mobile': 'min-height-contain'}  px-3 px-lg-5`}> {/*min-height-contain-mobile*/}
            <Row className={`${windowWidth ? 'pt-5 mt-5' : 'pt-4'} px-2 px-lg-0`}>
              {children}
            </Row>
          </div>
          <Footer/>

        </div>

      </div>
    </div>
  );
}
