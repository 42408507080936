class AdminResponseRoleUserModel {
    constructor({
                    role = '',
                    permissions = [],
                } = {}) {
        this.role = role;
        this.permissions = permissions;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const attributes = {
            role :{
                'label': 'Role',
                'sortable': true,
                'sortField': "role",
                'value': (item) => item.role || "",
            },
            permissions :{
                'label': 'Permissions',
                'sortable': true,
                'sortField': "permissions",
                'value': (item) => item.permissions || "",
            },

        }

        return attributes;
    }
}

export default AdminResponseRoleUserModel;