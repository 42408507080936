import i18n from "../../../AppSystem/i18n";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class ResponsePay {
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    costBearerId = '',
                    costBearerName = '',
                    accountingId = '',
                    accountingName = '',
                    type = '',
                    date= '',
                    clearingNumber = '',
                    accountNumber = '',
                    bG= '',
                    pG= '',
                    oCR= '',
                    message = '',
                    description = '',
                    amount = 0,
                    vatAmount = 0,
                    fileId = '',
                    fileName = '',
                    additionalInfo = {},
                    additionalFileName = '',
                    status = '',
                    reason = '',
                    approvedDeniedByName = '',
                    createdBy = '',
                    createdByName = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.accountingId = accountingId;
        this.accountingName = accountingName;
        this.type = type;
        this.date = date;
        this.clearingNumber = clearingNumber;
        this.accountNumber = accountNumber;
        this.bG = bG;
        this.pG = pG;
        this.oCR = oCR;
        this.message = message;
        this.description = description;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.fileId = fileId;
        this.fileName = fileName;
        this.additionalInfo = additionalInfo;
        this.additionalFileName = additionalFileName;
        this.status = status;
        this.reason = reason;
        this.approvedDeniedByName = approvedDeniedByName;
        this.createdBy = createdBy;
        this.createdByName = createdByName;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null, customField = null) {
        const costBearerId = i18n.t('reimbursement.costBearer');
        const file = i18n.t('modelLabel.file');
        const accounting = i18n.t('modelLabel.accounting');
        const createdBy = i18n.t('modelLabel.createdBy');


        const attributes =  {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': "id",
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': "orgId",
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': 'Org',
                'sortable': true,
                'sortField': "orgName",
                'value': (item) => item.orgName || "",
            },
            costBearerId :{
                'label': costBearerId,
                'sortable': true,
                'sortField':'costBearerId',
                'value': (item) => item.costBearerId || "",
            },
            costBearerName :{
                'label': costBearerId,
                'sortable': true,
                'sortField':'costBearerName',
                'value': (item) => item.costBearerName || "",
            },
            accountingId :{
                'label': accounting,
                'sortable': true,
                "sortField":'accountingId',
                'value': (item) => item.accountingId || "",
            },
            accountingName :{
                'label': accounting,
                'sortable': true,
                "sortField":'accountingName',
                'value': (item) => item.accountingName || "",
            },
            type :{
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                "sortField":'type',
                'value': (item) => item.type === "Bankkonto" ? i18n.t('payReceive.bankAccount') : (item.type === "Bankgiro" ? i18n.t('payReceive.bankgiro'): i18n.t('payReceive.plusgiro')),
            },
            date: {
                'label': i18n.t('modelLabel.whenToPay'),
                'sortable': true,
                "sortField":'date',
                'value': (item) => item.date ? ChangeDateFormat("date", item.date) : i18n.t('modelLabel.soon'),
            },
            clearingNumber: {
                'label': i18n.t('userSetting.modelClearingNumber'),
                'sortable': true,
                "sortField":'clearingNumber',
                'value': (item) => item.clearingNumber || '',
            },
            accountNumber: {
                'label': i18n.t('userSetting.modelAccountNumber'),
                'sortable': true,
                "sortField":'accountNumber',
                'value': (item) => item.accountNumber || '',
            },
            bG: {
                'label': 'BG',
                'sortable': true,
                "sortField":'bG',
                'value': (item) => item.bG || '',
            },
            pG: {
                'label': 'PG',
                'sortable': true,
                "sortField":'pG',
                'value': (item) => item.pG || '',
            },
            oCR: {
                'label': 'OCR',
                'sortable': true,
                "sortField":'OCR',
                'value': (item) => item.oCR || '',
            },
            message: {
                'label': i18n.t('modelLabel.message'),
                'sortable': true,
                "sortField":'message',
                'value': (item) => item.message || '',
            },
            description: {
                'label': i18n.t('modelLabel.description'),
                'sortable': true,
                "sortField":'description',
                'value': (item) => item.description || "",
            },
            amount: {
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                "sortField":'amount',
                'value': (item) => item.amount || '',
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'sortable': true,
                "sortField":'vatAmount',
                'value': (item) => item.vatAmount || "",
            },
            fileId: {
                'label': file,
                'sortable': true,
                "sortField":'fileId',
                'value': (item) => item.fileId || '',
            },
            fileName: {
                'label': file,
                'sortable': true,
                "sortField":'fileName',
                'value': (item) => item.fileName || '',
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width' : (item.status === 'Nekad' ? "box-red me-4 fixed-width" : 'box-custom-grey me-4 fixed-width'),
                'value': (item) => item.status === 'Skickat' ? i18n.t('reimbursement.sent') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : '' || "",
            },
            reason :{
                'label': i18n.t('modelLabel.reason'),
                'sortable': true,
                'sortField': "reason",
                'value': (item) => item.reason || "",
            },
            approvedDeniedByName :{
                'label': i18n.t('modelLabel.approvedDeniedByName'),
                'sortable': true,
                'sortField': "approvedDeniedByName",
                'value': (item) => item.approvedDeniedByName || "",
            },
            createdBy: {
                'label': createdBy,
                'sortable': true,
                "sortField":'createdBy',
                'value': (item) => item.createdBy || '',
            },
            createdByName: {
                'label': createdBy,
                'sortable': true,
                "sortField":'createdByName',
                'value': (item) => item.createdByName || '',
            },
        };

        return attributes;
    }
}

export default ResponsePay;