import {Card, CardBody, Table} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const UserSystemRoles = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card className='mb-3'>
            <CardBody>
                <h5 className="mb-1 text-green fw-bold">{t('adminLabel.systemRoles')}</h5>
                <Table className="table-borderless">
                    <tbody>
                    {data.map((item, index) =>
                        <React.Fragment key={'systemRole' + index}>
                            <tr>
                                <th className="ps-0"
                                    scope="row">{t('role.role')}:
                                </th>
                                <td className="text-muted">{item.role}</td>
                            </tr>
                            {item.permissions && item.permissions.length > 0 ?
                                <tr key={'permission' + index}>
                                    <th className="ps-0"
                                        scope="row">{t('role.permissions')}:
                                    </th>
                                    <td className="text-muted auto-wrap-td-info">{item.permissions.join(', ')}</td>
                                </tr>
                                : ''}
                            {data.length - 1 !== index &&
                                <tr className='border-bottom-white'>
                                    <td colSpan="2" className='p-1'>&nbsp;</td>
                                </tr>
                            }
                        </React.Fragment>
                    )}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}