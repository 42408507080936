import * as yup from "yup";
import {string} from "yup";
import i18n from "../../../AppSystem/i18n";

class AdminRequestInternalTransaction {
    constructor({
                    originOrgId = '',
                    destinationOrgId = '',
                    amount = 0
                } = {}) {
        this.originOrgId = originOrgId;
        this.destinationOrgId = destinationOrgId;
        this.amount = amount;
    }

    static getAttributes(filter = null, validationSchema = null ,optionsData = null, customField = null) {

        const orgIdPlaceholder = i18n.t('formPlaceholder.selectOrg');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            originOrgId: string().required(fieldRequired),
            destinationOrgId: string().required(fieldRequired),
            amount: string().required(fieldRequired).test('is-positive',  `${i18n.t('validationMessage.greater0')}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            })
        });

        let optionsOrg = []
        if (optionsData) {
            optionsData.orgId?.map(item => {
                optionsOrg?.push({
                    label: item.name + ' ('+ item.type +')',
                    value: item.id
                });
            });
        }

        const attributes =  {
            originOrgId :{
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'select',
                    'options': optionsOrg,
                    "search": true,
                    'default': '',
                    'placeholder': orgIdPlaceholder,
                    'validation': completeSchema.fields.originOrgId,
                },
                'value': (item) => item.originOrgId || "",
            },
            destinationOrgId :{
                'label': i18n.t('modelLabel.orgDestination'),
                'form': {
                    'type': 'select',
                    'options': optionsOrg,
                    "search": true,
                    'default': '',
                    'placeholder': orgIdPlaceholder,
                    'validation': completeSchema.fields.destinationOrgId,
                },
                'value': (item) => item.destinationOrgId || "",
            },
            amount: {
                'label': i18n.t('modelLabel.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || '',
            },
        };

        if (validationSchema === true) {
            return completeSchema;
        }
        return attributes;
    }
}

export default AdminRequestInternalTransaction;