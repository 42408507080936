import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-input-2';

export function CustomPhoneInput({ value, onChange, style = '', country = 'es', onBlur, id = null, typeImport = '' }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isTouched, setIsTouched] = useState(false);


  //CHANGE STYLE PHONEINPUT
  useEffect(() => {
    const loadStyles = async () => {
      if (typeImport === 'bootstrap') {
        await import('react-phone-input-2/lib/bootstrap.css');
      } else if (typeImport === 'plain') {
        await import('react-phone-input-2/lib/plain.css');
      }
    };

    loadStyles();
  }, [typeImport]);


  useEffect(() => {
    if (value !== '') {
      setPhoneNumber(value);
    }
  }, [value]);


  const handleOnChange = async (value, props) => {
    const prefix = props.dialCode;
    let cleanedNumber = value;
    if (value[prefix?.length] == '0') {
      cleanedNumber = value.substring(0, prefix?.length) + value.substring(prefix.length + 1, value.length);
    }
    setPhoneNumber(cleanedNumber);
    setIsTouched(true);
  };

  const handleBlur = () => {
    setIsTouched(true);
    if (onBlur) {
      // errors.phone.includes(`+`);
    }
  };

  const inputStyle = {
    width: '100%',
  };


  return (
    <div>
      <PhoneInput
        country={country}
        id={id}
        value={phoneNumber}
        onChange={async (value, props) => {
          await handleOnChange(value, props);
          await onChange('+' + value);
        }}
        onBlur={() => handleBlur()}
        className={style}
        inputStyle={inputStyle}
        enableSearch={true}
        enableAreaCodes={true}
        countryCodeEditable={false}
      />
    </div>
  );
}
