import { useEffect, useState } from "react";
import { redirectWhenCloseSession } from "./RedirectWhenCloseSession";
import { logoutUser } from "../../AppSystem/Redux/Auth/AuthSlice";

export const calculateTimeRemaining = (endDate) => {

   if (!endDate) {
    return { hours: 0, minutes: 0, seconds: 0 };
   }

    const now = new Date();
    const end = new Date(endDate);
    
    //end.setHours(end.getHours() - 1);
    //end.setMinutes(end.getMinutes() - 1);
    if (isNaN(end.getTime())) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
    const timeDiff = end - now;
  
    if (timeDiff <= 0) return { hours: 0, minutes: 0, seconds: 0 };
  
    const hours = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
    const minutes = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
    return { hours, minutes, seconds };
  };

  export const useTimer = (endDate, token, dispatch) => {
    const [timeRemaining, setTimeRemaining] = useState(() => calculateTimeRemaining(endDate));
    const [hasNotified, setHasNotified] = useState(false);

    useEffect(() => {

      if (!endDate) {
        return;
      }
      const intervalId = setInterval(() => {
        const newTimeRemaining = calculateTimeRemaining(endDate);
        setTimeRemaining(newTimeRemaining);

        if (newTimeRemaining.hours === 0 && newTimeRemaining.minutes === 0 && newTimeRemaining.seconds === 0) {
            if (!hasNotified) {
              dispatch(logoutUser());
              redirectWhenCloseSession(token);
              setHasNotified(true);
            }
          }

      }, 10000);
  
      return () => clearInterval(intervalId);
    }, [endDate]);
  
    return timeRemaining;
  };
  