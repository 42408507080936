import { useEffect, useState } from 'react';

export function CheckMobile(width) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = event => {
      setIsMobile(event.matches);
    };

    const setupMediaQuery = () => {
      const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
      setIsMobile(mediaQuery.matches);
      mediaQuery.addListener(handleResize);
    };

    const cleanupMediaQuery = () => {
      const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
      mediaQuery.removeListener(handleResize);
    };

    setupMediaQuery();
    return cleanupMediaQuery;
  }, []);

  return isMobile;
}
