import i18n from "i18next";

class AdminRequestCreatePaymentModel {
    constructor({
                    instructedAmount = {},
                    creditorName = '',
                    creditorAccount = {},
                    message='',
                    product='',
                    paymentCollectionId='',

                } = {}) {
        this.instructedAmount = instructedAmount;
        this.creditorName = creditorName;
        this.creditorAccount = creditorAccount;
        this.message = message;
        this.product = product;
        this.paymentCollectionId = paymentCollectionId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        /*const amount = i18n.t('modelLabel.amount');
        const creditorName = i18n.t('openPayment.creditorName');
       const creditorAccount = i18n.t('openPayment.creditorAccountIban');*/

        const attributes = {
            instructedAmount :{
                //'label': amount,
                //'sortable': true,
                //'sortField': 'instructedAmount',
                'value': (item) => item.instructedAmount || "",
            },
            creditorName :{
               // 'label': creditorName,
                //'sortable': true,
                //'sortField': 'creditorName',
                'value': (item) => item.creditorName || "",
            },
            creditorAccount:{
                //'label': creditorAccount,
                //'sortable': true,
                //'sortField': 'creditorAccount',
                'value': (item) => item.creditorAccount || "",
            },
            message :{
                //'label': 'message',
                //'sortable': true,
                //'sortField': 'message',
                'value': (item) => item.message || "",
            },
            product :{
                //'label': 'product',
                //'sortable': true,
                //'sortField': 'product',
                'value': (item) => item.product || "",
            },
            paymentCollectionId :{
                //'label': 'paymentCollectionId',
                //'sortable': true,
                //'sortField': 'paymentCollectionId',
                'value': (item) => item.paymentCollectionId || "",
            },
        }
        return attributes;
    }
}
export default AdminRequestCreatePaymentModel;