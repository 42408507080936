import {Accordion, AccordionItem, Button, Col, Collapse, Row} from "reactstrap";
import classnames from "classnames";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementExpense from "../Models/Request/RequestReimbursementExpense";
import React, {useEffect, useRef, useState} from "react";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import _ from 'lodash';
import {useTranslation} from "react-i18next";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";

export function ReimbursementExpense({ data, onChange, onValidate, onCancel, editMode = false, accountingJson, extraField, setIsDeletedFile, setIsDeletedExtraFile, extraField2, attributeValue = null}) {
    const { t } = useTranslation()
    // CANCEL STATE
    const [cancelModel, setCancelModel] = useState(data);

    // STATES
    const [accordionStatus, setAccordionStatus] = useState('1');
    const [localDisplay, setLocalDisplay] = useState(false);
    const [flagDate, setFlagDate] = useState(true);
    const [wrongFileFormat, setWrongFileFormat] = useState(false);

    //REF
    const formDivRefExpense = useRef(null);

    // TOGGLE ACCORDION
    const ToggleAccordion = (id) => {
        if (accordionStatus === id) {
            setAccordionStatus();
        } else {
            setAccordionStatus(id);
        }
    };

    // CANCEL MODEL
    useEffect(() => {
        if (editMode === true) {
            const copyModel = _.cloneDeep(data);
            setCancelModel(copyModel);
            setLocalDisplay(true);
        }
    }, [editMode]);

    const messageAccounting = InputMessage(accountingJson.accounting, data?.accountingId);

    // EXTRA FIELDS
    let program = "";
    let participantsFile = "";
    let programFile = "";
    if (data?.additionalInfo && Object.keys(data?.additionalInfo).length > 0) {
        if (data?.additionalInfo.program !== undefined) {
            program = <Col lg={12}>
                <CustomInput model={RequestReimbursementExpense} attribute="program"
                             customField={extraField !== null && extraField.requestName === "Program" ? extraField : (extraField2 !== null && extraField2.requestName === "Program" ? extraField2 : null)} data={data?.additionalInfo.program}
                             customChange={(e) => {
                                 onChange(e, "dynamic")
                             }}
                />
            </Col>;
        }

        if (data?.additionalInfo.participantsFileId !== undefined) {
            participantsFile = <Col lg={12}>
                <CustomInput model={RequestReimbursementExpense} attribute="participantsFileId"
                             data={data?.additionalInfo.participantsFileId} customChange={(e) => {
                    onChange(e, "dynamic", "participantsFileId")
                }}
                             fileId={data?.additionalInfo.participantsFileId !== "" ? data?.additionalInfo.participantsFileId : null}
                             fileExtension={data?.additionalInfo.participantsFileId !== "" ? data?.additionalFileExtension : null}
                             fileName={data?.additionalInfo.participantsFileId !== "" ? data?.additionalFileName : null}
                             wrongFileFormat={wrongFileFormat} customField={extraField !== null && extraField.requestName === "ParticipantsFileId" ? extraField : (extraField2 !== null && extraField2.requestName === "ParticipantsFileId" ? extraField2 : null)}
                             setIsDeleted={setIsDeletedExtraFile}
                />
            </Col>;
        }
        if (data?.additionalInfo.programFileId !== undefined) {
            programFile = <Col lg={12}>
                <CustomInput model={RequestReimbursementExpense} attribute="programFileId"
                             data={data?.additionalInfo.programFileId} customChange={(e) => {
                    onChange(e, "dynamic", "programFileId")
                }}
                             fileId={data?.additionalInfo.programFileId !== "" ? data?.additionalInfo.programFileId : null}
                             fileExtension={data?.additionalInfo.programFileId !== "" ? data?.additionalFileExtension : null}
                             fileName={data?.additionalInfo.programFileId !== "" ? data?.additionalFileName : null}
                             wrongFileFormat={wrongFileFormat} customField={extraField !== null && extraField.requestName === "ProgramFileId" ? extraField : (extraField2 !== null && extraField2.requestName === "ProgramFileId" ? extraField2 : null)}
                             setIsDeleted={setIsDeletedExtraFile}
                />
            </Col>;
        }
    }

    return(
        <>
            <Accordion id="accordionBordered" className="" open={accordionStatus} toggle={ToggleAccordion}>
                <AccordionItem className="accordion-grey  mb-4 border-0 rounded-0">
                    <h2 className="accordion-header" id="accordionborderedExample3">
                        <button className={classnames(`py-2 pe-1 w-100 accordion-button d-flex justify-content-between ${localDisplay === false ? 'bg-green text-white' : 'accordion-grey text-green' } fw-bold border-bottom-0`, { collapsed: !localDisplay })} type="button" onClick={() => {setLocalDisplay(!localDisplay)}} style={{ cursor: "pointer" }}
                        >
                            <div>
                                {t('reimbursement.expense')}
                            </div>
                            {localDisplay === false ? <i className="bx bx-chevron-down text-white fs-3"></i> : <i className="bx bx-chevron-up fs-3"></i>}
                        </button>
                    </h2>
                    <Collapse isOpen={localDisplay} className="accordion-collapse" id="accor_borderedExamplecollapse3" >
                        <div className="accordion-body" ref={formDivRefExpense}>
                            <Row>
                                <Col lg={6} className="pt-3">
                                    <CustomInput model={RequestReimbursementExpense} attribute='dateReceive' data={data?.dateReceive} customChange={(e) => {
                                        onChange(e, "dateReceive");
                                    }}/>

                                </Col>
                                <Col lg={6} className="pt-3">
                                    <CustomInput model={RequestReimbursementExpense} attribute='accountingId' data={data?.accountingId}
                                     customChange={(e) => {
                                         onChange(e, "accountingId");
                                     }}
                                     customOptions={Object.keys(accountingJson).length > 0 ? accountingJson : null}/>
                                    {messageAccounting}
                                </Col>
                                <Col lg={data?.vatAmount === null ? 12 : 6} className="pt-3">
                                    <CustomInput model={RequestReimbursementExpense} attribute='amount' data={data?.amount !== 0 ? data?.amount : ""} customChange={(e) => onChange(e, "amount")}/>
                                </Col>
                                {data?.vatAmount !== null ?
                                    <Col lg={6} className="pt-3 position-relative">
                                        <CustomInput model={RequestReimbursementExpense} attribute='vatAmount'
                                                     data={data?.vatAmount}
                                                     customChange={(e) => onChange(e, "vatAmount")}/>
                                        <div className={`error tooltip-custom-up-field ${data?.amount !== 0 && data?.vatAmount !== 0 && data?.amount <= data?.vatAmount ? 'opacity-1' : 'opacity-0'}`} style={{color: '#f56e6e'}}>
                                            {t('validationMessage.vatLessAmount')}
                                        </div>
                                    </Col>
                                    : ''}
                                <Col lg={12} className="pt-3">
                                    <CustomInput model={RequestReimbursementExpense} attribute='fileId' data={''}
                                                 customChange={(e) => {
                                                     onChange(e, "fileId")
                                                 }}
                                                 fileId={data?.fileId !== "" ? data?.fileId : null}
                                                 fileExtension={data?.fileExtension && data?.fileExtension !== "" ? data?.fileExtension : null} fileName={data?.fileName && data?.fileName !== "" ? data?.fileName : (data?.fileExtension && data?.fileExtension !== "" ? data?.fileExtension : null)} wrongFileFormat={wrongFileFormat}
                                                 setIsDeleted={setIsDeletedFile}
                                                 validateAttribute={attributeValue}
                                    />
                                </Col>
                                {program}
                                {participantsFile}
                                {programFile}
                                {editMode === true ?
                                    <Col lg={12} className="text-center text-danger">
                                        {t('validationMessage.fileDeletedImportant')}
                                    </Col>
                                : ""}
                                <Col lg={12} className="text-end pt-3">
                                    {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModel)}>{t('button.cancel')}</Button> : ""}
                                    <CustomSubmitButton text={editMode === true ? t('button.save') : t('reimbursement.addExpense')} customClick={onValidate} typeFile={true} formDivRef={formDivRefExpense}/>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>
        </>
    );
}