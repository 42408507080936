import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  userData: {},
  orgData: "",
  rolesData: [],
  tokenData: '',
  refreshTokenData: '',
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.userData = action.payload.userData;
      state.isAuthenticated = true;
    },
    setOrg(state, action) {
      state.orgData = action.payload.orgData;
      state.isAuthenticated = true;
    },
    saveRefreshToken(state, action) {
      state.refreshTokenData = action.payload.refreshTokenData;
    },
    saveToken(state, action) {
      state.tokenData = action.payload.tokenData;
    },
    saveRoles(state, action) {
      state.rolesData = action.payload.rolesData;
    },
    logoutUser(state) {
      state.userData = {};
      state.orgData = "";
      state.rolesData = [];
      state.tokenData = '';
      state.refreshTokenData = '';
      state.isAuthenticated = false;
    },
  },
});

export const { setUser,setOrg, logoutUser, saveRefreshToken, saveToken, saveRoles } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;