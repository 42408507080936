import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import SortableList from "../../Common/Components/Lists/SortableList";
import {Col, Row} from "reactstrap";
import ResponseDigitalAccountModel from "../Models/Response/ResponseDigitalAccount";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";

export function AdminDigitalAccountList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const search = LoadLocalStorage("search");

    useEffect(() => {
        if (search) {
            localStorage.removeItem('search');
        }
    }, [search]);

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                    navigate(`${ADMIN_PATHS.DIGITAL_ACCOUNT_VIEW}?id=${item.id}`);
                }} />
            </>
        );
    }
    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        "visualId",
        "orgName",
        "orgType",
        "costPlace",
        "updatedAt",
        'balance',
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id', 'orgId');
    }
    const ignoreFields = ["OrgId", "Id", "CreatedBy"];
    const listOptions = {
        "model": ResponseDigitalAccountModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/digital-account/admin/search",
        "nameFile": t('digitalAccount.label'),
        "ignoreFields" : ignoreFields,
        "urlGetSearch" : "/digital-account/admin/search",
    };

    return(
       <>
           <Col lg={12} className='card card-backend p-4 mb-5'>
               <h2 className='rokkitt-font'>{t('adminLabel.digitalAccount')}</h2>

               <SortableList
                   listOptions={listOptions}
               />
           </Col>
       </>
    );
}