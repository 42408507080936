import {Col, Row} from "reactstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {PAY_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import ResponseSwedbankPay from "../Models/Response/ResponseSwedbankpay";
import { AttestSwedbankPayViews } from "./SwedBankView";

export function SwedBankPayInfo () {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();

    const [responsePay, setResponsePay] = useState(new ResponseSwedbankPay());
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    useEffect(() => {
        const handlePayResponse  = (response) => {
            setResponsePay(response);
        };
        const PayApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/swedbank/quickpayment?quickpaymentId=${id}`,
                };
                await ApiCall(apiCallOptions, handlePayResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        PayApiCall();
    }, []);

    return (
        <>
            <Row>
                <Col lg={1} className="px-0">
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'ms-3 mb-3'}
                        onClick={() => {
                            navigate(PAY_PATHS.SWEDBANKPAY)
                        }}
                    />
                </Col>
            </Row>
            <Col lg={12} className="pb-5 px-0">
                <Row className="px-3">
                    <Col lg={12} className="py-4 card-backend px-0 px-lg-4">
                        {responsePay && responsePay.id !== "" ? (
                            <AttestSwedbankPayViews data={responsePay}/>
                        ) : ""}
                    </Col>
                </Row>
            </Col>
        </>
    );
}