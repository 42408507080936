import * as yup from "yup";
import {string} from "yup";

class AdminRequestAttestApprovedModel {
    constructor({
                    id = '',
                    attested = '',

                } = {}) {
        this.id = id;
        this.attested = attested;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const completeSchema = yup.object().shape({
            id: string().required(''),
        });

        const attributes = {
            id :{
                'label': 'Id',
                'form': {
                    'type': 'text',
                    'placeholder': 'Enter Id',
                    // 'validation': completeSchema.Fields.role,
                },
                'value': (item) => item.id || "",
            },
            attested :{
                'label': 'Attested',
                'form': {
                    'type': 'text',
                    'placeholder': 'Enter Attested',
                    //'validation': completeSchema.Fields.permissions,
                },
                'value': (item) => item.attested || "",
            },

        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default AdminRequestAttestApprovedModel;