import { Col, Container, Row } from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ORGANIZATION_PATHS} from "../../config";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useTranslation} from "react-i18next";
import {UserProfileHeader} from "../../Common/Components/User/UserProfileHeader";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {UserOrgRoles} from "../../Common/Components/User/UserOrgRoles";

export function OrgUsersInfo() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { ApiCall } = useApiCallService();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const idValue = new URLSearchParams(window.location.search).get('id');
    const [apiResponseRoleList, setResponseRoleList] = useState(null);
    const [apiResponseUser, setApiResponseUser] = useState(null);

    useEffect(() => {
        const handleApiResponse  = (apiResponseUser) => {
            setApiResponseUser(apiResponseUser);
        };
        const GetInfoUser = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetInfoUser();
    }, [idValue]);

    useEffect(() => {
        const handleApiResponse  = (apiResponseRoleList) => {
            setResponseRoleList(apiResponseRoleList);
        };
        const GetRoleUser = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/role/org/list?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetRoleUser();
    }, [idValue]);

    return(
        <>
            <Row>
                <Col lg={1}>
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'m-3 rounded'}
                        onClick={() => {
                            navigate(ORGANIZATION_PATHS.ORG_USERS)
                        }}
                    />
                </Col>
            </Row>
            {apiResponseUser ?
                <>
                    <Container fluid>
                        <UserProfileHeader data={apiResponseUser} isSuperAdmin={isSuperAdmin} />
                        <Row className='justify-content-center'>
                            <Col lg={12} className='px-4 p-lg-5 padding-top-profile'>
                                <Row>
                                    <Col lg={6}>
                                        {/* ORGANIZATION ROLES */}
                                        {apiResponseRoleList ?
                                            <UserOrgRoles data={apiResponseRoleList} isSuperAdmin={isSuperAdmin} />
                                        : ''}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </>
                : ''}
        </>
    );
}