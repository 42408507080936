import {Button} from "reactstrap";
import $ from 'jquery';
import {useEffect, useState} from "react";

/*
//EXAMPLE FOR USE CUSTOMSUBMITBUTTON IN PAGE

   //const {createOrganization} = useOrganizationService(); // IMPORT THE SERVICE YOU NEED
    //const [organizationFormInfo, setOrganizationFormInfo] = useState(new OrganizationModel()); //STATE FOR SAVE DATA FORM

    //const completeSchema = OrganizationModel.getAttributes( null, true); // OBTAIN THE SCHEMA FROM THE MODEL

    // HANDLESUBMIT

    // const handleDataOrg  = async (organizationFormInfo)=>{
    //     const putOrganization = {
    //         ...organizationFormInfo
    //     }
    //     await createOrganization(putOrganization);
    // }

    // const handleSubmit = async (data) => {
    //     try {
    //         await completeSchema.validate(data, { abortEarly: false });
    //         handleDataOrg(new OrganizationModel(organizationFormInfo));
    //     } catch (validationErrors) {
    //         console.error(validationErrors);
    //     }
    //
    // };

    //IN RETURN

    <CustomSubmitButton text='submit' customClick={handleSubmit(organizationFormInfo)}/>



 */
export function CustomSubmitButton({text = '', customClick = null, id='', icon = null, bgColor= 'btn-submit-kassan', customClass='', typeFile = false, tooltipText = null, formDivRef= null}) {

    // SHOW ERRORS FOR ALL FIELDS

    const [testClick, setTestClick] = useState(false);

    useEffect(() => {
        if (testClick === true) {
            setTimeout(() => {
                $('div.react-datepicker__input-container input').removeClass('react-datepicker-ignore-onclickoutside');
                setTestClick(false);
            },80)
        }
    }, [testClick]);


     const triggerClick = () => {
         let formInputs =[];
         if(formDivRef !== null && formDivRef !== false){
             formInputs =  formDivRef.current.querySelectorAll('input, select, textarea');
         } else {
             formInputs = document.querySelectorAll('input, select, textarea');
         }


         if (formInputs.length > 0) {
             formInputs.forEach(input => {
                 input.focus();
                 // if (input.id === 'date') {
                 setTimeout(() =>{
                     $('.react-datepicker__tab-loop').addClass('d-none');
                    input.blur();

                 },1)

                 //}
             });
         }

         setTestClick(true);

         if (typeFile === true) {
             const fileDivs = document.querySelectorAll('[id*="divFile"]');
             if (fileDivs) {
                 fileDivs.forEach(fileDiv => {
                     fileDiv.click();
                 });
             }

         }

     };

    return(
        <Button className={`${bgColor} ${customClass} position-relative`} id={id} onClick={()=>{
            if (formDivRef !== false) {
                triggerClick();
            }

            if(customClick) {
                customClick();
            }

        }}>
            {
                icon ?
                    <i className={icon}/>
                    : ''
            }

            {text}
            {tooltipText !== null ? (<div className='tooltip-custom-button'> {tooltipText}</div>) : ''}
        </Button>
    );

}