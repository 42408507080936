//import * as yup from "yup";
//import {string} from "yup";

class ResponseAccountingAccountModel {
    constructor({
                    id = "",
                    name= "",
                    showLabel= "",
                    message= "",
                } = {}) {
        this.id = id;
        this.name = name;
        this.showLabel = showLabel;
        this.message = message;
    }

    static getAttributes(filter = null) {


        const attributes = {
            id :{
                'label': 'ID',
                'form':  false,
                'sortable': true,
                'sortField': 'id',
                'filter': { 'type': 'text', 'placeholder': 'ID' },
                'id': 'id',
                'name': 'id',
                'value': (item) => item.id || "",
            },
            showLabel :{
                'label': 'ShowLabel',
                'form':  false,
                'sortable': true,
                'sortField': 'showLabel',
                'filter': { 'type': 'text', 'placeholder': 'ShowLabel' },
                'id': 'showLabel',
                'showLabel': 'showLabel',
                'value': (item) => item.showLabel || "",
            },
            message :{
                'label': 'Message',
                'form':  false,
                'sortable': true,
                'sortField': 'message',
                'filter': { 'type': 'text', 'placeholder': 'Message' },
                'id': 'message',
                'name': 'message',
                'value': (item) => item.message || "",
            },
        }

        if (typeof filter === "string") {
            return { [filter]: attributes[filter] };
        }
        if (Array.isArray(filter)) {
            const filteredAttributes = {};
            filter.forEach(key => {
                filteredAttributes[key] = attributes[key];
            });
            return filteredAttributes;
        }
        return attributes;
    }
}

export default ResponseAccountingAccountModel;