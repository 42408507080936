import {
    adminAttestRoutes,
    adminDigitalAccountRoutes,
    adminHonorariumCreateRoutes,
    adminHonorariumViewRoutes,
    adminInternalTransactionRoutes,
    adminInvoiceRoutes,
    adminOrgViewRoutes,
    adminReportTaxtAuthorityRoutes,
    adminRoleRoutes,
    adminSalaraySheetRoutes,
    adminSieRoutes,
    adminTransferRoutes,
    adminUserRoutes, collectRoutes,
    digitalAccountRoutes,
    invoiceCreate,
    invoiceView,
    orgRoutes,
    payRoutes,
    publicRoutes,
    QRcodeCreate,
    QRcodeView,
    quickPaymentRoutes,
    reimbursementCreateRoutes,
    reimbursementViewRoutes, reportsRoutes,
    salarySheet,
    transactionRoutes,
    userRoutes,
} from '../../routes';
import {useSelector} from "react-redux";
import {CheckUserPermission} from "../Helpers/CheckUserPermission";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../Helpers/CheckUserRole";
import {CheckUserOrganization} from "../Helpers/CheckUserOrganization";
import { useTimer } from '../../Common/Helpers/CalculateTimeRemaining';
import { useDispatch } from 'react-redux';


// DEFINE ROUTES
export function DefineRoutes() {
    const {tokenData, refreshTokenData, isAuthenticated } = useSelector(state => state.auth);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // DEFAULT ROUTES: PUBLIC ROUTES
    let routes = publicRoutes;
    // let subs = collectRoutes[0].subs;

    useTimer(refreshTokenData?.expires,tokenData,dispatch);

    // IS AUTHENTICATED
    if (isAuthenticated === true) {
        // REPLACE DEFAULT ROUTES FOR USER ROUTES
        routes = userRoutes;
        //// CHECK PERMISSIONS
        /*// REIMBURSEMENT
        if (CheckUserPermission("reimbursement.create")) {
          routes = [...routes, ...reimbursementCreateRoutes];
        }
        if (CheckUserPermission("reimbursement.view")) {
          routes = [...routes, ...reimbursementViewRoutes];
        }
        if (CheckUserPermission("reimbursement.create") && CheckUserPermission("reimbursement.view")) {
          const createRoute = reimbursementCreateRoutes[0];
          createRoute.hideInMenu = true;
          const viewRoute = reimbursementViewRoutes;
          viewRoute.push(createRoute);
          routes = [...routes, ...viewRoute];
        }
        if (CheckUserPermission("reimbursement")) {
          const createRoute = reimbursementCreateRoutes[0];
          createRoute.hideInMenu = true;
          const viewRoute = reimbursementViewRoutes;
          viewRoute.push(createRoute);
          routes = [...routes, ...viewRoute];
        }
    */
        // NEW REIMBURSEMENT
        let reimbursementRoutes = [];
        if (CheckUserPermission("reimbursement") || (CheckUserPermission("reimbursement.create") && CheckUserPermission("reimbursement.view"))) {
            const createRoute = reimbursementCreateRoutes[0];
            createRoute.hideInMenu = true;
            const viewRoute = reimbursementViewRoutes;
            viewRoute.push(createRoute);
            reimbursementRoutes = [...reimbursementRoutes, ...viewRoute];
        } else {
            if (CheckUserPermission("reimbursement.create") && !CheckUserPermission("reimbursement.view")) {
                reimbursementRoutes = [...reimbursementRoutes, ...reimbursementCreateRoutes];
            } else {
                if (CheckUserPermission("reimbursement.view") && CheckUserPermission("reimbursement.create")) {
                    const createRoute = reimbursementCreateRoutes[0];
                    createRoute.hideInMenu = true;
                    const viewRoute = reimbursementViewRoutes;
                    viewRoute.push(createRoute);
                    reimbursementRoutes = [...reimbursementRoutes, ...viewRoute];
                } else {
                    if (CheckUserPermission("reimbursement.view") && !CheckUserPermission("reimbursement.create")) {
                        reimbursementRoutes = [...reimbursementRoutes, ...reimbursementViewRoutes];
                    }
                }
            }
        }

        if (reimbursementRoutes.length > 0) {
            routes = [...routes, ...reimbursementRoutes];
        }

        // SALARYSHEET
        if (CheckUserPermission("salary-sheet") || CheckUserPermission("salary-sheet.view")) {
            routes = [...routes, ...salarySheet];
        }

        // ACCOUNT
        if (CheckUserPermission("account")) {  //"account.view"
            routes = [...routes, ...digitalAccountRoutes];
        }

        // PAY
        if (CheckUserPermission("pay") || CheckUserPermission("pay.create") || CheckUserPermission("pay.view")) {
            routes = [...routes, ...payRoutes];
        }

        // QUICKPAYMENT
        /*if (CheckUserPermission("quick-payment") || CheckUserPermission("quick-payment.view") || CheckUserPermission("quick-payment.create")) {
          routes = [...routes, ...quickPaymentRoutes];
        }*/

        // INVOICE
        /* let invoiceRoutes = [];
         if (CheckUserPermission("invoice") || (CheckUserPermission("invoice.create") && CheckUserPermission("invoice.view") && CheckUserPermission("invoice.update"))) {
           const viewRoute = invoiceView;
           invoiceCreate.map(item =>{
             const createRoute = item;
             createRoute.hideInMenu = true;
             viewRoute.push(createRoute);
           })
           invoiceRoutes = [...invoiceRoutes, ...viewRoute];
         } else {
           if (CheckUserPermission("invoice.create") && !CheckUserPermission("invoice.view")) {
             invoiceRoutes = [...invoiceRoutes, ...invoiceCreate];
           } else {
             if ((CheckUserPermission("invoice.view") && CheckUserPermission("invoice.update")) || (CheckUserPermission("invoice.view") && CheckUserPermission("invoice.create"))) {
               const createRoute = invoiceCreate[0];
               createRoute.hideInMenu = true;
               const viewRoute = invoiceView;
               viewRoute.push(createRoute);
               invoiceRoutes = [...invoiceRoutes, ...viewRoute];
             } else {
               if (CheckUserPermission("invoice.view") && !CheckUserPermission("invoice.create")) {
                 invoiceRoutes = [...invoiceRoutes, ...invoiceView];
               }
             }
           }
         }*/

        /*if (invoiceRoutes.length > 0) {
          routes = [...routes, ...invoiceRoutes];
        }*/

        //// COLLECT
        let collectR = collectRoutes;
        // QUICKPAYMENT
        if (CheckUserPermission("quick-payment") || CheckUserPermission("quick-payment.view") && CheckUserPermission("quick-payment.create")) {

            collectR[0].subs.push(...quickPaymentRoutes[0].subs);

            collectR.push(quickPaymentRoutes[1]);
            collectR.push(quickPaymentRoutes[2]);
            collectR.push(quickPaymentRoutes[3]);

        } else {
            if(CheckUserPermission("quick-payment.view")) {
                collectR[0].subs.push(...quickPaymentRoutes[0].subs);
                collectR.push(quickPaymentRoutes[1]);
                collectR.push(quickPaymentRoutes[3]);
            }
        }

        // ORG-QR
        if (CheckUserPermission("org-qr") || (CheckUserPermission("org-qr.create") && CheckUserPermission("org-qr.view") && CheckUserPermission("org-qr.update"))) {

            collectR[0].subs.push(...QRcodeView[0].subs);

            collectR.push(QRcodeView[1]);
            collectR.push(QRcodeView[2]);

            collectR.push(QRcodeCreate[0]);

        } else {
            if(CheckUserPermission("org-qr.view")) {
                collectR[0].subs.push(...QRcodeView[0].subs);
                collectR.push(QRcodeView[1]);
                collectR.push(QRcodeView[2]);
            }


        }
        // INVOICE
        if (CheckUserPermission("invoice") || (CheckUserPermission("invoice.create") && CheckUserPermission("invoice.view") && CheckUserPermission("invoice.update"))) {

            collectR[0].subs.push(...invoiceView[0].subs);

            collectR.push(invoiceView[1]);
            collectR.push(invoiceCreate[0]);
            collectR.push(invoiceCreate[1]);

        } else {
            if(CheckUserPermission("invoice.view")) {
                collectR[0].subs.push(...invoiceView[0].subs);
                collectR.push(invoiceView[1]);
            }

        }

        const uniqueSubs = collectR[0].subs.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.path === value.path &&
                    t.label === value.label &&
                    t.component === value.component &&
                    t.hideInMenu === value.hideInMenu
                ))
        );
        collectR[0].subs = uniqueSubs;

        const uniqueArray = collectR.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.path === value.path &&
                    t.component === value.component &&
                    t.hideInMenu === value.hideInMenu
                ))
        );

        collectR = uniqueArray;

        if (collectR.length > 0) {
            routes = [...routes, ...collectR];
        }

        // ORG
        if (CheckUserOrganization()) {
            if (CheckUserPermission("org")) {  //"org.view"
                routes = [...routes, ...orgRoutes];
            }
        }

        // QR-CODE
        /*let qRRoutes = [];
        if (CheckUserPermission("org-qr") || (CheckUserPermission("org-qr.create") && CheckUserPermission("org-qr.view") && CheckUserPermission("org-qr.update"))) {
          const createRoute = QRcodeCreate[0];
          createRoute.hideInMenu = true;
          const viewRoute = QRcodeView;
          viewRoute.push(createRoute);
          qRRoutes = [...qRRoutes, ...viewRoute];
        } else {
          if (CheckUserPermission("org-qr.create") && !CheckUserPermission("org-qr.view")) {
            qRRoutes = [...qRRoutes, ...QRcodeCreate];
          } else {
            if ((CheckUserPermission("org-qr.view") && CheckUserPermission("org-qr.update")) || (CheckUserPermission("org-qr.view") && CheckUserPermission("org-qr.create"))) {
              const createRoute = QRcodeCreate[0];
              createRoute.hideInMenu = true;
              const viewRoute = QRcodeView;
              viewRoute.push(createRoute);
              qRRoutes = [...qRRoutes, ...viewRoute];
            } else {
              if (CheckUserPermission("org-qr.view") && !CheckUserPermission("org-qr.create")) {
                qRRoutes = [...qRRoutes, ...QRcodeView];
              }
            }
          }
        }*/

        /* if (qRRoutes.length > 0) {
           routes = [...routes, ...qRRoutes];
         }*/

        // CHECK ADMIN ROLE
        if (CheckUserRole("Admin") || CheckUserRole("Support") || CheckUserRole("OPEnhet") || CheckUserRole("Företrädare")) {
            //const adminVisualRoutes = adminGlobalRoutes;
            //let subs = adminVisualRoutes[0].subs;
            let adminRoutes = [];

            // ATTEST

            if (CheckUserPermission("attest")) {   //"attest.view"
                adminRoutes = [...adminRoutes, ...adminAttestRoutes];
            }

            // DIGITAL ACCOUNT
            if (CheckUserPermission("account") || CheckUserPermission("account.view")) {
                adminRoutes = [...adminRoutes, ...adminDigitalAccountRoutes];
            }

            // INTERNAL TRANSACTION
            if (CheckUserPermission("internal-transaction") || CheckUserPermission("internal-transaction.create")) {
                adminRoutes = [...adminRoutes, ...adminInternalTransactionRoutes];
            }

            // TRANSACTIONS
            if (CheckUserPermission("open-payment") || CheckUserPermission("open-payment.view")) {   // FOR NOW WORKING WITH "open-payment" permisssion, but need be updated
                adminRoutes = [...adminRoutes, ...adminTransferRoutes];
            }

            // INVOICE
            if (CheckUserPermission("invoice")) {
                adminRoutes = [...adminRoutes, ...adminInvoiceRoutes];
            }

            // HONORARIUM

            let honorariumRoutes = [];
            if (CheckUserPermission("honorarium") || (CheckUserPermission("honorarium.create") && CheckUserPermission("honorarium.view") && CheckUserPermission("honorarium.update"))) {
                const createRoute = adminHonorariumCreateRoutes[0];
                createRoute.hideInMenu = true;
                const viewRoute = adminHonorariumViewRoutes;
                viewRoute.push(createRoute);
                honorariumRoutes = [...honorariumRoutes, ...viewRoute];
            } else {
                if (CheckUserPermission("honorarium.create") && !CheckUserPermission("honorarium.view")) {
                    honorariumRoutes = [...honorariumRoutes, ...adminHonorariumCreateRoutes];
                } else {
                    if ((CheckUserPermission("honorarium.view") && CheckUserPermission("honorarium.update")) || (CheckUserPermission("honorarium.view") && CheckUserPermission("honorarium.create"))) {
                        const createRoute = adminHonorariumCreateRoutes[0];
                        createRoute.hideInMenu = true;
                        const viewRoute = adminHonorariumViewRoutes;
                        viewRoute.push(createRoute);
                        honorariumRoutes = [...honorariumRoutes, ...viewRoute];
                    } else {
                        if (CheckUserPermission("honorarium.view") && !CheckUserPermission("honorarium.create")) {
                            honorariumRoutes = [...honorariumRoutes, ...adminHonorariumViewRoutes];
                        }
                    }
                }
            }

            if (honorariumRoutes.length > 0) {
                adminRoutes = [...adminRoutes, ...honorariumRoutes];
            }

            ///// REPORTS
            let reports = reportsRoutes;

            // REPORT TAX AUTHORITY
            if (CheckUserPermission("tax-authority")) {
                reports[0].subs.push(...adminReportTaxtAuthorityRoutes[0].subs);
                reports.push(adminReportTaxtAuthorityRoutes[1]);
            }

            // EXPORT SIE
            if (CheckUserPermission("sie")) { //sie
                reports[0].subs.push(...adminSieRoutes[0].subs);
                reports.push(adminSieRoutes[1]);
            }

            // SALARYSHEET
            if (CheckUserPermission("salary-sheet") || CheckUserPermission("salary-sheet.view")) { //"user.view"
                reports[0].subs.push(...adminSalaraySheetRoutes[0].subs);
                reports.push(adminSalaraySheetRoutes[1]);
            }

            const uniqueSubsReports = reports[0].subs.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.path === value.path &&
                        t.label === value.label &&
                        t.component === value.component &&
                        t.hideInMenu === value.hideInMenu
                    ))
            );
            reports[0].subs = uniqueSubsReports;

            const uniqueArrayReports = reports.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.path === value.path &&
                        t.component === value.component &&
                        t.hideInMenu === value.hideInMenu
                    ))
            );

            reports = uniqueArrayReports;
            if (reports.length > 0) {
                adminRoutes = [...adminRoutes, ...reports];
            }

            //////// SYSTEM
            // ORG
            if (CheckUserPermission("org") || CheckUserPermission("org.view") ) {
                adminRoutes = [...adminRoutes, ...adminOrgViewRoutes];
            }
            // USER
            if (CheckUserPermission("user") || CheckUserPermission("user.view")) { //"user.view"
                adminRoutes = [...adminRoutes, ...adminUserRoutes];

            }
            // ROLE
            if (CheckUserPermission("role") || CheckUserPermission("role.create") || CheckUserPermission("role.update") || CheckUserPermission("role.view")   ) {
                adminRoutes = [...adminRoutes, ...adminRoleRoutes];
            }


            /*if (subs.length > 0) {
              adminVisualRoutes[0].subs = subs;
              adminRoutes = [...adminRoutes, ...adminVisualRoutes];
            }*/
            if (adminRoutes.length > 0) {
                routes = [...routes, ...adminRoutes];
            }
        }
        // SUPER ADMIN
        if (CheckUserRole("SuperAdmin")) {
            routes = [...routes, ...transactionRoutes];
        }
    }

    return routes.map(route => ({
        ...route,
        title: route.title ? t(route.title) : null,
        name: route.name ? t(route.name) : '',
        subs: route.subs ? route.subs.map(sub => ({
            ...sub,
            label: sub.label ? t(sub.label) : ''
        })) : []
    }));
}