class AdminRequestCreatePaymentBGPGModel {
    constructor({
                    invoiceReference = '',
                    debtorAccountMessage = '',
                    instructedAmount = {},
                    creditorName = '',
                    creditorGiro = {},
                    paymentCollectionId='',

                } = {}) {
        this.invoiceReference = invoiceReference;
        this.debtorAccountMessage = debtorAccountMessage;
        this.instructedAmount = instructedAmount;
        this.creditorName = creditorName;
        this.creditorGiro = creditorGiro;
        this.paymentCollectionId = paymentCollectionId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        /*const amount = i18n.t('modelLabel.amount');
        const creditorName = i18n.t('openPayment.creditorName');
       const creditorAccount = i18n.t('openPayment.creditorAccountIban');*/

        const attributes = {
            invoiceReference :{
                //'label': amount,
                //'sortable': true,
                //'sortField': 'instructedAmount',
                'value': (item) => item.invoiceReference || "",
            },
            debtorAccountMessage :{
                //'label': amount,
                //'sortable': true,
                //'sortField': 'instructedAmount',
                'value': (item) => item.debtorAccountMessage || "",
            },
            instructedAmount :{
                //'label': amount,
                //'sortable': true,
                //'sortField': 'instructedAmount',
                'value': (item) => item.instructedAmount || "",
            },
            creditorName :{
               // 'label': creditorName,
                //'sortable': true,
                //'sortField': 'creditorName',
                'value': (item) => item.creditorName || "",
            },
            creditorGiro:{
                //'label': creditorAccount,
                //'sortable': true,
                //'sortField': 'creditorAccount',
                'value': (item) => item.creditorGiro || "",
            },
            paymentCollectionId :{
                //'label': 'paymentCollectionId',
                //'sortable': true,
                //'sortField': 'paymentCollectionId',
                'value': (item) => item.paymentCollectionId || "",
            },
        }
        return attributes;
    }
}
export default AdminRequestCreatePaymentBGPGModel;