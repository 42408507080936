export const se  = {
    footer: {
        text: 'Design och utveckling av Trust Anchor Group',
    },
    userNotifications: {
        notifications: 'Meddelanden',
        text: 'Markera alla som lästa',
    },
    roterLogin:{
        buttonBack: 'Tillbaka',
        spinner: 'Laddar användardata...',
        loginQR: 'Inloggning QR'
    },
    notificationsDropdown:{
        notifications: 'Anmälningar',
        text: 'Markera alla som lästa',
        icon: 'Visa mer...',
    },
    sortableList:{
        search: 'Sök',
        buttonTable: 'Tabell',
        buttonGrid: 'Grid',
        loading: 'Laddar...',
        itemsPage: 'rader per sida',
        noResults: 'Inga resultat hittades.',
        apiErrorResult: 'Kunde inte ansluta till API',
        gettingData:'Hämtar data...',
        exportData: 'Exportera ',
        all:'Alla rader'
    },
    reportProceeding:{
        calculations:{
            title: 'Beräkningar',
            subtitle: 'Utveckling',
            salary: 'Arvode',
            gross: 'Brutto',
            hours: 'Antal tim * timarvode eller fast belopp',
            mileage: 'Bilersättning',
            taxFree:{
                title: 'Skattefri del:',
                text: '',
            },
            taxable: {
                title: 'Skattepliktig del:',
                text: 'Km kr + Totalt per passagerare km kr - skattefri del',
            },
            incomeTax:{
                title: 'Inkomstskatt:',
                text: 'Beskattningsbar * Inkomstskatt % / 100',
                textGross: 'Brutto * Inkomstskatt % / 100'
            },
            net:{
                title: 'Netto:',
                text: 'Arbetsgivaravgift + beskattningsbar - inkomstskatt',
            },
            employmentTax: {
                title: 'Arbetsgivaravgift:',
                text: 'Beskattningsbar * Arbetsgivaravgift % / 100',
                textGross: 'Brutto * Arbetsgivaravgift % / 100'
            },
            expenses: 'Utlägg',
            amount: 'Belopp:',
            amountWithout: 'Belopp exkl moms:',
            tax: 'Skatt',
            total: 'Totalt',
            totalText: 'Belopp + Skatt',
            amountTotal: 'Belopp - Skatt'
        }
    },
    ////////////////////////////////////////////////////////////
    payReceive: {
        payReceiveTitle: 'Betala och Ta emot',
        payReceive: 'Betala/Ta emot',
        pay: 'Registrera utbetalning',
        quickPay: 'Registrera betalningsönskemål',
        bankAccount: 'Bankkonto',
        bankgiro: 'Bankgiro',
        plusgiro: 'Plusgiro',
        selectPayment: 'Välj betalsätt och fyll i betalningsuppgifter',
        payment:'Betalning',
        transfers:'Överföringar',
        sign: 'Signera för betalning',
        // buttonPay: ' Betala Faktura',
        // buttonManagement: 'Hantera utlägg',
        // buttonTransfer: 'Direktöverföring',
        quickPayment: 'Snabb betalning',
        quickPayments: 'Snabba betalningar',
        quickPaymentSubtitle: 'Fyll i betalningsuppgifterna',
        //temporalAlert:'Denna sida är endast visuell, den har ingen funktionalitet',
        payInvoice:'Betala faktura',
        paymentType: 'Betalningstyp',
    },
    payDirectTransmission:{
        transfer: 'Direktöverföring',
        paymentRefers: 'Betalning avser*',
        number: 'Clearingnummer*',
        accountNumber: 'Kontonummer*',
        notes: 'Notering*',
        amount: 'Belopp*',
        //save: 'Spara i lista',
       // sign: 'Signera för betalning',
        second: 'Andra fliken',
        managing: 'Hantera faktura',
        selectInvoice: 'Välj en faktura från listan för att hantera betalningar',
    },
    /*payExpenses:{
        notifications: 'Notiser',
        number: 'Antal förfallna',
        unpaid: 'Obetalda utlägg',
        from: 'Sedan',
        paid: 'Betalda utlägg',
        add: 'Lägg till utlägg',
        new: 'Nytt kvitto',
        manage: 'Hantera faktura',
        select: 'Välj en faktura från listan för att hantera betalningar'
    },
    payInvoice:{
        notifications: 'Notiser',
        number: 'Antal förfallna',
        unpaid: 'Obetalda fakturor',
        date: 'Förfallodatum',
        paid: 'Betalda fakturor',
        add: 'Lägg till faktura',
        new: 'Skapa faktura',
        download: 'Ladda ner underlag',
        sign: 'Signera för betalning',
        manage: 'Hantera faktura',
        select: 'Välj en faktura från listan för att hantera betalningar'
    },*/
    //////////////////////////////////////New/////////////////////////////
    modelLabel: {
        id: 'Id',
        org:'Organisation',
        orgId: 'Organisations-Id',
        orgName:'Organisationsnamn',
        orgType: 'Organisationstyp',
        orgDetails:'Uppgifter om organisationen',
        orgNumber:'Organisationsnummer',
        orgDestination: 'Destination',
        costPlace: 'Kostnadsställe',
        createdAt:'Skapad',
        createdBy:'Skapad av',
        creatorName:'Skapad av',
        attestAmount:'Attestbelopp',
        updatedBy:'Uppdaterad av',
        updatedAt: 'Uppdaterad kl',
        updatedByName: 'Uppdaterad av (namn)',
        modelId:'Modell Id',
        user: 'Användare',
        userId: 'Användar-Id',
        userName: 'Personens namn',//Användarnamn
        name:'Namn',
        parentId: 'Föräldra-Id',
        parentName: 'Tillhör',
        fullName: 'Fullständigt namn ',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        info: 'Upplysningar',
        country: 'Land ',
        sweden: 'Sverige',
        pnr: 'Personnummer',
        email: 'E-post',
        phone: 'Telefon',
        employmentTax: 'Arbetsgivaravgift',
        incomeTax: 'Inkomstskatt',
        net:'Netto',
        date:'Datum',
        dateReceive: 'Kvittodatum',
        status:'Status',
        amount:'Belopp',
        amountInvoice:'Belopp före moms',
        addAmount: 'Lägg till belopp', ///,mirar si esta traduccion se usa en algun sitio, si no borrarla
        type:'Typ',
        typeId:'Typ Id',
        types:'Typer',
        vatAmount: 'Varav moms',
        vatAmountInvoice: 'Moms 25%',
        accountingId:'Utläggstyp',
        accounting:'Utläggstyp',
        fileId: 'Fil Id',
        fileName:'Filnamn',
        file: 'Lägg till underlag',
        files: 'Filer',
        additionalFileId: 'Ytterligare fil Id',
        additionalFileName:'Ytterligare filnamn',
        kr: 'kr',
        km: 'km',
        hours: 'Timmar',
        kilometers: 'Körsträcka (km)',//Körsträcka (km) //kilometres
        total: 'Totalt',
        totalAmount: 'Totalt belopp',
        welcome: 'Välkommen',
        profile: 'Profil',
        logOut: 'Logga ut',
        paid: 'Betald',
        unpaid: 'Obetald',
        yearly: 'Årligen',
        monthly: 'Månadsvis',
        description:'Beskrivning',
        model:'Modell',
        balance: 'Balans',
        currency:'Valuta',
        from:'Från',
        to:'Till',
        added:'Tillagt',
        grossAmount:'Summa',
        soon: 'Snarast',
        whenToPay: 'Betaldatum',
        reason: "Orsak",
        forYou: 'För dig',
        creditorName: 'Mottagare',
        forYourOrganization: 'För din organisation',
        userSalary: 'Ersättning',
        initialized: 'Skickad',
        aborted: 'Avbruten',
        message: 'Meddelande',
        recipient: 'Mottagare',
        paymentMethod: "Betalningsmetod",
        transactionDate: "Transaktionsdatum",
        approvedDeniedByName: "Övervakas av",
        generated: "Genererad",
        salarySheet: "Lönespecifikation",
        active: "Aktiv",
        deleted: "Raderad",
        taxAuthority: "Rapporter till skattemyndigheten",
        lastRunAt: 'Senaste körningen',
        paymentType: 'Betalningstyp',
        credited:'Krediterad',
        revoked: 'Återkallad',
        attestQueue: 'Attestkö',
        TheSwedishTaxAgency: 'Skatteverket',
        comment:'Kommentar',
        download: 'Ladda ner',
        transaction: 'Öppna betalningstransaktioner',
        bookedDate: 'Bokningsdatum',
        balanceAfterTransaction: 'Balans efter transaktion',
        openPaymentAccountId: 'Öppna betalningskontots ID',
        openPaymentTransactionId: 'Öppna betalningstransaktionens ID',
        openPaymentStatus: 'Öppna betalningsstatus',
        transactionListId: 'Transaktionslistans ID',
        updateTransactions: 'Uppdatera transaktioner',
        consent: 'Samtycke',
        consentCode: 'Samtyckeskod',
        invoiceNumber: 'Fakturanummer',
        paidOut: 'Utbetald',
        sentAt: 'Skickas kl',
        daily: 'Dagligen (Test)',
        collect: 'Samla in',
        reports:'Rapporter',
        totalDistance: 'Sammanlagd sträcka (km)',
        refresh: 'Uppdatera',
        refreshExpired: 'QR-kodsessionen har löpt ut.',
    },
    adminLabel: {
        admin:'Administration',
        organizations: 'Systemets organisationer',
        details:'Organisationens uppgifter',
        users:'Systemets användare',
        systemRoles: 'Systemroller',
        orgUser: 'Organisationens användare',
        orgUsers:'Organisationens användare',
        orgRoles: 'Systemets roller',
        digitalAccount: 'Digitala kassor i systemet',
        systemAdmin: 'Systemadministratör',
        system:'Systemet',
        allAccount: 'Alla kassor',
        assignRoles: 'Tilldela roller',
        assignOrg: "Tilldela organisation",
    },
    button:{
        back: 'Tillbaka',
        cancel: 'Avbryt',
        delete: 'Radera',
        send: 'Skicka',
        sendToAttest: 'Skicka till attest',
        sendAndCreateCopy: 'Skicka och skapa kopia',
        save: 'Spara',
        loading: 'Laddar...',
        edit: "Redigera",
        info: 'Info',
        accept:'OK',
        continue:'Fortsätt',
        moreInfo:'Mer information',
        hideInfo:'Dölj information',
        create: 'Skapa',
        assign: 'Tilldela',
        active: 'Aktivera',
        taxAuthority: "AGI-rapporter",
        renew: 'Förnya',
        authorize: 'Godkänn',
        home: "Hem",
        close: "Stäng fönstret",

    },

    validationMessage:{
        sendError: 'Ett fel har uppstått, försök igen senare eller kontakta LRF',
        sendSuccessfully: 'Ersättningsbegäran har lämnats in!',
        fileDeleted: 'Filen har raderats',
        fileFormatIncorrect: 'Filformatet är felaktigt',
        fileFormatCorrect: 'Filformatet är korrekt',
        sendForm: 'Dina uppgifter skickas nu in, vänligen vänta...',
        modalVar: 'Är du säker på att du vill radera',
        modalImage: 'Är du säker på att du vill radera den här bilden?',
        modalItem: 'Är du säker på att du vill ta bort detta objekt?',
        modalAmount: 'Är du säker på att du vill radera detta belopp?',
        fieldRequired: 'Fält krävs',
        validOption: 'Vänligen välj ett giltigt alternativ',
        greater0: 'Värdet måste vara större än 0',
        digits4: 'Numret måste vara 4 siffror',
        digits7: 'Numret måste vara 7 siffror',
        digits2: 'Numret måste vara 2 siffror',
        max12: 'Max 12 siffror',
        max140: 'Max 140 tecken',
        between05and24: 'Värdet måste vara mellan 0,5 och 24',
        minDigit4: 'Numret måste vara minst fyra siffror',
        vatLessAmount: 'Momsbeloppet är för stort',
        fileDeletedImportant: 'Obs! Om du tar bort bilden raderas den permanent, även om du sedan klickar på Avbryt',
        wrong: 'Något gick fel',
        changeNotSave:' Du har osparade ändringar. Vill du verkligen gå vidare?',
        reimbursementSuccessfully:'Ersättningsbegäran har skapats',
        reimbursementSavedSuccessfully:'Ersättningsbegäran har sparats',
        internalTransactionSuccessfully: 'Intern transaktion har skapats',
        honorariumSuccessfully:'Arvode har skapats',
        honorariumUpdate:'Arvodet har uppdaterats',
        bankAccountSave:'Bankkontot har sparats',
        bankAccountUpdate:'Bankkonto har uppdaterats',
        attestApproved:'Attest genomförd',
        attestDenied: "Transaktionen har nekats",
        roleSuccessfully:'Rollen har skapats framgångsrikt',
        permissionUpdate:'Behörigheterna har uppdaterats',
        typeSuccessfully:'Typ har skapats framgångsrikt',
        notAccount:'Personen har inte registrerat ett bankkonto',
        insufficientBalance:'Organisationens konto har inte tillräckliga medel',
        modalAttest:'Vill du verkligen attestera denna post?',
        modalAttestDeny:'Vill du verkligen neka denna post?',
        modalAttestDenyHeader: 'Neka post',
        canNotBeEmpty: "Fält får inte vara tomt",
        paySuccessfully: 'Betalningen har skapats',
        selectPayment: 'Du måste välja en betalning',
        sendReimbursement:'Vill du verkligen skicka in denna ersättningsbegäran?',
        organizationNotMoney: 'Organisationen har inte tillräckligt med pengar',
        sameOrg: 'Organisationerna kan inte vara samma',
        payAborted: 'Betalningen har avbrutits',
        needAccount: 'Du måste ha ett konto för att skicka en begäran',
        goAddAccount: 'Gå till Lägg till konto',
        invalidEmail: 'E-postmeddelandet måste innehålla minst ett @ och en giltig domän (t.ex. example@domain.com)',
        deleteUser: 'Vill du verkligen radera användaren?',
        activeUser: 'Vill du verkligen aktivera användaren?',
        client: 'Slienten har skapats framgångsrikt',
        invoiceSave: 'Fakturan har sparats',
        invoiceSend: 'Fakturan har skickats',
        invoiceSendToAttest: 'Fakturan har skickats för attest',
        needSave: 'Fakturan måste sparas',
        QRCode:'QR-koden har skapats framgångsrikt',
        clientPermission: 'Du behöver klienttillstånd för att visa detta fält',
        loadingPayment:'Ladda betalningsmetod',
        dontLetter:'Endast siffror tillåtna',
        onlyNumbers: 'Felaktigt talformat, använd heltal eller tal med upp till två decimaler',
        creditInvoice :'Kreditfaktura skapades framgångsrikt',
        sendCreditInvoice:'Vill du verkligen skapa en fakturakredit?',
        changeStatus:'Är du säker på att du vill ändra statusen till betalad?',
        consentExpired: 'Samtycket har gått ut',
        consentNotFound: 'Samtycket hittades inte',
        accountIdNotValid: 'Kontot är inte giltigt',
        serviceUnavailableTryAgain: 'Tjänsten är inte tillgänglig, försök igen senare.',
        renewConsentSuccessfully: "Samtycke har förnyats framgångsrikt",
        consentAuthorizationPage: "Sidan för auktorisering av samtycke kommer att öppnas snart",
        activeConsentSuccessfully: "Samtycke har aktiverats framgångsrikt",
        updateTransactionsSuccessfully: "Transaktioner har uppdaterats framgångsrikt",
        consentRejected: "Samtycket har avvisats",
        sendingReimbursement: "Skickar ersättningsbegäran, vänta...",
        savingReimbursement: "Sparar ersättningsbegäran, vänta...",
        sendingPay: "Skickar betalningen, vänta...",
        savingClient: "Sparar klienten, vänta...",
        sendingInvoice: "Skickar fakturan, vänta...",
        savingInvoice: "Sparar fakturan, vänta...",
        sendingQr: "Skickar QR-koden, vänta...",
        sendingInternalTransaction: "Skickar intern transaktion, vänta...",
        sendingHonorarium: "Skickar arvodet, vänta...",
        loadingSieData: "Laddar SIE-data, vänta...",
        assigningRoles: "Tilldelar roller, vänta...",
        assigningOrgs: "Tilldelar organisationer, vänta...",
        sendingConsent: "Skickar samtycke, vänta...",
        deleteFixed: "Är du säker på att du vill ta bort arvodet?",
        deleteMileage: "Är du säker på att du vill ta bort milersättningen?",
        deleteExpense: "Är du säker på att du vill ta bort utlägget?",
        consentIsValidated: "Samtycket är validerat",
        consentIsValidating: "Validerar samtycke, vänta...",
        selectAmountType: "Det är nödvändigt att välja en beloppstyp",
    },
    formPlaceholder: {
        selectDate: 'Välj datum',
        selectOption: 'Välj ett alternativ',
        datePicker: 'Från datum - Till datum',
        enterRole: 'Ange roll',
        enterPermissions: 'Ange behörigheter',
        enterType: 'Ange typ',
        enterQuantity: '', //Ange antal
        enterKm: 'Ange sträcka i km',
        enterAmount: 'Ange belopp',
        dragAndDrop: 'Släpp en fil här eller klicka för att välja en',
        enterDate:'Ange datum',
        enterPurpose:'Ange syfte',
        enterVatAmount: 'Ange momsbelopp',
        enterHours: 'Ange timmar',
        enterOrigin: 'Ange avreseort',
        enterDeparture: 'Ange avreseort',
        enterDestination: 'Ange destination',
        enterPassengers: 'Ange passagerare',
        enterTotalAmount: 'Ange totalt belopp',
        selectUserName: 'Välj användarnamn',
        selectOrg:'Välj organisation',
        firstPayment:'Datum för första betalning',
        modelEnterClearingNumber:'Ange clearingnummer',
        modelEnterAccountNumber:'Ange kontonummer',
        reason: 'Ange orsak',
        digits: 'siffror',
        enterCreditorName:'Ange borgenärens namn',
        selectClientName: 'Välj mottagarnamn',
        enterRecipient: 'Ange mottagarreferens',
        enterReference: 'Ange referens',
        enterPrice: 'Ange enhetspris',
        enterClientName: 'Ange mottagarens namn',
        enterOrgNumber: 'Ange organisationsnummer',
        enterAddress: 'Ange adress',
        enterPostalCode: 'Ange postnummer',
        enterCity: 'Ange ort',
        enterEmail: 'Ange e-postadress',
        enterTitle: 'Rubrik som skrivs ut på skylten, ex Fika',
        enterQRName: 'Namn som bara syns här i systemet, Fika vid årsmöte 2024',
        enterDescription: 'Beskrivning som syns på skylten, ex Kaffe och kaka 20 kr',
        enterComment: 'Ange kommentar'
    },
    reimbursement: {
        reportProcessing: 'Ersättningsbegäran',
        reportProcessings: 'Ersättningsbegäran',
        newProcessing: 'Skapa ny ersättningsbegäran',
        sendInfo: "Ange åtminstone ett arvode,bilersättning eller tillägg",
        salary: 'Arvode',//Lägg till arvode
        hourly: 'Timbaserat',
        movable: 'Rörligt',
        fixed: 'Engångsbelopp',
        addSalary: 'Lägg till arvode',
        purpose: 'Syfte',
        costBearer: 'Aktivitetsområde',
        costBearerName: 'Aktivitetsområdets namn',
        expense:'Lägg till utlägg',
        expenseTitle:'Utlägg',
        addExpense: 'Lägg till kostnad',
        mileageCompensation: 'Bilersättning',
        mileage:'Bilersättning',
        addMileageCompensation: 'Lägg till bilersättning',
        passengers: 'Passagerare',
        numberPassengers: 'Antal passagerare',
        addCompensation: 'Lägg till',
        addPassengers: 'Lägg till passagerare',
        addMileage: 'Lägg till körsträcka',
        cancelPassenger: 'Ta bort passagerare',
        origin: 'Från',
        destination: 'Till',
        receipt: 'Lägg till underlag',
        programInvitation: 'Bifoga program eller kallelse',
        listParticipants: 'Bifoga deltagarlista',
        amount:'Belopp',
        addReceipts:'Lägg till underlag',
        newReimbursementId: "Ersättningsbegäran Duplikatreferens",
        deniedMessage: "Denna begäran om ersättning godkändes inte. Klicka på knappen nedan för att göra korrigeringar och skicka in på nytt.",
        deniedMessageCreate: "Tidigare begäran om ersättning godkändes inte",
        sent: 'För godkännande',
        draft: 'Utkast',
        passengersHelpText: 'Lägg samman de sträckor som varje medpassagerare åkt och skriv in',
    },
    honorarium: {
        honorarium:'Årligt arvode',
        update: 'Uppdatering om Arvoden',
        create: 'Skapa årligt arvode',
        annual: 'Årligt arvodesbelopp',
        payment: 'Betalningsintervall',
        first: 'Första betaldatum',
        queued: 'Betalningar i kö',
        newHonorarium: 'Nytt arvode'
    },
    role:{
        userRole:'Användarroll',
        orgRole:'Organisationsroll',
        orgRoles:'Organisationens roll',
        orgType:'Organisationstyp',
        roles:'Roller',
        permissions:'Behörigheter',
        infoAlert:' Kom ihåg att trycka på enter för att infoga behörigheterna.',
        addRole: 'Lägg till roll',
        addType:'Lägg till typ',
        role:'Roll',
        theseAreThePermissions: 'Det här är de möjliga behörigheterna',
        explanation: 'Om du väljer det första ordet har du full behörighet. ("attest" är som "attest.approve" och "attest.view").Om du lägger till "create","update", "approve","deny" behörigheter individuellt, måste du också välja "view"'
    },
    userSetting: {
        bankAccount:'Bankkonto',
        settings:'Inställningar',
        modelClearingNumber:'Clearingnummer',
        modelAccountNumber:'Kontonummer',

    },
    digitalAccount:{
        label: 'Digital kassa',
        list:'Kontolista',
        accountRowList:'Kontohändelser',
        accountRowInfo:'Kontorad Info',
    },
    attest: {
        attest: 'Attest',
        approve:'Godkänn',
        labelList:'Attestlista',
        labelInfo:'Intyga info',
        attested: 'Intygad',
        approved:'Godkänd',
        pending:'För granskning',
        deny: "Neka",
        denied: "Nekad",
        deniedBy: 'Nekad av',
        paymentInformation: 'Betalningsinformation',
        recipientAccount: 'Mottagarens konto',
        paidDateTime: 'Utbetalningsdatum',
    },
    dashboard: {
        dashboard: 'Instrumentbräda',
        bodyTitle: 'Tack för att du är med och testar vår nya plattform!',
        bodyText: 'Tack för att du tar dig tid att testa. Er feedback är viktig för att vi ska kunna lösa eventuella buggar och förbättra upplevelsen.',
    },
    exportSieFile: {
        label:'Bokföring',
        moddelExportDocument:'Vill du förhandsgranska filen eller exportera den direkt?',
        modelPreview:'Förhandsvisning',
        modelErrorMessageExport:'Det finns ingen information att exportera i detta datumintervall.',
        periodNotFound: 'Det finns inga uppgifter under denna tidsperiod',
    },
    openPayment: {
        creditorName: 'Mottagare',
        creditorAccountIban: 'Mottagarkonto',
        creditorAccountBban: 'Mottagarkonto',
        bankTransfers: 'Banköverföringar',
        pendingBankList:'Väntande banköverföringar',
        historyBankList: 'Överföringshistorik',
        goHistory: 'Se historik',
        bulkPayment: 'Signeringskorg',
        sign:'Signera',
        instructedAmount:'Belopp',
        activeConsentCode: "Din aktiva samtyckeskod är",
        consentCodeNotFound: "Samtyckeskoden hittades inte",
        infoMessage: `Steg för att auktorisera och aktivera en samtyckessession:\n 1. Klicka på knappen "Förnya" för att skapa/förnya ett samtycke om du inte har ett eller om det har löpt ut (steg första).\n 2. Efter att ha klickat på knappen "Auktorisera" (steg 2) öppnas en sida för att verifiera användaren med BankId eller liknande, och slutligen omdirigeras du till en sida där samtycke kommer att vara aktivt.\n 3. När du har slutfört dessa steg kan du klicka på knappen "Uppdatera transaktioner".`
    },
    internalTransaction: {
        internalTransaction: 'Intern transaktion',
        create: 'Skapa intern transaktion',
    },
    accessibility:{
        selectLanguage:'Språkväljare',
        logo:'Kassans logotyp kan du gå till instrumentpanelen från denna länk',
        goFirst:'gå till första sidan',
        goLast:'gå till sista sidan',
        prevPage:'föregående sida',
        backPage: 'nästa sida',
        userOptions:'användaralternativ',
        registered:'registrerad',
    },
    invoice: {
        invoice: 'Skapa faktura',
        creditInvoice: 'Kreditfaktura',
        creditInvoiceRef: 'Fakturareferens',
        createInvoice: 'Skapa faktura',
        createCreditInvoice: 'Skapa kreditfaktura',
        clientName: 'Mottagare',
        invoiceList: 'Fakturalista',
        addClient:'Lägg till ny mottagare',
        billingAddress: 'Faktureringsadress',
        invoiceRecipient: 'Fakturamottagare',
        expiringDate: 'Förfallodatum',
        date: 'Förfallodatum',
        recipientRef:'Er referens',
        reference:'Referens',
        invoiceRow: 'Fakturarader',
        productName: 'Namn/beskrivning',
        quantity:'Antal',
        enterQuantity:'Ange antal',
        unitPrice: 'Enhetspris (exkl moms)',
        vatAmount: 'Momssats',
        addInvoiceRow: 'Lägg till fakturarad',
        draft:'Spara utkast',
        sendAttest: 'Skicka för attest',
        previewInvoice: 'Förhandsgranska faktura',
        address: 'Adress',
        postalCode:'Postnummer',
        city: 'Ort',
        totalPriceRow: 'Radbelopp',
        changeStatus: 'Ändra status',
        paid:'Betalad',
        sentInvoices: 'Skickade fakturor',
    },
    QRCode: {
        title: 'Rubrik',
        name: 'Internt namn för insamlingen',
        orgQRLabel: "QR-koder",
        thereIsComment: 'Kräv att betalare anger ett meddelande',
        generate: 'Generera QR-skylt',
        create: 'QR-kod för insamling',
        totalPaid:'Insamlat belopp',
        totalPayments:'Antal betalningar',
        amount: 'Eget belopp att bidra med',
        choosePaymentMethod: 'Välj betalsätt',
        label: 'QR-kod för insamling',
        totalCollected: 'Totalt insamlat',
        disable: 'Inaktivera insamling',
        enable: 'Aktivera insamling',
        addCollection: 'Skapa ny insamling',
        listActive: 'Aktiva QR-koder',
        listInactive: 'Avaktiverade QR-koder',
        defaultAmounts: 'Ange förvalda belopp',
        defaultAmountsToContribute: 'Ange ett eller flera valbara belopp. Tryck på enter efter varje belopp för att bekräfta. ',
        addCustomAmount: 'Låt betalaren ange valfritt belopp'
    }
};
