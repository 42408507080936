import {Col, Spinner} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export function LoadingData({data}) {
    const { t } = useTranslation();
    return(
        data === null ?
            <Col lg={12} className="text-center py-5">{t('sortableList.apiErrorResult')}</Col>
            :
            <Col lg={12} className="d-flex justify-content-center py-5">
                <Spinner color='success' className="me-3" />
                {t('sortableList.gettingData')}
            </Col>

    );

}