import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import * as signalR from '@microsoft/signalr';
import { API_URL } from '../../config';

const NotificatioSignalRComponent = ({ onMessageReceived }) => {
  const { userData } = useSelector(state => state.auth);
  const [hubConnection, setHubConnection] = useState(null);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${API_URL}/notifications?userId=${userData.id}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        withCredentials: true,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect(0, 0, 10000)
      .build();

    setHubConnection(connection);

    return () => {
      connection.stop();
    };
  }, [userData]);

  useEffect(() => {
    if (hubConnection) {
      hubConnection.start()
        .then(() => {
          hubConnection.on('ReceiveNotification', onReceiveNotification);
        })
        .catch(error => {
          console.error('Error establishing SignalR connection:', error);
        });
    }
  }, [hubConnection]);

  const onReceiveNotification = (message) => {
    onMessageReceived(message); 
  };

  return <></>;
};

export default NotificatioSignalRComponent;

