class AdminResponseUpdateBasketStatusModel {
    constructor({
                    scaStatus = '',
                    links = {},
                    data = '',
                } = {}) {
        this.scaStatus = scaStatus;
        this.links = links;
        this.data = data;

    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            scaStatus :{
                //'label': 'scaStatus',
                'sortable': true,
                'sortField': 'scaStatus',
                'value': (item) => item.scaStatus || "",
            },
            links :{
                //'label': 'open Payment Id',
                'sortable': true,
                'sortField': 'links',
                'value': (item) => item.links || "",
            },
            data :{
                //'label': 'open Payment Id',
                'sortable': true,
                'sortField': 'data',
                'value': (item) => item.data || "",
            },

        }
        return attributes;
    }
}
export default AdminResponseUpdateBasketStatusModel;