import { useDispatch } from 'react-redux';
import { logoutUser} from "../../../AppSystem/Redux/Auth/AuthSlice";
import {PUBLIC_PATHS} from "../../../config";
import {useEffect} from "react";
import {CustomReload} from "../../../AppSystem/Helpers/CustomReload";


const LogOut = () => {
    const dispatch = useDispatch();
    useEffect(async() => {

        dispatch(logoutUser());
        CustomReload(PUBLIC_PATHS.APP);
    }, []);

}
export default LogOut;


