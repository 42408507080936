import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";

class RequestReimbursement {
    constructor({
                    id = '',
                    orgId = '',
                    orgName = '',
                    date = '',
                    purpose = '',
                    costBearerId = '',
                    costBearerName = '',
                    status = '',
                    fixedId = '',
                    mileageId = '',
                    expenseIds = [],
                    reason = ""
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.orgName = orgName;
        this.date = date;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.status = status;
        this.fixedId = fixedId;
        this.mileageId = mileageId;
        this.expenseIds = expenseIds;
        this.reason = reason;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const date = i18n.t('modelLabel.date');
        const purpose = i18n.t('reimbursement.purpose');
        const costBearerId = i18n.t('reimbursement.costBearer');
        const fixedId = i18n.t('reimbursement.fixed');
        const mileageId = i18n.t('reimbursement.mileage');
        const expenseIds = i18n.t('reimbursement.expense');
        const required = i18n.t('validationMessage.required');
        const orgId = i18n.t('modelLabel.org');

        let optionsCostBearer = [];
        if (optionsData) {
            optionsData.costBearer.map(item => {
                optionsCostBearer.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const completeSchema = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            orgName: string().nullable(),
            date: string().nullable().test("is-required", `${required}`, value => {
                return value !== "";
            }),
            purpose: string().nullable().test("is-required", `${required}`, value => {
                return value !== "";
            }),
            costBearerId: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.validOption')}`, value => {
                return value !== "";
            }),
            status: string().nullable(),
        });

        const attributes = {
            id :{
                'label': "Id",
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.id,
                },
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': orgId,
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': orgId,
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.orgName,
                },
                'value': (item) => item.orgName || "",
            },
            date :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.date,
                },
                'value': (item) => item.date || "",
            },
            purpose :{
                'label': purpose,
                'form': {
                    'type': 'text',
                    'placeholder': purpose,
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': costBearerId,
                'form': {
                    'type': 'select',
                    'options':optionsCostBearer,
                    'placeholder': costBearerId,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            costBearerName :{
                'label': costBearerId,
                'value': (item) => item.costBearerName || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.status,
                },
                'value': (item) => item.status || "",
            },
            fixedId :{
                'label': fixedId,
                'form': {
                    'type': 'text',
                    'placeholder': fixedId,
                    //'validation': completeSchema.Fields.fixedId,
                },
                //'name':'fixedId',
                'value': (item) => item.fixedId || "",
            },
            mileageId :{
                'label': mileageId,
                'form': {
                    'type': 'text',
                    'placeholder': mileageId,
                    //'validation': completeSchema.Fields.name,
                },
                'value': (item) => item.mileageId || "",
            },
            expenseIds :{
                'label': expenseIds,
                'form': {
                    'type': 'text',
                    'placeholder': expenseIds,
                    //'validation': completeSchema.Fields.name,
                },
                'value': (item) => item.expenseIds || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestReimbursement;