import React, {useEffect, useState} from "react";
import AdminRequestHonorariumCreate from "../Models/Request/AdminRequestHonorariumCreate";
import {Card, Col, Row, Spinner} from "reactstrap";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseUserModel from "../../User/Models/Response/ResponseUserModel";
import ResponseOrgModel from "../../Org/Models/Response/ResponseOrgModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import $ from 'jquery'
import {useTranslation} from "react-i18next";
import AdminResponseHonorarium from "../Models/Response/AdminResponseHonorarium";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";

export function AdminHonorariumCreate() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();

    const [honorarium, setHonorarium] = useState(new AdminRequestHonorariumCreate());
    const [responseHonorarium, setResponseHonorarium] = useState(new AdminResponseHonorarium());

    const completeSchemaHonorarium = AdminRequestHonorariumCreate.getAttributes( null, true);

    const [apiResponseUser, setApiResponseUser] = useState('');
    const [apiResponseOrg, setApiResponseOrg] = useState(null);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(null);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);
    const [costSelected, setCostSelected] = useState(null);

    const [displaySpinner, setDisplaySpinner] = useState(false);

    // GET USERS FOR SELECT
    useEffect(() => {
        const userData = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: '/user/admin/search?perPage=10000&sortfield=firstName&sort=asc',
                };
                ApiCall(apiCallOptions, setApiResponseUser);
            } catch (error) {
                console.log('error', error);
            }
        };
        userData()
    }, []);

    // GET ORGANIZATIONS FOR SELECT
    useEffect(() => {
        if (selectedUser !== null) {
            const handleApiResponse = (apiResponse) => {
                setApiResponseOrg(apiResponse);
            };
            const orgData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/admin/org-user/search?userid=${selectedUser}&perPage=10000&sortfield=name&sort=asc`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            orgData();
        }
    }, [selectedUser]);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list",
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    let costJson = {};
    if (costResponse !== null) {
        const costBearerArray = [];
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
        costJson = {
            costBearer: costBearerArray
        };
    }

    let jsonUsers = {};
    if (apiResponseUser !== null && apiResponseUser !== '' ) {
        const userArray = [];
        apiResponseUser.items.forEach((item) => {
            const userModel = new ResponseUserModel(item);
            userArray.push(userModel);
        });
        jsonUsers = {
            userId: userArray,
        };
    }

    let jsonOrg = {};
    if(selectedUser !== null) {
        if (apiResponseOrg !== null) {
            const orgArray = [];
            apiResponseOrg.items.forEach((item) => {
                const orgModel = new ResponseOrgModel(item);
                orgArray.push(orgModel);
            });
            jsonOrg = {
                orgId: orgArray,
            };
        }
    }

    const handleChangeHonorarium = (e, name) => {
        let value;
        if (name === 'paymentInterval') {
            if (e && e.target && e.target.value) {
                value = parseInt(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'annualHonorarium') {
            if (e && e.target && e.target.value) {
                value = CheckDecimal(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'firstPayment') {
            value = ChangeDateFormat('date', e);
        }
        if (name === "userId") {
            if (apiResponseUser !== null && apiResponseUser !== '') {
                apiResponseUser.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedUser(item.id);
                            value = e.value;
                        }
                    }
                });
            }
        }
        if (name === "orgId") {
            if (apiResponseOrg !== null) {
                apiResponseOrg.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedOrg(item.id);
                            value = e.value;
                        }
                    }
                });
            }
        }
        if (name === "costBearerId" && costResponse !== null) {
            costResponse.forEach(item => {
                if (item.id === e.target.value) {
                    setCostSelected(item.id);
                }
            });
            value = e.target.value;
        }
        setHonorarium((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleValidation = async () => {
        try {
            await completeSchemaHonorarium.validate(honorarium, { abortEarly: false });
            $('#submitHonorarium').prop('disabled', true);
            setDisplaySpinner(true);
            if (honorarium && honorarium.userId !== "") {
                const apiCallOptions = {
                    method: "post",
                    url: "/honorarium/admin",
                    data: honorarium,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseHonorarium);
                } catch (error) {
                    setDisplaySpinner(false);
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (responseHonorarium && responseHonorarium.id !== "") {
            FlashMessage(t('validationMessage.honorariumSuccessfully'), 'flash-messageSuccess');
            setTimeout(()=>{
                navigate(`${ADMIN_PATHS.HONORARIUM_INFO}?id=${responseHonorarium.id}`);
            },2000);
        }
    }, [responseHonorarium]);

    // TODO: MOVE TO CUSTOM INPUT
    const annualHonorariumInput = document.getElementById('annualHonorarium');
    if (annualHonorariumInput) {
        annualHonorariumInput.addEventListener('keypress', function(e) {
            const key = e.key;
            if (!((key >= '0' && key <= '9') || key === '.' || key === ',')) {
                e.preventDefault();
            }
        });
    }

   const messageCostBearer = InputMessage(costResponse, costSelected);

    return(
        <>
            <Col>
                <CustomButton text={t('button.back')} icon={'bx bx-chevron-left'} buttonSize={'small'} customClasses={'mb-3 ms-3'} onClick={() => {
                        navigate(ADMIN_PATHS.HONORARIUM_LIST);
                    }} />
            </Col>
            <Row className="pb-4">
                <Col lg={6}>
                    <Card className="card-backend p-4">
                        <Row>
                            <Col xs='auto'>
                                <h2 className='rokkitt-font'>{t('honorarium.create')}</h2>
                            </Col>
                            <InfoPageMessage message={'Test: message create Honorarium'}/>
                        </Row>
                        {apiResponseUser !== null && apiResponseUser !== '' ?
                            <Row>
                                <Col lg={6}>
                                    {/*{apiResponseUser !== null && apiResponseUser !== '' ?*/}
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='userId' data={''} customChange={(e) => {
                                        handleChangeHonorarium(e, 'userId');
                                    }} customOptions={jsonUsers}/>
                                    {/*: ''}*/}
                                </Col>
                                <Col lg={6}>
                                    {selectedUser !== null ?
                                        <CustomInput model={AdminRequestHonorariumCreate} attribute='orgId' data={''} customChange={(e) => {
                                            handleChangeHonorarium(e, 'orgId');
                                        }} customOptions={jsonOrg}/>
                                        : '' }
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='firstPayment' data={''} customChange={(e) => handleChangeHonorarium(e, 'firstPayment')}/>
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='paymentInterval' data={''} customChange={(e) => handleChangeHonorarium(e, 'paymentInterval')}/>
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='annualHonorarium' data={''} customChange={(e) => handleChangeHonorarium(e, 'annualHonorarium')}/>
                                </Col>
                                <Col lg={6}>
                                    {costResponse !== null ?
                                        <>
                                            <CustomInput model={AdminRequestHonorariumCreate} attribute='costBearerId' data={''} customChange={(e) => {
                                                handleChangeHonorarium(e, 'costBearerId');
                                            }} customOptions={costJson}/>
                                            {messageCostBearer}
                                        </>

                                        : ''}
                                </Col>
                                <Col lg={12} className="text-end pt-5">
                                    {displaySpinner === true ?
                                        <>
                                            {t('validationMessage.sendingHonorarium')} <Spinner color='success' className="me-3" />
                                        </>
                                    : ''}
                                    <CustomSubmitButton text={t('button.send')} id='submitHonorarium' icon='bx bx-send pe-2' customClick={handleValidation} />
                                </Col>
                            </Row>
                            :
                            <LoadingData data={apiResponseUser}/>}

                    </Card>
                </Col>
            </Row>
        </>
    )
}