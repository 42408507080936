import useApiCallService from "../../Common/Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";

export function AdminOrganizationInfo() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { t } = useTranslation()
    const { ApiCall } = useApiCallService();
    const [apiResponseOrgInfo, setApiResponseOrgInfo] = useState(null);
    const idValue = new URLSearchParams(window.location.search).get('id');
    const navigate = useNavigate();

    useEffect(() => {

        const handleApiResponseGetOrgUser  = (apiResponseOrgInfo) => {
            setApiResponseOrgInfo(apiResponseOrgInfo);

        };
        const GetOrgUser = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/org/admin?id=${idValue}`,
                };

                await ApiCall(apiCallOptions, handleApiResponseGetOrgUser );

            } catch (error) {
                console.log('error', error);
            }
        };

        GetOrgUser();

    }, []);

    return(
        <>
            <Col lg={1} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'m-3 rounded'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.ADMIN_ORGANIZATION)
                    }}
                />
            </Col>
            {apiResponseOrgInfo !== null ?
                <Col lg="12" className='card card-backend p-4 mb-5'>
                    <h2 className="rokkitt-font">{t('adminLabel.details')}</h2>
                    <ul className="list-unstyled">
                        {isSuperAdmin === true ?
                            <li>
                                <span className="fw-bold col-1">{t('modelLabel.id')}:</span>
                                <span className="col-11">{apiResponseOrgInfo.id}</span>
                            </li>
                            : ''}
                        {apiResponseOrgInfo.visualId !== "" ?
                            <li className="pt-2 row">
                                <span className="fw-bold col-1">{t('modelLabel.id')}:</span>
                                <span className="col-11">{apiResponseOrgInfo.visualId}</span>
                            </li>
                            : ""}
                        <li className="pt-2 row">
                            <span className="fw-bold col-1">{t('modelLabel.name')}:</span>
                            <span className="col-11">{apiResponseOrgInfo.name}</span>
                        </li>
                        {apiResponseOrgInfo.parentId !== undefined ?
                            <>
                                {isSuperAdmin === true ?
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-1">{t('modelLabel.parentId')}:</span>
                                        <span className="col-11">{apiResponseOrgInfo.parentId}</span>
                                    </li>
                                    : ''}
                                <li className="pt-2 row">
                                    <span className="fw-bold col-1">{t('modelLabel.parentName')}:</span>
                                    <span className="col-11">{apiResponseOrgInfo.parentName}</span>
                                </li>
                            </>
                            : ''}
                        <li className="pt-2 row">
                            <span className="fw-bold col-1">{t('modelLabel.type')}:</span>
                            <span className="col-11">{apiResponseOrgInfo.type}</span>
                        </li>
                        <li className="pt-2 row">
                            <span className="fw-bold col-1">{t('modelLabel.createdAt')}:</span>
                            <span className="col-11">{ChangeDateFormat('dateTime', apiResponseOrgInfo.createdAt)}</span>
                        </li>
                    </ul>
                </Col>
                : ''}
        </>);

}