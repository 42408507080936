import useApiCallService from "../../Common/Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import SortableList from "../../Common/Components/Lists/SortableList";
import AdminResponseAttestModel from "../Model/Response/AdminResponseAttestModel";
import {Button, Col} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AdminRequestAttestApprovedModel from "../Model/Request/AdminRequestAttestApprovedModel";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {useSelector} from "react-redux";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import AdminRequestAttestDenyModel from "../Model/Request/AdminRequestAttestDenyModel";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";

export function AdminAttestList() {
    const { ApiCall } = useApiCallService();
    const { userData } = useSelector(state => state.auth);
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();

    // API REQUEST
    const [apiRequestAcceptAttest, setApiRequestAcceptAttest] = useState(new AdminRequestAttestApprovedModel());
    const [apiRequestDenyAttest, setApiRequestDenyAttest] = useState(new AdminRequestAttestDenyModel());

    //API RESPONSE
    //const [responseAttestList, setResponseAttestList] = useState(null);
    const [apiResponseAttest, setApiResponseAttest] = useState(null);

    const completeSchemaAccept = AdminRequestAttestApprovedModel.getAttributes( null, true);
    const completeSchemaDeny = AdminRequestAttestDenyModel.getAttributes( null, true);

    const [typeAttest, setTypeAttest] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
    const [showConfirmModalDeny, setShowConfirmModalDeny] = useState(false);

    const [updateList, setUpdateList] = useState(0);

    const [errorReason, setErrorReason] = useState(false);

    let permissionUpdate = false;
    if (CheckUserPermission("attest.approve") || CheckUserPermission("attest")) {
        permissionUpdate = true;
    }

    let permissionDeny = false;
    if (CheckUserPermission("attest.deny") || CheckUserPermission("attest")) {
        permissionDeny = true;
    }

    //updates the component to show when you have accepted or denied attest, it may launch several calls to the api
   /* useEffect(() => {
        setUpdateList(updateList + 1)
    }, [apiResponseAttest]);
    */

    // PUT APPROVED
    const handleValidation = async (type) => {
        try {
            if (type === "approve") {
                await completeSchemaAccept.validate(apiRequestAcceptAttest, { abortEarly: false });
            } else {
                await completeSchemaDeny.validate(apiRequestDenyAttest, { abortEarly: false });
            }
            const apiCallOptions = {
                method: type === "approve" ? "put" : "delete",
                url: "/attest/admin",
                data: type === "approve" ? apiRequestAcceptAttest : apiRequestDenyAttest,
            };
            setTypeAttest(type);
            try {
                await ApiCall(apiCallOptions, setApiResponseAttest, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    // HANDLE APPROVE
    const handleChangeAttestApprove = (item) => {
        setApiRequestAcceptAttest((prevFormData) => ({
            ...prevFormData,
            id: item.id,
            attested: true,
        }))
    }

    // HANDLE DENY
    const handleChangeAttestDenyId = (id) => {
        setApiRequestDenyAttest((prevFormData) => ({
            ...prevFormData,
            id: id,
        }))
    }

    // HANDLE DENY REASON
    const handleChangeAttestDenyReason = (e) => {
        if (e && e.target && e.target.value && e.target.value !== "") {
            setApiRequestDenyAttest((prevFormData) => ({
                ...prevFormData,
                reason: e.target.value,
            }))
            setErrorReason(false);
        } else {
            setErrorReason(true);
        }
    }

    const renderActionColumn = (item) => {
        return (
            <>
                {item.createdBy !== userData?.id && item.status === "Skickat" && permissionUpdate ?
                    <CustomButton icon="bx bx-list-check pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4} buttonColor="#cde0da"  tooltipText={t('attest.approve') } customClasses="rounded me-2 py-2" buttonBorder={false} id={item.id} onClick={() => {
                        handleChangeAttestApprove(item);
                        setShowConfirmModalApprove(true);
                    }} />
                : ''}
                {item.createdBy !== userData?.id && item.status === "Skickat" && permissionDeny ?
                    <CustomButton icon="bx bx-list-minus pb-custom" iconColor="#F2495C" hoverIconColor='white' iconSize={4} tooltipText={t('attest.deny') } customClasses="rounded me-2 box-red py-2" buttonBorder={false} id={item.id} onClick={() => {
                        handleChangeAttestDenyId(item.id);
                        setShowConfirmModalDeny(true);
                    }} />
                : ''}
                <CustomButton icon="bx bx-show pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded py-2" buttonBorder={false} id={item.id} onClick={() => {
                     navigate(`${ADMIN_PATHS.ATTEST_INFO}?id=${item.id}`);
                }} />
            </>
        );
    }

    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        "visualId",
        'createdAt',
        "recipient",
        "orgName",
        "costPlace",
        "model",
        "amount",
        "status",
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id', 'modelId');
    }
    const ignoreFields = ["Id", "OrgId", "ModelId", "CreatedBy"];
    const listOptions = {
        "model": AdminResponseAttestModel,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/attest/admin/search",
        "nameFile": t('attest.attest'),
        "ignoreFields" : ignoreFields,
        "urlGetSearch" : "/attest/admin/search",
    };


    const contentBodyApproved = (
        <div>{t('validationMessage.modalAttest')}</div>

    );
    const contentFooterApproved = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("approve");
            }} >{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() => setShowConfirmModalApprove(false)}>{t('button.cancel')}</Button>
        </>
    );

    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5 className="rokkitt-font">{t('validationMessage.modalAttestDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => handleChangeAttestDenyReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                        style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalAttestDeny')}</div>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("deny");
            }}>{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalDeny(false);
                setErrorReason(false);
            }}>{t('button.cancel')}</Button>
        </>
    );

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (apiResponseAttest !== null && typeAttest !== '') {
            if (typeAttest === "approve") {
                FlashMessage(t('validationMessage.attestApproved'), 'flash-messageSuccess');
                setShowConfirmModalApprove(false);
            } else {
                setShowConfirmModalDeny(false);
                FlashMessage(t('validationMessage.attestDenied'), 'flash-messageSuccess');
            }
            setTimeout(()=>{
                CustomReload();
            },2000);
        }
    }, [apiResponseAttest]);

    //Manage the different error messages

    useEffect(() => {
        if (errorMessage !== '') {
            if (errorMessage === 'InsufficientBalance') {
                FlashMessage(t('validationMessage.insufficientBalance'), 'flash-messageError');
            }
            if (errorMessage === 'UserNeedBankAccount') {
                FlashMessage(t('validationMessage.notAccount'), 'flash-messageError');
            }
            setApiResponseAttest(null);
            setErrorMessage('');
        }
    }, [errorMessage]);

    return <>
        <Col lg={12} className='card card-backend p-4 mb-5'>
            <CustomModal isOpen={showConfirmModalApprove} toggle={() => setShowConfirmModalApprove(!showConfirmModalApprove)} body={contentBodyApproved} footer={contentFooterApproved}/>
            <CustomModal isOpen={showConfirmModalDeny} toggle={() => setShowConfirmModalDeny(!showConfirmModalDeny)} body={contentBodyDeny} footer={contentFooterDeny} />
            <h2 className='rokkitt-font'>{t('attest.labelList')}</h2>
                <SortableList
                   // key={updateList}
                    listOptions={listOptions}
                />
        </Col>
    </>


}