import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class ResponseDigitalAccountModel{
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    orgType = '',
                    costPlace = '',
                    currency = '',
                    balance = 0,
                    createdAt = '',
                    updatedAt = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.orgName = orgType;
        this.costPlace = costPlace;
        this.currency = currency;
        this.balance = balance;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes =  {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId: {
                'label': 'Organization ID',
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            orgType :{
                'label': i18n.t('modelLabel.orgType'),
                'sortable': true,
                'sortField': 'orgType',
                'value': (item) => item.orgType || "",
            },
            costPlace :{
                'label': i18n.t('modelLabel.costPlace'),
                'sortable': true,
                'sortField': 'costPlace',
                'value': (item) => item.costPlace || "",
            },
            currency: {
                'label': i18n.t('modelLabel.currency'),
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.currency || "",
            },
            balance: {
                'label': i18n.t('modelLabel.balance'),
                'sortable': true,
                'sortField': 'balance',
                'insideClass' : (item)=> item.balance >= 0 ? 'text-green fixed-width text-end' : 'text-red fixed-width text-end', //w-50
                "value": (item) =>  item.balance ? ToCustomDecimal(item.balance) +' kr' : "",
                'thClass': 'ps-5',
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '' || "",
            },
            updatedAt :{
                'label': i18n.t('modelLabel.updatedAt'),
                'sortable': true,
                'sortField': 'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : ''|| "",
            },
        };

        return attributes;
    }
}

export default ResponseDigitalAccountModel;