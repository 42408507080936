import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class ResponseQueuedPaymentsModel{
    constructor({
                    date = ChangeDateFormat('date', new Date()),
                    value = 0,
                    done = null,
                    status = ''
                } = {}) {
        this.date = date ? ChangeDateFormat('date', date) : '' ;
        this.value = value;
        this.done = done;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const attributes = {
            date :{
                'sortable': true,
                'sortField': 'date',
                'value': (item) => item.date ? ChangeDateFormat('dateTime', item.date) : 'not set'|| "not set",
            },
            value :{
                'sortable': true,
                'sortField': 'value',
                'value': (item) => item.value || "",
            },
            done :{
                'sortable': true,
                'sortField': 'done',
                'value': (item) => item.done || "",
            },
            done :{
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.status || "",
            },
        }

        return attributes;
    }
}
export default ResponseQueuedPaymentsModel;