import {Button, Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Helpers/ChangeDateFormat";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckUserPermission} from "../../../AppSystem/Helpers/CheckUserPermission";

export const UserProfileHeader = ({ data, isSuperAdmin = null, type = null, handleClickTrash = null, handleClickActive = null }) => {
    const { t } = useTranslation();

    let permissionDelete = false;
    if (CheckUserPermission("user.delete") || CheckUserPermission("user")) {
        permissionDelete = true;
    }

    let permissionActive = false;
    if (CheckUserPermission("user.active") || CheckUserPermission("user")) {
        permissionActive = true;
    }

    return (
        <>
            <div className="position-relative ">
                <div className="profile-wid-bg z-0"></div>
                {type != null && type === "Admin" && handleClickTrash != null && (!data.status || (data.status && data.status !== "Raderad")) && permissionDelete === true ?
                    <div className="position-absolute end-0 top-0">
                        <Button className="ms-3 box-red border-0 position-relative rounded mt-3 me-3" onClick={handleClickTrash}>
                            <i className="bx bx-trash align-middle pb-1"/>
                            <div className="tooltip-custom-button">
                                {t("button.delete")}
                            </div>
                        </Button>
                    </div>
                : ""}
                {type != null && type === "Admin" && handleClickActive != null && data.status && data.status === "Raderad" && permissionActive === true ?
                    <div className="position-absolute end-0 top-0">
                        <Button className="ms-3 box-green border-0 position-relative rounded mt-3 me-3" onClick={handleClickActive}>
                            <i className="bx bx-user-check align-middle pb-1"/>
                            <div className="tooltip-custom-button">
                                {t("button.active")}
                            </div>
                        </Button>
                    </div>
                    : ""}
            </div>
            <Row className="pt-4 mb-4 mb-lg-3 pb-lg-4 position-absolute z-3">
                <Col lg="12" className="p-2 ps-5">
                    <h3 className=" text-white mb-3">{data.firstName} {data.lastName}</h3>
                    <Row className="hstack text-white-50 gap-1">
                        {isSuperAdmin && isSuperAdmin === true ?
                            <Col lg="auto" className="me-5">
                                <i className="bx bx-user me-1 text-white-75 fs-16 align-middle pe-2" />
                                {data.id}
                            </Col>
                        : ''}
                        {data.visualId !== "" ?
                            <Col lg="auto" className="me-5">
                                <i className="bx bx-hash me-1 text-white-75 fs-16 align-middle pe-2" />
                                {data.visualId}
                            </Col>
                        : ""}
                        <Col lg="auto" className="me-5">
                            <i className="bx bx-map-pin me-1 text-white-75 fs-16 align-middle pe-2" />
                            {t('modelLabel.sweden')}
                        </Col>
                        <Col lg="auto" className="me-5">
                            <i className="bx bx-id-card me-1 text-white-75 fs-16 align-middle pe-2" />
                            {data.pnr}
                        </Col>
                        <Col lg="auto" className="me-5">
                            <i className="bx bx-envelope me-1 text-white-75 fs-16 align-middle pe-2" />
                            {data.email}
                        </Col>
                        <Col lg="auto" className="me-5">
                            <i className="bx bx-phone me-1 text-white-75 fs-16 align-middle pe-2" />
                            {data.phone}
                        </Col>
                        <Col lg="auto" className="me-5">
                            <i className="bx bx-time-five me-1 text-white-75 fs-16 align-middle pe-2" />
                            {ChangeDateFormat('dateTime', data.createdAt)}
                        </Col>
                        {data.status && data.status !== "" && data.status === "Raderad" ?
                            <Col lg="auto" className="me-5">
                                <i className="bx bx-x-circle me-1 text-white-75 fs-16 align-middle pe-2" />
                                {t('modelLabel.deleted')}
                            </Col>
                        : ''}
                        {data.status === null || (data.status !== "Raderad") ?
                            <Col lg="auto" className="me-5">
                                <i className="bx bx-user-check me-1 text-white-75 fs-16 align-middle pe-2" />
                                {t('modelLabel.active')}
                            </Col>
                        : ''}
                    </Row>
                </Col>
            </Row>
        </>
    )
}