import {Card, Col, Row, Table} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import AdminRequestRoleUserModel from "../Models/Request/AdminRequestRoleUserModel";
import { TagsInput } from "react-tag-input-component";
import AdminRequestOrgTypeModel from "../Models/Request/AdminRequestOrgTypeModel";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {useTranslation} from "react-i18next";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {TreeCheckboxes} from "./TreeCheckboxes";

export function AdminManagementRoles({data,onChange, onValidate, checkAddRoleButton, permissionTree,/*requestData*//* keyTree*/}) {
    const { t } = useTranslation();
    const [permissionId, setPermissionId] = useState('');
    const [key, setKey] = useState(0);
    const [tagValue ,setTagValue] = useState([]);

    let permissionC = false;
    if (CheckUserPermission("role.create") || CheckUserPermission("role")) {
        permissionC = true;
    }

    let permissionU = false;
    if (CheckUserPermission("role.update") || CheckUserPermission("role")) {
        permissionU = true;
    }

    return(
        <>
            <Card className="p-3 border-top-square border-bottom-0 border-end-0 border-start-0">
                <Row className=" mb-0 px-4">
                    <Col lg={12} className='p-0'>
                        <Row>
                            <Col lg={2}>
                                <h4 className='text-green'>{data === null || data[0].role !== undefined ? t('role.roles') : t('modelLabel.types')}</h4>
                            </Col>
                            <Col lg={4}>
                                <h4 className='text-green'>{t('role.permissions')}</h4>
                            </Col>
                            {/*<Col lg={6} className='text-warning'>
                                <i className='bx bx-error'/>{t('role.infoAlert')}
                            </Col>*/}
                        </Row>
                        <Card className='px-3 py-1 rounded-2  bg-card-grey' >
                            {data?.map((item,index) => {
                                    return(
                                        <Row key={'rowEditPerm'+index} className={`px-0 py-3 bg-card-grey ${index === data.length - 1 ? 'border-0' : 'border-bottom '}`}>
                                            <Col lg={2} className="align-self-center">
                                                <span className="fw-bold pe-2">{data[index].role ? data[index].role : data[index].type }</span>
                                            </Col>
                                            <Col lg={9} className={`${permissionId === 'userPermissions'+index ? '' : 'd-none'} ps-0`}>

                                                <div className="py-2 px-3 bg-white rounded">
                                                    <TreeCheckboxes treeData={permissionTree}
                                                                    data={item.permissions}
                                                                    key={key}
                                                                    onChange={onChange}
                                                        />
                                                </div>

                                                {/*<TagsInput
                                                    key={key}
                                                    value={tagValue}
                                                    onChange={(e)=>{
                                                        //onChange(e, 'editPermissions',data[index].role)
                                                        onChange(e, 'permissions')

                                                    }}
                                                    //separators="',','Enter'"
                                                    classNames={{input:"p-0"}}
                                                    name="permisions"
                                                    //placeHolder="Enter permissions"
                                                />*/}

                                            </Col>
                                            <Col lg={9} className={` d-flex align-items-center ${permissionId === 'userPermissions'+index ? 'd-none' : ''}`}>
                                                {data[index]?.permissions && data[index]?.permissions.length > 0 ?
                                                    <div dangerouslySetInnerHTML={{ __html: data[index].permissions.join(', &nbsp;&nbsp;&nbsp;') }} />
                                                    : ''}
                                            </Col>
                                            <Col lg={1} className={`${ permissionId === 'userPermissions' + index ? '' : 'd-none'} ps-0 text-end`}>
                                                <CustomButton text="" icon='bx bx-undo  py-1' iconColor='#EB5757'
                                                              iconSize={5} buttonColor='#e9cbcd'
                                                              buttonRadius={3} hoverButtonRadius={3}
                                                              hoverIconColor='white' hoverButtonColor='#EB5757'
                                                              tooltipText={t('button.cancel')}
                                                              customClasses="border-0 mb-1"
                                                              onClick={() => {
                                                                  onChange("", 'cancelEditRole');
                                                                  setKey((prevKey) => prevKey + 1);
                                                                  setPermissionId('');
                                                              }}
                                                />
                                                <CustomButton text="" icon='bx bx-check  py-1' iconColor='#157553'
                                                              iconSize={5} buttonColor='#bed1cd'
                                                              buttonRadius={3} hoverButtonRadius={3}
                                                              hoverIconColor='white' hoverButtonColor='#157553'
                                                              customClasses="ms-2 border-0 mb-1"
                                                              tooltipText={t('button.save')}
                                                              onClick={async () => {
                                                                  await onValidate("edit");
                                                                  setPermissionId('');
                                                              }}
                                                />
                                            </Col>
                                            <Col lg={1} className={`${ permissionId === 'userPermissions' + index ? 'd-none' : ''} text-end`}>
                                                {permissionU ?
                                                    <CustomButton text="" icon='bx bx-edit py-1' iconColor='#157553' iconSize={5}
                                                                  buttonColor='#bed1cd' buttonRadius={3} hoverButtonRadius={3}
                                                                  hoverIconColor='white' hoverButtonColor='#157553'
                                                                  tooltipText={t('button.edit')}
                                                                  customClasses="ms-3 border-0"
                                                                  onClick={() => {
                                                                        setTagValue(data[index]?.permissions)
                                                                        setPermissionId('userPermissions' + index);
                                                                        setKey((prevKey) => prevKey + 1);
                                                                        if(data[index].role ) {
                                                                            onChange(index, 'editRole');
                                                                        } else {
                                                                            onChange(index, 'editType');
                                                                        }
                                                                  }}
                                                    />
                                                : ""}
                                            </Col>
                                        </Row>
                                    )
                                }
                            )}
                        </Card>
                        {checkAddRoleButton === true ?
                            <Row className="py-2 px-0 ps-3">
                                <Col lg={3} className="fw-bold ps-0">
                                    {data !== null && data[0].role !== undefined ?
                                        <CustomInput model={AdminRequestRoleUserModel} attribute='role' data='' customChange={(e)=>{onChange(e, 'role')}}/>
                                        :
                                        <CustomInput model={AdminRequestOrgTypeModel} attribute='type' data='' customChange={(e)=>{onChange(e, 'type')}}/>
                                    }
                                </Col>
                                <Col lg={8} className="">
                                    <Row>
                                        <Col lg={2} className="align-self-center text-end fw-bold">
                                            {t('role.permissions')}:
                                        </Col>
                                        <Col lg={10}>
                                            <div className="py-2 px-3 bg-white rounded">
                                                <TreeCheckboxes treeData={permissionTree}
                                                                data={tagValue}
                                                                key={key}
                                                                onChange={onChange}
                                                />
                                            </div>
                                           {/* <TagsInput
                                                onChange={(e)=>{
                                                    onChange(e, 'permissions')
                                                }}
                                                classNames={{input:"p-0"}}
                                                name="permisions"
                                                placeHolder={t('formPlaceholder.enterPermissions')}
                                            />*/}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={1} className="ps-2">
                                    <CustomButton text="" icon='bx bx-undo' iconColor='#EB5757'
                                                  iconSize={5} buttonColor='#e9cbcd'
                                                  buttonRadius={3} hoverButtonRadius={3}
                                                  hoverIconColor='white' hoverButtonColor='#EB5757'
                                                  customClasses="border-0"
                                                  tooltipText={t('button.cancel')}
                                                  onClick={() => {
                                                      onChange("", 'cancelRole');
                                                  }}
                                    />
                                    <CustomSubmitButton icon='bx bx-check fw-bold' bgColor='bg-green-light' tooltipText={t('button.send')} customClass='border-0 text-green ms-3 ' customClick={async () => {
                                        await onValidate("create");
                                    }}/>
                                </Col>
                            </Row>
                            :''}
                    </Col>
                </Row>
                {checkAddRoleButton === false && permissionC ?
                    <Row className="pt-3 ps-3">
                        <Col lg={12}>
                            <CustomButton
                                customClasses="rounded"
                                text={data === null || data[0].role !== undefined ? t('role.addRole') : t('role.addType')}
                                icon="bx bx-plus pe-2"
                                onClick={() => {
                                    onChange("", 'addRole');
                                }}
                            />
                        </Col>
                    </Row>
                    :''}
            </Card>
        </>)
}