import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';

class RequestClient{
    constructor({
                    name = '',
                    orgNumber = "",
                    address = '',
                    postalCode = "",
                    city = '',
                    email = '',
                } = {}) {
        this.name = name;
        this.orgNumber = orgNumber;
        this.address = address;
        this.postalCode = postalCode;
        this.city = city;
        this.email = email;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const greaterO = i18n.t('validationMessage.greater0');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            name: string().required(fieldRequired),
            orgNumber: string().nullable()/*.test('is-positive', greaterO, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            })*/,
            address: string().nullable(),
            postalCode:  string().nullable()/*.test('is-positive', greaterO, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            })*/,
            city: string().nullable(),
            email: string().required(fieldRequired)
                .max(45, 'Max. 45 characters')
                .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, i18n.t('validationMessage.invalidEmail')),
        });

        const attributes = {
            name :{
                'label': i18n.t('invoice.clientName'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterClientName'),
                    'validation': completeSchema.fields.name,
                    'mandatory': 'mandatory',
                },
                'value': (item) => item.name || "",
            },
            orgNumber :{
                'label': i18n.t('modelLabel.orgNumber'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterOrgNumber'),
                    'validation': completeSchema.fields.orgNumber,
                },
                'value': (item) => item.orgNumber || "",
            },
            address :{
                'label': i18n.t('invoice.address'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterAddress'),
                    'validation': completeSchema.fields.address,
                },
                'value': (item) => item.address || "",
            },
            postalCode :{
                'label': i18n.t('invoice.postalCode'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterPostalCode'),
                    'validation': completeSchema.fields.postalCode,
                },
                'value': (item) => item.postalCode || "",
            },
            city :{
                'label': i18n.t('invoice.city'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterCity'),
                    'validation': completeSchema.fields.city,
                },
                'value': (item) => item.city || "",
            },
            email :{
                'label': i18n.t('modelLabel.email'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterEmail'),
                    'validation': completeSchema.fields.email,
                    'mandatory': 'mandatory',
                },
                'value': (item) => item.email || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestClient;