import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";

class ResponseOrgModel{
    constructor({
                    id = '',
                    visualId = '',
                    name = '',
                    type = '',
                    createdAt = ChangeDateFormat('dateTime', new Date()),
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.name = name;
        this.type = type;
        this.createdAt = createdAt ? ChangeDateFormat('dateTime', createdAt) : '' ;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes =  {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            name: {
                'label': i18n.t('modelLabel.orgName'),
                'sortable': true,
                'sortField': 'name',
                'value': (item) => item.name || "",
            },
            type: {
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.type || "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : 'not set'|| "not set",
            }
        };

        return attributes;
    }
}

export default ResponseOrgModel;