import { useSelector } from "react-redux";

/*
Usage:
CheckUserRole("Admin")
*/

// CHECK USER ROLE
export function CheckUserRole(value) {
    const { rolesData } = useSelector(state => state.auth);
    let hasPermission = false;
    if (rolesData && Array.isArray(rolesData) && rolesData?.length > 0) {
        rolesData.forEach((role) => {
            if (role.role === value) {              
                hasPermission = true;
            }
        });
    }
    return hasPermission;
}