import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {Card, Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {useTranslation} from "react-i18next";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";

export function Organization() {
    const { t } = useTranslation();
    const search = LoadLocalStorage("search");

    const { ApiCall } = useApiCallService();
    const [apiResponseOrganization, setApiResponseOrganization] = useState(null);

    useEffect(() => {
        if (search) {
          localStorage.removeItem('search');
        }
    },[search]);

    useEffect(() => {

        const handleApiResponseGetOrgUser  = (apiResponseOrganization) => {
            setApiResponseOrganization(apiResponseOrganization);

        };
        const GetOrgUser = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/user/org",
                };

                await ApiCall(apiCallOptions, handleApiResponseGetOrgUser );

            } catch (error) {
                console.log('error', error);
            }
        };

        GetOrgUser();

    }, []);
    return(
        <>
            {apiResponseOrganization !== null ?
                <Col lg="12" className="px-0">
                    <Card className='card card-backend p-4 mb-5'>
                        <h2 className="rokkitt-font">{t('adminLabel.details')}</h2>
                        <ul className="list-unstyled">
                            {apiResponseOrganization.visualId !== "" ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-1">{t('modelLabel.id')}:</span>
                                    <span className="col-11">{apiResponseOrganization.visualId}</span>
                                </li>
                            : ""}
                            <li className="pt-2 row">
                                <span className="fw-bold col-1">{t('modelLabel.name')}:</span>
                                <span className="col-11">{apiResponseOrganization.name}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-1">{t('modelLabel.parentName')}:</span>
                                <span className="col-11">{apiResponseOrganization.parentName}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-1">{t('modelLabel.type')}:</span>
                                <span className="col-11">{apiResponseOrganization.type}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-1">{t('modelLabel.createdAt')}:</span>
                                <span className="col-11">{ChangeDateFormat('dateTime', apiResponseOrganization.createdAt)}</span>
                            </li>
                        </ul>
                    </Card>
                </Col>
            : ''}
        </>);

}