import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import NotificatioSignalRComponent from '../../../AppSystem/Helpers/Notifications';
import {  USER_PATHS } from '../../../config';
import '../../../App.css'
import useApiCallService from '../../Helpers/ApiCall';
import {useTranslation} from "react-i18next";
import {LoadLocalStorage} from "../../Helpers/LoadLocalStorage";

const NotificationDropdown = ({ notification, toggleNotification }) => {
  const { t } = useTranslation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { ApiCall } = useApiCallService();
  const [apiResponse, setApiResponse] = useState(null);
  const search = LoadLocalStorage("search");

  useEffect(() => {

    const handleApiResponse  = (apiResponse) => {
      if (apiResponse !== null && apiResponse !== undefined) {
        setNotifications(apiResponse.notifications);
        setNotificationCount(apiResponse.count)
      }
    };
    const GetUnreadNotifications = async () => {
      try {
                 
        const apiCallOptions = {
          method: "get",
          url: "/notification?readStatus=1",
        };

       ApiCall(apiCallOptions, handleApiResponse );  
       
      } catch (error) {
        console.log('error', error);
      }     
    };   
    
    GetUnreadNotifications();

  }, []);

 
  const handleMessageReceived = (message) => {
  const notificationExists = notifications.find(notification => notification.id === message.id);

    if (!notificationExists) {
     setNotifications(prevNotifications => [...prevNotifications, message]);
     setNotificationCount(prevCount => prevCount + 1);
    }
  };

  const markAllAsRead = async () => {
    try {

      const apiCallOptions = {
        method: "put",
        url: "/notification/mark-all-read",
      };
      
      ApiCall(apiCallOptions, setApiResponse);  

      setNotifications([]); 
      setNotificationCount(0); 
    } catch (error) {
      console.log('error', error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {

      const apiCallOptions = {
        method: "put",
        url: `/notification?notificationId=${notificationId}`,
      };
      
      ApiCall(apiCallOptions, setApiResponse);  
       
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === notificationId ? { ...notification, read: true } : notification
        )
      );
 
      setNotificationCount(prevCount => prevCount - 1);
      setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== notificationId));
    
    } catch (error) {
      console.log('Error al marcar la notificación como leída:', error);
    }
  };

  const getSeverityColorClass = (severity) => {
    switch (severity) {
      case 'Information':
        return 'bg-white';
      case 'Warning':
        return 'bg-warning bg-opacity-50';
      case 'Error':
        return 'bg-danger bg-opacity-50';
      case 'Success':
        return 'bg-success bg-opacity-50';
      default:
        return '';
    }
  };

  return (
    <Dropdown className="d-inline-block" isOpen={notification} toggle={toggleNotification}>
      <NotificatioSignalRComponent onMessageReceived={handleMessageReceived} />
      <DropdownToggle tag="button" type="button" className="btn header-item noti-icon" id="page-header-notifications-dropdown" aria-label={t('notificationsDropdown.notifications')}>
        <i className="bx bx-bell font-size-22 align-middle" />
        {notificationCount > 0 && <span className="noti-dot bg-danger rounded-pill">{notificationCount}</span>}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h5 className="m-0 font-size-15"> {t('notificationsDropdown.notifications')} </h5>
            </Col>
            <div className="col-auto">
              <Link to="#" className="small fw-semibold text-decoration-underline" onClick={markAllAsRead}>
                {' '}
                {t('notificationsDropdown.text')}
              </Link>
            </div>
          </Row>
        </div>

        <SimpleBar data-simplebar style={{ maxHeight: '250px' }}>
          {notifications.map(notification => (
           <Link to="#" className="text-reset notification-item" key={notification.id}>
             <div className={`d-flex align-items-center notification-container ${getSeverityColorClass(notification.severity)}`}>
               <div className="flex-grow-1">
                 <h6 className="mb-1">{notification.type}</h6>
                 <div className="font-size-13 text-muted">
                    <p className="mb-1">{notification.regarding}</p>         
                    <p className="mb-1">{notification.details.general.details}</p>
                    <p className="mb-1">{notification.text}</p>
                    <p className="mb-0">
                     <i className="bx bx-time-five"></i> <span>{notification.createdAt}</span>
                   </p>
                 </div>
                </div>
               <div className="form-check">
               <style>{`.custom-checkbox-input { border-color: grey; /* Establece el color del borde del checkbox como azul */} `}</style>
                 <input
                    type="checkbox"
                    className="form-check-input rounded-circle custom-checkbox-input"
                    id={`notification-checkbox-${notification.id}`}
                    onChange={() => markNotificationAsRead(notification.id)}
                    title="Mark as read"
                  />
                  <label className="form-check-label" htmlFor={`notification-checkbox-${notification.id}`}>
                 </label>
               </div>
             </div>
            </Link>
         ))}
        </SimpleBar>

        <div className="p-2 border-top d-grid">
          <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to={USER_PATHS.NOTIFICATIONS}  onClick={() => {
            if (search) {
              localStorage.removeItem('search');
            }
            localStorage.removeItem('currentPage');
            localStorage.removeItem('itemsPerPage');
            toggleNotification();
          }}>
            <i className="uil-arrow-circle-right me-1"></i> <span> {t('notificationsDropdown.icon')}</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
