import {useEffect, useState} from "react";
import {logDOM} from "@testing-library/react";

export function TreeCheckboxes  ({treeData, data, onChange}) {
    const [expandedCategories, setExpandedCategories] = useState({});
    const [dataCheck, setDataCheck] = useState(data);


    useEffect(() => {
        if (data) {
            const resultArray = data.map(value => {
                if (value.includes('.')) {
                    return value.split('.')[0];
                }
            });
            const filteredArray = resultArray.filter(item => item !== undefined);

            if (filteredArray && filteredArray.length > 0) {
                const newExpandedCategories = filteredArray.reduce((acc, category) => {
                    if (!acc[category]) {
                        acc[category] = true;
                    }
                    return acc;
                }, { ...expandedCategories });

                setExpandedCategories(newExpandedCategories);
            }
        }

    }, [dataCheck]);

    const toggleCategory = (category) => {
        setExpandedCategories({
            ...expandedCategories,
            [category]: !expandedCategories[category]
        });
    };

    const handleParentChange = (category) => {
        if (dataCheck) {
            if (dataCheck.some(perm => perm === category)) {
                // Remove category if it is already in data
                setDataCheck(prevData => prevData.filter(perm => perm !== category));
            } else {
                // Add category if no data is available
                const isChildren = dataCheck.some(perm => perm.startsWith(category + '.'));
                if (isChildren) {
                    const newDataCheck = dataCheck.filter(perm => !perm.startsWith(category + '.'));
                    const updateDataCheck = [...newDataCheck, category];
                    setDataCheck(updateDataCheck);
                }else {
                    setDataCheck(prevData => [...prevData, category]);
                }

            }
        } else {
            setDataCheck( [category]);
        }


    };

    const handleChildChange = (perm) => {
        const parent = perm.split('.')[0];
        const siblings = treeData.permissions.filter(p => p.startsWith(parent + '.'));
        let finalCheck = null;

        if (dataCheck) {
            if (dataCheck.includes(parent)) {
                const newDataCheck = dataCheck.filter(item => item !== parent);
                const updatedDataCheck = [...newDataCheck, ...siblings.filter(sibling => sibling !== perm)];
                setDataCheck(updatedDataCheck);
                finalCheck = updatedDataCheck;
            } else {
                if(dataCheck.includes(perm)) {
                    const newDataCheck = dataCheck.filter(item => item !== perm);
                    setDataCheck(newDataCheck);
                    finalCheck = newDataCheck;
                } else {
                    const newDataCheck = [...dataCheck, perm];
                    setDataCheck(newDataCheck);
                    finalCheck = newDataCheck
                }
            }
        } else {
            setDataCheck( [perm]);
            finalCheck = [perm];
        }


        // COMPROBAR ARRAY
        if (finalCheck != null) {
            const allElementsExist = siblings.every(element => finalCheck.includes(element));
            // EXISTEN TODOS LOS HIJOS
            if (allElementsExist) {
                const parent = siblings[0].split('.')[0];
                const updateDataCheck = finalCheck.map(item => siblings.includes(item) ? parent : item);
                const FinalData = Array.from(new Set(updateDataCheck));
                setDataCheck(FinalData);
            }
        }
    };

    useEffect(() => {
        onChange(dataCheck , 'permissions');
    }, [dataCheck]);

    //aqui se extraen los valores padre para el arbol
    //el new Set() elimina las palabras duplicadas
   // const categories = Array.from(new Set(treeData.permissions.map(perm => perm.split('.')[0])));


    let categories = [];
    if (treeData !== null) {
        categories = Array.from(
        new Set(
            treeData.permissions.map(perm => {
                const parts = perm.split('.');
                return parts.length > 1 ? parts[0] : perm;
            })
        ));
    }


    return (
        <div>
            {categories.map((category, index) => {
                const hasChildren = treeData.permissions.some(perm => perm.startsWith(category + '.'));
                const children = treeData.permissions.filter(perm => perm.startsWith(category + '.'));
                const childrenCategories = children.map(child => child.split('.')[0]);

                return (
                    <div key={category}>
                        {hasChildren ? (
                            <span onClick={() => toggleCategory(category)} style={{ cursor: 'pointer', marginRight: 8 }}>
                                {expandedCategories[category] ? <i className='bx bx-chevron-down' /> : <i className='bx bx-chevron-right' />}
                            </span>
                        ) :
                        <span  style={{ marginRight: 24 }}>
                        </span>
                        }
                        <label>
                            <input
                                type="checkbox"
                                checked={ dataCheck?.some(perm => perm === category)}
                                onChange={() => {
                                    handleParentChange(category);
                                }}
                                className="me-2"
                            />
                            {category}
                        </label>
                        {expandedCategories[category] && hasChildren && (
                            <div style={{ marginLeft: 45 }}>
                                {treeData.permissions.filter(perm => perm.startsWith(category + '.')).map((perm) => (
                                    <div key={perm}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={dataCheck?.some(perm2 => perm2 === perm) || childrenCategories.some(childCategory => dataCheck?.includes(childCategory))}
                                                onChange={() => {
                                                    handleChildChange(perm);
                                                }}
                                                className="me-2"
                                            />
                                            {perm.split('.')[1]}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )

            })}
        </div>
    );
};