import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import usaFlag from '../../../AppSystem/assets/img/flags/usa.jpg';
import spainFlag from '../../../AppSystem/assets/img/flags/spain.jpg';
import swedenFlag from '../../../AppSystem/assets/img/flags/sweden.jpg';
import React, {useEffect, useState} from 'react';
import i18n from '../../../AppSystem/i18n';
import {useTranslation} from "react-i18next";


/*For use i18n you need install:
    "i18next": "^23.8.2",
    "i18next-browser-languagedetector": "^7.2.0",
    "i18next-http-backend": "^2.4.3",
    Install in the package.json.

    In the folder i18n you can find the index file with the languages and the different files to translate in different languages.

For make the translations, you must define in your component:
const { t } = useTranslation();
In the return, you must put t('name that you put in the translations file')
Ex: <p>{t('header.topBar.buttonLanguage')}</p>*/
//Example in 'src'/'Auth'/'Views'/'Dashboard.js':
// <h3>{/*{t('privateProfile.dashboard.title')}*/} Welcome to Kassan</h3>
// <p className="m-0">{/*{t('privateProfile.dashboard.body')}*/}You can now start using Kassan, here is a little help</p>

//The implementation of this button is in the 'Header' --> 'src'/'Auth'/'Components'/'Nav'/'Header.js'
/*<ButtonChangeLanguage type="backend" />*/


export function ButtonChangeLanguage({ type }) {
  /*    Uncomment this line for view the example for use
    const { t } = useTranslation();*/


  //Language Dropdown
  const [languages, setLanguages] = useState(false);
  const [flag, setFlag] = useState(swedenFlag);
  const { t } = useTranslation();


  useEffect(() => {
    // Retrieve the selected language from localStorage
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setFlag(getFlagForLanguage(storedLanguage));
    }
  }, []);

  const toggleLanguage = () => {
    setLanguages(!languages);
  };

  //Change language
  const changeLanguage = languageCode => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem('selectedLanguage', languageCode);
   // setLanguages(false);
    setFlag(getFlagForLanguage(languageCode));
  };

  // Flag for language
  const getFlagForLanguage = languageCode => {
    switch (languageCode) {
      case 'en-US':
        return usaFlag;
      case 'se-SE':
        return swedenFlag;
      case 'es-ES':
        return spainFlag;
      default:
        return swedenFlag;
    }
  };

  const getLanguagesNames = languageCode =>{
    switch (languageCode) {
      case 'en-US':
        return 'US';
      case 'se-SE':
        return 'SE';
      case 'es-ES':
        return 'ES';
      default:
        break;
    }
  }
  return (
      <>
        <Dropdown className="d-inline-block language-switch" isOpen={languages} toggle={toggleLanguage}>
          <DropdownToggle tag="button" type="button" className="btn header-item" aria-label={t('accessibility.selectLanguage')}>
            <img className="header-lang-img" src={flag} alt="Header Language" height="16" />
          </DropdownToggle>

          <DropdownMenu className={`dropdown-menu-end ${type === 'backend' ? '' : 'menu-landing dropdown-inverse bg-primary landing-position'}`}>
            {Object.keys(i18n.options.resources).map(language => (
                <a  key={language} className="dropdown-item notify-item language" onClick={() => {
                  changeLanguage(language);
                  toggleLanguage();
                }}>
                  <img src={getFlagForLanguage(language)} alt="" className="me-1" height="12" />{' '}
                  {/*<span className="align-middle">{i18n.t(`translation:${language}`)}</span>*/}
                  <span className="align-middle">{getLanguagesNames(language)}</span>
                </a>
            ))}
          </DropdownMenu>
        </Dropdown>
        {/*   Uncomment this line for view the example for use
      <p>{t('header.topBar.buttonLanguage')}</p>*/}
      </>
  );
}
