import React, {forwardRef, useEffect, useState} from "react";
import {Button, Col, Input, Label, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import RequestPay from "../Models/Request/RequestPay";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomDateLocale} from "../../Common/Helpers/CustomDateLocale";
import DatePicker from "react-datepicker";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import $ from "jquery";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";

export function CreateFormPay({ type, data, costBearerArray = [], accountingArray = [], onChange, extraField, onValidate, displaySpinner, setIsDeletedFile, setIsDeletedExtraFile }) {
    const { t } = useTranslation()

    const [wrongFileFormat, setWrongFileFormat] = useState(false);
    const [displayDateError, setDisplayDateError] = useState(false);

    const [displayClearingNumberError, setDisplayClearingNumberError] = useState(false);
    const [displayAccountNumberError, setDisplayAccountNumberError] = useState(false);
    const [displayBGError, setDisplayBGError] = useState(false);
    const [displayPGError, setDisplayPGError] = useState(false);
    const [displayFileError, setDisplayFileError] = useState(false);

    const [recipient, setRecipient] = useState("");

    // DATE BUTTONS
    const customLocale = CustomDateLocale();
    const [activeButton, setActiveButton] = useState(null);
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        if (buttonId === 1){
            onChange("", "noDate");
        } else {
            onChange("", "date");
            setDisplayDateError(true);
        }



    };
    const [startDate, setStartDate] = useState('');
    const ButtonDatePiker = forwardRef(({ value, onClick }, ref) => (
        <Button ref={ref} className={`text-muted fw-bold ${activeButton === 2 ? 'active text-green bg-grey-tab' : 'bg-white border-0'}`}
                onClick={() => {
                    handleButtonClick(2);
                    onClick();
                }}>
            <div>{t('formPlaceholder.selectDate')}</div>
            {activeButton === 2 ? ChangeDateFormat("date", value) : ''}
        </Button>
    ));

    // MAX LENGTH
    useEffect(() => {
        setTimeout(() => {
            const clearingNumber = document.getElementsByName("clearingNumber");
            if (clearingNumber.length > 0) {
                clearingNumber[0].setAttribute("maxlength", "5");
            }
            const accountNumber = document.getElementsByName("accountNumber");
            if (accountNumber.length > 0) {
                accountNumber[0].setAttribute("maxlength", "12");
            }
            const bG = document.getElementsByName("bG");
            if (bG.length > 0) {
                bG[0].setAttribute("maxlength", "8");
            }
            const pG = document.getElementsByName("pG");
            if (pG.length > 0) {
                pG[0].setAttribute("maxlength", "8");
            }
            /*const oCR = document.getElementsByName("oCR");
            if (oCR.length > 0) {
                oCR[0].setAttribute("maxlength", "25");
            }
            const message = document.getElementsByName("message");
            if (message.length > 0) {
                message[0].setAttribute("maxlength", "140");
            }*/
        }, 500);
    }, [type]);
    // MAX LENGTH
    useEffect(() => {
        setTimeout(() => {
            const oCR = document.getElementsByName("oCR");
            if (oCR.length > 0) {
                oCR[0].setAttribute("maxlength", "25");
            }
            const message = document.getElementsByName("message");
            if (message.length > 0) {
                message[0].setAttribute("maxlength", "70");
            }
        }, 500);
    }, [recipient]);

    useEffect(() => {
        if (data?.date !== "") {
            setDisplayDateError(false);
        }
    }, [data?.date]);

    useEffect(() => {
        if (data?.clearingNumber !== "") {
            setDisplayClearingNumberError(false);
        }
    }, [data?.clearingNumber]);

    useEffect(() => {
        if (data?.accountNumber !== "") {
            setDisplayAccountNumberError(false);
        }
    }, [data?.accountNumber]);

    useEffect(() => {
        if (data?.bG !== "") {
            setDisplayBGError(false);
        }
    }, [data?.bG]);

    useEffect(() => {
        if (data?.pG !== "") {
            setDisplayPGError(false);
        }
    }, [data?.pG]);

    useEffect(() => {
        if (data?.fileId !== "") {
            setDisplayFileError(false);
        }
    }, [data?.fileId]);

    useEffect(() => {
        if (type === "Bankkonto") {
            $('input[value="bankPayment"]').prop('checked', true);
            $('input[value="giroPayment"]').prop('checked', false);
            $('input[value="plusPayment"]').prop('checked', false);
        }
        if (type === "Bankgiro"){
            $('input[value="bankPayment"]').prop('checked', false);
            $('input[value="giroPayment"]').prop('checked', true);
            $('input[value="plusPayment"]').prop('checked', false);
        }
        if (type === "Plusgiro"){
            $('input[value="bankPayment"]').prop('checked', false);
            $('input[value="giroPayment"]').prop('checked', false);
            $('input[value="plusPayment"]').prop('checked', true);
        }
    }, [data?.type]);

    const messageCostBearer = InputMessage(costBearerArray, data?.costBearerId);
    const messageAccounting = InputMessage(accountingArray, data?.accountingId);

    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col lg={6} className="pt-4">
                        <Row>
                            <Col lg={6}>
                                <CustomInput model={RequestPay} attribute='costBearerId' data={data?.costBearerId} customChange={(e) => {
                                    onChange(e, "costBearerId");
                                }} customOptions={costBearerArray.length > 0 ? costBearerArray : null}/>
                                {messageCostBearer}
                            </Col>
                            <Col lg={6}>
                                <CustomInput model={RequestPay} attribute='accountingId' data={data?.accountingId} customChange={(e) => {
                                    onChange(e, "accountingId");
                                }} customOptions={accountingArray.length > 0 ? accountingArray : null}/>
                                {messageAccounting}
                            </Col>
                            <Col lg={12}>
                                <CustomInput model={RequestPay} attribute='creditorName' data={data?.creditorName} customChange={(e) => {
                                    onChange(e, "creditorName");
                                }} />
                            </Col>
                            <Col lg={data?.vatAmount !== null ? 6 : 12 }>
                                <CustomInput model={RequestPay} attribute='amount' data={data?.amount !== 0 ? data?.amount : ""} customChange={(e) => {
                                    onChange(e, "amount");
                                }} />
                            </Col>
                            {data?.vatAmount !== null ?
                                <Col lg={6} className="position-relative">
                                    <CustomInput model={RequestPay} attribute='vatAmount' data={data?.vatAmount !== 0 ? data?.vatAmount : ""} customChange={(e) => {
                                        onChange(e, "vatAmount");
                                    }} />
                                    <div className={`error tooltip-custom-up-field ${data?.amount !== 0 && data?.vatAmount !== 0 && data?.amount <= data?.vatAmount ? 'opacity-1' : 'opacity-0'}`} >
                                        {t('validationMessage.vatLessAmount')}
                                    </div>
                                </Col>
                            : ''}
                            <Col lg={12} className="position-relative">
                                <CustomInput model={RequestPay} attribute='fileId' data={''} customChange={(e) => {
                                    onChange(e, "fileId")
                                }} fileId={data?.fileId !== "" ? data?.fileId : null}
                                             fileExtension={data?.fileExtension && data?.fileExtension !== "" ? data?.fileExtension : null}
                                             fileName={data?.fileName && data?.fileName !== "" ? data?.fileName : (data?.fileExtension && data?.fileExtension !== "" ? data?.fileExtension : null)}
                                             setIsDeleted={setIsDeletedFile} />
                                <div className={`error tooltip-custom-normal ${displayFileError === true ? 'opacity-1' : 'opacity-0'}`}>
                                    {t('validationMessage.fieldRequired')}
                                </div>
                            </Col>
                            {data.additionalInfo !== null && Object.keys(data.additionalInfo).length > 0 ?
                                <Col lg={12}>
                                    <CustomInput model={RequestPay} attribute={Object.keys(data?.additionalInfo)[0]}
                                                 data={data?.additionalFileExtension === "" && data?.additionalInfo.program !== undefined && data?.additionalInfo.program !== "" ? data?.additionalInfo.program : ""}
                                                 customChange={(e) => {
                                                     onChange(e, "dynamic")
                                                 }}
                                                 fileId={data?.additionalFileExtension && data?.additionalFileExtension !== "" && data?.additionalInfo.participantsFileId !== undefined && data?.additionalInfo.participantsFileId !== "" ? data?.additionalInfo.participantsFileId : null}
                                                 fileExtension={data?.additionalFileExtension !== "" && data?.additionalInfo.participantsFileId !== undefined && data?.additionalInfo.participantsFileId !== "" ? data?.additionalFileExtension : null}
                                                 fileName={data?.additionalFileName && data?.additionalFileName !== "" ? data?.additionalFileName : (data?.additionalFileExtension && data?.additionalFileExtension !== "" && data?.additionalInfo.participantsFileId !== undefined && data?.additionalInfo.participantsFileId !== "" ? data?.additionalFileExtension : null)}
                                                 wrongFileFormat={wrongFileFormat}
                                                 customField={extraField !== null ? extraField : null}
                                                 setIsDeleted={setIsDeletedExtraFile}
                                    />
                                </Col>
                                : ''}
                        </Row>
                    </Col>
                    <Col lg={6} className="pt-4">
                        <Row>
                            <Col lg={12} className="pb-3 position-relative">
                                <Label className="d-block">{t('modelLabel.whenToPay')}:</Label>
                                <Row className="d-flex align-items-center">
                                    <Col md={6} lg={5} xl={3} className="pe-0">
                                        <Button className={`text-muted fw-bold px-2 ${activeButton === 1 ? 'active text-green bg-grey-tab' : 'bg-white border-0'}`} onClick={() => handleButtonClick(1)}>
                                            {t('modelLabel.soon')}
                                        </Button>
                                    </Col>
                                    <Col xs={7} md={4} lg={5} xl={4} className='text-md-center'>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(e) => {
                                                setStartDate(e);
                                                onChange(e, "date")
                                            }}
                                            customInput={<ButtonDatePiker/>}
                                            locale={customLocale}
                                            minDate={new Date()}
                                            format="yyyy-MM-dd"
                                        />
                                        <div className={`error tooltip-custom-up-field w-50 ${displayDateError === true ? 'opacity-1' : 'opacity-0'}`} >
                                            {t('validationMessage.fieldRequired')}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12}>
                                <Row className="py-2">
                                    <CustomInput model={RequestPay} attribute='type' data={''} customChange={(e) => {
                                        onChange("", e.target.value);
                                        $("input[name='recipinent']").prop('checked', false);
                                    }}/>
                                </Row>
                            </Col>
                            <Col lg={12}>
                                <Row className={type === "Bankkonto" ? "" : "d-none"}>
                                    <Col lg={6} className="position-relative">
                                        <CustomInput model={RequestPay} attribute='clearingNumber' data={data?.clearingNumber || ""} customChange={(e) => {
                                            onChange(e, "clearingNumber");
                                        }}/>
                                        <div className={`error tooltip-custom-normal ${displayClearingNumberError === true ? 'opacity-1' : 'opacity-0'}`} >
                                            {t('validationMessage.digits4')}
                                        </div>
                                    </Col>
                                    <Col lg={6} className="position-relative">
                                        <CustomInput model={RequestPay} attribute='accountNumber' data={data?.accountNumber || ""} customChange={(e) => {
                                            onChange(e, "accountNumber");
                                        }}/>
                                        <div className={`error tooltip-custom-normal ${displayAccountNumberError === true ? 'opacity-1' : 'opacity-0'}`}>
                                            {t('validationMessage.max12')}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={type !== "Bankkonto" ? "" : "d-none"}>
                                    <Col lg={6} className={`position-relative ${type === "Bankgiro" ? "" : "d-none"}`}>
                                        <CustomInput model={RequestPay} attribute='bG' data={data?.bG || ""}
                                                     customChange={(e) => {
                                                         onChange(e, "bG");
                                                     }}/>
                                        <div className={`error tooltip-custom-normal ${displayBGError === true ? 'opacity-1' : 'opacity-0'}`} >
                                            {t('validationMessage.digits7')}
                                        </div>
                                    </Col>
                                    <Col lg={6} className={`position-relative ${type === "Plusgiro" ? "" : "d-none"}`}>
                                        <CustomInput model={RequestPay} attribute='pG' data={data?.pG || ""}
                                                     customChange={(e) => {
                                                         onChange(e, "pG");
                                                     }}/>
                                        <div className={`error tooltip-custom-normal ${displayPGError === true ? 'opacity-1' : 'opacity-0'}`} >
                                            {t('validationMessage.digits2')}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <Row>
                                            <Col className="col-12">
                                                <Label>{t("modelLabel.recipient")}:</Label>
                                            </Col>
                                            <Col className="col-12">
                                                <ul className="p-0 list-unstyled d-flex">
                                                    <li className="pe-5">
                                                        <Label className='d-inline ps-2'>OCR
                                                            <Input className='flex-grow-1 float-start' type="radio" name="recipinent"
                                                                   onChange={() => {onChange("", "selectOCR"); setRecipient("oCR")}} />
                                                        </Label>
                                                    </li>
                                                    <li className="pe-5">
                                                        <Label className='d-inline ps-2'>{t("modelLabel.message")}
                                                            <Input className='flex-grow-1 float-start' type="radio" name="recipinent"
                                                                   onChange={() => {onChange("", "selectMessage"); setRecipient("message")}} />
                                                        </Label>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                        {data.oCR != null && data.message == null ?
                                            <CustomInput model={RequestPay} attribute='oCR' data={data?.oCR || ""} customChange={(e) => {
                                                         onChange(e, "oCR");
                                                     }}/>
                                        : ""}
                                        {data.oCR == null && data.message != null ?
                                            <CustomInput model={RequestPay} attribute='message' data={data?.message || ""} customChange={(e) => {
                                                onChange(e, "message");
                                            }}/>
                                            : ""}
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12}>
                                <CustomInput model={RequestPay} attribute='description' data={data?.description}
                                             customChange={(e) => {
                                                 onChange(e, "description");
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-end">
                    <Col className="text-end col-12">
                        {displaySpinner === true ?
                            <>
                                <span className="me-2">{t('validationMessage.sendingPay')}</span>
                                <Spinner color='success'/>
                            </>
                            : ""}
                        <CustomSubmitButton text={t('payReceive.payment')} id='submit' icon='bx bx-send pe-2'
                                            customClass="ms-3 p-1 bg-green px-3 px-lg-4" customClick={async () => {
                            if (data?.date === "" ) {
                                setDisplayDateError(true);
                            }
                            if (data?.fileId === "") {
                                setDisplayFileError(true);
                            }
                            let checkForm = true;
                            if (type === "Bankkonto") {
                                if (data?.clearingNumber === "") {
                                    setDisplayClearingNumberError(true);
                                    checkForm = false;
                                }
                                if (data?.accountNumber === "") {
                                    setDisplayAccountNumberError(true);
                                    checkForm = false;
                                }
                            } else {
                                if (data?.bG === "") {
                                    setDisplayBGError(true);
                                    checkForm = false;
                                }
                                if (data?.pG === "") {
                                    setDisplayPGError(true);
                                    checkForm = false;
                                }
                            }
                            if (checkForm === true) {
                                onValidate();
                            }
                        }} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}