import {AdminManagementRoles} from "../Components/AdminManagementRoles";
import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import AdminRequestRoleUserModel from "../Models/Request/AdminRequestRoleUserModel";
import AdminResponseRoleUserModel from "../Models/Response/AdminResponseRoleUserModel";
import {useNavigate} from "react-router-dom";
import {isArray} from "lodash";
import {TabMenu} from "../Components/TabMenu";
import AdminResponseOrgTypeModel from "../Models/Response/AdminResponseOrgTypeModel";
import AdminRequestOrgTypeModel from "../Models/Request/AdminRequestOrgTypeModel";
import {useTranslation} from "react-i18next";
import i18n from "../../AppSystem/i18n";
import {Card, Col, Row} from "reactstrap";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";

export function AdminRole() {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate()

    //ADD ROLES
    const [addRole, setAddRole] = useState(false);

    //LIST USER ROLE
    const [responseUserRoleList, setResponseUserRoleList] = useState(null);

    //LIST ORG ROLE
    const [responseOrgRoleList, setResponseOrgRoleList] = useState(null);

    //LIST ORG TYPE
    const [responseOrgTypeList, setResponseOrgTypeList] = useState(null);

    //REQUEST
    const [requestUserRole, setRequestUserRole] = useState(new AdminRequestRoleUserModel());
    const [requestOrgRole, setRequestOrgRole] = useState(new AdminRequestRoleUserModel());
    const [requestOrgType, setRequestOrgType] = useState(new AdminRequestOrgTypeModel());

    //console.log('requestUserRole', requestUserRole)

    //RESPONSE
    const [responseUserRole, setResponseUserRole] = useState(new AdminResponseRoleUserModel());
    const [responseOrgRole, setResponseOrgRole] = useState(new AdminResponseRoleUserModel());
    const [responseOrgType, setResponseOrgType] = useState(new AdminResponseOrgTypeModel());
    //console.log('responseUserRoleList' ,responseUserRoleList)

    //TRANSLATIONS
    const userRoleLabel = i18n.t('role.userRole');
    const orgRoleLabel = i18n.t('role.orgRole');
    const orgTypeLabel = i18n.t('role.orgType');

    /*let permissionCreate = false;
    if (CheckUserPermission("role.create") || CheckUserPermission("role")) {
        permissionCreate = true;
    }*/

    //BUTTON MENU
    const [dataButtonMenu, setDataButtonMenu] = useState({
        label:[userRoleLabel,orgRoleLabel, orgTypeLabel],
        buttonId:1
    });

    useEffect(() => {
        setDataButtonMenu((prevData) =>({
            ...prevData,
            label: [userRoleLabel, orgRoleLabel, orgTypeLabel],
        }));
    }, [userRoleLabel, orgRoleLabel, orgTypeLabel]);

    // VALIDATION SCHEMAS
    const completeSchemaUserRole = AdminRequestRoleUserModel.getAttributes( null, true);
    const completeSchemaOrgRole = AdminRequestRoleUserModel.getAttributes( null, true);
    const completeSchemaOrgType = AdminRequestOrgTypeModel.getAttributes( null, true);

    const [typeMethod,setTypeMethod]= useState('')


    //HANDLE BUTTON MENU
    const handleButtonRoute = (name) => {
        let buttonId = 1;
        if(name === 'firstStep') {
            buttonId = 1;
            setAddRole(false);
        }
        if(name === 'secondStep') {
            buttonId = 2;
            setAddRole(false)
        }
        if(name === 'thirdStep') {
            buttonId = 3;
            setAddRole(false)
        }
        setDataButtonMenu((prevData) =>({
            ...prevData,
            buttonId: buttonId,
        }));

    };
///////////////// USER  ROLE //////////////////
    //API CALL TO GET ROLE USER LIST
    useEffect(() => {
        const handleRoleUserListResponse = (responseUserRoleList) => {
            setResponseUserRoleList(responseUserRoleList);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/role/admin/user/list",
                };
                await ApiCall(apiCallOptions, handleRoleUserListResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();

    }, []);

    useEffect(() => {
        if (responseUserRole !== null && responseUserRole.role !== "" && responseUserRoleList !== null) {
            const handleRoleUserListResponse = (responseUserRoleList) => {
                setResponseUserRoleList(responseUserRoleList);
            };
            const CostApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/role/admin/user/list",
                    };
                    await ApiCall(apiCallOptions, handleRoleUserListResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            CostApiCall();
        }
    }, [responseUserRole]);

    //CREATE NEW USER ROLES AND VALIDATION
    const SubmitRoles = async (type) => {
        setTypeMethod(type);
        try {
            await completeSchemaUserRole.validate(requestUserRole, { abortEarly: false });
            const apiCallOptions = {
                method:type === "create" ? "post" : "put",
                url: "/role/admin/user",
                data: requestUserRole,
            };
            await ApiCall(apiCallOptions, setResponseUserRole);
            setTimeout(()=> {
                setRequestUserRole({
                    role: '',
                    permissions: [],
                });
            },80);

        } catch (error) {
            console.log(error);
            //FlashMessage('An error has occurred, please try again later or contact LRF', 'flash-messageError');
        }
    };

    //HANDLE CHANGE USER
    const HandleChangeRoleUser = (e, name, /*roleName = null*/) => {
        let roleValue = requestUserRole.role;
        let permissionsValue = requestUserRole.permissions;

        if (name === "addRole") {
            setAddRole(true);
        }

        if (name === "editRole") {
            roleValue = responseUserRoleList[e].role;
            permissionsValue = responseUserRoleList[e].permissions;
        }

        if (name === "cancelEditRole") {
             roleValue = '';
             permissionsValue = '';
        }
        if (name === "cancelRole") {
            setAddRole(false);
            roleValue = '';
            permissionsValue = [];
        }
        if (name === "role") {
            if (e && e.target && e.target.value !== "") {
                roleValue = e.target.value;
            }
        }
        if (name === "permissions") {

            if (isArray(e) && e.length > 0) {
                permissionsValue = e;
            } else {
                permissionsValue = [];
            }
        }

        setRequestUserRole((prevFormData) => ({
            ...prevFormData,
            role: roleValue,
            permissions: permissionsValue,
        }));
    }
   /* const HandleChangeTestRoleUser = (e, name, /!*roleName = null*!/) => {
        console.log('e', e);
        console.log('name', name);
    }
*/

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responseUserRole !== null && responseUserRole.role !== "") {
            if (typeMethod === 'create'){
                FlashMessage(t('validationMessage.roleSuccessfully'), 'flash-messageSuccess');
            }else{
                FlashMessage(t('validationMessage.permissionUpdate'), 'flash-messageSuccess');
            }
            setAddRole(false);
            // setRequestUserRole({
            //     role: '',
            //     permissions: [],
            // });
            /*setTimeout(() => {
                navigate(ADMIN_PATHS.ADMIN_ROLE);
            }, 2000);*/
        }




        if(responseUserRole === null) {
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    }, [responseUserRole]);

    ///////////////// ORG ROLE //////////////////
    //API CALL TO GET ORG ROLE LIST
    useEffect(() => {
        const handleOrgRoleListResponse = (responseOrgRoleList) => {
            setResponseOrgRoleList(responseOrgRoleList);
        };
        const OrgRoleListApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/role/admin/org/list",
                };
                await ApiCall(apiCallOptions, handleOrgRoleListResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        OrgRoleListApiCall();

    }, []);

    useEffect(() => {
        if (responseOrgRole !== null && responseOrgRole.role !== "" && responseOrgRoleList !== null) {
            const handleRoleOrgListResponse = (responseOrgRoleList) => {
                setResponseOrgRoleList(responseOrgRoleList);
            };
            const GetOrgRoleApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/role/admin/org/list",
                    };
                    await ApiCall(apiCallOptions, handleRoleOrgListResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            GetOrgRoleApiCall();
        }
    }, [responseOrgRole]);

    //CREATE NEW ORG ROLES AND VALIDATION
    const SubmitOrgRoles = async (type) => {
        setTypeMethod(type);
        try {
            await completeSchemaOrgRole.validate(requestOrgRole, { abortEarly: false });
            const apiCallOptions = {
                method:type === "create" ? "post" : "put",
                url: "/role/admin/org",
                data: requestOrgRole,
            };
            await ApiCall(apiCallOptions, setResponseOrgRole);

            //return { success: true };
        } catch (error) {
            console.log(error);
           // FlashMessage('An error has occurred, please try again later or contact LRF', 'flash-messageError');
        }
    };

    //HANDLE CHANGE ORG
    const HandleChangeOrgRole = (e, name) => {
        let roleValue = requestOrgRole.role;
        let permissionsValue = requestOrgRole.permissions;
        if (name === "addRole") {
            setAddRole(true);
        }

        if (name === "editRole") {
            roleValue = responseOrgRoleList[e].role;
            permissionsValue = responseOrgRoleList[e].permissions;
        }

        if (name === "cancelEditRole") {
            roleValue = '';
            permissionsValue = '';
        }
        if (name === "cancelRole") {
            setAddRole(false);
            roleValue = '';
            permissionsValue = [];
        }
        if (name === "role") {
            if (e && e.target && e.target.value !== "") {
                roleValue = e.target.value;
            }
        }
        if (name === "permissions") {
            if (isArray(e) && e.length > 0) {
                permissionsValue = e;
            } else {
                permissionsValue = [];
            }
        }

        setRequestOrgRole((prevFormData) => ({
            ...prevFormData,
            role: roleValue,
            permissions: permissionsValue,
        }));
    }

    // REDIRECT WHEN FINISH ORG ROLE
    useEffect(() => {
        if (responseOrgRole !== null && responseOrgRole.role !== "") {
            if (typeMethod === 'create'){
                FlashMessage(t('validationMessage.roleSuccessfully'), 'flash-messageSuccess');
            }else{
                FlashMessage(t('validationMessage.permissionUpdate'), 'flash-messageSuccess');
            }
            setAddRole(false);
            setRequestOrgRole({
                role: '',
                permissions: [],
            });
        }
        if(responseOrgRole === null) {
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    }, [responseOrgRole]);


    ///////////////// ORG TYPE //////////////////
    //API CALL TO GET ORG TYPE LIST
    useEffect(() => {
        const handleOrgTypeListResponse = (responseOrgTypeList) => {
            setResponseOrgTypeList(responseOrgTypeList);
        };
        const OrgTypeListApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/role/admin/type/org/list",
                };
                await ApiCall(apiCallOptions, handleOrgTypeListResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        OrgTypeListApiCall();

    }, []);

    useEffect(() => {
        if (responseOrgType !== null && responseOrgType.type !== "" && responseOrgTypeList !== null) {
            const handleOrgTypeListResponse = (responseOrgTypeList) => {
                setResponseOrgTypeList(responseOrgTypeList);
            };
            const GetOrgTypeApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/role/admin/type/org/list",
                    };
                    await ApiCall(apiCallOptions, handleOrgTypeListResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            GetOrgTypeApiCall();
        }
    }, [responseOrgType]);

    //CREATE NEW ORG TYPE AND VALIDATION
    const SubmitOrgType = async (type) => {
        setTypeMethod(type);
        try {
            await completeSchemaOrgType.validate(requestOrgType, { abortEarly: false });
            const apiCallOptions = {
                method:type === "create" ? "post" : "put",
                url: "/role/admin/type/org",
                data: requestOrgType,
            };
            await ApiCall(apiCallOptions, setResponseOrgType);

            //return { success: true };
        } catch (error) {
            console.log(error);
            //FlashMessage('An error has occurred, please try again later or contact LRF', 'flash-messageError');
        }
    };

    //HANDLE CHANGE ORG TYPE
    const HandleChangeOrgType = (e, name) => {
        let typeValue = requestOrgType.type;
        let permissionsValue = requestOrgType.permissions;
        if (name === "addRole") {
            setAddRole(true);
        }

        if (name === "editType") {
            typeValue = responseOrgTypeList[e].type;
            permissionsValue = responseOrgTypeList[e].permissions;
        }

        if (name === "cancelEditRole") {
            typeValue = '';
            permissionsValue = '';
        }
        if (name === "cancelRole") {
            setAddRole(false);
            typeValue = '';
            permissionsValue = [];
        }
        if (name === "type") {
            if (e && e.target && e.target.value !== "") {
                typeValue = e.target.value;
            }
        }
        if (name === "permissions") {
            if (isArray(e) && e.length > 0) {
                permissionsValue = e;
            } else {
                permissionsValue = [];
            }
        }

        setRequestOrgType((prevFormData) => ({
            ...prevFormData,
            type: typeValue,
            permissions: permissionsValue,
        }));
    }

    // REDIRECT WHEN FINISH ORG ROLE
    useEffect(() => {
        if (responseOrgType !== null && responseOrgType.type !== "") {
            if (typeMethod === 'create'){
                FlashMessage(t('validationMessage.typeSuccessfully'), 'flash-messageSuccess');
            }else{
                FlashMessage(t('validationMessage.permissionUpdate'), 'flash-messageSuccess');
            }
            setAddRole(false);
            setRequestOrgType({
                type: '',
                permissions: [],
            });
        }
        if(responseOrgType === null) {
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    }, [responseOrgType]);

    // PERMISSION TREE
    const [responsePermissionTree, setResponsePermissionTree] = useState(null);
    const [showHidePermission, setShowHidePermission] = useState(false);

    useEffect(() => {
        const handlePermissionTreeResponse = (responsePermissionTree) => {
            setResponsePermissionTree(responsePermissionTree);
        };
        const GetPermissionTreeApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/application/admin/permission-tree",
                };
                await ApiCall(apiCallOptions, handlePermissionTreeResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        GetPermissionTreeApiCall();
    }, []);

    return(
        <>
            <Card className="card-backend px-0 mb-5">
            {/*<h2 className="ps-4 pt-3 rokkitt-font">{t('role.roles')}</h2>*/}
                <Row className="px-4 pt-3">
                    <Col xs='auto'>
                        <h2 className='rokkitt-font'> {t('role.roles')}</h2>
                    </Col>
                    <InfoPageMessage message={ t("role.explanation" )}/>
                </Row>
                <TabMenu data={dataButtonMenu} onClick={handleButtonRoute}/>
                {dataButtonMenu?.buttonId === 1 ?
                    <AdminManagementRoles data={responseUserRoleList} onChange={HandleChangeRoleUser} onValidate={SubmitRoles} checkAddRoleButton={addRole} permissionTree={responsePermissionTree} /*requestData={requestUserRole}*//*key={keyTree}*//>
                    : ''
                }
                {dataButtonMenu?.buttonId === 2 ?
                    <AdminManagementRoles data={responseOrgRoleList} onChange={HandleChangeOrgRole} onValidate={SubmitOrgRoles} checkAddRoleButton={addRole} permissionTree={responsePermissionTree}/>
                    : ''
                }
                {dataButtonMenu?.buttonId === 3 ?
                    <AdminManagementRoles data={responseOrgTypeList} onChange={HandleChangeOrgType} onValidate={SubmitOrgType} checkAddRoleButton={addRole} permissionTree={responsePermissionTree}/>
                    : ''
                }

                {/*{responsePermissionTree != null ?
                    <Card className={`position-fixed bottom-50 mb-5 ${showHidePermission === true ? "w-50" : "w-3"} end-0 shadow p-3`}>
                        <Row>
                            <Col className={`col-10 ${showHidePermission === false ? "d-none" : ""}`}>
                                <span className="text-green fw-bold">{t("role.theseAreThePermissions")}</span><br />
                                <span className="text-warning">{t("role.explanation")}</span>
                            </Col>
                            <Col className="col-2 text-end">
                                <i className="bx bx-info-circle fs-5 text-green cursor-pointer-custom"
                                   onClick={() => setShowHidePermission(!showHidePermission)}/>
                            </Col>
                        </Row>
                        <div className={showHidePermission === false ? "d-none" : ""}>
                            <hr/>
                            {responsePermissionTree.permissions && responsePermissionTree.permissions != null && responsePermissionTree.permissions.length > 0 ?
                                responsePermissionTree.permissions.map((item, index) => {
                                    let value = `${item}, `;
                                    if (responsePermissionTree.permissions.length - 1 === index) {
                                        value = item;
                                    }
                                    return value;
                                })
                                : ""}
                        </div>
                    </Card>
                    : ""}*/}

            </Card>

        </>)

}