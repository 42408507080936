import {LoadLocalStorage} from "./LoadLocalStorage";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import sv from "date-fns/locale/sv";
import {StringToUpperFirst} from "./StringToUpperFirst";

export const CustomDateLocale = () => {
    const language = LoadLocalStorage('selectedLanguage') === 'es-ES' ? es : (LoadLocalStorage('selectedLanguage') === 'en-US' ? en : sv);
    return {
        ...language,
        localize: {
            ...language.localize,
            month: (monthIndex, options) => {
                return StringToUpperFirst(language.localize.month(monthIndex, options));
            },
            day: (dayOfWeekIndex, options) => {
                return StringToUpperFirst(language.localize.day(dayOfWeekIndex, options));
            },
        }
    };
}