export function CheckDecimal(stringValue) {
  if (stringValue === "" || stringValue === "," || stringValue === ".") {
    return 0;
  }
  // Check if the last character is a comma or a dot and delete it
  const lastWord = stringValue[stringValue.length - 1];
  if (lastWord === "," || lastWord === ".") {
    stringValue = stringValue.slice(0, -1);
  }
  if (stringValue.includes(",") === true) {
    stringValue = stringValue.replace(",", ".");
  }
  return parseFloat(stringValue);
}
