import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class ResponseInvoice {
    constructor({
                    id= '',
                    visualId= '',
                    invoiceNumber = '',
                    orgId= '',
                    orgName= '',
                    clientId = '',
                    clientName = '',
                    expiringDate = 0,
                    recipientReference = '',
                    reference = '',
                    amount = 0,
                    vatAmount = 0,
                    totalAmount = 0,
                    status = '', //draft, revoked, attestquoe, attestedAndSend, credited
                    type = '', // invoice, credit_invoice
                    fileUrl = '',
                    fileId = '',
                    reason = '',
                    approvedDeniedByName = '',
                    createdByName = '',
                    createdAt = '',
                    rows = [],
                    costBearerId = '',
                    costBearerName = '',
                    sentAt = '',
                    creditInvoiceReferenceId = ''

                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.invoiceNumber = invoiceNumber;
        this.orgId = orgId;
        this.orgName = orgName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.expiringDate = expiringDate;
        this.recipientReference = recipientReference;
        this.reference = reference;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.totalAmount = totalAmount;
        this.status = status;
        this.type = type;
        this.fileUrl = fileUrl;
        this.fileId = fileId;
        this.reason = reason;
        this.approvedDeniedByName = approvedDeniedByName;
        this.createdByName = createdByName;
        this.createdAt = createdAt;
        this.rows = rows;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.sentAt = sentAt;
        this.creditInvoiceReferenceId = creditInvoiceReferenceId;
    }

    static getAttributes(filter = null) {
        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            visualId:{
                'label': "Id",
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            invoiceNumber:{
                'label': i18n.t('modelLabel.invoiceNumber'),
                'sortable': true,
                'sortField': 'invoiceNumber',
                'value': (item) => item.invoiceNumber || ""
            },
            orgId :{
                'label': i18n.t('modelLabel.orgId'),
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.orgName'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            clientId :{
                'label': i18n.t('invoice.clientName'),
                'sortable': true,
                'sortField': 'clientId',
                'value': (item) => item.clientId || "",
            },
            clientName :{
                'label': i18n.t('invoice.clientName'),
                'sortable': true,
                'sortField': 'clientName',
                'value': (item) => item.clientName || "",
            },

            expiringDate :{
                'label': i18n.t('invoice.expiringDate'), //Dagar tills förfallodatum
                'sortable': true,
                'sortField': 'expiringDate',
                'value': (item) => item.expiringDate ? ChangeDateFormat('date', item.expiringDate) : '' ,
            },
            recipientReference :{
                'label': i18n.t('invoice.recipientRef'), //Mottagarens referens
                'sortable': true,
                'sortField': 'recipientReference',
                'value': (item) => item.recipientReference || "",
            },
            reference :{
                'label': i18n.t('invoice.reference'),//Referens
                'sortable': true,
                'sortField': 'reference',
                'value': (item) => item.reference || "",
            },
            amount :{
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'amount',
                'value': (item) => item.amount ? ToCustomDecimal(item?.amount) : "",
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'sortable': true,
                'sortField': 'vatAmount',
                'value': (item) => item.vatAmount ? ToCustomDecimal(item?.vatAmount) : "",
            },
            totalAmount :{
                'label': i18n.t('modelLabel.totalAmount'),
                'sortable': true,
                'sortField': 'totalAmount',
                'insideClass' : (item)=> item.totalAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                'value': (item) => item.totalAmount ? ToCustomDecimal(item?.totalAmount) + ' kr' : item.totalAmount === 0 && item.status === 'Attestera kö' ? '0 kr' : "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width' : (item.status === 'Nekad'|| item.status === 'Återkallad' ? "box-red me-4 fixed-width" : (item.status === 'Förslag' ? 'box-orange me-4 fixed-width' : (item.status === 'Krediterad' ? 'box-blue me-4 fixed-width' : "box-custom-grey me-4 fixed-width"))),
                'value': (item) => item.status === 'Attestera kö' ? i18n.t('modelLabel.attestQueue') : item.status === 'Förslag' ? i18n.t('reimbursement.draft') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : item.status === 'Betalad' ? i18n.t('modelLabel.paid') : item.status === 'Återkallad' ? i18n.t('modelLabel.revoked') : item.status === 'Krediterad' ? i18n.t('modelLabel.credited') : "" || "",
            },
            type :{
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.type || "",
            },
            fileUrl :{
                'label': 'file url',
                'sortable': true,
                'sortField': 'fileUrl',
                'value': (item) => item.fileUrl || "",
            },
            fileId :{
                'label': 'file url',
                'sortable': true,
                'sortField': 'fileId',
                'value': (item) => item.fileId || "",
            },
            reason :{
                'label': 'reason',
                'sortable': true,
                'sortField': 'reason',
                'value': (item) => item.reason || "",
            },
            approvedDeniedByName :{
                'label': 'approvedDeniedByName',
                'sortable': true,
                'sortField': 'approvedDeniedByName',
                'value': (item) => item.approvedDeniedByName || "",
            },
            createdByName :{
                'label': i18n.t('modelLabel.creatorName'),
                'sortable': true,
                'sortField': 'createdByName',
                'value': (item) => item.createdByName || "",
            },
            createdAt :{
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '',
            },
            rows :{
                'label': 'rows',
                'sortable': true,
                'sortField': 'rows',
                'value': (item) => item.rows || "",
            },
            costBearerId: {
                'label': i18n.t('reimbursement.costBearer'),
                'sortable': true,
                'sortField': 'costBearerId',
                'value': (item) => item.costBearerId || "",
            },
            costBearerName: {
                'label': i18n.t('reimbursement.costBearerName'),
                'sortable': true,
                'sortField': 'costBearerName',
                'value': (item) => item.costBearerName || "",
            },
            sentAt: {
                'label': i18n.t('modelLabel.sentAt'),
                'sortable': true,
                'sortField': 'sendAt',
                'value': (item) => item.sentAt || "",
            },
            creditInvoiceReferenceId: {
                'label': i18n.t('invoice.creditInvoiceRef'),
                'sortable': true,
                'sortField': 'creditInvoiceReferenceId',
                'value': (item) => item.creditInvoiceReferenceId || "",
            },
        }

        return attributes;
    }
}
export default ResponseInvoice;