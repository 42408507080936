import { Card, Col, Row } from 'reactstrap';
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.png"
import Delete from "../../AppSystem/assets/img/swedbank/delete.png"
import { useTranslation } from 'react-i18next';

const CheckoutPaymentCanceled = () => {
  
    
    return (
      <Row className='d-flex justify-content-center pt-5'>
      <Col lg={9} >
        <Card className="p-3">
          <Row className='d-flex justify-content-center px-4'>
              <Col lg={6} className='d-flex justify-content-center pt-5'>
              <img src={LogoLRF} />
              </Col>
              <Col lg={12} className='d-flex justify-content-center pt-5'>
                  <h2>Din betalning har avbrutits</h2>
              </Col>
              <Col lg={6} className='d-flex justify-content-center pt-5'>
              <img src={Delete} alt="" height="80" width="80" />
              
              </Col>
          </Row>
        
      </Card>
      </Col>
    </Row>
    );
  };
  
  export default CheckoutPaymentCanceled;