import {Col, Row} from 'reactstrap';
import {Link} from "react-router-dom";
import {CheckMobile} from "../../../AppSystem/Helpers/CheckMobile";

export function ChangeLink({ icon, link, hoverLink, label, mouseEnter, mouseLeave, condition, onClick , shortcutCreate, toggle = null }) {
  const windowWidth = CheckMobile(850);
  return (
    <Row onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className={toggle !== null && toggle === true  ? 'd- flex justify-content-center' :''}>
    {shortcutCreate === false ?
        <Col lg={12}> {/*10*/}
            <Link to={link} onClick={onClick}>
                <i className={`bx ${icon} fs-5`} />
                <span className="menu-item ps-1 " data-key="t-client">
                {label}
              </span>
            </Link>

        </Col> :
            <>
                <div className="col-2">
                    {condition === link && !windowWidth ? (
                        <Link to={hoverLink}>
                            <i className="bx bx-plus-circle fs-5" />
                        </Link>
                    ) : (
                        <a href="#">
                            <i className={`bx ${icon} fs-5`} />
                        </a>
                    )}

                </div>
                <div className="col-8 p-0">
                    <Link to={link} className="ps-1" onClick={onClick}>
                  <span className="menu-item" data-key="t-client">
                    {label}
                  </span>
                    </Link>
                </div>
            </>
    }


    </Row>
  );
}
