import {Card, CardBody, Table} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const UserOrgRoles = ({ data, isSuperAdmin = null}) => {
    const { t } = useTranslation();

    return (
        <Card className="mb-3">
            <CardBody>
                <h5 className="mb-3 text-green fw-bold">{t('role.orgRoles')} </h5>
                <Table className="table-borderless">
                    <tbody>
                    {data.map((item, index) =>
                        <React.Fragment key={'adminOrgList' + index}>
                            {isSuperAdmin && isSuperAdmin === true ?
                                <React.Fragment>
                                    <tr key={'Id' + index}>
                                        <th className="ps-0" scope="row">{t('modelLabel.id')}:</th>
                                        <td className="text-muted">{item.id}</td>
                                    </tr>
                                    <tr key={'roleId' + index}>
                                        <th className="ps-0" scope="row">{t('modelLabel.orgId')}:</th>
                                        <td className="text-muted">{item.orgId}</td>
                                    </tr>
                                </React.Fragment>
                            : ''}
                            {item.orgName && item.orgType ?
                                <tr key={'orgName' + index}>
                                    <th className="ps-0" scope="row">{t('modelLabel.orgName')}:</th>
                                    <td className="text-muted auto-wrap-td-info">{`${item.orgName} (${item.orgType})`}</td>
                                </tr>
                            : ''}
                            <tr key={'role' + index}>
                                <th className="ps-0" scope="row">{t('role.role')}:</th>
                                <td className="text-muted">{item.role}</td>
                            </tr>
                            {item.permissions && item.permissions.length > 0 ?
                                <tr key={'permission' + index}>
                                    <th className="ps-0" scope="row">{t('role.permissions')}:</th>
                                    <td className="text-muted auto-wrap-td-info">{item.permissions.join(', ')}</td>
                                </tr>
                                : ''}
                            {data.length - 1 !== index &&
                                <tr className='border-bottom-white'>
                                    <td colSpan="2" className='p-1'>&nbsp;</td>
                                </tr>
                            }
                        </React.Fragment>
                    )}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}