class AdminResponseOrgTypeModel {
    constructor({
                    type = '',
                    permissions = [],
                } = {}) {
        this.type = type;
        this.permissions = permissions;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const attributes = {
            type :{
                'label': 'Type',
                'sortable': true,
                'sortField': "type",
                'value': (item) => item.type || "",
            },
            permissions :{
                'label': 'Permissions',
                'sortable': true,
                'sortField': "permissions",
                'value': (item) => item.permissions || "",
            },

        }

        return attributes;
    }
}

export default AdminResponseOrgTypeModel;