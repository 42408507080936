import { LoginWithRoten } from '../../Auth/Components/RotenLogin/LoginWithRoten';

export  function LoginRoten() {
       
  return(          
    <div className="container-fluid p-0 bg-main ">
      <div id="layout-wrapper" className="bg-darkBlue">
       <LoginWithRoten />         
      </div>
    </div>
 );
}