//Example for use this Modal:

/*
const [showConfirmModalDelete, setShowConfirmModalDelete] = useState(false);

const contentBodyDelete = (
    <div>Are you sure you want to delete this item?</div>

);
const contentFooterDelete = (
    <>
        <Button className="button-bg-grow border-0" onClick={() => {
            setShowConfirmModalDelete(false); // Close Modal
        }}>Exit Without Saving</Button>
        {' '}
        <Button className="button-bg-denial border-0" onClick={() => setShowConfirmModalDelete(false)}>Cancel</Button>
    </>
);

return (
    <>
        <CustomModal isOpen={showConfirmModalDelete} toggle={() => setShowConfirmModalDelete(!showConfirmModalDelete)} body={contentBodyDelete} footer={contentFooterDelete}/>
        <Button onClick={()=>{setShowConfirmModalDelete(true)}}>Test Modal</Button>
    </>
    )
*/

//setShowConfirmModalDelete(true) for show modal


import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export function CustomModal({isOpen, toggle, header, body, footer, modalOptions = {}}) {
    // To be able to customize the close button, by default it uses the same action as the Modal toggle, now it is in ModalHeader toggle={null}, if we want the close cross put toggle={toggle}

    //To add additional attributes to the custom Modal:
    const { size, zIndex, ...otherAttributes } = modalOptions;

    const modalStyle = { zIndex: zIndex || 9999 };


    return (
        <Modal size={size || 'lg'} isOpen={isOpen} toggle={toggle} className="border-0 custom-modal-outline"  style={modalStyle}  keyboard={false}  backdrop="static"  {...otherAttributes}>
            {header ?
                <ModalHeader  className="border-bottom-0 d-flex justify-content-center" toggle={null}>{header}</ModalHeader>
            : ""}
            <ModalBody className="py-4 text-center">
                {body}
            </ModalBody>
            <ModalFooter className="justify-content-center border-top-0 pb-4">
                {footer}
            </ModalFooter>
        </Modal>
    );
}