import React, {useEffect, useState} from 'react';
import {
    Card,
    Col,
    Row,
    Button, Spinner
} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import useApiCallService from "../../Common/Helpers/ApiCall";
import RequestReimbursementFixed from "../Models/Request/RequestReimbursementFixed"
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementMileage from "../Models/Request/RequestReimbursementMileage";
import RequestReimbursementExpense from "../Models/Request/RequestReimbursementExpense";
import $ from 'jquery';
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseReimbursementFixed from "../Models/Response/ResponseReimbursementFixed";
import ResponseReimbursementMileage from "../Models/Response/ResponseReimbursementMileage";
import ResponseReimbursementExpense from "../Models/Response/ResponseReimbursementExpense";
import RequestReimbursement from "../Models/Request/RequestReimbursement";
import ResponseReimbursement from "../Models/Response/ResponseReimbursement";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {Link, useNavigate} from "react-router-dom";
import {REPORT_PROCEEDING_PATHS, USER_PATHS} from "../../config";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import {ReimbursementFixed} from "../Components/ReimbursementFixed";
import {ReimbursementMileage} from "../Components/ReimbursementMileage";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ReimbursementExpense} from "../Components/ReimbursementExpense";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import RequestReimbursementCommon from "../Models/Request/RequestReimbursementCommon";
import RequestPassenger from "../Models/Request/RequestPassenger";
import AccountingAccountModel from "../../Accounting/Models/Response/ResponseAccountingAccountModel";
import {StringToLowerCaseFirst} from "../../Common/Helpers/StringToLowerCaseFirst";
import {useTranslation} from "react-i18next";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {CustomUnsavedModal} from "../../Common/Components/Modal/CustomUnsavedModal";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CustomReimbursementView} from "../Components/CustomReimbursementView";
import {CustomReimbursementFixedView} from "../Components/CustomReimbursementFixedView";
import {CustomReimbursementMileageView} from "../Components/CustomReimbursementMileageView";
import {CustomReimbursementExpenseView} from "../Components/CustomReimbursementExpenseView";
import {ClearEmptyJson} from "../../AppSystem/Helpers/ClearEmptyJson";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import ResponseUserSettingsModel from "../../UserSettings/Model/Response/ResponseUserSettingsModel";
import {useDispatch, useSelector} from "react-redux";
import {CalculateTax} from "../../Common/Helpers/CalculateTax";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";

export function ReportProceeding() {
    const { t } = useTranslation()
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();

    const { userData, orgData } = useSelector(state => state.auth);
    const taxPercent = CalculateTax(userData.pnr);

    // REQUESTS
    const [requestReimbursement, setRequestReimbursement] = useState(new RequestReimbursement());
    const [requestReimbursementFixed, setRequestReimbursementFixed] = useState(new RequestReimbursementFixed());
    const [requestReimbursementMileage, setRequestReimbursementMileage] = useState(new RequestReimbursementMileage());
    const [requestReimbursementExpense, setRequestReimbursementExpense] = useState(new RequestReimbursementExpense());
    const [requestReimbursementExpenseList, setRequestReimbursementExpenseList] = useState([]);
    const [fileList, setFileList] = useState([]);

    // API RESPONSES
    const [responseReimbursement, setResponseReimbursement] = useState(new ResponseReimbursement());
    const [responseReimbursementFixed, setResponseReimbursementFixed] = useState(new ResponseReimbursementFixed());
    const [responseReimbursementMileage, setResponseReimbursementMileage] = useState(new ResponseReimbursementMileage());
    const [responseReimbursementExpense, setResponseReimbursementExpense] = useState(new ResponseReimbursementExpense());
    const [responseReimbursementExpenseList, setResponseReimbursementExpenseList] = useState([]);
    const [deniedReimbursement, setDeniedReimbursement] = useState(null);

    const [responseUserSettings, setResponseUserSettings] = useState(null);

    const [deleteApiResponse, setDeleteApiResponse] = useState(null);

    // EDIT MODE
    const [editModeFixed, setEditModeFixed] = useState(false);
    const [editModeMileage, setEditModeMileage] = useState(false);
    const [editModeExpense, setEditModeExpense] = useState(false);

    const [fileAttribute, setFileAttribute] = useState(false);

    // CHECK UNSAVED
    const [checkUnsaved, setCheckUnsaved] = useState(false);

    // ADDED STATES
    const [addedFixed, setAddedFixed] = useState(false);
    const [addedMileage, setAddedMileage] = useState(false);
    const [addedExpense, setAddedExpense] = useState(false);

    // STATES
    const [expenseIndex, setExpenseIndex] = useState(null);
    const [requestName , setRequestName] = useState("");
    const [requestName2 , setRequestName2] = useState("");
    const [fileType, setFileType] = useState("");
    const [extraField , setExtraField] = useState(null);
    const [extraField2 , setExtraField2] = useState(null);
    const [done, setDone] = useState(false);
    const [save, setSave] = useState(false);

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState(null);
    const [aSettingResponse, setASettingResponse] = useState(null);
    const [aExtraResponse, setAExtraResponse] = useState(null);

    // VALIDATION SCHEMAS
    const completeSchemaFixed = RequestReimbursementFixed.getAttributes( null, true);
    const completeSchemaMileage = RequestReimbursementMileage.getAttributes(null, true);
    const completeSchemaPassenger = RequestPassenger.getAttributes(null, true);
    const completeSchemaExpense = RequestReimbursementExpense.getAttributes( null, true);
    const completeSchema = RequestReimbursement.getAttributes( null, true);
    const completeSchemaCommon = RequestReimbursementCommon.getAttributes( null, true);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);
    const [costSelected, setCostSelected] = useState(null);

    // ORG RESPONSE
    const [orgResponse, setOrgResponse] = useState(null);
    const [orgSelected, setOrgSelected] = useState(null);
    const [defaultOrg, setDefaultOrg] = useState(false);

    // FILE RESPONSE
    const [fileResponse, setFileResponse] = useState(null);

    // HOURLY SALARY RESPONSE
    const [hourlyResponse, setHourlyResponse] = useState(null);

    // PAYABLE RESPONSES
    const [payableResponse, setPayableResponse] = useState(null);
    const [payablePassengerResponse, setPayablePassengerResponse] = useState(null);

    // DISPLAY FORMS
    const [displayFixed, setDisplayFixed] = useState(true);
    const [displayMileage, setDisplayMileage] = useState(true);
    const [displayExpense, setDisplayExpense] = useState(true);

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);

    // MODAL OPTIONS
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [reimbursementToDelete, setReimbursementToDelete] = useState("");
    const [displaySendModal, setDisplaySendModal] = useState(false);
    const [displayDeleteModalReimbursement, setDisplayDeleteModalReimbursement] = useState(false);

    const [isDeletedFile, setIsDeletedFile] = useState(false);
    const [isDeletedExtraFile, setIsDeletedExtraFile] = useState(false);

    let permissionCreate = false;
    if (CheckUserPermission("reimbursement.create") /*|| CheckUserPermission("reimbursement")*/) {
        permissionCreate = true;
    }

    // GET ID IF EXISTS FOR DRAFT MODE
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET REIMBURSEMENT FOR DRAFT MODE
    useEffect(() => {
        $("#submitReimbursement, #saveReimbursement").prop("disabled", true);
        if (id !== "") {
            $("#saveReimbursement").prop("disabled", false);
            const handleDraftResponse = (response) => {
                if (response !== null) {
                    setRequestReimbursement(new RequestReimbursement(response));
                    if (response.fixed) {
                        setDisplayFixed(false);
                        setEditModeFixed(false);
                        setAddedFixed(true);
                        setRequestReimbursementFixed(new RequestReimbursementFixed(response.fixed));
                    }
                    if (response.mileage) {
                        setDisplayMileage(false);
                        setEditModeMileage(false);
                        setAddedMileage(true);
                        setRequestReimbursementMileage(new RequestReimbursementMileage(response.mileage));
                    }
                    if (response.expenses && response.expenses.length > 0) {
                        setAddedExpense(true);
                        setEditModeExpense(false);
                        setRequestReimbursementExpenseList(response.expenses);
                    }
                }
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDraftResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // CHECK ORG NAME
    useEffect(() => {
        if (defaultOrg === false && requestReimbursement.orgId === "") {
            setRequestReimbursement((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
        }
    }, [defaultOrg]);
    useEffect(() => {
        if (defaultOrg === false && orgResponse !== null && requestReimbursement.orgId !== "") {
            orgResponse.forEach(item => {
                if (item.id === requestReimbursement.orgId) {
                    setOrgSelected(item.name);
                }
            });
            setDefaultOrg(true);
        }
    }, [orgResponse]);

    // GET REIMBURSEMENT IF THERE IS DENIED REIMBURSEMENT
    useEffect(() => {
        if (id !== "") {
            const handleDeniedResponse = (response) => {
                if (response !== null) {
                    setDeniedReimbursement(response);
                }
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement/check-denied?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDeniedResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // CHECK SEND AND SAVE BUTTONS
    useEffect(() => {
        if ((addedFixed === true || addedMileage === true || addedExpense === true) && requestReimbursement.date !== "" && requestReimbursement.purpose !== "" && requestReimbursement.costBearerId !== "" && requestReimbursement.orgId !== "") {
            $("#submitReimbursement, #saveReimbursement").prop("disabled", false);
        } else {
            $("#submitReimbursement").prop("disabled", true);
        }
        if (addedFixed === false && addedMileage === false && addedExpense === false) {
            $("#submitReimbursement").prop("disabled", true);
        }
    }, [addedFixed, addedMileage, addedExpense, requestReimbursement.date, requestReimbursement.purpose, requestReimbursement.costBearerId, requestReimbursement.orgId]);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list",
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // API CALL TO GET HOURLY SALARY
    useEffect(() => {
        const handleHourlyResponse  = (hourlyResponse) => {
            setHourlyResponse(hourlyResponse);
        };
        const HourlyApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=HourlySalary",
                };
                await ApiCall(apiCallOptions, handleHourlyResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        HourlyApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);

    // API CALL TO GET PAYABLE
    useEffect(() => {
        const handlePayable = (payableResponse) => {
            setPayableResponse(payableResponse);
        };
        const PayableApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=PayableTravelPer10Km",
                };
                await ApiCall(apiCallOptions, handlePayable);
            } catch (error) {
                console.log('error', error);
            }
        };
        PayableApiCall();
    }, []);

    // API CALL TO GET PAYABLE PASSENGER
    useEffect(() => {
        const handlePayablePassenger = (payablePassengerResponse) => {
            setPayablePassengerResponse(payablePassengerResponse);
        };
        const PassengerApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=PayablePassangerTravelPer10Km",
                };
                await ApiCall(apiCallOptions, handlePayablePassenger);

            } catch (error) {
                console.log('error', error);
            }
        };
        PassengerApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING SETTINGS
    useEffect(() => {
        if (requestReimbursementExpense.accountingId !== "") {
            const ASettingApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/setting?accountingId=${requestReimbursementExpense.accountingId}&name=iva`,
                    };
                    await ApiCall(apiCallOptions, setASettingResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            ASettingApiCall();
        }
    }, [requestReimbursementExpense.accountingId]);

    // API CALL TO GET ACCOUNTING EXTRA
    useEffect(() => {
        if (requestReimbursementExpense.accountingId !== "") {
            const AExtraApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/extra?accountingId=${requestReimbursementExpense.accountingId}`,
                    };
                    await ApiCall(apiCallOptions, setAExtraResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            AExtraApiCall();
        }
    }, [requestReimbursementExpense.accountingId]);

    // API CALL TO GET ORGANIZATIONS
    useEffect(() => {
        const OrgApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/user/org-list-permission",
                };
                await ApiCall(apiCallOptions, setOrgResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        OrgApiCall();
    }, []);

    let orgJson = {};
    if (orgResponse !== null) {
        const orgArray = [];
        orgResponse.forEach((item) => {
            orgArray.push({
                id: item.id,
                name: item.name,
            });
        });
        orgJson = {
            org: orgArray
        };
    }

    let costJson = {};
    if (costResponse !== null) {
        const costBearerArray = [];
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
        costJson = {
            costBearer: costBearerArray
        };
    }

    let accountingJson = {};
    if (accountingResponse !== null) {
        const accountingArray = [];
        accountingResponse.forEach((item) => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
        accountingJson = {
            accounting: accountingArray,
        };
    }

    // SUBMIT FORMS
    const SubmitFixed = async (type = null) => {
        if (type != null) {
            if (JSON.stringify(requestReimbursementFixed) !== JSON.stringify(new RequestReimbursementFixed())) {
                requestReimbursementFixed.status = "Förslag";
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/fixed",
                    data: ClearEmptyJson(requestReimbursementFixed),
                };
                try {
                    await ApiCall(apiCallOptions, setResponseReimbursementFixed);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } else {
            if (addedFixed === true) {
                requestReimbursementFixed.status = "Skickat";
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/fixed",
                    data: ClearEmptyJson(requestReimbursementFixed),
                };
                try {
                    await ApiCall(apiCallOptions, setResponseReimbursementFixed);
                } catch (error) {
                    console.log(error);
                    FlashMessage( t('validationMessage.sendError') , 'flash-messageError');
                }
            }
        }
    };

    const SubmitMileage = async (type = null) => {
        if (type != null) {
            if (JSON.stringify(requestReimbursementMileage) !== JSON.stringify(new RequestReimbursementMileage())) {
                requestReimbursementMileage.status = "Förslag";
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/mileage",
                    data: ClearEmptyJson(requestReimbursementMileage),
                };
                try {
                    await ApiCall(apiCallOptions, setResponseReimbursementMileage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } else {
            if (addedMileage === true) {
                requestReimbursementMileage.status = "Skickat";
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/mileage",
                    data: ClearEmptyJson(requestReimbursementMileage),
                };
                try {
                    await ApiCall(apiCallOptions, setResponseReimbursementMileage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        }
    };

    const SubmitExpense = async (type = null) => {
        const handleResponseReimbursementExpense = (expense) => {
            setResponseReimbursementExpense(expense);
            if (expense !== null && expense.id !== "") {
                setResponseReimbursementExpenseList((prevFormData) => ([
                    ...prevFormData,
                    expense.id
                ]));
            }
        };
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(async (item) => {
                if (type != null) {
                    if (JSON.stringify(item) !== JSON.stringify(new RequestReimbursementExpense())) {
                        item.status = "Förslag";
                        const finalItem = ClearEmptyJson(item);
                        if (item.vatAmount !== null && item.vatAmount === 0) {
                            finalItem.vatAmount = 0;
                        }
                        const apiCallOptions = {
                            method: "post",
                            url: "/reimbursement/expense",
                            data: finalItem,
                        };
                        try {
                            await ApiCall(apiCallOptions, handleResponseReimbursementExpense);
                        } catch (error) {
                            console.log(error);
                            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                        }
                    }
                } else {
                    if (addedExpense === true) {
                        item.status = "Skickat";
                        const finalItem = ClearEmptyJson(item);
                        if (item.vatAmount !== null && item.vatAmount === 0) {
                            finalItem.vatAmount = 0;
                        }
                        const apiCallOptions = {
                            method: "post",
                            url: "/reimbursement/expense",
                            data: finalItem,
                        };
                        try {
                            await ApiCall(apiCallOptions, handleResponseReimbursementExpense);
                        } catch (error) {
                            console.log(error);
                            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                        }
                    }
                }
            });
        }
    };

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (save === true) {
            FlashMessage(t('validationMessage.reimbursementSavedSuccessfully'), 'flash-messageSuccess');
            setTimeout(() => {
               //navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
                CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
            }, 2000);
        }
        else {
            if (responseReimbursement && responseReimbursement.id !== "") {
                FlashMessage(t('validationMessage.reimbursementSuccessfully'), 'flash-messageSuccess');
                setTimeout(() => {
                   // navigate(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_INFO}?id=${responseReimbursement.id}`);
                   // CustomReload(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_INFO}?id=${responseReimbursement.id}`);
                    CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
                }, 2000);
            }
        }

    }, [responseReimbursement]);

    // UPDATE REQUEST REIMBURSEMENT
    useEffect(() => {
        let fixedId = null;
        if (responseReimbursementFixed && responseReimbursementFixed.id !== "") {
            fixedId = responseReimbursementFixed.id;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            fixedId: fixedId,
        }));
    }, [responseReimbursementFixed]);

    useEffect(() => {
        let mileageId = null;
        if (responseReimbursementMileage && responseReimbursementMileage.id !== "") {
            mileageId = responseReimbursementMileage.id;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            mileageId: mileageId,
        }));
    }, [responseReimbursementMileage]);

    useEffect(() => {
        let expenseIds = null;
        if (responseReimbursementExpenseList.length > 0) {
            expenseIds = responseReimbursementExpenseList;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            expenseIds: expenseIds,
        }));
    }, [responseReimbursementExpenseList]);

    // FINAL SUBMIT
    useEffect(() => {
        if (done === true) {
            const SubmitApiCall = async () => {
                if (save === true) {
                    requestReimbursement.status = "Förslag";
                } else {
                    requestReimbursement.status = "Skickat";
                }
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement",
                    data: ClearEmptyJson(requestReimbursement),
                };
                try {
                    if (save === true) {
                        await completeSchemaCommon.validate(requestReimbursement, {abortEarly: false});
                    } else {
                        await completeSchema.validate(requestReimbursement, {abortEarly: false});
                    }
                    await ApiCall(apiCallOptions, setResponseReimbursement);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
            SubmitApiCall();
        }
    }, [done]);

    // HANDLE FILE
    const HandleUploadFile = async (e, setFileResponse, fileInfo) => {
        const formData = new FormData();
        let isValidFile = false;
        e.map(item => {
            if (item.type === 'image/png' || item.type === 'image/jpg' || item.type === 'image/gif' || item.type === 'image/jpeg' || item.type === "application/pdf" || item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item.type === "application/msword" || item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item.type === "application/vnd.ms-excel" || item.type === "text/plain") {
                formData.append('file', item);
                isValidFile = true;
                if (fileInfo === "fileId") {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        fileExtension: item.type,
                        fileName: item.name,
                    }));
                } else {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        additionalFileExtension: item.type,
                        additionalFileName: item.name,
                    }));
                }
            }
        })
        if (isValidFile) {
            const apiCallOptions = {
                method: "post",
                url: "/file",
                data: formData,
                dataTypes: "file"
            };
            try {
                await ApiCall(apiCallOptions, setFileResponse);
            } catch (error) {
                console.log('error', error);
            }
        } else {
            setFileResponse(null);
        }
    };

    /*const HandleDeleteFile = async (fileId) => {
        const [test, setTest] = useState(null);
        const apiCallOptions = {
            method: "delete",
            url: `/file?id=${fileId}`,
        };

        try {
            await ApiCall(apiCallOptions, setTest);
            setFileResponse(null);
        } catch (error) {
            console.log(error);
        }
    }*/

    // HANDLE CHANGES
    const HandleChangeCommon = (e, name) => {
        setCheckUnsaved(true);
        $("#saveReimbursement").prop("disabled", false);
        let value;
        let dateReceiveValue = requestReimbursementExpense.dateReceive;
        if (name === "orgId" && orgResponse !== null) {
            orgResponse.forEach(item => {
                if (item.id === e.target.value) {
                    setOrgSelected(item.name);
                }
            })
            value = e.target.value;
        } else if (name === "costBearerId" && costResponse !== null) {
            costResponse.forEach(item => {
                if (item.id === e.target.value) {
                    setCostSelected(item.name);
                }
            })
            value = e.target.value;
        } else if (name === "date") {
            value = ChangeDateFormat('date', e);
            if (requestReimbursementExpense.dateReceive === "") {
                dateReceiveValue = value;
            }
        } else {
            value = e.target.value;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setRequestReimbursementFixed((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            dateReceive: dateReceiveValue,
        }));
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                item[name] = value;
            });
            setRequestReimbursementExpenseList(requestReimbursementExpenseList);
        }
    };

    const HandleChangeFixed = (e, name) => {
        setCheckUnsaved(true);
        let hoursValue = requestReimbursementFixed.hours;
        let typeValue = requestReimbursementFixed.type;
        let amountValue = requestReimbursementFixed.amount;
        if (name === "hours") {
            typeValue = "Hourly";
            if (e && e.target && e.target.value !== "") {
                hoursValue = CheckDecimal(e.target.value);
                if (hourlyResponse !== null) {
                    amountValue = (hoursValue <= 14 ? hoursValue : 14) * hourlyResponse.value;
                }
            } else {
                hoursValue = 0;
                amountValue = 0;
            }
        }
        if (name === "amount") {
            typeValue = "Fixed";
            hoursValue = null;
            if (e && e.target && e.target.value !== "") {
                amountValue = CheckDecimal(e.target.value);
            } else {
                amountValue = 0;
            }
        }
        if (name === "fixed") {
            typeValue = "Fixed";
            hoursValue = null;
            amountValue = 0;
        }
        if (name === "hourly") {
            typeValue = "Hourly";
            if (requestReimbursementFixed.hours !== null && requestReimbursementFixed.hours !== 0) {
                hoursValue = 0;
                amountValue = 0;
            }
        }
        if (typeValue !== "") {
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                hours: hoursValue,
                type: typeValue,
                amount: amountValue,
            }));
        }
    };

    const HandleChangeMileage = (e, name) => {
        setCheckUnsaved(true);
        let fromValue = requestReimbursementMileage.from;
        let toValue = requestReimbursementMileage.to;
        let kmValue = requestReimbursementMileage.km;
        let amountValue = requestReimbursementMileage.amount;
        let passengersValue = requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0 ? requestReimbursementMileage.passengers : null;
        let quantityPassengerValue = 0;
        let kmPassengerValue = 0;
        let amountPassengerValue = 0;
        if (requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0) {
            quantityPassengerValue = requestReimbursementMileage.passengers[0].quantity;
            kmPassengerValue = requestReimbursementMileage.passengers[0].km;
            amountPassengerValue = requestReimbursementMileage.passengers[0].amount;
        }
        let totalAmountValue = requestReimbursementMileage.totalAmount;
        if (name === "from") {
            fromValue = e.target.value;
        }
        if (name === "to") {
            toValue = e.target.value;
        }
        if (name === 'km') {
            if (e && e.target && e.target.value !== "") {
                kmValue = CheckDecimal(e.target.value);
                if (payableResponse !== null) {
                    const calculateAmount = (kmValue * payableResponse.value) / 10;
                    amountValue = parseFloat(calculateAmount.toFixed(2));
                }
            } else {
                kmValue = 0;
                amountValue = 0;
            }
        }
        if (name === 'addPassenger') {
            passengersValue = [];
        }
        if (name === 'cancelPassenger') {
            passengersValue = null;
            quantityPassengerValue = 0;
            kmPassengerValue = 0;
        }
        if (name === 'quantityPassenger') {
            if (e && e.target && e.target.value !== "") {
                quantityPassengerValue = CheckDecimal(e.target.value);
            } else {
                quantityPassengerValue = 0;
            }
        }
        if (name === 'kmPassenger') {
            if (e && e.target && e.target.value !== "") {
                kmPassengerValue = CheckDecimal(e.target.value);
            } else {
                kmPassengerValue = 0;
            }
        }
        if (passengersValue !== null) {
            if (payablePassengerResponse !== null) {
                const calculateAmount = kmPassengerValue * payablePassengerResponse.value;
                let totalAmount = calculateAmount / 10;
                amountPassengerValue = parseFloat(totalAmount.toFixed(2));
                totalAmountValue = amountValue + amountPassengerValue;
                passengersValue[0] = {
                    quantity: quantityPassengerValue,
                    km: kmPassengerValue,
                    amount: amountPassengerValue,
                }
            }
        } else {
            totalAmountValue = amountValue;
        }
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            from: fromValue,
            to: toValue,
            km: kmValue,
            amount: amountValue,
            passengers: passengersValue,
            totalAmount: totalAmountValue,
        }));
    };

    const HandleChangeExpense = async (e, name, extraFieldName = null) => {
        setCheckUnsaved(true);
        let dateReceiveValue = requestReimbursementExpense.dateReceive;
        let amountValue = requestReimbursementExpense.amount;
        let vatAmountValue = requestReimbursementExpense.vatAmount;
        let accountingIdValue = requestReimbursementExpense.accountingId;
        let programValue = requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo.program && requestReimbursementExpense.additionalInfo.program !== "" ? requestReimbursementExpense.additionalInfo.program : "";
        if (name === "fileId") {
            HandleUploadFile(e, setFileResponse, "fileId");
            setFileType("fileId");
            setFileAttribute(false);
        }
        if (name === "dateReceive") {
            dateReceiveValue = ChangeDateFormat('date', e);
        }
        if (name === "amount") {
            if (e && e.target && e.target.value !== "") {
                amountValue = CheckDecimal(e.target.value);
            }
            else {
                amountValue = 0;
            }
        }
        if (name === "vatAmount") {
            if (e && e.target && e.target.value !== "") {
                vatAmountValue = CheckDecimal(e.target.value);
            } else {
                vatAmountValue = 0;
            }
        }
        if (name === "accountingId") {
            if (accountingResponse !== null) {
                accountingIdValue = e.target.value;
            }
        }
        if (name === "dynamic") {
            if (e && Array.isArray(e)) {
                if (extraFieldName !== null) {
                    setFileType(extraFieldName);
                }
                HandleUploadFile(e, setFileResponse, "dynamic");
            } else {
                programValue = e.target.value;
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    additionalInfo: {
                        ...prevFormData.additionalInfo,
                        program: programValue
                    }
                }));
            }
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            dateReceive: dateReceiveValue,
            amount: amountValue,
            vatAmount: vatAmountValue,
            accountingId: accountingIdValue,
        }));
    };

    // UPDATE VAT AMOUNT
    useEffect(() => {
        let vatAmountValue = requestReimbursementExpense.vatAmount;
        if (aSettingResponse !== null && aSettingResponse.value === "false") {
            vatAmountValue = null;
        } else {
            vatAmountValue = 0;
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            vatAmount: vatAmountValue,
        }));
    }, [aSettingResponse]);

    // UPDATE ADDITIONAL INFO
    useEffect(() => {
        let additionalInfoValue = requestReimbursementExpense.additionalInfo;
        if (aExtraResponse !== null) {
            const requestNameLower = StringToLowerCaseFirst(aExtraResponse.extraFields[0].requestName);
            additionalInfoValue = {
                [requestNameLower]: requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower] : "",
            };
            setRequestName(requestNameLower);
            setExtraField(aExtraResponse.extraFields[0]);
            // IF EXTRA FIELDS ARE MORE THAN 1
            if (aExtraResponse.extraFields.length > 1) {
                const requestNameLower2 = StringToLowerCaseFirst(aExtraResponse.extraFields[1].requestName);
                additionalInfoValue[requestNameLower2] = requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower2] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower2] : "";
                setRequestName2(requestNameLower2);
                setExtraField2(aExtraResponse.extraFields[1]);
            }
        } else {
            if (requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestName] !== "") {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpense.additionalInfo[requestName]);
            }
            additionalInfoValue = null;
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            additionalInfo: additionalInfoValue,
        }));
    }, [aExtraResponse]);

    // UPDATE FILE UPLOADED
    useEffect(() => {
        if (fileResponse !== null) {
            if (fileType === "fileId") {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    [fileType]: fileResponse.id,
                }));
            } else {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    additionalInfo: {
                        ...prevFormData.additionalInfo,
                        [fileType]: fileResponse.id
                    }
                }));
            }
        }
    }, [fileResponse]);

    // HANDLE VALIDATE FORMS
    const HandleValidateFixed = async () => {
        try {
            requestReimbursementFixed.status = "Förslag";
            await completeSchemaFixed.validate(requestReimbursementFixed, { abortEarly: false });
            setDisplayFixed(false);
            setEditModeFixed(false);
            setAddedFixed(true);
        } catch (err) {
            console.log(err)
        }
    }

    const HandleValidateMileage = async () => {
        try {
            requestReimbursementMileage.status = "Förslag";
            await completeSchemaMileage.validate(requestReimbursementMileage, { abortEarly: false });
            if (requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0) {
                await completeSchemaPassenger.validate(requestReimbursementMileage.passengers[0], {abortEarly: false});
            }
            setDisplayMileage(false);
            setEditModeMileage(false);
            setAddedMileage(true);
        } catch (err) {
            console.log(err)
        }
    }

    const HandleValidateExpense = async () => {
        let exit = false;
        if (requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0) {
            if (requestReimbursementExpense.additionalInfo.program && requestReimbursementExpense.additionalInfo.program === "") {
                exit = true;
            }
            if (requestReimbursementExpense.additionalInfo.participantsFileId && requestReimbursementExpense.additionalInfo.participantsFileId === "") {
                exit = true;
            }
            if (requestReimbursementExpense.additionalInfo.programFileId && requestReimbursementExpense.additionalInfo.programFileId === "") {
                exit = true;
            }
        }
        if (exit === false) {
            try {
                requestReimbursementExpense.status = "Förslag";
                if (requestReimbursementExpense.vatAmount >= 0 || requestReimbursementExpense.vatAmount === null) {
                    if (requestReimbursementExpense.amount > requestReimbursementExpense.vatAmount) {
                        await completeSchemaExpense.validate(requestReimbursementExpense, {abortEarly: false});
                        setFileAttribute(true);
                        if (editModeExpense === true) {
                            setRequestReimbursementExpenseList((prevFormData) => {
                                return prevFormData.map((item, i) => {
                                    if (i === expenseIndex) {
                                        return requestReimbursementExpense;
                                    } else {
                                        return item;
                                    }
                                });
                            });
                            setExpenseIndex(null);
                        } else {
                            setRequestReimbursementExpenseList((prevFormData) => ([
                                ...prevFormData,
                                requestReimbursementExpense
                            ]));
                        }
                        setEditModeExpense(false);
                        setAddedExpense(true);
                        setTimeout(() => {
                            setRequestReimbursementExpense((prevFormData) => ({
                                ...prevFormData,
                                fileId: "",
                                fileExtension: "",
                                amount: 0,
                                vatAmount: 0,
                                accountingId: "",
                                additionalInfo: null,
                                additionalFileExtension: ""
                            }));
                            $("#accountingId").val("");
                        }, 80);
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    // HANDLE CANCEL FORMS
    const HandleCancelFixed = (fixed) => {
        setRequestReimbursementFixed(fixed);
        setDisplayFixed(false);
        setEditModeFixed(false);
    }

    const HandleCancelMileage = (mileage) => {
        setRequestReimbursementMileage(mileage);
        setDisplayMileage(false);
        setEditModeMileage(false);
    }

    const HandleCancelExpense = (expense) => {
        if (editModeExpense === true) {
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.map((item, i) => {
                    if (i === expenseIndex) {
                        return expense;
                    } else {
                        return item;
                    }
                });
            });
            setExpenseIndex(null);
        } else {
            setRequestReimbursementExpenseList((prevFormData) => ([
                ...prevFormData,
                expense
            ]));
        }
        setEditModeExpense(false);
        setTimeout(() => {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                amount: 0,
                vatAmount: 0,
                accountingId: "",
                additionalInfo: null,
                additionalFileExtension: ""
            }));
            $("#accountingId").val("");
        }, 80);
    }

    // DELETE FORMS
    const DeleteFixed = () => {
        const tempFixed = new RequestReimbursementFixed();
        tempFixed.date = requestReimbursement.date;
        tempFixed.purpose = requestReimbursement.purpose;
        tempFixed.costBearerId = requestReimbursement.costBearerId;
        setRequestReimbursementFixed(tempFixed);
        setDisplayFixed(true);
        setEditModeFixed(false);
        setAddedFixed(false);
    };

    const DeleteMileage = () => {
        const tempMileage = new RequestReimbursementMileage();
        tempMileage.date = requestReimbursement.date;
        tempMileage.purpose = requestReimbursement.purpose;
        tempMileage.costBearerId = requestReimbursement.costBearerId;
        setRequestReimbursementMileage(tempMileage);
        setDisplayMileage(true);
        setAddedMileage(false);
    };

    const DeleteExpense = (index = null) => {
        if (index !== null) {
            if (requestReimbursementExpenseList[index].fileId !== "") {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpenseList[index].fileId);
            }
            if (requestReimbursementExpenseList[index].additionalInfo !== null && Object.keys(requestReimbursementExpenseList[index].additionalInfo).length > 0) {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpenseList[index].additionalInfo[requestName]);
            }
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.filter((item, i) => i !== index);
            });
            setExpenseIndex(null);
            setAddedExpense(false);
            setTimeout(() => {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    fileId: "",
                    fileExtension: "",
                    amount: 0,
                    vatAmount: 0,
                    accountingId: "",
                    additionalInfo: null,
                    additionalFileExtension: ""
                }));
                $("#accountingId").val("");
            }, 80);
        }
    };

    // MODAL OPTIONS
    const handleModalDelete = () => {
        switch (reimbursementToDelete) {
            case "Fixed":
                DeleteFixed();
                break;
            case "Mileage":
                DeleteMileage();
                break;
            case "Expense":
                DeleteExpense(expenseIndex);
                break;
            default:
                break;
        }
        setDisplayDeleteModal(false);
    };

    const modalContent = (
        <div>
            {reimbursementToDelete === "Fixed" ? t('validationMessage.deleteFixed') : reimbursementToDelete === "Mileage" ? t('validationMessage.deleteMileage') : t('validationMessage.deleteExpense')}
        </div>
    );
    const modalFooter = (
        <>
            <Button className="box-green border-0" onClick={handleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    const modalContentSend = (
        <div>
            {t('validationMessage.sendReimbursement')}
        </div>
    );
    const modalFooterSend = (
        <>
            <Button className="box-green border-0"  onClick={async () => {
                setDisplayFixed(false);
                setDisplayMileage(false);
                setDisplayExpense(false);
                if (addedFixed === true) {
                    await SubmitFixed();
                }
                if (addedMileage === true) {
                    await SubmitMileage();
                }
                if (addedExpense === true) {
                    await SubmitExpense();
                }
                $("#submitReimbursement, #saveReimbursement").addClass("disabled");
                setDisplaySpinner(true);
                setCheckUnsaved(false);
                setTimeout(() => {
                    setDone(true);
                }, 5000);
                setDisplaySendModal(false)
            }}>
                {t('button.send')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplaySendModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    // GET USER SETTING
    useEffect(() => {
        const handleUserSettingsResponse = (responseUserSettings) => {
            if (responseUserSettings !== null) {
                //$('#submitReimbursement').removeClass('d-none');
                $('#formReimbursement').removeClass('d-none');
                $('#viewReimbursement').removeClass('d-none');
                setResponseUserSettings(new ResponseUserSettingsModel(responseUserSettings));
            } else {
                $('#pendingAccountDiv').removeClass('d-none');
            }
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/usersetting/bank-account",
                };
                await ApiCall(apiCallOptions, handleUserSettingsResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // DELETE FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                fileName: "",
            }));
        }
    }, [isDeletedFile]);

    // DELETE EXTRA FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedExtraFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                additionalInfo: {
                    ...prevFormData.additionalInfo,
                    [fileType]: ""
                },
                additionalFileExtension: "",
                additionalFileName: "",
            }));
        }
    }, [isDeletedExtraFile]);

    // ADDITIONAL INFO
    /*useEffect(() => {
        if (requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0) {
            for (const key of Object.keys(requestReimbursementExpense.additionalInfo)) {
                setAdditionalKey(key);
                setAdditionalValue(requestReimbursementExpense.additionalInfo[key]);
            }
        }
    }, [requestReimbursementExpense.additionalInfo]);*/

    // CALCULATIONS VARIABLES
    /*const netFixed = ToCustomDecimal(requestReimbursementFixed?.amount * 0.7);
    const incomeTaxFixed = ToCustomDecimal(requestReimbursementFixed?.amount * 0.3);
    const employmentTaxFixed = ToCustomDecimal(requestReimbursementFixed?.amount * taxPercent);
    const taxFreeMileage = ToCustomDecimal((requestReimbursementMileage?.km / 10) * 25);
    const taxableMileage = ToCustomDecimal(requestReimbursementMileage?.amount + (requestReimbursementMileage?.passengers != null && requestReimbursementMileage.passengers?.length > 0 ? requestReimbursementMileage?.passengers[0]?.amount : 0) - parseFloat(taxFreeMileage));
    const incomeTaxMileage = ToCustomDecimal(parseFloat(taxableMileage) * 0.3);
    const netMileage = ToCustomDecimal(parseFloat(taxFreeMileage) + parseFloat(taxableMileage) - parseFloat(incomeTaxMileage));
    const employmentTaxMileage = ToCustomDecimal(parseFloat(taxableMileage) * taxPercent);*/

    let grossAmount = 0;
    grossAmount += requestReimbursementFixed?.amount;
    grossAmount += requestReimbursementMileage?.totalAmount;
    if(requestReimbursementExpenseList?.length > 0){
        requestReimbursementExpenseList?.map((item, index) =>{
            grossAmount += item.amount;
        })
    }

    // HANDLE MODAL DELETE
    const handleModalDeleteReimbursement = async () => {
        if (id !== "") {
            const apiCallOptions = {
                method: "delete",
                url: `/reimbursement/`,
                data: {
                    id: id
                }
            };
            try {
                await ApiCall(apiCallOptions, setDeleteApiResponse);
                setDisplayDeleteModal(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if (deleteApiResponse !== null && id !== "") {
            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
        }
    }, [deleteApiResponse]);

    const modalContentDelete = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooterDelete = (
        <>
            <Button className="box-green border-0" onClick={handleModalDeleteReimbursement}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModalReimbursement(false)}>{t('button.cancel')}</Button>
        </>
    );
    const messageCostBearer = InputMessage(costResponse, requestReimbursement.costBearerId);

    return (
        <>
            {
                permissionCreate === true ? '' :
                    <Col lg={12} className="px-0">
                        <CustomButton
                            text={t('button.back')}
                            icon={'bx bx-chevron-left'}
                            buttonSize={'small'}
                            customClasses={'mb-3 ms-3 backButton'}
                            onClick={() => {
                                navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
                            }}
                        />
                    </Col>
            }

            <Col lg={10} id='pendingAccountDiv' className={`box-red mt-2 p-1 d-none mx-auto`}>{t('validationMessage.needAccount')} <Link className='underline' to={USER_PATHS.SETTINGS}>{t('validationMessage.goAddAccount')} </Link>
            </Col>

            <Col lg={12} className="px-0">
            <Row className="pb-4">
                {id !== "" ?
                    <CustomModal isOpen={displayDeleteModalReimbursement} toggle={() => setDisplayDeleteModalReimbursement(!displayDeleteModalReimbursement)}
                             body={modalContentDelete} footer={modalFooterDelete}/>
                : ''}
                <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                             body={modalContent} footer={modalFooter}/>
                <CustomModal isOpen={displaySendModal} toggle={() => setDisplaySendModal(!displaySendModal)}
                             body={modalContentSend} footer={modalFooterSend}/>
                {/* UNSAVED MODAL */}
                {checkUnsaved === true ? <CustomUnsavedModal backButtonUrl={REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST} /> : ''}
                {/* FORMS */}
                <Col lg={6} id="formReimbursement" className="d-none">
                    <Card className="card-backend p-4">
                        <Row>
                            <Col xs='auto'>
                                <h2 className='rokkitt-font'> {t('reimbursement.reportProcessing')}</h2>
                            </Col>
                            <InfoPageMessage message={'Test: message create Report Proceeding'}/>
                        </Row>
                        {accountingResponse !== null &&  accountingResponse !== '' ?
                            <>
                                <Row>
                                    <Col lg={6} onClick={() => $('.react-datepicker__tab-loop').removeClass('d-none')}>
                                        <CustomInput model={RequestReimbursementCommon} attribute='date' data={requestReimbursement.date}
                                                     customChange={(e) => HandleChangeCommon(e, 'date')}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        {costResponse !== null ?
                                            <>
                                                <CustomInput model={RequestReimbursementCommon} attribute='costBearerId' data={requestReimbursement.costBearerId}
                                                             customChange={(e) => HandleChangeCommon(e, 'costBearerId')}
                                                             customOptions={costJson}/>
                                                {messageCostBearer}
                                            </>
                                            : ''}
                                    </Col>
                                    <Col lg={6}>
                                        <CustomInput model={RequestReimbursementCommon} attribute='purpose' data={requestReimbursement.purpose}
                                                     customChange={(e) => HandleChangeCommon(e, 'purpose')}/>
                                    </Col>
                                    <Col lg={6}>
                                        <CustomInput model={RequestReimbursementCommon} attribute='orgId' data={requestReimbursement.orgId}
                                                     customChange={(e) => HandleChangeCommon(e, 'orgId')} customOptions={orgJson}/>
                                    </Col>
                                </Row>
                                {/* FIXED COMPONENT */}
                                {displayFixed ? <ReimbursementFixed data={requestReimbursementFixed} onChange={HandleChangeFixed} onValidate={HandleValidateFixed} onCancel={HandleCancelFixed} editMode={editModeFixed} /> : ''}
                                {/* MILEAGE COMPONENT */}
                                {displayMileage ? <ReimbursementMileage data={requestReimbursementMileage} onChange={HandleChangeMileage} onValidate={HandleValidateMileage} onCancel={HandleCancelMileage} editMode={editModeMileage} /> : ''}
                                {/* EXPENSE COMPONENT */}
                                {displayExpense ? <ReimbursementExpense data={requestReimbursementExpense} onChange={HandleChangeExpense} onValidate={HandleValidateExpense} onCancel={HandleCancelExpense} editMode={editModeExpense} accountingJson={accountingJson} requestName={requestName} extraField={extraField}  setIsDeletedFile={setIsDeletedFile} setIsDeletedExtraFile={setIsDeletedExtraFile} extraField2={extraField2} attributeValue={fileAttribute} /> : ''}
                            </>
                            :
                             <LoadingData data={accountingResponse}/>
                        }

                    </Card>
                </Col>
                {/* DATA VIEW */}
                <Col lg={6} id="viewReimbursement" className="d-none">
                    {deniedReimbursement !== null && deniedReimbursement.status === "Nekad" && deniedReimbursement.reason !== null ?
                        <Card className="card-backend p-4 mb-3">
                            <Row>
                                <Col lg={12} className="text-center">
                                    <h2 className="text-red rokkitt-font">{t('reimbursement.deniedMessageCreate')}</h2>
                                </Col>
                                <Col lg={12} className="mb-3">
                                    <Card className="bg-card-grey p-3">
                                        <h5 className='rokkitt-font'>{t('attest.deniedBy')}</h5>
                                        {deniedReimbursement.deniedBy}
                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                        {deniedReimbursement.reason}
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        : ""}
                    <Card className="card-backend p-4">
                        <Row>
                            <Col lg={12} className="text-start">
                                <h2 className='rokkitt-font'> {t('modelLabel.added')}</h2>
                                <h5 className="rokkitt-font">{t('reimbursement.reportProcessings')}</h5>
                            </Col>
                            <Col lg={12}>
                                {/* COMMON */}
                                {requestReimbursement?.date !== '' || requestReimbursement?.costBearerId !== '' || requestReimbursement?.purpose !== '' || requestReimbursement?.orgId !== '' ?
                                    <CustomReimbursementView data={requestReimbursement} costSelected={costSelected} orgSelected={orgSelected} />
                                : ''}
                                {/* FIXED */}
                                {addedFixed === true ?
                                    <>
                                        <h5 className='rokkitt-font mt-2'>{t('reimbursement.salary')}</h5>
                                        <CustomReimbursementFixedView data={requestReimbursementFixed} editMode={editModeFixed}
                                                                      onClickDelete={() => {
                                                                            setDisplayDeleteModal(true);
                                                                            setReimbursementToDelete('Fixed');}}
                                                                      onClickEdit={() => {
                                                                          setDisplayFixed(true);
                                                                          setEditModeFixed(true);}}
                                            />
                                    </>
                                    : ''}
                            </Col>
                            {/* Mileage */}
                            {addedMileage === true ?
                                <Col lg={12}>
                                    <h5 className='rokkitt-font mt-2'>{t('reimbursement.mileage')}</h5>
                                    <CustomReimbursementMileageView data={requestReimbursementMileage} editMode={editModeMileage}
                                                                    onClickDelete={() => {
                                                                         setDisplayDeleteModal(true);
                                                                         setReimbursementToDelete('Mileage');
                                                                     }}
                                                                    onClickEdit={() => {
                                                                         setDisplayMileage(true);
                                                                         setEditModeMileage(true);
                                                                      } }
                                    />
                                </Col>
                                : ''}
                            {/* EXPENSE */}
                            {requestReimbursementExpenseList?.length > 0 ?
                                <h5 className='rokkitt-font mt-2'>{t('reimbursement.expenseTitle')}</h5>
                                : ''}
                            <CustomReimbursementExpenseView data={requestReimbursementExpenseList} accountingResponse={accountingResponse} editMode={editModeExpense}
                                                            onClickDelete={(item,index) => {
                                                                  setDisplayDeleteModal(true);
                                                                  setReimbursementToDelete('Expense');
                                                                  setExpenseIndex(index);
                                                              }}
                                                            onClickEdit={(item,index) => {
                                                                setRequestReimbursementExpense(item);
                                                                setExpenseIndex(index);
                                                                setEditModeExpense(true);
                                                                $("#accountingId").val(item.accountingId);
                                                             }}
                            />
                            {/* SEND FORM */}
                            <Col lg={12} className=" text-center pt-4">
                                <Row>
                                    {grossAmount > 0 && addedFixed === true || grossAmount > 0 && addedMileage === true || grossAmount > 0 && requestReimbursementExpenseList.length > 0 ?
                                        <Col lg={12} className="pb-4 rokkitt-font fs-5">
                                            <span className="fw-bold fs-5 text-green"> {t('modelLabel.grossAmount')}: </span> {ToCustomDecimal(grossAmount)} kr
                                        </Col>
                                        : ''}

                                    <Col lg={12} className="text-center pb-3">
                                        {displaySpinner === true ?
                                            <>
                                                {save === true ? t('validationMessage.savingReimbursement') : t('validationMessage.sendingReimbursement')} <Spinner color='success'/>
                                            </>
                                            : t("reimbursement.sendInfo")}
                                    </Col>
                                    <Col lg={12} className="d-flex justify-content-center justify-content-lg-end">
                                        {id !== "" ?
                                            <Button className=" rounded-2 box-red border-0  position-relative me-2 d-flex align-items-center" onClick={() => {
                                                setDisplayDeleteModalReimbursement(true);
                                            }}>
                                                <i className="bx bx-x-circle fs-5 pe-1"/> {t("button.delete")}
                                            </Button>
                                        : ''}
                                        <CustomSubmitButton customClass="me-2" text={t('button.save')}  id='saveReimbursement' icon='bx bx-save pe-2' doClick={false} customClick={async () => {
                                                setDisplayFixed(false);
                                                setDisplayMileage(false);
                                                setDisplayExpense(false);
                                                setSave(true);
                                                if (addedFixed === true) {
                                                    await SubmitFixed("save");
                                                }
                                                if (addedMileage === true) {
                                                    await SubmitMileage("save");
                                                }
                                                if (addedExpense === true) {
                                                    await SubmitExpense("save");
                                                }
                                                $("#submitReimbursement, #saveReimbursement").prop("disabled", true);
                                                setDisplaySpinner(true);
                                                setCheckUnsaved(false);
                                                setTimeout(() => {
                                                    setDone(true);
                                                }, 5000);
                                            }} />

                                            <CustomSubmitButton text={t('button.send')} id='submitReimbursement' icon='bx bx-send pe-2'
                                                                //customClass='d-none'
                                                                customClick={() => {
                                                                    setDisplaySendModal(true);
                                                                }} />
                                           {/* <div id='pendingAccountDiv' className={`box-red mt-2 p-1 w-100 d-none`}>{t('validationMessage.needAccount')} <Link className='underline' to={USER_PATHS.SETTINGS}>{t('validationMessage.goAddAccount')} </Link> </div>*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    {/* CALCULATIONS */}
                    {/*<h5 className="mt-5">{t('reportProceeding.calculations.title')} - <span className="fw-bold text-warning">{t('reportProceeding.calculations.subtitle')}</span></h5>
                    <Card className="bg-light float-end p-4">
                        <h5>{t('reportProceeding.calculations.salary')}</h5>
                        <Row>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.gross')}:</div>
                                <div>{t('reportProceeding.calculations.hours')}</div>
                                <div className="box-beig"><span
                                    className="fw-bold">{ToCustomDecimal(requestReimbursementFixed?.amount || 0)} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.net.title')} </div>
                                <div>{t('reportProceeding.calculations.gross')} * 70 / 100</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * 70 / 100 =`} <span
                                    className="fw-bold">{netFixed} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.incomeTax.title')} </div>
                                <div>{t('reportProceeding.calculations.incomeTax.textGross')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * 30 / 100 =`} <span
                                    className="fw-bold">{incomeTaxFixed} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.employmentTax.title')}</div>
                                <div>{t('reportProceeding.calculations.employmentTax.textGross')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * ${taxPercent} =`} <span
                                    className="fw-bold">{employmentTaxFixed} kr</span></div>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>{t('reportProceeding.calculations.mileage')}</h5>
                        <Row>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.taxFree.title')}</div>
                                <div>{t('reportProceeding.calculations.taxFree.text')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementMileage?.km || 0)} / 10 * 25 =`} <span
                                    className="fw-bold">{taxFreeMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.taxable.title')}</div>
                                <div>{t('reportProceeding.calculations.taxable.text')}</div>
                                <div
                                    className="box-beig">{`${ToCustomDecimal(requestReimbursementMileage?.amount || 0)} + ${requestReimbursementMileage?.passengers != null && requestReimbursementMileage?.passengers.length > 0 && requestReimbursementMileage?.passengers[0]?.amount ? ToCustomDecimal(requestReimbursementMileage?.passengers[0]?.amount || 0) : 0} - ${ToCustomDecimal((requestReimbursementMileage?.km || 0 / 10) * 25)} =`} <span className="fw-bold">{taxableMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.incomeTax.title')}</div>
                                <div>{t('reportProceeding.calculations.incomeTax.text')}</div>
                                <div className="box-beig">{`${taxableMileage} * 30 / 100 =`} <span className="fw-bold">{incomeTaxMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.net.title')}</div>
                                <div>{t('reportProceeding.calculations.net.text')}</div>
                                <div
                                    className="box-beig">{`${taxFreeMileage} + ${taxableMileage} - ${incomeTaxMileage} =`} <span className="fw-bold">{netMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.employmentTax.title')}</div>
                                <div>{t('reportProceeding.calculations.employmentTax.text')}</div>
                                <div className="box-beig">{`${taxableMileage} * ${taxPercent} =`} <span className="fw-bold">{employmentTaxMileage} kr</span></div>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>{t('reportProceeding.calculations.expenses')}</h5>
                        {requestReimbursementExpenseList?.length > 0 ?
                            requestReimbursementExpenseList?.map((item, index) => {
                                return (
                                    <div key={`expenseCalc${index}`}>
                                        {index > 0 ? <hr className="ps-5 w-50" /> : ''}
                                        <Row>
                                            <Col lg={item.vatAmount && item.vatAmount !== null ? "4" : "6"} className="mt-2">
                                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.amount')}</div>
                                                <div className="box-beig">
                                                    <span className="fw-bold">{ToCustomDecimal(item.amount)} kr</span>
                                                </div>
                                            </Col>
                                            {item.vatAmount && item.vatAmount !== null ?
                                                <>
                                                    <Col lg="4" className="mt-2">
                                                        <div className="fs-6 fw-bold">{t('reportProceeding.calculations.tax')}</div>
                                                        <div className="box-beig">
                                                            <span className="fw-bold">{ToCustomDecimal(item.vatAmount)} kr</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-2">
                                                        <div className="fs-6 fw-bold">{t('reportProceeding.calculations.amountWithout')}</div>
                                                        <div>{t('reportProceeding.calculations.amountTotal')}</div>
                                                        <div className="box-beig">
                                                            {`${ToCustomDecimal(item.amount)} - ${ToCustomDecimal(item.vatAmount)} =`} <span className="fw-bold">{ToCustomDecimal(item.amount - item.vatAmount)} kr</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            : ''}
                                        </Row>
                                    </div>
                                );
                            })
                        : ''}
                    </Card>*/}
                </Col>
            </Row>
            </Col>
        </>
    );
}