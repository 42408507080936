import i18n from "../../../AppSystem/i18n";
import * as yup from "yup";
import {string} from "yup";
import {StringToLowerCaseFirst} from "../../../Common/Helpers/StringToLowerCaseFirst";

class RequestPay {
    constructor({
                    costBearerId = '',
                    accountingId = '',
                    type = '',
                    date= '',
                    creditorName = '',
                    clearingNumber = '',
                    accountNumber = '',
                    bG= '',
                    pG= '',
                    oCR= '',
                    message = '',
                    description = '',
                    amount = 0,
                    vatAmount = 0,
                    fileId = '',
                    fileExtension = '',
                    fileName = '',
                    additionalInfo = {},
                    additionalFileExtension = '',
                    additionalFileName = '',
                } = {}) {
        this.costBearerId = costBearerId;
        this.accountingId = accountingId;
        this.type = type;
        this.date = date;
        this.creditorName = creditorName;
        this.clearingNumber = clearingNumber;
        this.accountNumber = accountNumber;
        this.bG = bG;
        this.pG = pG;
        this.oCR = oCR;
        this.message = message;
        this.description = description;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.fileId = fileId;
        this.fileExtension = fileExtension;
        this.fileName = fileName;
        this.additionalInfo = additionalInfo;
        this.additionalFileExtension = additionalFileExtension;
        this.additionalFileName = additionalFileName;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null, customField = null) {
        const fileIdPlaceholder = i18n.t('formPlaceholder.dragAndDrop');
        const selectOption = i18n.t('formPlaceholder.selectOption');
        const description = i18n.t('modelLabel.description');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');
        const greaterO = i18n.t('validationMessage.greater0');
        const placeholderDigits = i18n.t('formPlaceholder.digits');
        const digits2 = i18n.t('validationMessage.digits2');
        const message = i18n.t('modelLabel.message');


        const optionRadio = [
            {label: i18n.t('userSetting.bankAccount'), value: 'bankPayment' },
            {label:'Bankgiro', value: 'giroPayment' },
            {label:'Plusgiro', value: 'plusPayment' },
        ];

        let options = [];
        if (optionsData && optionsData.length > 0 ) {
            optionsData.map(item => {
                if (item.name && item.name !== "Övriga externa kostnader, avdragsgilla") {
                    options.push({
                        label: item.showLabel ?? item.name,
                        value: item.id
                    });
                }
            });
            const otherPosition = optionsData.findIndex(item => item.name === "Övriga externa kostnader, avdragsgilla");
            if (otherPosition !== -1) {
                options.push({
                    label: optionsData[otherPosition].showLabel ?? optionsData[otherPosition].name,
                    value: optionsData[otherPosition].id
                });
            }
        }

        // CUSTOM FIELDS
        let customAttribute = null;
        let attributeName = null;

        if (customField) {
            customAttribute = {
                'label': customField.label,
                'form': {
                    'type': StringToLowerCaseFirst(customField.inputType),
                    'placeholder': customField.inputType === 'File' ? fileIdPlaceholder : '',
                    'buttonView': false,
                },
                'id': StringToLowerCaseFirst(customField.requestName),
            };
            attributeName = StringToLowerCaseFirst(customField.requestName);
        }

        // SCHEMA
        const completeSchema = yup.object().shape({
            costBearerId: string().required(fieldRequired),
            accountingId: string().required(fieldRequired),
            type: string().required(fieldRequired),
            date: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.canNotBeEmpty')}`, value => {
                return value !== "";
            }),
            creditorName: string().required(fieldRequired),
            clearingNumber: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.digits4')}`, value => {
                if (!value || (value && value.trim === "")) {
                    return true;
                } else {
                    if (value && value !== "" && value.length >= 4) {
                        return true;
                    }
                }
            }),
            accountNumber: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.max12')}`, value => {
                if (!value || (value && value.trim === "")) {
                    return true;
                } else {
                    if (value && value !== "" && value.length <= 12) {
                        return true;
                    }
                }
            }),
            bG: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.digits7')}`, value => {
                if (!value || (value && value.trim === "")) {
                    return true;
                } else {
                    if (value && value !== "" && value.length >= 7) {
                        return true;
                    }
                }
            }),
            pG: string().nullable().test("is-not-empty", `${digits2}`, value => {
                if (!value || (value && value.trim === "")) {
                    return true;
                } else {
                    if (value && value !== "" && value.length >= 2) {
                        return true;
                    }
                }
            }),
            oCR: string().nullable().test("is-not-empty", `${digits2}`, value => {
                if (!value || (value && value.trim === "")) {
                    return true;
                } else {
                    if (value && value !== "" && value.length >= 2) {
                        return true;
                    }
                }
            }),
            message: string().nullable(),
            description: string().required(fieldRequired),
            amount: string().required(fieldRequired).test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            vatAmount: string().nullable().test('vatAmount', `${greaterO}`,
                function (value) {
                    if (value !== '' || value <= 0) return true;
                    const numericValue = parseFloat(value.toString().replace(',', '.'));
                    return !isNaN(numericValue) && numericValue < 0;
                }
            ),
            fileId: string().required(fieldRequired),
        });

        const attributes =  {
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options': options,
                    'default':'',
                    'placeholder': selectOption,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            accountingId :{
                'label': i18n.t('modelLabel.accounting'),
                'form': {
                    'type': 'select',
                    'options':options,
                    'default':'',
                    'placeholder': selectOption,
                    'validation': completeSchema.fields.accountingId,

                },
                'value': (item) => item.accountingId || "",
            },
            type :{
                'label': i18n.t('payReceive.paymentType') +':',
                'form': {
                    'type': 'radio',
                    'classRadio':'inLine',
                    'options': optionRadio,
                    'validation': completeSchema.fields.type,
                },
                'value': (item) => item.type || "",
            },
            date: {
                'label': i18n.t('modelLabel.whenToPay'),
                'form': {
                    'type': 'date',
                    'placeholder': 'Välj datum',
                    'colSizeInput': 1,
                    'validation': completeSchema.fields.date,
                    "minDate": new Date(),
                },
                'value': (item) => item.date || '',
            },
            creditorName: {
                'label': i18n.t('modelLabel.creditorName'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterCreditorName'),
                    'validation': completeSchema.fields.creditorName,
                },
                'value': (item) => item.creditorName || '',
            },
            clearingNumber: {
                'label': i18n.t('userSetting.modelClearingNumber'),
                'form': {
                    'type': 'number',
                    'placeholder': "4-5 " + placeholderDigits,
                    'validation': completeSchema.fields.clearingNumber,
                },
                'value': (item) => item.clearingNumber || '',
            },
            accountNumber: {
                'label': i18n.t('userSetting.modelAccountNumber'),
                'form': {
                    'type': 'number',
                    'placeholder': '123456789012',
                    'validation': completeSchema.fields.accountNumber,
                },
                'value': (item) => item.accountNumber || '',
            },
            bG: {
                'label': 'BG',
                'form': {
                    'type': 'number',
                    'placeholder': '7-8 ' + placeholderDigits,
                    'validation': completeSchema.fields.bG,
                },
                'value': (item) => item.bG || '',
            },
            pG: {
                'label': 'PG',
                'form': {
                    'type': 'number',
                    'placeholder': '2-8 ' + placeholderDigits,
                    'validation': completeSchema.fields.pG,
                },
                'value': (item) => item.pG || '',
            },
            oCR: {
                'label': 'OCR',
                'form': {
                    'type': 'number',
                    'placeholder': '2-25 ' + placeholderDigits,
                    'validation': completeSchema.fields.oCR,
                },
                'value': (item) => item.oCR || '',
            },
            message: {
                'label': message + " (" + i18n.t('validationMessage.max140') + ")",
                'form': {
                    'type': 'textarea',
                    'rows': 2,
                    'placeholder': message,
                    'validation': completeSchema.fields.message,
                },
                'value': (item) => item.message || '',
            },
            description: {
                'label': description,
                'form': {
                    'type': 'textarea',
                    'rows': 5,
                    'placeholder': description,
                    'validation': completeSchema.fields.description,
                },
                'value': (item) => item.description || "",
            },
            amount: {
                'label': i18n.t('reimbursement.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || '',
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterVatAmount'),
                    'validation': completeSchema.fields.vatAmount,
                },
                'value': (item) => item.vatAmount || "",
            },
            fileId: {
                'label': i18n.t('modelLabel.file'),
                'form': {
                    'type': 'file',
                    'buttonView': false,
                    'placeholder': fileIdPlaceholder,
                    'validation': completeSchema.fields.fileId,
                },
                'value': (item) => item.fileId || '',
            },
        };

        if (customField && customAttribute && attributeName) {
            attributes[attributeName] = customAttribute;
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}

export default RequestPay;