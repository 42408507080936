/*
You can use this component in the try-catch API responses
Example: try {
  FlashMessage('Login Successfully', 'flash-messageSuccess');
}catch (ex) {
  FlashMessage(ex.response.data.title + '. ' + 'Please try again', 'flash-messageError');
}
*/


export function FlashMessage(APImessage, type) {
  let flashMessage = APImessage;
  const messageDiv = document.getElementById('flash-message');
  const overlay = document.getElementById('overlay');

  if (messageDiv && overlay) {
    messageDiv.textContent = flashMessage;
    document.getElementById('flash-message').classList.add(type);

    // Apply styles to center the message
    messageDiv.style.display = 'block';
    /*  messageDiv.style.position = 'absolute';
      messageDiv.style.left = '50%';
      messageDiv.style.top = '50%';
      messageDiv.style.transform = 'translate(-50%, -50%)'*/

    // Apply styles to center the text
    messageDiv.style.textAlign = 'center';
    messageDiv.style.margin = '0 auto';

    overlay.style.display = 'block';
    overlay.style.alignContent = 'center';
    setTimeout(() => {
      messageDiv.style.display = 'none';
      overlay.style.display = 'none';
      document.getElementById('flash-message').classList.remove(type);
      flashMessage = '';
    }, 2300);
  }
}
