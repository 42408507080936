
/*Example for use this function:
const mongoDate = '2023-12-12T08:04:12.398+00:00'

let dateTest = ChangeDateFormat('date', mongoDate)
let dateHoursTest = ChangeDateFormat('dateTime', mongoDate)
let timeTest = ChangeDateFormat('time', mongoDate)
<p>Date Test: {dateTest} | Date Hour Test: {dateHoursTest} | Time test: {timeTest}</p>*/


// CHANGE DATE FORMAT (MONGO DB TO NORMAL DATE)
export function ChangeDateFormat(type, mongoDbDate) {
  if (!mongoDbDate) {
    return type === 'date' ? '' : '';
  }
  const originalDate = new Date(mongoDbDate);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');
  const hours = String(originalDate.getHours()).padStart(2, '0');
  const minutes = String(originalDate.getMinutes()).padStart(2, '0');
  let result;

  switch (type) {
    case 'date':
      result = `${year}-${month}-${day}`;
      break;
    case 'dateTime':
      result = `${year}-${month}-${day} ${hours}:${minutes}`;
      break;
    case 'time':
      result = `${hours}:${minutes}`;
      break;
    default:
      result = 'Any date available';
      break;
  }

  return result
}



