import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class AdminResponsePaymentModel {
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    provider = '',
                    type = '',
                    model = '',
                    modelId = '',
                    accountRowId = '',
                    paymentId = '',
                    modelInfo = [],
                    instructedAmount = '',
                    creditorAccountIban = '',
                    clearingNumber = '',
                    creditorName = '',
                    paymentType = '',
                    status =  '',
                    createdAt = '',
                    updatedAt = '',
                    honorariumDate = '',
                    attestAmount = '',
                    approvedBy = '',
                    createdAtAttest = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.provider = provider;
        this.type = type;
        this.model = model;
        this.modelId = modelId;
        this.accountRowId = accountRowId;
        this.paymentId = paymentId;
        this.modelInfo = modelInfo;
        this.instructedAmount = instructedAmount;
        this.creditorAccountIban = creditorAccountIban;
        this.clearingNumber = clearingNumber;
        this.creditorName = creditorName;
        this.paymentType = paymentType;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.honorariumDate = honorariumDate;
        this.attestAmount = attestAmount;
        this.approvedBy = approvedBy;
        this.createdAtAttest = createdAtAttest;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const createdBy = i18n.t('modelLabel.createdBy');

        const attributes = {
            id :{
                'label': "ID",
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            provider :{
                'label': 'leverantör',
                'sortable': true,
                'sortField': 'provider',
                'value': (item) => item.provider || "",
            },
            type :{
                'label': 'typ',
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.type || "",
            },
            model :{
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'model',
                'value': (item) => item.model === 'Förrättning' ? i18n.t('reimbursement.reportProcessing') : item.model === 'Arvode' ? i18n.t('honorarium.honorarium') : item.model === 'Betala' ? i18n.t('payReceive.pay') : item.model === 'Intern transaktion' ? i18n.t('internalTransaction.internalTransaction') : item.model === 'Faktura' ? i18n.t('invoice.invoice') : item.model || "",
            },
            modelId :{
                'label': i18n.t('modelLabel.typeId'),
                'sortable': true,
                'sortField': 'modelId',
                'value': (item) => item.modelId || "",
            },
            accountRowId :{
                'label': 'Kontorad',
                'sortable': true,
                'sortField': 'accountRowId',
                'value': (item) => item.accountRowId || "",
            },
            paymentId :{
                'label': 'Betalning',
                'sortable': true,
                'sortField': 'paymentId',
                'value': (item) => item.paymentId || "",
            },
            modelInfo: {
                'label': 'Model Info',
                'sortable': true,
                'sortField': 'modelInfo',
                'value': (item) => item.modelInfo || "",
            },
            instructedAmount :{
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'amount',
                'insideClass' : (item)=> item.instructedAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.instructedAmount ? ToCustomDecimal(item.instructedAmount) +' kr' : "",
                'thClass': 'ps-5'
            },
            creditorAccountIban :{
                'label': i18n.t('payReceive.bankAccount'),
                'sortable': true,
                'sortField': "creditorAccountIban",
                'value': (item) => item.creditorAccountIban || "",
            },
            clearingNumber :{
                'label': i18n.t('payDirectTransmission.number'),
                'sortable': true,
                'sortField': "clearingNumber",
                'value': (item) => item.clearingNumber || "",
            },
            creditorName :{
                'label': i18n.t('modelLabel.creditorName'),
                'sortable': true,
                'sortField': "recipient",
                'value': (item) => item.creditorName || "",
            },
            paymentType :{
                'label': 'Betalningstyp',
                'sortable': true,
                'sortField': "paymentType",
                'value': (item) => item.paymentType || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width' : (item.status === 'Utbetald' ? "box-green me-4 fixed-width" :(item.status === 'Nekad' ? "box-red me-4 fixed-width" : 'box-custom-grey me-4 fixed-width')),
                'value': (item) => item.status === 'Skickat' ? i18n.t('reimbursement.sent') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : item.status === 'Utbetald' ? i18n.t('modelLabel.paidOut') : '' || "",
            },
            createdAt :{
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : ''|| "",
            },
            updatedAt :{
                'label': 'Updated At',
                'sortable': true,
                'sortField': 'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : ''|| "",
            },
            honorariumDate :{
                'label': 'Honorarium Datum',
                'sortable': true,
                'sortField': 'honorariumDate',
                'value': (item) => item.honorariumDate || "",
            },
            attestAmount :{
                'label': i18n.t('modelLabel.attestAmount'),
                'sortable': true,
                'sortField': 'amount',
                'insideClass' : (item)=> item.attestAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.attestAmount ? ToCustomDecimal(item.attestAmount) +' kr' : "",
                'thClass': 'ps-5'
            },
            createdAtAttest :{
                'label': 'Updated At',
                'sortable': true,
                'sortField': 'createdAtAttest',
                'value': (item) => item.createdAtAttest ? ChangeDateFormat('dateTime', item.createdAtAttest) : ''|| "",
            },
            approvedBy :{
                'label': i18n.t('modelLabel.creditorName'),
                'sortable': true,
                'sortField': "approvedBy",
                'value': (item) => item.approvedBy || "",
            },
        }
        return attributes;
    }
}
export default AdminResponsePaymentModel;