import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';

class RequestInvoice {
    constructor({
                    id= '',
                    visualId ='',
                    clientId = '',
                    expiringDate = '',
                    recipientReference = '',
                    //reference = '',
                   /* amount = 0,
                    vatAmount = 0,
                    totalAmount = 0,*/
                    status = '', //draft, revoked, attestquoe, attestedAndSend, credited
                   // type = '', // invoice, credit_invoice
                    fileUrl = '',
                    rows = [],
                    costBearerId = ''

                } = {}) {

                    if (!expiringDate) {
                        const today = new Date();
                        today.setDate(today.getDate() + 14);
                        expiringDate = today.toISOString().split('T')[0]; // Formato yyyy-mm-dd
                    }
        this.id = id; //visualId ='',
        this.visualId = visualId;
        this.clientId = clientId;
        this.expiringDate = expiringDate;
        this.recipientReference = recipientReference;
        //this.reference = reference;
       /* this.amount = amount;
        this.vatAmount = vatAmount;
        this.totalAmount = totalAmount;*/
        this.status = status;
        //this.type = type;
        this.fileUrl = fileUrl;
        this.rows = rows;
        this.costBearerId = costBearerId;
    }

    static getAttributes(filter = null, validationSchema = null ,optionsData = null) {
        const greaterO = i18n.t('validationMessage.greater0');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            clientId: string().required(fieldRequired),
            expiringDate: string().required(fieldRequired),
            recipientReference: string().required(fieldRequired),
            //reference: string().required(fieldRequired),
           // type: string().required(fieldRequired),
            rows: yup.array().min(1, ),
            costBearerId: string().required(fieldRequired),
            status: string().required(fieldRequired),
        });

        let optionsCost = [];
        let optionsClient = [];
        if (optionsData) {
            optionsData.costBearer?.map(item => {
                optionsCost?.push({
                    label: item.name,
                    value: item.id
                });
            })
            optionsData.client?.map(item => {
                optionsClient?.push({
                    label: item.name,
                    value: item.id
                });
            })

        }
        /*const optionsType = [
            {label: i18n.t('invoice.invoice') , value: 'Faktura'},
            {label: i18n.t('invoice.creditInvoice') , value: 'Kredit Faktura'},
        ];*/


        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            visualId :{
                'value': (item) => item.visualId || "",
            },
            clientId :{
                'label': i18n.t('invoice.clientName'),
                'form': {
                    'type': 'select',
                    'options': optionsClient, // cambiar esta opcion por la de los cliente cuando este
                    "search": true,
                    'placeholder':  i18n.t('formPlaceholder.selectClientName'),
                    'validation': completeSchema.fields.clientId,
                },
                'value': (item) => item.clientId || "",
            },
            expiringDate :{
                'label': i18n.t('invoice.date'), //Dagar tills förfallodatum
                'form': {
                    'type': 'datePredefined',
                    'placeholder': i18n.t('formPlaceholder.selectDate'),
                    'validation': completeSchema.fields.expiringDate,
                },
                'value': (item) => item.expiringDate || "",
            },
            recipientReference :{
                'label': i18n.t('invoice.recipientRef'), //Mottagarens referens
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterRecipient'),
                    'validation': completeSchema.fields.recipientReference,
                },
                'value': (item) => item.recipientReference || "",
            },
            /*reference :{
                'label': i18n.t('invoice.reference'),//Referens
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterReference'),
                    'validation': completeSchema.fields.reference,
                },
                'value': (item) => item.reference || "",
            },*/
           /* amount :{
                'label': i18n.t('modelLabel.amount'),
                'form': {
                    'type': 'number',
                    'inputType': 'inline',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    //'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'form': {
                    'type': 'number',
                    'inputType': 'inline',
                    'placeholder': i18n.t('formPlaceholder.enterVatAmount'),
                    //'validation': completeSchema.fields.vatAmount,
                },
                'value': (item) => item.vatAmount || "",
            },
            totalAmount :{
                'label': i18n.t('modelLabel.totalAmount'),
                'form': {
                    'type': 'number',
                    'inputType': 'inline',
                    'placeholder': i18n.t('formPlaceholder.enterTotalAmount'),
                    //'validation': completeSchema.fields.totalAmount,
                },
                'value': (item) => item.totalAmount || "",
            },*/
            status :{
                'label': i18n.t('modelLabel.status'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.status'),
                    'validation': completeSchema.fields.status,
                },
                'value': (item) => item.status || "",
            },
           /* type :{
                'label': i18n.t('modelLabel.type'),
                'form': {
                    'type': 'select',
                    'options': optionsType,
                    'placeholder': i18n.t('formPlaceholder.type'),
                    'validation': completeSchema.fields.type,
                },
                'value': (item) => item.type || "",
            },*/
            fileUrl :{
                'label': 'file url',
                'form': {
                    'type': 'text',
                    'placeholder': '',
                    //'validation': completeSchema.fields.fileUrl,
                },
                'value': (item) => item.fileUrl || "",
            },
            rows :{
                'label': 'rows',
                'form': {
                    'type': 'text',
                    'placeholder': '',
                    'validation': completeSchema.fields.rows,
                },
                'value': (item) => item.rows || "",
            },
            costBearerId: {
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options':optionsCost,
                    'default': '',
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestInvoice;