import {Col, Row} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseUserModel from "../../User/Models/Response/ResponseUserModel";
import {useNavigate} from "react-router-dom";
import {ORGANIZATION_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";

export function OrgUsers() {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show pb-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')}  customClasses="rounded" buttonBorder={false}
                              id={item.id}
                              onClick={() => {
                                  //setIndividualUserId(item);
                                  const newUrl = `${ORGANIZATION_PATHS.ORG_USERS_INFO}?id=${item.id}`;
                                  navigate(newUrl);
                              }}
                />
            </>
        );
    }
    const ignoreFields = ["Roles", "Id", "Addresses"];
    const listOptions = {
        "model": ResponseUserModel,
         'columns':[ "visualId", 'firstName','lastName','pnr','email' ,'phone'],
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/org/users/search",
        "nameFile": t('adminLabel.orgUsers'),
        "ignoreFields" : ignoreFields,
        "urlGetSearch" : "/org/users/search",
    };

    // useEffect(() => {
    //
    //     const handleApiResponse  = (apiResponseRoleList) => {
    //
    //         setResponseRoleList(apiResponseRoleList);
    //
    //     };
    //     const GetRoleUser = async () => {
    //         if ( individualUserId !== null) {
    //             try {
    //
    //                 const apiCallOptions = {
    //                     method: "get",
    //                     url: `/user/role/org/list?id=${individualUserId}`,
    //                 };
    //
    //                 await ApiCall(apiCallOptions, handleApiResponse );
    //
    //             } catch (error) {
    //                 console.log('error', error);
    //             }
    //         }
    //     };
    //
    //     GetRoleUser();
    //
    // }, [individualUserId]);

    return(
        <>
            <Col lg="12" className='card card-backend p-4 mb-5'>
                <h2 className="pb-2 rokkitt-font">{t('adminLabel.orgUser')}</h2>
                <SortableList
                    listOptions={listOptions}
                />
            </Col>

        </>);

}