import React, { useEffect, useState } from 'react';
import useApiCallService from '../../Common/Helpers/ApiCall';
import { Card, Col, Row } from 'reactstrap';
import { CustomReload } from '../../AppSystem/Helpers/CustomReload';
import { CustomButton } from '../../Common/Components/Button/CustomButton';
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.png"
import { useTranslation } from 'react-i18next';
import {API_URL, APP_URL} from "../../config";

const CheckoutPage = () => {
  const { ApiCall } = useApiCallService();
  const [errorMessageResult, setErrorMessageResult] = useState(null);
  const { t } = useTranslation()
  const [paymentIdResult, setPaymentIdResult] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentId = searchParams.get('payment_id');
    setPaymentIdResult(paymentId);
    const handleUpdateStatusApiResponse  = (response) => {
       if (!response) {
            return;
        }

        if (response.paymentOrder !== null) {
          const paymentOrder = response.paymentOrder;
          
          const status = paymentOrder.status;

          if (status === 'Paid' && paymentOrder.paid !== null && paymentOrder.paid.Instrument !== null) {
            CustomReload((APP_URL) + '/checkout-completed');
          } else if (status === 'Cancelled') {
            CustomReload((APP_URL) +'/checkout-canceled');
          } else {
            CustomReload((APP_URL) +'/checkout-canceled');
          }
        }
        
    }

    const handleApiResponse  = (apiResponse) => {
      if (apiResponse) {
        const script = document.createElement('script');
        script.setAttribute('src', apiResponse);
        script.onload = () => {
          // When the 'view-checkout' script is loaded, we can initialize the
          // Payment Menu inside 'checkout-container'.
          window.payex.hostedView.checkout({
            container: {
              checkout: 'checkout-container'
            },
            culture: 'sv-SE',
            onPaid: () => {
              // TODO: Do a capture and update db, then do a location.href = <completeUrl>
              // window.location.replace(window.location.href);
              UpdateStatus(paymentId);            
            }
          }).open();

        };
        // Append the Checkout script to the <head>
        document.head.appendChild(script);

      }else{
        setErrorMessageResult('Something went wrong with sessionId, please contact the kassan administrator');
      }  
    };

    const fetchCheckoutScript = async (paymentId) => {
      try {
          const apiCallOptions = {
              "method" : "get",
              "url" : `/swedbank/url?swedbankId=${paymentId}`,
            };
         await ApiCall(apiCallOptions, handleApiResponse);
      } catch (error) {
        console.error('Error fetching the checkout script:', error);
      }
    };

    const UpdateStatus = async (paymentId) => {
      try {
        const apiCallOptions = {
          "method" : "get",
          "url" : `/swedbank?id=${paymentId}&updateStatus=${true}`,
        };
        await ApiCall(apiCallOptions, handleUpdateStatusApiResponse);
      } catch (error) {
        console.error('Error fetching the checkout script:', error);
      }
    };
    fetchCheckoutScript(paymentId);
  }, []);


  const handleBackToTestAccount = async () => {
    CustomReload((APP_URL)+'/checkout-canceled');
  };
  return (
<Row className='d-flex justify-content-center' >
  <Col lg={9} >
    <Card className="p-3">
      <Row className='d-flex justify-content-center px-4'>
          <Col lg={6} className='d-flex justify-content-center pt-5'>
          <img src={LogoLRF} />
          </Col>
          <Col lg={12}>
              <div 
                  id="checkout-container" 
              >
                  {/* Contenido del checkout-container */}
              </div>
          </Col>
          <Col lg={6} className='d-flex justify-content-center pt-5'>
              <CustomButton
              text={t('button.cancel')}
              buttonSize={'big'}
              buttonWidth={'block'}
              customClasses={'mb-3 ms-3 backButton'}
              onClick={handleBackToTestAccount}
          />
          </Col>
      </Row>
    
  </Card>
  </Col>
</Row>
  );
};

export default CheckoutPage;
