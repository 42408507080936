
const RedirectView = () => {

    return(                
        <>  
            <div className="spinner-container">
                <div className="spinner-wrapper">
                    <h1 style={{ marginBottom: '20px' }}>
                        <>
                            Din session har löpt ut, vänligen logga in igen på
                            <br />
                            <a href="https://www.lrf.se/kassan/" style={{
                                color: '#006400', 
                                textDecoration: 'underline'
                            }} >www.lrf.se/kassan</a>.
                        </>
                    </h1>
                </div>
            </div>            
        </>
        );
}
export default RedirectView;