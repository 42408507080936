import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {CustomReload} from "../../../AppSystem/Helpers/CustomReload";
import {CustomModal} from "./CustomModal";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

// IMPORTANT: IF PAGE HAS A BACK BUTTON, MUST BE ADDED CLASS "backButton" TO BUTTON ELEMENT

export function CustomUnsavedModal({ backButtonUrl = null, modalMessage = null }) {
    const { t } = useTranslation();

    const navigate = useNavigate()

    const [displayUnsavedModal, setDisplayUnsavedModal] = useState(false);
    const [navigateUrl, setNavigateUrl] = useState("");

    const rootElement = document.getElementById('root');

    // UNSAVED MODAL CONTENT
    const confirmUnsavedModalContent = (
        <div>
            {modalMessage !== null ? modalMessage : t("validationMessage.changeNotSave")}
        </div>
    );

    // UNSAVED MODAL FOOTER
    const confirmUnsavedModalButtons = (
        <>
            <Button className="box-green border-0" onClick={() => {
                if (navigateUrl !== "") {
                    setDisplayUnsavedModal(false);
                    navigate(navigateUrl);
                    CustomReload();
                }
            }}>
                {t('button.accept')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayUnsavedModal(false)}>
                {t('button.cancel')}
            </Button>
        </>
    );

    // HANDLE UNSAVED CHANGES
    const HandleUnsavedChanges = (e) => {
        const div = document.querySelector(".page-content");
        // CLICK INSIDE
        if (div.contains(e.target)) {
            if (backButtonUrl !== null) {
                if (e.target.onclick !== null && e.target.tagName && e.target.tagName === "BUTTON" && e.target.className && e.target.className.includes("backButton")) {
                    // STOP NAVIGATION FOR INSIDE ROUTE
                    e.stopPropagation();
                    setDisplayUnsavedModal(true);
                    setNavigateUrl(backButtonUrl);
                }
            }
            return;
        }
        else {
            // CLICK OUTSIDE
            if (e.target.parentElement && e.target.parentElement.tagName === "A" && e.target.parentElement.attributes.href && e.target.parentElement.attributes.href.value && e.target.parentElement.attributes.href.value !== "") {
                // STOP NAVIGATION FOR INSIDE ROUTE
                if (e?.target?.parentElement?.parentElement?.className?.trim() === "dropdown") {
                    return;
                }
                e.preventDefault();
                //e.stopPropagation();
                setDisplayUnsavedModal(true);
                setNavigateUrl(e.target.parentElement.attributes.href.value);
            }
            if (e.target.parentElement && e.target.parentElement.parentElement && e.target.parentElement.parentElement.tagName === "A" && e.target.parentElement.parentElement.attributes.href && e.target.parentElement.parentElement.attributes.href.value && e.target.parentElement.parentElement.attributes.href.value !== "") {
                e.preventDefault();
                //e.stopPropagation();
                setDisplayUnsavedModal(true);
                setNavigateUrl(e.target.parentElement.parentElement.attributes.href.value);
            }
            if (e.target.onclick !== null && e.target.tagName === "A" && e.target.attributes.href && e.target.attributes.href.value !== "") {
                if (e.target?.parentElement?.className?.trim() === "dropdown") {
                    return;
                }
                e.preventDefault();
                //e.stopPropagation();
                setDisplayUnsavedModal(true);
                setNavigateUrl(e.target.attributes.href.value);
            }
        }
    };

    // HANDLE UNSAVED CHANGES CLOSE
    const HandleUnsavedChangesClose = (e) => {
        e.preventDefault();
    };

    // USE EFFECT TO HANDLE UNSAVED CHANGES
    useEffect(() => {
        rootElement.addEventListener('click', HandleUnsavedChanges, true);
        window.addEventListener('beforeunload', HandleUnsavedChangesClose);
        return () => {
            rootElement.removeEventListener('click', HandleUnsavedChanges);
            window.removeEventListener('beforeunload', HandleUnsavedChangesClose);
        }
    }, []);

    return (
        <CustomModal isOpen={displayUnsavedModal} toggle={() => setDisplayUnsavedModal(!displayUnsavedModal)} body={confirmUnsavedModalContent} footer={confirmUnsavedModalButtons} />
    );
}