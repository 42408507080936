import useApiCallService from "../../Common/Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import ResponseAccountRowModel from "../Models/Response/ResponseAccountRowModel";
import {Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ORGANIZATION_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AccountRowView} from "../Components/AccountRowView";

export function AccountRowInfo() {
    const { ApiCall } = useApiCallService();
    const idValue = new URLSearchParams(window.location.search).get('id');
    const { t } = useTranslation();
    const navigate = useNavigate();

    //API RESPONSE
    const [apiResponseAccountRow, setApiResponseAccountRow] = useState(new ResponseAccountRowModel());

    useEffect(() => {
        const handleApiResponseAccountRow  = (apiResponseAccountRow) => {
            setApiResponseAccountRow(apiResponseAccountRow);
        };
        const GetAccountRow = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/digital-account/account-row?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponseAccountRow );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetAccountRow();
    }, [idValue]);

    return(
    <>
        <Col lg={1} className="px-0">
            <CustomButton
                text={t('button.back')}
                icon={'bx bx-chevron-left'}
                buttonSize={'small'}
                customClasses={'ms-3 mb-3'}
                onClick={() => {
                    navigate(ORGANIZATION_PATHS.DIGITAL_ACCOUNT)
                }}
            />
        </Col>

        {apiResponseAccountRow !== null ?
            <AccountRowView data={apiResponseAccountRow}/>
            : ''}
    </>
    );
}