//Install:  react-beautiful-dnd
/* npm install react-beautiful-dnd */

//Example for use this component:

/*const YourOtherComponent = () => {
  const [items, setItems] = useState([
    { id: '1', text: 'Item 1' },
    { id: '2', text: 'Item 2' },
    { id: '3', text: 'Item 3' },
    // elements need have an ID
  ]);


    const handleDragEnd = ({ reorderedItems  }) => {
        /// Logic for handling the drag and drop event and API calls for Sort the list in the DB
        Update the local status with the new arrangement

        setItems(reorderedItems);
        // API calls
    };

    return (
        <DraggableListTest
            items={items}
            droppableId="uniqueIdForThisList"
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                    <div>{item.text}</div>
            )}
               renderItem={(row) => (
                            <>
                                <Row key={row.id}>
                                    <Col md={11}>
                                            <>
                                                <RowsInvoiceCard row={row} />
                                                <hr />
                                            </>
                                    </Col>
                                </Row>
                            </>
                        )}
            side="horizontal" || "vertical"
           style={'largerBorder'} *OPTIONAL PARAMETER
        />
    );
};*/

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DragAndDrop = ({ items, droppableId, onDragEnd, renderItem,  side, style = null }) => {
    const isHorizontal = side === 'horizontal';
    switch (style) {
        case 'largerBorder':
            style = { padding: '10px', border: '5px solid black' };
            break;
        case 'smallBorder':
            style = { padding: '10px', border: '1px solid black' };
            break;
        default:
            style = { }
            break;
    }

    const handleDragEnd = (result) => {
        /* Handle the drop event here and update the order of the elements.
         You can use result.source.index and result.destination.index to get the indexes.
         Then you can update the state with the new order of the elements.*/

        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        onDragEnd({ reorderedItems  });

    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={droppableId} direction={isHorizontal ? 'horizontal' : 'vertical'}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`draggable-list-container ${isHorizontal ? 'horizontal' : 'vertical'}`}
                        style={style}

                    >
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(draggableProvided) => (
                                    <div
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        ref={draggableProvided.innerRef}
                                        className="draggable-item"
                                    >
                                        {renderItem(item)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDrop;
