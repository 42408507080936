class AdminRequestBasketStatusModel {
    constructor({
                    basketId = '',
                    paymentAuthorisationId = '',
                    authenticationMethodId = '',
                } = {}) {
        this.basketId = basketId;
        this.paymentAuthorisationId = paymentAuthorisationId;
        this.authenticationMethodId = authenticationMethodId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            basketId :{
                //'label': 'basket Id',
                //'sortable': true,
                //'sortField': 'basketId',
                'value': (item) => item.basketId || "",
            },
            paymentAuthorisationId :{
                //'label': payment Authorisation Id,
               // 'sortable': true,
               // 'sortField': 'paymentAuthorisationId',
                'value': (item) => item.paymentAuthorisationId || "",
            },
            authenticationMethodId :{
                //'label': 'authentication Method Id',
                //'sortable': true,
                //'sortField': 'authenticationMethodId',
                'value': (item) => item.authenticationMethodId || "",
            },
        }
        return attributes;
    }
}
export default AdminRequestBasketStatusModel;