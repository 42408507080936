//Example for use CustomButtton
/*
            < CustomButton
            text=" CustomButton"
           // textSize={1}
            //hoverTextSize={2}
           // textColor = 'pink'
           // hoverTextColor={'blue'}
            //icon={'bx bx-smile'}
            //iconSize={1}
            //hoverIconSize={3}
            // iconColor={'red'}
            // hoverIconColor={'orange'}
            //buttonColor={'pink'}
            //hoverButtonColor={'black'}
            //buttonSize={'small'}
            //hoverButtonSize={'small'}
            // buttonWidth={''}
            //hoverButtonWidth={'block'}
            //buttonBorder={5}
            //hoverButtonBorder={3}
            //borderColor={'gray subtle'}
            //hoverBorderColor={'blue'}
            //buttonShadow={true}
            //hoverButtonShadow={false}
            //buttonRadius={5}
            //hoverButtonRadius={6}
            // customClasses={'p-3 mb-2 bg-primary text-white'}
            //tooltipText={'Hello'}
            //tooltipColor={'red'}
            //tooltipTextColor={'black'}
            //tooltipTextSize={2}
            //customClassesTooltip={'p-3'}
            //customHoverClasses={'p-3 mb-2 bg-primary text-white'}
            //id={'test'}
            //hidden={false}
            //disabled={false}
            //onClick={()=>{alert('hello')}}
            />


You can set the color if you want by color name or by hexadecimal code for the background, the text of the button and the tooltip.
To use the border with color you need put border true and for the borderColor you have this options:

         'blue'
         'primary'

         'blue subtle'
         'primary-subtle'

         'gray'
         'secondary'

         'gray subtle'
         'secondary-subtle'

         'green'
         'success'

         'green subtle'
         'success-subtle'

         'red'
         'danger'

         'red subtle'
         'danger-subtle'

         'yellow'
         'warning'

         'yellow subtle'
         'warning-subtle'

         'light blue'
         'info'

         'light blue subtle':
         'info-subtle'

          'light subtle'
         'light-subtle'

         'dark'
          'light'

         'dark subtle'
         'dark-subtle'

         'black'

         'white'



The default button is medium size, but you can change it to "big" or "small".
If you don't put borderRadius is the default button, false is like a square, 1 little's border rounded to 5 (rounded borders)

If you don't put any width, the default button is with width 'auto', you can change this value if you put buttonWidth 'block' for have width 100%

You can change textSize for the button 1 to 7 (big to small)
You can change iconSize for the icon 1 to 7 (big to small)

If you want to use specific rules for the hover or the tooltip use customHoverClasses, customClassesTooltip

If you need more customClasses for the button you can use customClasses
You can hide or disabled the button with hidden={true} || disabled{true}
You can put an id, onClick and an icon
If you want use a tooltip you can change the colorText, the color and other custom classes that you want using classesTooltip, */


import React, {useState} from "react";

export function CustomButton(buttonOptions) {
    const [isHovered, setIsHovered] = useState(false);

    let classes = [];
    let classesTooltip = [];
    let classesIcon = [];
    let tooltipBeforeStyle = {}
    let styles = {}
    let iconCustomStyle = {}
    let stylesTooltip = {}


    const setDefaultHoverOptions = () => {
        buttonOptions = { ...buttonOptions };
        buttonOptions.hoverButtonSize = buttonOptions.hoverButtonSize || buttonOptions.buttonSize;
        buttonOptions.hoverTextSize = buttonOptions.hoverTextSize || buttonOptions.textSize;
        buttonOptions.hoverTextColor = buttonOptions.hoverTextColor || buttonOptions.textColor;
        buttonOptions.hoverButtonWidth = buttonOptions.hoverButtonWidth || buttonOptions.buttonWidth;
        buttonOptions.hoverButtonBorder = buttonOptions.hoverButtonBorder || buttonOptions.buttonBorder;
        buttonOptions.hoverBorderColor =  buttonOptions.hoverBorderColor || buttonOptions.borderColor;
        buttonOptions.hoverButtonRadius = buttonOptions.hoverButtonRadius || buttonOptions.buttonRadius;
        buttonOptions.hoverButtonShadow =  buttonOptions.hoverButtonShadow || buttonOptions.buttonShadow;
        buttonOptions.hoverButtonColor =  buttonOptions.hoverButtonColor || buttonOptions.buttonColor;
        buttonOptions.hoverIconSize = buttonOptions.hoverIconSize || buttonOptions.iconSize;
        buttonOptions.hoverIconColor = buttonOptions.hoverIconColor || buttonOptions.iconColor;

    };


if(isHovered) {
    setDefaultHoverOptions();
    if (buttonOptions.hoverButtonSize) {
        switch (buttonOptions.hoverButtonSize) {
            case 'medium':
                classes +=  ' btn';
                break;
            case 'big':
                classes +=  ' btn-lg';
                break;
            case 'small':
                classes +=  ' btn-sm';
                break;
            default:
                classes +=  ' btn';
        }
    }


    const hoverTextSize = buttonOptions.hoverTextSize
    switch (hoverTextSize) {
        case 1:
            classes +=  ' fs-1';
            break;
        case 2:
            classes +=  ' fs-2';
            break;
        case 3:
            classes +=  ' fs-3';
            break;
        case 4:
            classes +=  ' fs-4';
            break;
        case 5:
            classes +=  ' fs-5';
            break;
        case 6:
            classes +=  ' fs-6';
            break;
        case 7:
            classes +=  '';
            break;
        default:
            classes +=  ' fs-6';
    }


    const hoverButtonWidth = buttonOptions.hoverButtonWidth
    switch (hoverButtonWidth) {
        case 'block':
            classes +=  ' w-100' ;

            break;
        default:
            classes += ' w-auto'
    }

    const hoverButtonBorder = buttonOptions.hoverButtonBorder
    switch (hoverButtonBorder) {
        case 1:
            classes +=  ' border border-1';
            break;
        case 2:
            classes +=  ' border border-2';
            break;
        case 3:
            classes +=  ' border border-3';
            break;
        case 4:
            classes +=  ' border border-4';
            break;
        case 5:
            classes +=  ' border border-5';
            break;
        case false:
            classes +=  ' border border-0';
            break;

        default:
            classes +=  ' border border-0';
    }

    const hoverBorderColor = buttonOptions.hoverBorderColor
    switch (hoverBorderColor) {
        case 'blue':
        case 'primary':
            classes +=  ' border-primary';
            break;
        case 'blue subtle':
        case 'primary-subtle':
            classes +=  ' border-primary-subtle';
            break;
        case 'gray':
        case 'secondary':
            classes +=  ' border-secondary';
            break;
        case 'gray subtle':
        case 'secondary-subtle':
            classes +=  ' border-secondary-subtle';
            break;
        case 'green':
        case 'success':
            classes +=  ' border-success';
            break;
        case 'green subtle':
        case 'success-subtle':
            classes +=  ' border-success-subtle';
            break;
        case 'red':
        case 'danger':
            classes +=  ' border-danger';
            break;
        case 'red subtle':
        case 'danger-subtle':
            classes +=  ' border-danger-subtle';
            break;
        case 'yellow':
        case 'warning':
            classes +=  ' border-warning';
            break;
        case 'yellow subtle':
        case 'warning-subtle':
            classes +=  ' border-warning-subtle';
            break;
        case 'light blue':
        case 'info':
            classes +=  ' border-info';
            break;
        case 'light blue subtle':
        case 'info-subtle':
            classes +=  ' border-info-subtle';
            break;
        case 'light':
            classes +=  ' border-light';
            break;
        case 'light subtle':
        case 'light-subtle':
            classes +=  ' border-light-subtle';
            break;
        case 'dark':
            classes +=  ' border-dark';
            break;
        case 'dark subtle':
        case 'dark-subtle':
            classes +=  ' border-dark-subtle';
            break;
        case 'black':
            classes +=  ' border-black';
            break;
        case 'white':
            classes +=  ' border-white';
            break;

        default:
            classes +=  ' border-success-subtle';
    }


    const hoverButtonRadius = buttonOptions.hoverButtonRadius
    switch (hoverButtonRadius) {

        case 1:
            classes +=  ' rounded-1';
            break;
        case 2:
            classes +=  ' rounded-2';
            break;
        case 3:
            classes +=  ' rounded-3';
            break;
        case 4:
            classes +=  ' rounded-4';
            break;
        case 5:
            classes +=  ' rounded-5';
            break;
        case false:
            classes +=  ' rounded-0';
            break;

        default:
            styles.borderRadius = '6px'
    }

    const hoverButtonShadow = buttonOptions.hoverButtonShadow
    switch (hoverButtonShadow) {

        case true:
            classes +=  ' shadow';
            break;
        case false:
            classes +=  '';
            break;

        default:
            classes +=  '';
    }


    //Colors for the button
    if ( buttonOptions.hoverTextColor ) {
        styles.color = buttonOptions.hoverTextColor;
    }else{
        styles.color = 'white'
    }

    if (buttonOptions.hoverButtonColor) {
        styles.backgroundColor = buttonOptions.hoverButtonColor;
    }else{
        styles.backgroundColor = '#084F35'
    }

    //Colors for the tooltip
    if ( buttonOptions.tooltipTextColor ) {
        stylesTooltip.color = buttonOptions.tooltipTextColor;
    }else{
        stylesTooltip.color = 'white'
    }

    if (buttonOptions.tooltipColor) {
        stylesTooltip.backgroundColor = buttonOptions.tooltipColor;
        stylesTooltip.borderColor = `${buttonOptions.tooltipColor} transparent transparent  transparent`;
    }else{
        stylesTooltip.backgroundColor = '#888888'
    }

    const tooltipTextSize = buttonOptions.tooltipTextSize
    switch (tooltipTextSize) {
        case 1:
            classesTooltip +=  ' fs-1';
            break;
        case 2:
            classesTooltip +=  ' fs-2';
            break;
        case 3:
            classesTooltip +=  ' fs-3';
            break;
        case 4:
            classesTooltip +=  ' fs-4';
            break;
        case 5:
            classesTooltip +=  ' fs-5';
            break;
        case 6:
            classesTooltip +=  ' fs-6';
            break;
        case 7:
            classesTooltip +=  '';
            break;
        default:
            classesTooltip +=  '';
    }

    tooltipBeforeStyle = {
        borderColor: stylesTooltip.borderColor || ' #888888 transparent transparent transparent'
    };


    if(classesIcon){
        const hoverIconSize = buttonOptions.hoverIconSize || ''
        switch (hoverIconSize) {
            case 1:
                classesIcon +=  ' fs-1';
                break;
            case 2:
                classesIcon +=  ' fs-2';
                break;
            case 3:
                classesIcon +=  ' fs-3';
                break;
            case 4:
                classesIcon +=  ' fs-4';
                break;
            case 5:
                classesIcon +=  ' fs-5';
                break;
            case 6:
                classesIcon +=  ' fs-6';
                break;
            case 7:
                classesIcon +=  '';
                break;
            default:
                classesIcon +=  ' fs-6';
        }

        if ( buttonOptions.hoverIconColor ) {
            iconCustomStyle.color = buttonOptions.hoverIconColor
        }else{
            iconCustomStyle.color = 'white'
        }
    }


}else{
    if (buttonOptions.buttonSize) {
        switch (buttonOptions.buttonSize) {
            case 'medium':
                classes +=  ' btn';
                break;
            case 'big':
                classes +=  ' btn-lg';
                break;
            case 'small':
                classes +=  ' btn-sm';
                break;
            default:
                classes +=  ' btn';
        }
    }

    const textSize = buttonOptions.textSize
    switch (textSize) {
        case 1:
            classes +=  ' fs-1';
            break;
        case 2:
            classes +=  ' fs-2';
            break;
        case 3:
            classes +=  ' fs-3';
            break;
        case 4:
            classes +=  ' fs-4';
            break;
        case 5:
            classes +=  ' fs-5';
            break;
        case 6:
            classes +=  ' fs-6';
            break;
        case 7:
            classes +=  '';
            break;
        default:
            classes +=  ' fs-6';
    }


    const buttonWidth = buttonOptions.buttonWidth
    switch (buttonWidth) {
        case 'block':
            classes +=  ' w-100' ;

            break;
        default:
            classes += ' w-auto'
    }

    const buttonBorder = buttonOptions.buttonBorder
    switch (buttonBorder) {
        case 1:
            classes +=  ' border border-1';
            break;
        case 2:
            classes +=  ' border border-2';
            break;
        case 3:
            classes +=  ' border border-3';
            break;
        case 4:
            classes +=  ' border border-4';
            break;
        case 5:
            classes +=  ' border border-5';
            break;
        case false:
            classes +=  ' border border-0';
            break;

        default:
            classes +=  ' border border-0';
    }

    const borderColor = buttonOptions.borderColor
    switch (borderColor) {
        case 'blue':
        case 'primary':
            classes +=  ' border-primary';
            break;
        case 'blue subtle':
        case 'primary-subtle':
            classes +=  ' border-primary-subtle';
            break;
        case 'gray':
        case 'secondary':
            classes +=  ' border-secondary';
            break;
        case 'gray subtle':
        case 'secondary-subtle':
            classes +=  ' border-secondary-subtle';
            break;
        case 'green':
        case 'success':
            classes +=  ' border-success';
            break;
        case 'green subtle':
        case 'success-subtle':
            classes +=  ' border-success-subtle';
            break;
        case 'red':
        case 'danger':
            classes +=  ' border-danger';
            break;
        case 'red subtle':
        case 'danger-subtle':
            classes +=  ' border-danger-subtle';
            break;
        case 'yellow':
        case 'warning':
            classes +=  ' border-warning';
            break;
        case 'yellow subtle':
        case 'warning-subtle':
            classes +=  ' border-warning-subtle';
            break;
        case 'light blue':
        case 'info':
            classes +=  ' border-info';
            break;
        case 'light blue subtle':
        case 'info-subtle':
            classes +=  ' border-info-subtle';
            break;
        case 'light':
            classes +=  ' border-light';
            break;
        case 'light subtle':
        case 'light-subtle':
            classes +=  ' border-light-subtle';
            break;
        case 'dark':
            classes +=  ' border-dark';
            break;
        case 'dark subtle':
        case 'dark-subtle':
            classes +=  ' border-dark-subtle';
            break;
        case 'black':
            classes +=  ' border-black';
            break;
        case 'white':
            classes +=  ' border-white';
            break;

        default:
            classes +=  ' border-success-subtle';
    }


    const buttonRadius = buttonOptions.buttonRadius
    switch (buttonRadius) {

        case 1:
            classes +=  ' rounded-1';
            break;
        case 2:
            classes +=  ' rounded-2';
            break;
        case 3:
            classes +=  ' rounded-3';
            break;
        case 4:
            classes +=  ' rounded-4';
            break;
        case 5:
            classes +=  ' rounded-5';
            break;
        case false:
            classes +=  ' rounded-0';
            break;

        default:
            styles.borderRadius = '6px'
    }

    const buttonShadow = buttonOptions.buttonShadow
    switch (buttonShadow) {

        case true:
            classes +=  ' shadow';
            break;
        case false:
            classes +=  '';
            break;

        default:
            classes +=  '';
    }

    //Colors for the button
    if ( buttonOptions.textColor ) {
        styles.color = buttonOptions.textColor;
    }else{
        styles.color = 'white'
    }

    if (buttonOptions.buttonColor) {
        styles.backgroundColor = buttonOptions.buttonColor;
    }else{
        styles.backgroundColor = '#157553'
    }

    if(classesIcon){
        const iconSize = buttonOptions.iconSize
        switch (iconSize) {
            case 1:
                classesIcon +=  ' fs-1';
                break;
            case 2:
                classesIcon +=  ' fs-2';
                break;
            case 3:
                classesIcon +=  ' fs-3';
                break;
            case 4:
                classesIcon +=  ' fs-4';
                break;
            case 5:
                classesIcon +=  ' fs-5';
                break;
            case 6:
                classesIcon +=  ' fs-6';
                break;
            case 7:
                classesIcon +=  '';
                break;
            default:
                classesIcon +=  ' fs-6';
        }

        if ( buttonOptions.iconColor ) {
            iconCustomStyle.color = buttonOptions.iconColor;
        }else{
            iconCustomStyle.color = 'white'
        }
    }
}

    const finalIconClasses = classesIcon !== [] ? classesIcon : "";
    const finalClasses = classes !== [] ? classes : "";
    const finalTooltipClasses = classesTooltip !== [] ? classesTooltip : "";
    const finalStyles = styles !== {} ? styles : "";
    const finalIconStyles = iconCustomStyle !== {} ? iconCustomStyle : "";

    return (
        <>
            {
                    <button
                        className={`p-custom-button ${finalClasses} ${buttonOptions.customClasses} ${
                            isHovered ? buttonOptions.customHoverClasses : ''
                        } ${buttonOptions.tooltipText ? 'position-relative' : ''} ${
                            buttonOptions.icon && buttonOptions.text ? 'd-flex align-items-center' : ''
                        }`}

                        style={ finalStyles }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={buttonOptions.onClick}
                        id={buttonOptions.id}
                        hidden={buttonOptions.hidden}
                        disabled={buttonOptions.disabled}
                    >

                        {buttonOptions.icon && (
                            <i className={`${buttonOptions.icon} ${finalIconClasses} align-middle size-icon-custom-button`} style={ finalIconStyles} />

                        )}

                        {buttonOptions.tooltipText && /*isHovered &&*/ (
                            <div className={`tooltip-custom-button ${buttonOptions.customClassesTooltip} ${finalTooltipClasses} `} style={stylesTooltip} >
                               {/* <div className="tooltip-customBefore" style={tooltipBeforeStyle}></div>*/}
                                {buttonOptions.tooltipText}
                            </div>
                        )}

                        {buttonOptions.text ? buttonOptions.text  : ''}
                    </button>
            }
        </>
    );
}
