import React, {useEffect} from "react";
import { Col, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import RequestSwedbankPay from "../Models/Request/RequestSwedbanPay";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";

export function CreateFormSwedbankPay({ data, costBearerArray = [], accountingArray = [], onChange, onValidate, onValidateCopy, displaySpinner}) {
    const { t } = useTranslation()

    // CHECK NUMBER FORMAT
    const checkNumberFormat = (e, name) => {
        const key = e.key;
        const currentValue = e.target.value;
        const isValidChar = key >= '0' && key <= '9';
        const hasReachedMaxLength = currentValue.replace(/[^0-9]/g, '').length >= (name === "accountNumber" ? 12 : 5);
        if (!isValidChar || hasReachedMaxLength) {
            e.preventDefault();
        }
    }
    /*useEffect(() => {
        if (!data?.accountingId && accountingArray.length > 0) {
            onChange({ target: { value: accountingArray[0].id } }, "accountingId");
        }
    }, [accountingArray, data?.accountingId, onChange]);*/

    useEffect(() => {
        if (data?.accountNumber) {
            const accountNumberInput = document.getElementsByName('accountNumber');
            if (accountNumberInput.length > 0) {
                accountNumberInput[0].addEventListener('keypress', function (e) {
                    checkNumberFormat(e, "accountNumber");
                });
            }
        }
    }, [data?.accountNumber]);

    const messageCostBearer = InputMessage(costBearerArray, data?.costBearerId);

    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col lg={6} className="pt-4">
                        <Row>
                            <Col lg={12}>
                                <CustomInput model={RequestSwedbankPay} attribute='costBearerId' data={data?.costBearerId} customChange={(e) => {
                                    onChange(e, "costBearerId");
                                }} customOptions={costBearerArray.length > 0 ? costBearerArray : null}/>
                                {messageCostBearer}
                            </Col>
                            {/* <Col lg={12}>
                                <CustomInput model={RequestSwedbankPay} attribute='accountingId' data={data?.accountingId} customChange={(e) => {
                                    onChange(e, "accountingId");
                                }} customOptions={accountingArray.length > 0 ? accountingArray : null}/>
                            </Col> */}
                            <Col lg={12}>
                                <CustomInput model={RequestSwedbankPay} attribute='email' data={data?.email} customChange={(e) => {
                                    onChange(e, "email");
                                }} />   
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className="pt-4">
                        <Row>
                            <Col lg={12}>
                                <CustomInput model={RequestSwedbankPay} attribute='amount' data={data?.amount !== 0 ? data?.amount : ""} customChange={(e) => {
                                    onChange(e, "amount");
                                }} />
                            </Col>
                            {/* {data?.vatAmount !== null ?
                                <Col lg={6} className="position-relative">
                                    <CustomInput model={RequestSwedbankPay} attribute='vatAmount' data={data?.vatAmount !== 0 ? data?.vatAmount : ""} customChange={(e) => {
                                        onChange(e, "vatAmount");
                                    }} />
                                    <div className={`error tooltip-custom-up-field ${data?.amount !== 0 && data?.vatAmount !== 0 && data?.amount <= data?.vatAmount ? 'opacity-1' : 'opacity-0'}`} style={{color: '#f56e6e'}}>
                                        {t('validationMessage.vatLessAmount')}
                                    </div>
                                </Col>
                                : ''} */}
                            <Col lg={12}>
                                <CustomInput model={RequestSwedbankPay} attribute='description' data={data?.description} customChange={(e) => {
                                    onChange(e, "description");
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-end">
                    <Col className="text-end col-12 d-flex justify-content-end align-items-stretch">
                        {displaySpinner === true ?
                            <>
                                <span className="me-2">{t('validationMessage.sendingPay')}</span>
                                <Spinner color='success' className="me-3"/>
                            </>
                        : ""}
                        <CustomSubmitButton text={t('button.sendAndCreateCopy')} icon = "bx bx-copy pe-2" id='submitCopy' customClasses="ms-3 p-1 bg-green px-3 px-lg-4"  customClick={async () => {onValidateCopy()}} />
                        <CustomSubmitButton text={t('button.send')}  id='submit' icon='bx bx-send pe-2' customClass="ms-3 p-1 bg-green px-3 px-lg-4" customClick={async () => {
                            onValidate();
                        }} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}