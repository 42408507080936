import i18n from "../../../AppSystem/i18n";
import * as yup from "yup";
import {string} from "yup";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";


class RequestSwedbankPay {
    constructor({  
                    amount = 0,
                    vatAmount = 0,
                    description = '',
                    email = '',
                    costBearerId = '',
                    accountingId = '',
                } = {}) {
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.description = description;
        this.email = email;
        this.costBearerId = costBearerId;
        this.accountingId = accountingId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null, customField = null) {

        const selectOption = i18n.t('formPlaceholder.selectOption');
        const description = i18n.t('modelLabel.description');
        const email = i18n.t('modelLabel.email');
        const greaterO = i18n.t('validationMessage.greater0');
        const canNotBeEmpty = i18n.t('validationMessage.canNotBeEmpty');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        let options = [];
        if (optionsData && optionsData.length > 0 ) {
            optionsData.map(item => {
                if (item.name && item.name !== "Övrigt utlägg") {
                    options.push({
                        label: item.name,
                        value: item.id
                    });
                }
            });
            const otherPosition = optionsData.findIndex(item => item.name === "Övrigt utlägg");
            if (otherPosition !== -1) {
                options.push({
                    label: optionsData[otherPosition].name,
                    value: optionsData[otherPosition].id
                });
            }
        }

        // CUSTOM FIELDS
        let customAttribute = null;
        let attributeName = null;

        // SCHEMA
        const completeSchema = yup.object().shape({
            costBearerId: string().required(fieldRequired),
            accountingId: string().required(fieldRequired),
            clearingNumber: string().nullable().test("is-not-empty", `${canNotBeEmpty}`, value => {
                return value !== "";
            }),
            accountNumber: string().nullable().test("is-not-empty", `${canNotBeEmpty}`, value => {
                return value !== "";
            }),         
            description: string().required(fieldRequired),
            email: string().required(fieldRequired).test('is-valid-email', i18n.t('validationMessage.invalidEmail'), value => {
                if (!value) return false;
                const emails = value.split(';').map(email => email.trim()).filter(email => email);
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emails.every(email => emailRegex.test(email));
            }),
            amount: string().required(fieldRequired).test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            vatAmount: string().nullable().test('vatAmount', `${greaterO}`,
                function (value) {
                    if (value !== '' || value <= 0) return true;
                    const numericValue = parseFloat(value.toString().replace(',', '.'));
                    return !isNaN(numericValue) && numericValue < 0;
                }
            )
        });

        const attributes =  { 
            amount: {
                'label': i18n.t('reimbursement.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterVatAmount'),
                    'validation': completeSchema.fields.vatAmount,
                },
                'value': (item) => item.vatAmount || "",
            },  
            description: {
                'label': description,
                'form': {
                    'type': 'textarea',
                    'rows': 4,
                    'placeholder': description,
                    'validation': completeSchema.fields.description,
                },
                'value': (item) => item.description || "",
            },
            email: {
                'label': email,
                'form': {
                    'type': 'text',
                    'placeholder': email,
                    'validation': completeSchema.fields.email,
                },
                'value': (item) => item.email || "",
            }, 
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options': options,
                    'default':'',
                    'placeholder': selectOption,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            accountingId :{
                'label': i18n.t('modelLabel.accounting'),
                'form': {
                    'type': 'select',
                    'options':options,
                    'default':'',
                    'placeholder': selectOption,
                    'validation': completeSchema.fields.accountingId,

                },
                'value': (item) => item.accountingId || "",
            }      
        };

        if (customField && customAttribute && attributeName) {
            attributes[attributeName] = customAttribute;
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}

export default RequestSwedbankPay;