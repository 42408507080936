import {Card, Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import React from "react";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function CardDigitalAccount({data}) {
    const { t } = useTranslation();

    return(
        <>
            <Card className='py-3 px-4'> {/*card-backend*/}
                {data !== null &&  data.id !== '' ?
                    <>
                        <Row>
                            <Col lg={12} className="fs-5 questrial-regular text-grey-custom pb-2">
                                <span className="fw-bold"> {data.orgName} </span>
                                <span>({data.orgType})</span>

                            </Col>
                           {/* <Col lg={4} className="text-end font-Space-Grotesk text-green fw-bold">
                                <span className='text-grey-custom pe-1'>{t('modelLabel.createdAt')}: </span>
                                {ChangeDateFormat('date', data.createdAt)}
                            </Col>*/}
                        </Row>
                        <Row>
                            <Col lg={12} className="py-3 font-Space-Grotesk ">
                                <span className={`fs-2 fw-bold ${data.balance >= 0 ? 'text-green' : 'text-red'}`}> {data.balance ? ToCustomDecimal(data.balance) : 0} kr </span>
                            </Col>
                        </Row>
                       {/* <Row>
                            <Col lg={6} className="questrial-regular text-grey-custom fw-bold">
                                {t('digitalAccount.label')} {data.currency}
                            </Col>
                            <Col lg={6} className="text-end font-Space-Grotesk text-green fw-bold">
                                <span className='text-grey-custom pe-1'>{t('modelLabel.createdAt')}: </span>
                                {ChangeDateFormat('date', data.createdAt)}
                            </Col>
                        </Row>*/}

                    </>
                    :
                    ''
                }

            </Card>

        </>
    )

}