// CHECK JWT EXPIRED UTC TIME
export function CheckExpired(type, token) {
    let expirationDate;
    if (type === 'jwt') {
      const jwtPayload = JSON.parse(atob(token.split('.')[1]));
      expirationDate = new Date(jwtPayload.exp * 1000);
    } else {
      expirationDate = new Date(token);
    }
    // GET UTC TIME
    let offset = expirationDate.getTimezoneOffset();
    expirationDate.setMinutes(expirationDate.getMinutes() + offset);
    const currentDate = new Date();
    // GET UTC TIME
    offset = currentDate.getTimezoneOffset();
    currentDate.setMinutes(currentDate.getMinutes() + offset);
    if (expirationDate < currentDate) {
      return true;
    }
    return false;
  }
  