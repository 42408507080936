import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { CheckMobile } from "../../../AppSystem/Helpers/CheckMobile";
import { CustomReload } from "../../../AppSystem/Helpers/CustomReload";
import useApiCallService from "../../Helpers/ApiCall";
import {useTranslation} from "react-i18next";

export  function CustomListNotifications() {
    const { t } = useTranslation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [forceUpdateToggle, setForceUpdateToggle] = useState(false);
  const windowWidth = CheckMobile(998);
  const { ApiCall } = useApiCallService();
  const [apiResponse, setApiResponse] = useState(null);

    useEffect(() => {

      const handleApiResponse  = (apiResponse) => {

        setNotifications(apiResponse.notifications.reverse());
        setNotificationCount(apiResponse.count)
      };

        const GetListNotifications = async () => {
          try {
                        
            const apiCallOptions = {
              method: "get",
              url: "/notification?readStatus=2",
            };
    
           ApiCall(apiCallOptions, handleApiResponse);  

          } catch (error) {
            console.log('error', error);
          }
          
        };   
        GetListNotifications();

      }, [forceUpdateToggle]);

      const markAllAsRead = async () => {
        try {
        
          const apiCallOptions = {
            method: "put",
            url: "/notification/mark-all-read",
          };
          
          await ApiCall(apiCallOptions, setApiResponse);  

          CustomReload(); 
        } catch (error) {
          console.log('error', error);
        }
      };

      const markNotificationAsRead = async (notificationId) => {
        try {
    
          const apiCallOptions = {
            method: "put",
            url: `/notification?notificationId=${notificationId}`,
          };
          
          await ApiCall(apiCallOptions, setApiResponse);
           
          setNotifications(prevNotifications =>
            prevNotifications.map(notification =>
              notification.id === notificationId ? { ...notification, read: true } : notification
            )
          );
        
          setForceUpdateToggle(prev => !prev);

          const notificationsReadCount = notifications.filter(notification => notification.read).length;

          if (notificationsReadCount === notifications.length) {
                CustomReload();
            }
        } catch (error) {
          console.log('Error al marcar la notificación como leída:', error);
        }
      };

      const getSeverityColorClass = (severity) => {
        switch (severity) {
          case 'Information':
            return 'bg-white bg-opacity-50';
          case 'Warning':
            return 'bg-warning bg-opacity-50';
          case 'Error':
            return 'bg-danger bg-opacity-50';
          case 'Success':
            return 'bg-success bg-opacity-50';
          default:
            return '';
        }
      };

    return(
        <>
        <Row className={windowWidth ? 'pt-5 mt-5 justify-content-center' : 'pt-4 justify-content-center'}>
            <Col lg="8" className="card-backend p-4">
                <Row>
                    <Col lg={12} className="pb-5 d-flex align-items-center justify-content-between">
                        <h4>{t('userNotifications.notifications')}</h4>
                        <div className="col-auto">
                             <Link to="#" className="small fw-semibold text-decoration-underline" onClick={markAllAsRead}>
                              {' '}
                                 {t('userNotifications.text')}
                             </Link>
                        </div>
                    </Col>             

                        <SimpleBar data-simplebar style={{ maxHeight: '500px' }}>
                          {notifications.map(notification => (
                           <Link to="#" className="text-reset notification-item" key={notification.id}>
                                <div className={`d-flex align-items-center notification-container ${getSeverityColorClass(notification.severity)}`} style={{ borderRadius: '10px' , marginTop: '20px', marginBottom: '20px'}}>
                                    <div className="flex-grow-1">
                                        <h6 className="mb-1">{notification.type}</h6>
                                        <div className="font-size-13 text-muted">
                                            <p className="mb-1">{notification.regarding}</p>     
                                            <p className="mb-1">{notification.text}</p>    
                                            <p className="mb-1">{notification.details.general.details}</p>
                                            <p className="mb-0">
                                               <i className="bx bx-time-five"></i> <span>{notification.createdAt}</span>
                                            </p>
                                        </div>
                                    </div>    
                                    <div className="form-check">
                                        <p className="mb-0">
                                          {notification.isRead ? <i className="bx bxs-check-circle"></i> : <i className="bx bxs-envelope"></i> }
                                        </p>              
                                        <div className="form-check"> 
                                        <style>{`.custom-checkbox-input { border-color: grey; /* Establece el color del borde del checkbox como azul */} `}</style>
                                         {notification.isRead ? null : 
                                             <input
                                                   type="checkbox"
                                                   className="form-check-input rounded-circle custom-checkbox-input"
                                                   id={`notification-checkbox-${notification.id}`}
                                                   onChange={() => markNotificationAsRead(notification.id)}
                                                   title="Mark as read"
                                                />}
                                             <label className="form-check-label" htmlFor={`notification-checkbox-${notification.id}`}> </label>
                                        </div>
                                    </div>       
                                </div>
                            </Link>
                            ))}
                        </SimpleBar>

                    <Col lg={12}>
                        
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
   );
  }