class AdminResponseAuthorisationSignBasketModel {
    constructor({
                    authorisationId = '',
                    scaStatus = '',
                } = {}) {
        this.authorisationId = authorisationId;
        this.scaStatus = scaStatus;

    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            authorisationId :{
                //'label': 'authorisation Id',
                'sortable': true,
                'sortField': 'authorisationId',
                'value': (item) => item.authorisationId || "",
            },
            scaStatus :{
                //'label': 'scaStatus',
                'sortable': true,
                'sortField': 'scaStatus',
                'value': (item) => item.scaStatus || "",
            },
        }
        return attributes;
    }
}
export default AdminResponseAuthorisationSignBasketModel;