import {Card, Col, Row, Container, CardBody, Table} from "reactstrap";
import useApiCallService from "../Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import {ChangeDateFormat} from "../Helpers/ChangeDateFormat";
import {useTranslation} from "react-i18next";
import {UserProfileHeader} from "../Components/User/UserProfileHeader";
import {UserSystemRoles} from "../Components/User/UserSystemRoles";
import {UserOrgRoles} from "../Components/User/UserOrgRoles";

export const Profile = () => {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const [apiResponse, setApiResponse] = useState(null);
    const [apiRoleInfoResponse, setApiRoleInfoResponse] = useState(null);
    const [apiOrgRoleInfoResponse, setApiOrgRoleInfoResponse] = useState(null);

    useEffect(() => {
        const handleApiResponse  = (apiResponse) => {
            setApiResponse(apiResponse);
        };
        const UserDataApi = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/user",
                };
                ApiCall(apiCallOptions, handleApiResponse );
            } catch (error) {
                console.log('error', error);
            }
        };
        UserDataApi();
    }, []);

    //API CALL SYSTEM ROLE
    useEffect(() => {
        const handleApiRoleInfoResponse  = (apiRoleInfoResponse) => {
            setApiRoleInfoResponse(apiRoleInfoResponse);
        };
        const UserRoleInfoApi = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/user/role/list",
                };
                ApiCall(apiCallOptions, handleApiRoleInfoResponse );
            } catch (error) {
                console.log('error', error);
            }
        };
        UserRoleInfoApi();
    }, []);

    //API CALL ORGANIZATION ROLE
    useEffect(() => {
        const handleApiOrgRoleInfoResponse  = (apiOrgRoleInfoResponse) => {
            setApiOrgRoleInfoResponse(apiOrgRoleInfoResponse);
        };
        const UserOrgRoleInfoApi = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    //url: "/user/role/org/list",
                    url:"/user/role/org/list/all",
                };
                ApiCall(apiCallOptions, handleApiOrgRoleInfoResponse );
            } catch (error) {
                console.log('error', error);
            }
        };
        UserOrgRoleInfoApi();
    }, []);

    return (
        <>
            {apiResponse ?
                <>
                    <Col lg={12} className="px-0">
                        <UserProfileHeader data={apiResponse} />
                        <Row className='justify-content-center'>
                            <Col lg={12} className='px-4 p-lg-5 padding-top-profile'>
                                <Row>
                                    <Col lg={6}>
                                        {apiRoleInfoResponse ?
                                            <UserSystemRoles data={apiRoleInfoResponse} />
                                        : ''}
                                    </Col>
                                    <Col lg={6}>
                                        {apiOrgRoleInfoResponse ?
                                            <UserOrgRoles data={apiOrgRoleInfoResponse} />
                                        : ''}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </>
                : ''}
        </>
    );
}