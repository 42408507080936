import {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {USER_PATHS} from '../../../config';
import {Link} from "react-router-dom";
import {CheckMobile} from "../../../AppSystem/Helpers/CheckMobile";
import {SaveLocalStorage} from "../../../Common/Helpers/SaveLocalStorage";
import { CheckUserRole } from '../../../AppSystem/Helpers/CheckUserRole';
import {SelectOrganization} from "../../../Common/Components/SelectOrganization/SelectOrganization";
import LogoLRF from '../../../AppSystem/assets/img/Logo-LRF.png'
import {useTranslation} from "react-i18next";
import {CheckUserOrganization} from "../../../AppSystem/Helpers/CheckUserOrganization";
import {LoadLocalStorage} from "../../../Common/Helpers/LoadLocalStorage";


export function VerticalSidebar({/*onMenuClick*/}) {
  const { t } = useTranslation();
  const [isToggle, setIsToggle] = useState(false);
  //const windowWidth = CheckMobile(819);
  const windowWidthPrincipalPage = CheckMobile(1106); //1024
  const [elementHeight , setElementHeight] = useState(0)

  const tToggle = () => {
    const body = document.getElementById('sideBar');

    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
      body.classList.remove('right-bar-enabled');
      body.classList.toggle('vertical-collpsed');
     // document.getElementById('title-breadcrumb').classList.toggle('ps-5')
      document.getElementById('contentId').classList.toggle('ps-custom-content-page')
    } else {
      body.classList.toggle('sidebar-enable');
      body.classList.toggle('vertical-collpsed');
      body.classList.remove('right-bar-enabled');
      body.setAttribute('data-sidebar-size', 'sm');
    }
    setIsToggle(!isToggle);
    setCheckClick(false);
  };
  useEffect(() => {
    if(windowWidthPrincipalPage === true) {
      const body = document.getElementById('sideBar');
      body.classList.add('vertical-collpsed-mobile');
    } else {
        const body = document.getElementById('sideBar');
        body.classList.remove('vertical-collpsed-mobile');
      }

  }, [windowWidthPrincipalPage]);

    // else {
  //   const body = document.getElementById('sideBar');
  //   body.classList.remove('vertical-collpsed-mobile');
  // }
  useEffect(() => {
    if(windowWidthPrincipalPage === true) {
      document.getElementById('sidebar-menu').classList.add('vertical-collpsed-text');
    }else {
      document.getElementById('sidebar-menu').classList.remove('vertical-collpsed-text');
    }

  }, [windowWidthPrincipalPage]);

  // Condition for scroll class
  const screenHeight = 500; //820

  useEffect(() => {
    const initialCheck = () => {
      const sidebarElement = document.getElementById('sidebar-menu-scroll');
      if (sidebarElement) {
        setElementHeight(sidebarElement.offsetHeight)
      }
    }
    initialCheck();
  }, [elementHeight]);

  const isAdmin = CheckUserRole('Admin');
  const [checkClick, setCheckClick] = useState(false)
  const checkUser = CheckUserOrganization();
  const search = LoadLocalStorage("search");
  
  return (
    <>
      <div className="vertical-menu" id="sideBar">
        <div className={`${isToggle === true ? 'p-1 navbar-brand-box-toggle ':'navbar-brand-box '}`}>
          <Link to={USER_PATHS.APP} className="logo text-white" onClick={() => {
              SaveLocalStorage('headerTitle', t("dashboard.dashboard"));
              if (search) {
                localStorage.removeItem('search');
              }
            localStorage.removeItem('currentPage');
            localStorage.removeItem('itemsPerPage');
          }}>
            <span className='d-flex justify-content-center w-100'>
              {!windowWidthPrincipalPage ?
                  <img src={LogoLRF} alt={t('accessibility.logo')} className={`img-fluid ${isToggle === true ? 'size-img-logo-sm': 'size-img-logo'}`}/>

                  :
                  <img src={LogoLRF} alt={t('accessibility.logo')} className={`img-fluid size-img-logo-sm ${isToggle === true ? '' : 'd-none' }`}/>}
              
            </span>
          </Link>
          <div className='bg-sidebar-select d-flex justify-content-center'>
              <li className='dropdown list-unstyled'>
                {isToggle ? <i className=' bx bx-buildings fs-5 p-icon-selectOrg icono-boton' onClick={() => setCheckClick(!checkClick)}></i>:

                    ''}
                {isToggle ?
                    <ul className='metismenu list-unstyled'>
                      <li>
                        <div className={`${checkClick === true ? 'sub-menu-especial-select' : 'd-none'}`}>
                          <SelectOrganization/>
                        </div>
                      </li>
                    </ul>
                    :
                    <ul className='metismenu list-unstyled'>
                      <li>
                        <div className={windowWidthPrincipalPage === true ? 'd-none' : ''}> {/*${checkClick === true ? 'sub-menu-especial-select' : ''} ${isToggle && checkClick === false ? 'd-none': ''}*/}
                          <SelectOrganization/>
                        </div>
                      </li>
                    </ul>
                }
              </li>
          </div>
        </div>
          <button
            type="button"
            className="btn btn-sm px-3 header-item vertical-menu-btn topnav-hamburger "
            onClick={() => {
              tToggle();
              if (isToggle) {
                document.getElementById('page-topbar').classList.remove('header-margin-left');
                document.getElementById('footer').classList.remove('footer-margin-left');
                // document.getElementById('footer').classList.remove('width-footer-toggle');
                // document.getElementById('footer').classList.add('width-footer');
                document.getElementById('contentId').classList.remove('main-content-margin-left')
                if(windowWidthPrincipalPage){
                  document.getElementById('sidebar-menu').classList.remove('vertical-collpsed-text');
                  document.getElementById('sidebar-menu').classList.add('d-none');

                }

              } else {
                document.getElementById('page-topbar').classList.add('header-margin-left');
                document.getElementById('footer').classList.add('footer-margin-left');
                // document.getElementById('footer').classList.add('width-footer-toggle');
                // document.getElementById('footer').classList.remove('width-footer');
                document.getElementById('contentId').classList.add('main-content-margin-left')

                if(windowWidthPrincipalPage){
                  document.getElementById('sidebar-menu').classList.remove('vertical-collpsed-text');
                  document.getElementById('sidebar-menu').classList.remove('d-none');
                }
              }
            }}
          >
            {isToggle === true ? (
              <i className="bx bx-right-arrow-alt text-secondary fs-3" style={{ position: 'absolute', left: 0 }} />
            ) : (
              <div className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </button>
        <div id="sidebar-menu-scroll" className={
          checkUser === true
              ? elementHeight > screenHeight && !isToggle
                  ? 'sidebar-menu-scroll-on position-relative bg-sidebar'
                  : 'sidebar-menu-scroll position-relative bg-sidebar'
              : 'sidebar-menu-scroll-without-org position-relative bg-sidebar'
          }>
          <div className="" id="sidebar-menu">
              <Sidebar toggle={isToggle} activeMarket={false} /* onMenuClick={onMenuClick}*//>
              {isAdmin ? <h6 className="pt-4 text-center">{isToggle === true ? 'S-A' : t('adminLabel.systemAdmin')}</h6> : ''}
          </div>
        </div>
      </div>
    </>
  );
}
