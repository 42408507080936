
import {saveToken, saveRefreshToken, setUser, saveRoles, setOrg} from '../../AppSystem/Redux/Auth/AuthSlice';

// EXAMPLE OF USE : 
//import { SaveRedux } from '../../../Common/Helpers/SaveRedux';
//SaveRedux(dispatch,'refreshTokenData', refreshToken);


// SAVE REDUX
export function SaveRedux(dispatch, reduxName, data) {

  const action = {
    tokenData: saveToken,
    refreshTokenData: saveRefreshToken,
    userData: setUser,
    rolesData: saveRoles,
    orgData: setOrg
  }[reduxName];
  switch (reduxName) {
    case 'tokenData':
      const tokenData = data;
      dispatch(action({ tokenData }));
      break;
    case 'refreshTokenData':
      const refreshTokenData = data;
      dispatch(action({ refreshTokenData }));
      break;
    case 'userData':
      const userData = data;
      dispatch(action({ userData }));
      break;
      case 'orgData':
      const orgData = data;
      dispatch(action({ orgData }));
      break;
    case 'rolesData':
      const rolesData = data;
      dispatch(action({ rolesData }));
      break;
    default:
      break;
  }
}
