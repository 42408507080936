import { LoginWithNeuroIdQR } from '../../Auth/Components/NeuroIdLogin/LoginWithNeuroId';

export  function LoginQR() {
       
  return(          
    <div className="container-fluid p-0 bg-main ">
      <div id="layout-wrapper" className="bg-darkBlue">
       <LoginWithNeuroIdQR />         
      </div>
    </div>
 );
}