import { useSelector } from 'react-redux';

/*
Usage:
CheckUserPermission("admin")
*/

// CHECK USER PERMISSION
export function CheckUserPermission(value) {
    const { rolesData } = useSelector(state => state.auth);
    let hasPermission = false;
    if (rolesData && Array.isArray(rolesData) && rolesData?.length > 0) {
        rolesData.forEach((role) => {
            if (role.permissions && Array.isArray(role.permissions) && role.permissions?.length > 0) {
                if (role.permissions.some((permission) => permission === value)) {
                    // REPLACED IF CONDITION FOR: role.permissions.some((permission) => permission.includes(value))
                    hasPermission = true;
                }
            }
        });
    }
    return hasPermission;
}