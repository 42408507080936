import * as yup from "yup";
import {string} from "yup";
import i18n from "../../../AppSystem/i18n";

class AdminRequestOrgTypeModel {
    constructor({
                    type = '',
                    permissions = [],
                } = {}) {
        this.type = type;
        this.permissions = permissions;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const completeSchema = yup.object().shape({
            type: string().required(i18n.t('formPlaceholder.fieldRequired')),
            //permissions: string().required('Field required'),
        });

        const attributes = {
            type :{
                'label': i18n.t('modelLabel.type'),
                'form': {
                    'type': 'text',
                    'inputType': 'inline',
                    'placeholder': i18n.t('formPlaceholder.enterType'),
                    'validation': completeSchema.fields.type,
                },
                'value': (item) => item.type || "",
            },
            // permissions :{
            //     'label': 'Permissions',
            //     'form': {
            //         'type': 'text',
            //         'inputType': 'inline',
            //         'placeholder': 'Enter permission',
            //         'validation': completeSchema.Fields.permissions,
            //     },
            //     'value': (item) => item.permissions || "",
            // },

        }

        if (validationSchema === true) {
            return completeSchema;
        }
        return attributes;
    }
}
export default AdminRequestOrgTypeModel;