import {useNavigate} from "react-router-dom";
import React from "react";
import {Button, Col, Row} from "reactstrap";

export function TabMenu({data, onClick}) {
    const navigate = useNavigate()
    // const [activeButton, setActiveButton] = useState(1);
    // const handleButtonClick = (buttonId) => {
    //     setActiveButton(buttonId);
    // };
    // const handleButtonRoute = (route) => {
    //     navigate(route);
    // };
   // console.log('buttonId', data?.buttonId)
    return(
        <>
            <Col xs={12}>
                <Row className='mx-0'>
                    <Col xs={4} className="d-flex justify-content-center px-0 ">
                        <Button className={`${data?.buttonId === 1 ? 'active-button' : ''} w-100 rounded-0 py-3 bg-white text-muted fw-bold border-0 `}
                                onClick={() =>{
                                    // setTimeout(()=>{
                                    //     handleButtonClick(1)
                                    // },300)
                                    onClick('firstStep');
                                    // handleButtonRoute(USER_PATHS.PAY_INVOICE);
                                }}>
                            {data?.label[0]}
                        </Button>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-center px-0 ">
                        <Button className={`${data?.buttonId === 2 ? 'active-button' : ''} w-100 rounded-0 py-3 bg-white text-muted fw-bold border-0 `}
                                onClick={() => {
                                    onClick('secondStep');
                                    // setTimeout(()=>{
                                    //     handleButtonClick(2)
                                    // },300)
                                    //handleButtonRoute(USER_PATHS.PAY_EXPENSES);
                                }}>
                            {data?.label[1]}
                        </Button>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-center px-0">
                        <Button className={`${data?.buttonId === 3 ? 'active-button' : ''} w-100 rounded-0 py-3 bg-white text-muted fw-bold border-0 `}
                                onClick={() =>{
                                    onClick('thirdStep');
                                    // handleButtonClick(3)
                                    // handleButtonRoute(USER_PATHS.PAY_DIRECT_TRANSMISSION);
                                }
                                }>
                            {data?.label[2]}
                        </Button>
                    </Col>
                </Row>

            </Col>

        </>
    );

}