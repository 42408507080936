class AdminRequestAuthorisationSignBasketModel {
    constructor({
                    openPaymentId= '',

                } = {}) {
        this.openPaymentId = openPaymentId;

    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            openPaymentId :{
                //'label': 'openPaymentId',
                //'sortable': true,
                //'sortField': 'openPaymentId',
                'value': (item) => item.openPaymentId || "",
            },
        }
        return attributes;
    }
}
export default AdminRequestAuthorisationSignBasketModel;