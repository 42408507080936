import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";
import {StringToLowerCaseFirst} from "../../../Common/Helpers/StringToLowerCaseFirst";

class RequestReimbursementExpense {
    constructor({
                    id = "",
                    orgId = "",
                    date = '',
                    purpose = '',
                    costBearerId = '',
                    fileId = '',
                    fileExtension = '',
                    fileName = '',
                    dateReceive = '',
                    amount = 0,
                    vatAmount = 0,
                    accountingId = '',
                    additionalInfo = {},
                    additionalFileExtension = '',
                    additionalFileName = '',
                    status = "",
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.date = date;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.fileId = fileId;
        this.fileExtension = fileExtension;
        this.fileName = fileName;
        this.dateReceive = dateReceive;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.accountingId = accountingId;
        this.additionalInfo = additionalInfo;
        this.additionalFileExtension = additionalFileExtension;
        this.additionalFileName = additionalFileName;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null ,optionsData = null, customField = null) {
        const date = i18n.t('modelLabel.date');
        const purpose = i18n.t('reimbursement.purpose');
        const fileIdPlaceholder = i18n.t('formPlaceholder.dragAndDrop');
        const greaterO = i18n.t('validationMessage.greater0');

        let optionsAccounting = [];
        if (optionsData) {
            optionsData.accounting.map(item => {
                if (item.name !== "Övriga externa kostnader, avdragsgilla") {
                    optionsAccounting.push({
                        label: item.showLabel ?? item.name,
                        value: item.id
                    });
                }
            });
            const otherPosition = optionsData.accounting.findIndex(item => item.name === "Övriga externa kostnader, avdragsgilla");
            if (otherPosition !== -1) {
                optionsAccounting.push({
                    label: optionsData.accounting[otherPosition].showLabel ?? optionsData.accounting[otherPosition].name,
                    value: optionsData.accounting[otherPosition].id
                });
            }
        }

        let customAttribute = null;
        let attributeName = null;

        if (customField) {
            customAttribute = {
                'label': customField.label,
                'form': {
                    'type': StringToLowerCaseFirst(customField.inputType),
                    'placeholder': customField.inputType === 'File' ? fileIdPlaceholder : '',
                    'buttonView': false,
                },
                'id': StringToLowerCaseFirst(customField.requestName),
            };
            attributeName = StringToLowerCaseFirst(customField.requestName);
        }

        const completeSchema = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            date: string().nullable(),
            purpose: string().nullable(),
            costBearerId: string().nullable(),
            fileId: string().nullable().test("is-required", `${i18n.t('validationMessage.required')}`, value => {
                return value !== "";
            }),
            dateReceive: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.canNotBeEmpty')}`, value => {
                return value !== "";
            }),
            amount: string().nullable().test('is-positive', `${greaterO}`, value => {
                 const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            vatAmount: string().nullable().test('vatAmount', `${greaterO}`,
                function (value) {
                    if (value !== '' || value <= 0) return true;
                    const numericValue = parseFloat(value.toString().replace(',', '.'));
                    return !isNaN(numericValue) && numericValue < 0;
                }
            ),
            accountingId: string().test("valid-option", `${i18n.t('validationMessage.validOption')}`, value => {
                return value !== "";
            }),
            status: string().nullable(),
        });

        const attributes = {
            id :{
                'label': "Id",
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.id,
                },
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            date :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.date || "",
            },
            purpose :{
                'label': purpose,
                'form': {
                    'type': 'text',
                    'placeholder': purpose,
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    //'placeholder': selectOptionPlaceholder,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            fileId :{
                'label': i18n.t('reimbursement.addReceipts'),
                'form': {
                    'type':'file',
                    'placeholder': fileIdPlaceholder,
                    'buttonView': false,
                    'validation': completeSchema.fields.fileId,
                },
                'value': (item) => item.fileId || "",
            },
            dateReceive :{
                'label': i18n.t('modelLabel.dateReceive'),
                'form': {
                    'type': 'date',
                    'placeholder': i18n.t('formPlaceholder.enterDate'),
                    'validation': completeSchema.fields.dateReceive,
                    "maxDate": new Date(),
                },
                'value': (item) => item.dateReceive || "",
            },
            amount :{
                'label': i18n.t('reimbursement.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterVatAmount'),
                    'validation': completeSchema.fields.vatAmount,
                },
                'value': (item) => item.vatAmount || "",
            },
            accountingId :{
                'label': i18n.t('modelLabel.accounting'),
                'form': {
                    'type': 'select',
                    'options':optionsAccounting,
                    'default':'',
                    //'placeholder': selectOptionPlaceholder,
                    'validation': completeSchema.fields.accountingId,

                },
                'value': (item) => item.accountingId || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.status,
                },
                'value': (item) => item.status || "",
            },
            // additionalInfo :{
            //     'label': 'Additional Info',
            //     'sortable': false,
            //     'filter': false,
            //     'id':'additionalInfo',
            //     'name':'additionalInfo',
            //     'value': (item) => item.additionalInfo || "",
            // },
        }

        if (customField && customAttribute && attributeName) {
            attributes[attributeName] = customAttribute;
        }
        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestReimbursementExpense;