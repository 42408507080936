import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";

class RequestReimbursementMileage {
    constructor({
                    id = "",
                    orgId = "",
                    date = '',
                    purpose = '',
                    costBearerId = '',
                    from = '',
                    to = '',
                    km = 0,
                    amount = 0,
                    passengers = [],
                    totalAmount = 0,
                    status = "",
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.date = date;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.from = from;
        this.to = to;
        this.km = km;
        this.amount = amount;
        this.passengers = passengers;
        this.totalAmount = totalAmount;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const date = i18n.t('modelLabel.date');
        const purpose = i18n.t('reimbursement.purpose');
        const costBearerId = i18n.t('reimbursement.costBearer');
        const canNotBeEmpty = i18n.t('validationMessage.canNotBeEmpty');
        const greaterO = i18n.t('validationMessage.greater0');

        let optionsCostBearer = [];
        if (optionsData) {
            optionsData.costBearer.map(item => {
                optionsCostBearer.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const completeSchema = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            date: string().nullable(),
            purpose: string().nullable(),
            costBearerId: string().nullable(),
            from: string().nullable().test("is-not-empty", `${canNotBeEmpty}`, value => {
                return value !== "";
            }),
            to: string().nullable().test("is-not-empty", `${canNotBeEmpty}`, value => {
                return value !== "";
            }),
            km: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            amount: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
           // passengers:yup.array().optional('invalid value'),
            totalAmount: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            status: string().nullable(),
        });

        const attributes = {
            id :{
                'label': "Id",
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.id,
                },
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            date :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.date || "",
            },
            purpose :{
                'label': purpose,
                'form': {
                    'type': 'text',
                    'placeholder': purpose,
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': costBearerId,
                'form': {
                    'type': 'select',
                    'options':optionsCostBearer,
                    'placeholder': costBearerId,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.purpose || "",
            },
            from :{
                'label': i18n.t('reimbursement.origin'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterOrigin'),
                    'validation': completeSchema.fields.from,
                },
                'value': (item) => item.from || "",
            },
            to :{
                'label': i18n.t('reimbursement.destination'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterDestination'),
                    'validation': completeSchema.fields.to,
                },
                'value': (item) => item.to || "",
            },
            km :{
                'label': i18n.t('modelLabel.kilometers'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterKm'),
                    'validation': completeSchema.fields.km,
                },
                'value': (item) => item.km || "",
            },
            amount :{
                'label': i18n.t('modelLabel.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            passengers :{
                'label': i18n.t('reimbursement.passengers'),
                'form': {
                    'type': 'text',
                    'placeholder': i18n.t('formPlaceholder.enterPassengers'),
                    //'validation': completeSchema.Fields.passengers,
                },
                'value': (item) => item.passengers || "",
            },
            totalAmount :{
                'label': i18n.t('modelLabel.totalAmount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterTotalAmount'),
                    'validation': completeSchema.fields.totalAmount,
                },
                'value': (item) => item.totalAmount || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.status,
                },
                'value': (item) => item.status || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestReimbursementMileage;