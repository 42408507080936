export function CalculateTax(pnr) {
  // PASSING STRING TO A DATE
  const birthYear = parseInt(pnr.substring(0, 4));
  // TAX PERCENTAGE
  let taxPercent = 0.3142;
  // 0 FOR PEOPLE BORN BEFORE 1937
  if (birthYear <= 1937) {
    taxPercent = 0;
    return taxPercent
  }
  const previousYear = new Date().getFullYear() - 1;
  const ageLastYear = previousYear - birthYear;
  // 10 FOR PEOPLE THAT BECAME AT LEAST 66 YEARS OLD ANY TIME LAST YEAR
  if (ageLastYear >= 66) {
    taxPercent = 0.1021;
    return taxPercent
  }
  return taxPercent;
}