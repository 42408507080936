import {  Card, Col, Row } from 'reactstrap';
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.png"
import Check from "../../AppSystem/assets/img/swedbank/check.png"
import { useTranslation } from 'react-i18next';

const CheckoutCompleted = () => {
  
    
  return (
<Row className='d-flex justify-content-center pt-5'>
  <Col lg={9} >
    <Card className="p-3">
      <Row className='d-flex justify-content-center px-4'>
          <Col lg={6} className='d-flex justify-content-center pt-5'>
          <img src={LogoLRF} />
          </Col>
          <Col lg={12} className='d-flex justify-content-center pt-5'>
              <h2>Betalningsflödet har slutförts.</h2>
          </Col>
          <Col lg={6} className='d-flex justify-content-center pt-5'>
          
          
          </Col>
      </Row>
    
  </Card>
  </Col>
</Row>
  );
};

export default CheckoutCompleted;