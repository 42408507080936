import * as yup from "yup";
import {bool, string} from "yup";
import i18n from 'i18next';

class RequestQRPayment{
    constructor({
                    id= '',
                    amount = 0,
                    comment = '',
                } = {}) {
        this.id = id;
        this.amount = amount;
        this.comment = comment;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            //amount: string().required(fieldRequired),
            comment: string().nullable(),
        });

        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            amount :{
                'label': i18n.t('QRCode.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            comment :{
                'label': i18n.t('modelLabel.comment'),
                'form': {
                    'type': 'textarea',
                    'rows': 5,
                    'placeholder': i18n.t('formPlaceholder.enterComment'),
                    'validation': completeSchema.fields.comment,
                },
                'value': (item) => item.comment || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestQRPayment;