import { param } from "jquery";

export function UrlStructure({params, filter, url}) {
    if (params && (Object.keys(params).length !== 0 || (typeof params === "string" && params !== ""))) {
        const match = params.match(/searchCriteria=([^&]*)/);
        if (match !== null) {
            let finalSearch = params.replace(/searchCriteria=[^&]*/, '');
            if (finalSearch.charAt(0) === "&") {
                finalSearch = finalSearch.substring(1);
            }
            if (finalSearch.charAt(finalSearch.length -1) === "&") {
                finalSearch = finalSearch.slice(0, -1);
            }
            params = finalSearch;
        }
        if (params.charAt(0) === "&") {
            url += `${params}`;      
        } else {            
            const match = url.match(/sortField=([^&]*)&sort=([^&]*)/);
            
            if(match !== null){
                const newParams = url.replace(/sortField=([^&]*)&sort=([^&]*)/,params);
                url = `${newParams}`
            }else{
                url += `&${params}`;
            }
        }
        
    }
    if (filter && typeof filter === "string" && filter !== "searchCriteria=" && filter !== "") {
        if (filter.charAt(0) === "&") {
            url += `${filter}`;
        } else {
            url += `&${filter}`;
        }
    }
    if (url && url.charAt(url.length -1) === "&") {
        url = url.slice(0, -1);
    }
    return url;
}