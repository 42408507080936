class AdminRequestCreateBasketModel {
    constructor({
                    paymentsCollectionIds=[],

                } = {}) {
        this.paymentsCollectionIds = paymentsCollectionIds;

    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const attributes = {
            paymentsCollectionIds :{
                //'label': 'paymentsCollectionIds',
                //'sortable': true,
                //'sortField': 'paymentsCollectionIds',
                'value': (item) => item.paymentsCollectionIds || "",
            },
        }
        return attributes;
    }
}
export default AdminRequestCreateBasketModel;