import {
    Accordion,
    AccordionItem, Button,
    Col,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementFixed from "../Models/Request/RequestReimbursementFixed";
import React, {useEffect, useRef, useState} from "react";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function ReimbursementFixed({ data, onChange, onValidate, onCancel, editMode = false }) {
    const { t } = useTranslation()
    // CANCEL STATE
    const [cancelModel, setCancelModel] = useState(data);

    // STATES
    const [accordionStatus, setAccordionStatus] = useState('1');
    const [activeTab, setActiveTab] = useState("1");
    const [localDisplay, setLocalDisplay] = useState(false);

    //REF
    const formDivRefFixed = useRef(null);

    // TOGGLE ACCORDION
    const ToggleAccordion = (id) => {
        if (accordionStatus === id) {
            setAccordionStatus();
        } else {
            setAccordionStatus(id);
        }
    };

    // CANCEL MODEL
    useEffect(() => {
        if (editMode === true) {
            const copyModel = _.cloneDeep(data);
            setCancelModel(copyModel);
            if (copyModel.type === 'Fixed') {
                setActiveTab('2');
            } else {
                setActiveTab('1');
            }
            setLocalDisplay(true);
        }
    }, []);

    // DESIGN
    const HandleToggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return(
        <>
            <Accordion id="accordionBordered" className="" open={accordionStatus} toggle={ToggleAccordion}>
                <AccordionItem className="accordion-grey bg-green mb-4 border-0 rounded-0"  >
                    <h2 className="accordion-header" id="accordionborderedExample1">
                        <button className={classnames(`py-2 pe-1 w-100 accordion-button d-flex justify-content-between ${localDisplay === false ? 'bg-green text-white' : 'accordion-grey text-green' } fw-bold border-bottom-0`, { collapsed: !localDisplay })} type="button" onClick={() => {setLocalDisplay(!localDisplay)}} style={{ cursor: "pointer" }}
                        >
                            <div>
                                {t('reimbursement.addSalary')}
                            </div>
                            {localDisplay === false ? <i className="bx bx-chevron-down text-white fs-3"></i> : <i className="bx bx-chevron-up fs-3"></i>}
                        </button>
                    </h2>
                    <Collapse isOpen={localDisplay} className="accordion-collapse accordion-grey bg-danger border-0" id="accor_borderedExamplecollapse1" >
                        <div className="accordion-body border-0" ref={formDivRefFixed}>
                            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                                <NavItem className="bg-grey-tab ">
                                    <NavLink style={{ cursor: "pointer" }}
                                            className={classnames("text-muted fw-bold",{
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                HandleToggle("1");
                                                onChange("", "hourly");
                                            }}
                                    >
                                        {t('reimbursement.movable')}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="bg-grey-tab ">
                                    <NavLink style={{ cursor: "pointer" }}
                                            className={classnames("text-muted fw-bold", {
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                HandleToggle("2");
                                                onChange("", "fixed");
                                            }}
                                    >
                                        {t('reimbursement.fixed')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent
                                activeTab={activeTab}
                                className="text-muted"
                            >
                                <TabPane tabId="1">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <Row>
                                                <Col /*md={8}*/ lg={7}>
                                                    <CustomInput model={RequestReimbursementFixed} attribute='hours' data={data.hours !== null && data.hours !== 0 ? data.hours : ""} customChange={(e) => {
                                                            onChange(e, "hours");
                                                        }}/>
                                                </Col>
                                                <Col lg={1} className="align-self-end text-center pb-4">
                                                    =
                                                </Col>
                                                <Col /*md={3} */lg={4} className="align-self-end pb-3">
                                                    <div id="divAmountHourly" className='box-beig'>{data.amount !== 0 ? ToCustomDecimal(data.amount) : 0} kr</div>
                                                </Col>
                                                <Col lg={12} className="text-end pt-3">
                                                    {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModel)}>{t('button.cancel')}</Button> : ""}
                                                    <CustomSubmitButton text={editMode === true ?  t('button.save') :  t('reimbursement.addSalary')} customClick={onValidate} formDivRef={formDivRefFixed}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <Row>
                                                <Col lg={12}>
                                                    <CustomInput model={RequestReimbursementFixed} attribute='amount' data={data.amount !== 0 ? data.amount : ""}  customChange={(e) => onChange(e, "amount")}/>
                                                </Col>
                                                <Col lg={12} className="text-end pt-3">
                                                    {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModel)}>{t('button.cancel')}</Button> : ""}
                                                    <CustomSubmitButton text={editMode === true ? t('button.save') :  t('reimbursement.addSalary')} customClick={onValidate} formDivRef={formDivRefFixed}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>
        </>
    );
}