import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { persistReducer } from 'reduxjs-toolkit-persist';
import rootReducer from './Reducers';
import {REDUX_PERSIST_KEY} from "../../config";

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistedStore = persistStore(store);
export { store, persistedStore };
